import React from "react";
import { useSearchParams, useParams } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar';
import Service from '../../../../../services';
import "./index.scss";

const withRouter = (WrappedComponent) => {
    return (props) => {
        const [searchParams] = useSearchParams();
        const params = Object.fromEntries(searchParams.entries());
        const urlParams = useParams();
        return <WrappedComponent {...props} searchParams={params} urlParams={urlParams} />;
    };
};

class ExamResultsDownload extends React.Component {
    constructor(props) {
        super(props);
        const { searchParams } = this.props;

        try {
            const studentData = JSON.parse(searchParams.studentData || '{}');
            const examId = JSON.parse(searchParams.examId || '""');
            const userId = JSON.parse(searchParams.userId || '""');

            this.state = {
                examId,
                userId,
                studentData,
                isLoading: false
            };
        } catch (error) {
            console.error('Error parsing search params:', error);
            this.state = {
                examId: '',
                userId: '',
                studentData: {},
                isLoading: false,
                error: error.message
            };
        }
        this.service = new Service();
    }

    componentDidMount() {

    }


    renderStudentOverview = () => {
        const { studentData } = this.state;
        const { studentAnalytics, examAnalytics } = studentData;

        return (
            <div className="student-overview">
                <div className="student-header">
                    <h3>{studentAnalytics.studentName}'s Performance Report</h3>
                </div>
                <div className="stats-grid">
                    <div className="stat-card">
                        <h4>Total Marks</h4>
                        <p>{studentAnalytics.totalMarks}</p>
                        <div className="stat-details">
                            <small>Class Average: {examAnalytics.averageMarks.toFixed(2)}</small>
                            <small>Highest Score: {examAnalytics.highestMarks}</small>
                        </div>
                    </div>
                    <div className="stat-card">
                        <h4>Rank</h4>
                        <p>{studentAnalytics.rank}</p>
                        <small>Out of {examAnalytics.totalStudents}</small>
                    </div>
                    <div className="stat-card">
                        <h4>Percentile</h4>
                        <p>{studentAnalytics.percentile}%</p>
                    </div>
                    <div className="stat-card">
                        <h4>Accuracy</h4>
                        <p>{studentAnalytics.accuracy}%</p>
                    </div>
                </div>
            </div>
        );
    }

    renderSubjectWiseComparison = () => {
        const { studentData } = this.state;
        const { studentAnalytics } = studentData;

        return (
            <div className="subject-comparison">
                <h3>Subject-wise Analysis</h3>
                <div className="subject-charts">
                    {studentAnalytics.subjectWisePerformance.map(subject => (
                        <div key={subject.subjectName} className="subject-card">
                            <h4>{subject.subjectName}</h4>
                            <div className="chart-container">
                                <ResponsiveBar
                                    data={[
                                        {
                                            type: "Your Score",
                                            value: parseFloat(subject.marks)
                                        },
                                        {
                                            type: "Class Average",
                                            value: subject.classAverage
                                        },
                                        {
                                            type: "Highest Score",
                                            value: subject.highestScore
                                        }
                                    ]}
                                    keys={['value']}
                                    indexBy="type"
                                    margin={{ top: 20, right: 20, bottom: 150, left: 60 }}
                                    padding={0.3}
                                    layout="vertical"
                                    height={400}
                                    colors={d => parseFloat(d.value) >= 0 ? '#88C0D0' : '#BF616A'}
                                    minValue={Math.min(-5, Math.floor(subject.marks))}
                                    maxValue={Math.max(5, Math.ceil(subject.highestScore))}
                                    enableGridX={true}
                                    gridXValues={[0]}
                                    markers={[
                                        {
                                            axis: 'y',
                                            value: 0,
                                            lineStyle: { stroke: '#b0b0b0', strokeWidth: 1 }
                                        }
                                    ]}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Marks',
                                        legendPosition: 'middle',
                                        legendOffset: -40
                                    }}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 10,
                                        tickRotation: -30,
                                        legend: '',
                                        legendPosition: 'middle',
                                        legendOffset: 40
                                    }}
                                    enableLabel={true}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    animate={false}
                                />
                            </div>
                            <div className="subject-stats">
                                <p>Accuracy: {subject.accuracy}%</p>
                                <p>Correct: {subject.correct}</p>
                                <p>Incorrect: {subject.incorrect}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderPerformanceCharts = () => {
        const { studentData } = this.state;
        const { examAnalytics } = studentData;

        return (
            <div className="performance-charts">
                <div className="marks-distribution">
                    <h3>Marks Distribution</h3>
                    <div className="chart-wrapper">
                        <ResponsiveBar
                            data={Object.entries(examAnalytics.marksDistribution).map(([range, count]) => ({
                                range,
                                count
                            }))}
                            keys={['count']}
                            indexBy="range"
                            margin={{ top: 30, right: 30, bottom: 40, left: 40 }}
                            padding={0.3}
                            height={300}
                            layout="vertical"
                            colors={{ scheme: 'nivo' }}
                            responsive={true}
                            useMesh={true}
                            animate={false}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { studentData, error } = this.state;

        // Add debug log
        console.log('Render State:', {
            hasStudentData: !!studentData,
            hasAnalytics: !!studentData?.studentAnalytics,
            studentData,
            error
        });

        if (error) {
            return <div>Error loading data: {error}</div>;
        }

        if (!studentData || !studentData.studentAnalytics) {
            return (
                <div>
                    <p>No data available</p>
                    <p>Debug info:</p>
                    <pre>{JSON.stringify(this.props.searchParams, null, 2)}</pre>
                </div>
            );
        }

        return (
            <div className="student-report-container">
                {this.renderStudentOverview()}
                {this.renderSubjectWiseComparison()}
                {this.renderPerformanceCharts()}
            </div>
        );
    }
}

export default withRouter(ExamResultsDownload);

