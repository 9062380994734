import React, { Component } from 'react';
import { connect } from 'react-redux';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { getFormFields } from '../../utile/formHelper';
import Authentication from '../../session';
import { baseUrlAdmin } from '../../store/apiConstants';
import withRouter from '../lib/withRouter';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { AcademicSessionCalIcon, ClassetEditIcon, PlainCalenderIcon } from '../svgIcons';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetCalendar from '../../classetComponents/classetCalender';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import './styles.scss';
import { getBranchesLatest } from '../../store/actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BasicLazyParams, convertUtc } from '../../utile';
import uuidv4 from 'uuid/v4';
import { Tooltip } from 'chart.js';

const createFeeInstallmentFields = require('./feeInstallment.json');

class FeeInstallments extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFeeInstallmentFields);
        this.state = {
            currentTab: 0,
            term: this.formFields.data,
            formValidations: this.formFields.formValidations,
            IsDailogBoxopenForYearChange: false,
            isLoading: false,
            termsData: [],
            selectedBranchId: this.props.branchData.length > 0 ? this.props.branchData[0].key : '',
            editTerm: {},
            lazyParams: BasicLazyParams,
            isDialogOpenForCreateTerm: false,
            planName: '',
            numberOfInstallments: 1,
            installmentDetails: Array.from({ length: 1 }, (_, i) => ({
                name: `Installment ${i + 1}`,
                date: undefined
            })),
            isDialogOpenForViewTerm: false,
            viewTerm: {}
        };
        this.service = new Service();
    }
    handleViewRequest = (term) => {
        this.setState({
            viewTerm: term,
            isDialogOpenForViewTerm: true
        });
    };
    handleInstallmentChange = (e) => {
        const newNumber = e.value;
        this.setState(prevState => ({
            numberOfInstallments: newNumber,
            installmentDetails: Array.from({ length: newNumber }, (_, i) => ({
                ...(prevState.installmentDetails[i] || {
                    name: `Installment ${i + 1}`,
                    date: undefined
                })
            }))
        }));
    };

    handleInstallmentDetailChange = (index, field, value) => {
        this.setState(prevState => ({
            installmentDetails: prevState.installmentDetails.map((detail, i) =>
                i === index ? { ...detail, [field]: value } : detail
            )
        }));
    };

    componentDidMount() {
        this.props.getBranchesLatest(true);
        this.getInstallmentsList()
    }

    getInstallmentsList = async () => {
        let { lazyParams, } = this.state;
        const AcademicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        if (this.state.selectedBranchId === '') {
            this.setState({ isLoading: false });
        } else {
            let url = `${baseUrlAdmin}/feeInstallments?academicYear=${AcademicYear}&branchId=${this.state.selectedBranchId}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}`;
            try {
                const res = await this.service.get(url, true);
                if (res?.res?.status && res.status) {
                    this.setState({ termsData: res?.res?.data, totalRecords: res?.res?.totalRecords, isLoading: false });
                } else {
                    const error = 'Some error occurred';
                    this.setState({ error, isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
                }
            } catch (e) {
                console.log(e);
                this.setState({ e, isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
            }
        }

    };

    validateInstallmentDates = (installments) => {
        try {
            for (let i = 0; i < installments.length; i++) {
                if (installments[i].date === null || installments[i].date === undefined) {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: `Please select due date for ${installments[i].name}`, life: 3000 });
                    return false; // Return false if any date is missing
                }
                if (i > 0) {
                    const currentDate = new Date(installments[i].date);
                    const previousDate = new Date(installments[i - 1].date);

                    // Check if dates are valid
                    if (isNaN(currentDate.getTime()) || isNaN(previousDate.getTime())) {
                        console.error('Invalid date format detected');
                        return false;
                    }

                    // Check if dates are in ascending order
                    if (currentDate <= previousDate) {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: `Invalid order: ${installments[i].name} date is not after ${installments[i - 1].name}`, life: 3000 });
                        console.error(`Invalid order: ${installments[i].name} date is not after ${installments[i - 1].name}`);
                        return false;
                    }

                    // Optional: Check if there's at least one day difference
                    const oneDayInMs = 24 * 60 * 60 * 1000;
                    if (currentDate.getTime() - previousDate.getTime() < oneDayInMs) {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: `${installments[i].name} must be at least one day after ${installments[i - 1].name}`, life: 3000 });
                        console.error(`${installments[i].name} must be at least one day after ${installments[i - 1].name}`);
                        return false;
                    }
                }
            }
            return true; // Return true if all validations pass
        } catch (error) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Error validating dates', life: 3000 });
            console.error('Error validating dates:', error);
            return false;
        }
    }

    onCreateTerm = async () => {
        const { planName, numberOfInstallments, installmentDetails, editTerm } = this.state;
        try {

            if (planName === '') {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter plan name', life: 3000 });
                return;
            }
            if (numberOfInstallments === 0 || numberOfInstallments === null || numberOfInstallments === undefined) {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please select number of installments', life: 3000 });
                return;
            }
            let flag = this.validateInstallmentDates(installmentDetails);
            if (!flag) {
                return;
            } else {
                const AcademicYear = localStorage.getItem('userAcademicYear');
                const branchId = this.state.selectedBranchId;
                const data = {
                    academicYear: AcademicYear,
                    branchId,
                    planName: planName,
                    numberOfInstallments,
                    installments: installmentDetails.map(({ name, date }) => ({
                        installmentId: uuidv4(),
                        name,
                        date
                    }))
                };
                this.setState({ isLoading: true });

                if (editTerm._id) {
                    data._id = editTerm._id;
                    const res = await this.service.post(`${baseUrlAdmin}/feeInstallments`, data, true);
                    if (res?.res?.status && res.status) {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Fee Plan updated successfully', life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
                    }
                } else {
                    // Create new term
                    const res = await this.service.post(`${baseUrlAdmin}/feeInstallments`, data, true);
                    if (res?.res?.status && res.status) {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Fee Plan created successfully', life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
                    }
                }
                this.setState({ isDialogOpenForCreateTerm: false, term: {}, editTerm: {}, isLoading: false });
                this.getInstallmentsList();
            }
        } catch (e) {
            console.log(e);
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    }

    handleEditRequest = (term) => {
        // if feeplan is assigned in schedule dont give edit option.
        if (term.isUsed) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Fee Plan is assigned in schedule', life: 3000 });
            return;
        } else {
            this.setState({
                editTerm: term,
                isDialogOpenForCreateTerm: true,
                planName: term.planName,
                numberOfInstallments: term.numberOfInstallments,
                installmentDetails: term.installments.map((installment, index) => ({
                    name: installment.name,
                    date: new Date(installment.date) // Ensure date is a Date object
                }))
            });
        }

    };

    onPage = (event) => {
        this.setState((prevState) => ({
            lazyParams: {
                ...prevState.lazyParams,
                ...event
            }
        }), this.getInstallmentsList);
    };
    getValidDate = (dateValue) => {
        if (!dateValue) return null;
        const date = new Date(dateValue);
        return isNaN(date.getTime()) ? null : date;
    };
    render() {
        const { numberOfInstallments, installmentDetails } = this.state;

        return (
            <div style={{ marginTop: '1rem' }}>
                <div style={{ marginLeft: '1.25rem' }}>
                    <div style={{ marginBottom: '110px' }}>
                        <ClassetDropdown
                            required={true}
                            label={'Branch'}
                            icon={<AcademicSessionCalIcon />}
                            placeholder={'Select Branch'}
                            options={this.props.branchData.map((branch) => ({ label: branch.name, value: branch.key })) || []}
                            onChange={(e) => this.setState({ selectedBranchId: e.target.value }, () => this.getInstallmentsList())}
                            value={this.state.selectedBranchId}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ marginTop: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1.5rem', marginRight: '1.5rem' }}>
                                    {this.state.selectedBranchId && <Authorizer permId={PERMISSIONS.CREATE_TERM}>
                                        <Button
                                            label="ADD"
                                            icon={'pi pi-plus-circle text-lg opacity-60'}
                                            className="AddButton px-4 py-2"
                                            onClick={() => {
                                                this.setState({ isDialogOpenForCreateTerm: true, planName: '', numberOfInstallments: 1, installmentDetails: Array.from({ length: 1 }, (_, i) => ({ name: `Installment ${i + 1}`, date: undefined })) });
                                            }}
                                        />
                                    </Authorizer>}
                                </div>
                                <div className="ma-m30">
                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.termsData}
                                        dataKey="id"
                                        paginator
                                        lazy
                                        onPage={this.onPage}
                                        first={this.state.lazyParams?.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fee Types"
                                        globalFilter={this.state.globalFilter}
                                        responsiveLayout="scroll"
                                    >
                                        <Column
                                            header="S.No."
                                            className="datatable-cel"
                                            body={(rowData, index) => <p>{index.rowIndex + 1}</p>}
                                        />
                                        <Column field="planName" header="Plan Name" className="datatable-cel" />
                                        <Column field="numberOfInstallments" header="No of Installments" className="datatable-cel" />
                                        {/* <Column field="" header="Total Assigned Schedule" className="datatable-cel" /> */}


                                        <Column
                                            className=" "
                                            header="Actions"
                                            body={(rowData, { rowIndex }) => {
                                                return (
                                                    <div className="flex cursor-pointer">
                                                        <div onClick={() => this.handleEditRequest(rowData)}>
                                                            <ClassetEditIcon width={28} height={28} color={'black'} />
                                                        </div>
                                                        <div onClick={() => this.handleViewRequest(rowData)} style={{ marginLeft: '10px' }}>
                                                            <i className="pi pi-eye" style={{ fontSize: '1.5rem', color: 'black' }}></i>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        ></Column>

                                    </DataTable>

                                    {this.state.isLoading && <LoadingComponent />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    visible={this.state.isDialogOpenForViewTerm}
                    draggable={false}
                    closeOnEscape={false}
                    style={{
                        width: '90%',
                        maxWidth: '1000px',
                        margin: '0 auto'
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => (
                        <div style={{ padding: '1rem', borderBottom: '1px solid #dee2e6' }}>
                            <h3 style={{ textAlign: 'center', margin: 0, fontSize: '1.5rem', fontWeight: '600' }}>
                                View Fee Plan for {this.state.viewTerm.planName}
                            </h3>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button label="Close" className="confirmDialogCancelBtn px-6" onClick={() => this.setState({ isDialogOpenForViewTerm: false, viewTerm: {} })} />
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForViewTerm: false, viewTerm: {} })}
                >
                    <div style={{ padding: '1.5rem' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Plan Name
                                </label>
                                <p>{this.state.viewTerm.planName}</p>
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Number of Installments
                                </label>
                                <p>{this.state.viewTerm.numberOfInstallments}</p>
                            </div>
                        </div>

                        <div style={{ marginTop: '2rem' }}>
                            {this.state.viewTerm.installments?.map((installment, index) => (
                                <div key={index} style={{
                                    padding: '1rem',
                                    marginBottom: '1rem',
                                    border: '1px solid #dee2e6',
                                    borderRadius: '4px'
                                }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                                        <div>

                                            <p>{installment.name}</p>
                                        </div>
                                        <div>
                                            <p>{new Date(installment.date).toLocaleDateString()}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isDialogOpenForCreateTerm}
                    draggable={false}
                    closeOnEscape={false}
                    style={{
                        width: '90%',
                        maxWidth: '1000px',
                        margin: '0 auto'
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => (
                        <div style={{ padding: '1rem', borderBottom: '1px solid #dee2e6' }}>
                            <h3 style={{ textAlign: 'center', margin: 0, fontSize: '1.5rem', fontWeight: '600' }}>
                                {`${this.state.editTerm?._id ? 'Update' : 'Add'}`} Fee Plan for {this.props.branchData.map((branch) => ({ label: branch.name, value: branch.key })).find(branch => branch.value === this.state.selectedBranchId)?.label}
                            </h3>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button label="Accept" className="confirmDialogAcceptBtn mr-4  px-6" onClick={() => this.onCreateTerm()} />
                            <Button label="Cancel" className="confirmDialogCancelBtn px-6" onClick={() => this.setState({ term: {}, isDialogOpenForCreateTerm: false, editTerm: {} })} />
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForCreateTerm: false, term: {}, editTerm: {} })}
                >
                    <div style={{ padding: '1.5rem' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Plan Name <span style={{ color: 'red' }}>*</span>
                                </label>
                                <ClassetInputText
                                    style={{ width: '100%' }}
                                    value={this.state.planName}
                                    onChange={(e) => this.setState({ planName: e.target.value })}
                                    placeholder="Enter Plan Name"
                                />
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Number of Installments <span style={{ color: 'red' }}>*</span>
                                </label>
                                <ClassetDropdown
                                    style={{ width: '100%' }}
                                    value={numberOfInstallments}
                                    options={Array.from({ length: 12 }, (_, i) => ({
                                        label: `${i + 1} Installment${i === 0 ? '' : 's'}`,
                                        value: i + 1
                                    }))}
                                    onChange={this.handleInstallmentChange}
                                    placeholder="Select Number of Installments"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '2rem' }}>
                            {Array.from({ length: numberOfInstallments }).map((_, index) => (
                                <div key={index} style={{
                                    padding: '1rem',
                                    marginBottom: '1rem',
                                    border: '1px solid #dee2e6',
                                    borderRadius: '4px'
                                }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                                        <div>
                                            <ClassetInputText
                                                style={{ width: '100%' }}
                                                value={installmentDetails[index].name}
                                                onChange={(e) => this.handleInstallmentDetailChange(index, 'name', e.target.value)}
                                                placeholder={`Installment ${index + 1} Name`}
                                            />
                                        </div>
                                        <div>
                                            <ClassetCalendar
                                                icon={<PlainCalenderIcon width={24} height={24} color={'grey'} />}
                                                className="border-calendar"
                                                width={'343px'}
                                                height={'48px'}
                                                inputClassName="font-semibold"
                                                calendarMode={'single'}
                                                value={this.getValidDate(installmentDetails[index].date)}
                                                onChange={(e) => this.handleInstallmentDetailChange(index, 'date', (e.value))}
                                                placeholder="Select Due Date"
                                                // minDate={new Date()}
                                                // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                                // minDate={new Date(installmentDetails[index - 1]?.date) ?
                                                //     new Date(installmentDetails[index - 1]?.date?.getTime() + (24 * 60 * 60 * 1000))
                                                //     : new Date()}
                                                // maxDate={new Date(installmentDetails[index + 1]?.date) ?
                                                //     new Date(installmentDetails[index + 1]?.date?.getTime() - (24 * 60 * 60 * 1000))
                                                //     : new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms?.permissionIds,
    userInfo: state.currentUserPerms?.userInfo || {},
    boardsDropDownOptions: state.dropDownOptionsData?.boardsDropDownOptions || [],
    boards: state.boardsData.boards || [],
    common: state.common,
    branchData: (state.branchDataLatest?.data?.data.filter(each => each.level == 1)) || [],
    academicSessionsDropDownOptions: state.dropDownOptionsData?.academicSessionsDropDownOptions || []
});

export default connect(mapStatesToProps, { getBranchesLatest })(Authentication(withRouter(FeeInstallments)));