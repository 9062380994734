import moment from 'moment/moment';
import React, { Component } from 'react'
import './feeReciept.scss';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import Authentication from './../session';
import { Dialog } from 'primereact/dialog';

class FeeReciept extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reciept: {
                transactionId: "123233",
                studentName: "Shashidhar katkam",
                fatherName: 'Srnivas',
                registrationNo: "3223434",
                academicYear: "2022-2023",
                transactionDate: '2023-02-18T13:15:55.019Z',
                boardName: "CBSE",
                className: "XI class",
                totalAmount: "1000.00",
                amountBreakup: [
                    {
                        particulars: "application fee",
                        amount: '1000.00'
                    },
                    {
                        particulars: "registration fee",
                        amount: '500.00'
                    },
                    {
                        particulars: "misc. fee",
                        amount: '200.00'
                    }
                ],
                paymentMethod: "cash",
                recievedBy: "cash",
                org: {
                    "_id": "623b37dbd392cd5bdcf7afc8",
                    "name": "New organization 1",
                    "logoRectangle": {
                        "fileName": "macademy-logo.jpg",
                        "storageBucket": "classet-profiles",
                        "storageKey": "uploads/0macademy-logo.jpg",
                        "storageLocation": "https://classet-profiles.s3.ap-south-1.amazonaws.com/uploads/0macademy-logo.jpg"
                    },
                    "logoSquare": {
                        "fileName": "macademy-logo2.jpg",
                        "storageBucket": "classet-profiles",
                        "storageKey": "uploads/0macademy-logo2.jpg",
                        "storageLocation": "https://classet-profiles.s3.ap-south-1.amazonaws.com/uploads/0macademy-logo2.jpg"
                    },
                    "address": "hywerwerrwerrereww r ew we werewrewrew rwe rdvvdsvvdsvdsvsdvsdvsdsdvsdvvdsddsdvsdsvvhywerwerrwerrereww r ew we werewrewrew rwe rdvvdsvvdsvdsvsdvsdvsdsdvsdvvdsddsdvsdsvv ",
                    "mobileOperational": "8886678939",
                    "mobileAccounts": "8886678939",
                    "orgCode": "ada",
                    "emailConfig": true,
                    "smsConfig": true,
                    "whatsAppConfig": true
                }
            }
        }
    }


    render() {
        const { reciept } = this.props;

        const { logoRectangle, orgName, orgAddress, mobileOperational } = this.props;
        return (
            <>
                <Dialog
                    style={{ width: '60%' }}
                    header='Fee Receipt'
                    headerClassName='text-center'
                    modal
                    footer={() => {
                        return <>
                            <ReactToPrint
                                pageStyle="padding:'10px'"
                                content={() => this.componentRef}>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <>  <Button data-pr-position="bottom"
                                            data-pr-tooltip="Download "
                                            onClick={handlePrint}
                                            className='print-button ma-mr20 p-ml-10 p-mb-4'
                                            label='Print'
                                        ></Button>
                                            <Tooltip className="table-li-tooltip" target=".egraph" />
                                        </>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </>
                    }}
                    draggable={false}
                    // footer={this.footer}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.props.hideFeeReciept}
                >
                    <div className='grid ml-4' ref={el => this.componentRef = el}>
                        <div className='col-3 receipt-image'>
                            <img src={`${logoRectangle?.storageLocation}`} className='logo-img'/>
                        </div>
                        <div className='col-8 -ml-8 receipt-main-heading'>
                            <div className='mb-1 mt-2 address'>
                            <h2 className='heading-font mb-2'>{orgName}</h2>
                                <h4 className='heading-font'>{orgAddress}, Mobile Number : {mobileOperational}</h4>
                            </div>
                        </div>
                        <div className='col-12'>
                            <hr className='dashed' />
                        </div>
                        <div className='col-12 receipt-heading mb-4'>
                            <h3 className='heading-font text-center'>FEE RECEIPT</h3>
                        </div>

                        <div className='grid reciept-details'>
                            <div className='col-2'>
                                <h4 className='heading-font'>Transaction ID </h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {this.props.transactionId}</h4>
                            </div>
                            <div className='col-2'>
                                <h4 className='heading-font'>Registation ID </h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {reciept?.registrationNo}</h4>
                            </div>
                            <div className='col-2 '>
                                <h4 className='heading-font'>Reciept Date</h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {moment(reciept?.recieptDate).format('DD-MM-YYYY')}</h4>
                            </div>
                            <div className='col-2 '>
                                <h4 className='heading-font'>Transaction Date </h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {moment(reciept?.transactionDate).format('DD-MM-YYYY')}</h4>
                            </div>
                            <div className='col-2 '>
                                <h4 className='heading-font'>Name Of the Student</h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {reciept?.studentName}</h4>
                            </div>
                            {/* <div className='col-2'>
                            <h4 className='heading-font'>Receipt No</h4>
                        </div>
                        <div className='col-4'>
                            <h4 className='heading-font'>: asdf</h4>
                        </div> */}
                            {/* <div className='col-2 '>
                            <h4 className='heading-font'>Admission No</h4>
                        </div>
                        <div className='col-4'>
                            <h4 className='heading-font'>: asdf</h4>
                        </div> */}
                            {/* <div className='col-2 '>
                            <h4 className='heading-font'>Unique ID/Enrollment Code </h4>
                        </div>
                        <div className='col-4'>
                            <h4 className='heading-font'>: asdf</h4>
                        </div> */}
                            <div className='col-2 '>
                                <h4 className='heading-font'>Father Name</h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {reciept.fatherName}</h4>
                            </div>
                            <div className='col-2 '>
                                <h4 className='heading-font'>Academic Year</h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {reciept.academicYear}</h4>
                            </div>
                            <div className='col-2 '>
                                <h4 className='heading-font'>Board Name</h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {reciept.boardName}</h4>
                            </div>
                            <div className='col-2 '>
                                <h4 className='heading-font'>Class Name</h4>
                            </div>
                            <div className='col-4'>
                                <h4 className='heading-font'>: {reciept.className}</h4>
                            </div>
                        </div>
                        <div className='col-12'>
                            <table className='table-border1 w-11 ml-10 mt-3'>
                                <tr>
                                    <th className='table-border heading-font w-1'>S.no</th>
                                    <th className='table-border heading-font'>Particulars</th>
                                    <th className='table-border heading-font'>Amount</th>
                                </tr>
                                {reciept.amountBreakup.map((e, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className='table-border heading-font'>{i + 1}</td>
                                                <td className='table-border heading-font'>{e.particulars}</td>
                                                <td className='table-border heading-font'>{e.amount}</td>
                                            </tr>
                                        </>
                                    )
                                })}

                                {/* <tr>
                                <td className='table-border heading-font'>1</td>
                                <td className='table-border heading-font'>Application Fee ( )</td>
                                <td className='table-border heading-font'>1000.00</td>
                            </tr> */}

                                <tr>
                                    <td className='table-border heading-font total' colSpan='2'>Total Amount</td>
                                    <td className='table-border heading-font'>{reciept.totalAmount}</td>
                                </tr>
                            </table>
                        </div>
                        <div className='col-12'>
                            <p className='heading-font ml-10'>Payment Method : {reciept.paymentMethod}</p>
                            {reciept.paymentMethod == 'cheque' &&
                                <div>
                                    <p className='heading-font ml-10'>Cheque No : {reciept.chequeNo}</p>
                                    <p className='heading-font ml-10'>Cheque Date : {moment(reciept.chequeDate).format('DD-MM-YYYY')}</p>
                                    <p className='heading-font ml-10'>Bank Name : {reciept.bankName}</p>
                                    <p className='heading-font ml-10'>Branch : {reciept.bankBranch}</p>
                                </div>
                            }
                            {reciept.paymentMethod == 'credit/debit' &&
                                <div>
                                    <p className='heading-font ml-10'>Type of Card : {reciept.cardType}</p>
                                    <p className='heading-font ml-10'>Last 4 Digits Of Card : {reciept.cardLast4Digits}</p>
                                    <p className='heading-font ml-10'>Card Transaction ID : {reciept.cardTransactionId}</p>
                                    <p className='heading-font ml-10'>Card Transaction Date : {moment(reciept.cardTransactionDate).format('DD-MM-YYYY')}</p>
                                </div>
                            }
                            {reciept.paymentMethod == 'upiPayment' &&
                                <div>
                                    <p className='heading-font ml-10'>UPI Transaction ID : {reciept.upiTransactionId}</p>
                                    <p className='heading-font ml-10'>UPI Transaction Date : {moment(reciept.upiTransactionDate).format('DD-MM-YYYY')}</p>
                                    <p className='heading-font ml-10'>UPI UTR Number : {reciept.upiUtrNumber}</p>
                                </div>
                            }
                            <div className='manager'>
                                
                            </div>
                            <div className='mb-3 p-mt-3 flex justify-content-between'>
                                <h4 className='heading-font white-space-normal ml-10 mt-1'>Note : Parents are requested to preserve this receipt for future clarifications in respect of <br></br> fee paid by you.Fee once paid will not be refunded or transferred.Cheques subjects to realization.</h4>
                                <h3 className='heading-font'>Cashier/Manager</h3>
                            </div>
                        </div>

                    </div>

                </Dialog>
            </>
        )
    }
}




const mapStateToProps = (state) => ({
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgAddress: state.orgData.address,
    mobileOperational: state.orgData.mobileOperational,
});

export default connect(mapStateToProps, {})(
    Authentication(FeeReciept)
);
