import React, { Component } from 'react'
import withRouter from '../lib/withRouter';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Service from '../services'
import { BasicLazyParams } from '../../utile';
import BranchToSectionMultiDDPreFill from '../BaseDropdownComponents/BranchToSectionMultiDDPreFill';
import { getBranchesLatest } from '../../store/actions';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import './styles.scss'
class StudentInfomationReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
        this.service = new Service()
    }
    setDropdownValues = (dropdownsData) => {
        const branchChanged = this.state.dropdownsData?.branchIds !== dropdownsData.branchIds;
        this.setState({
            dropdownsData,
            branchChanged
        }, () => {
            if (this.state.branchChanged && this.state.dropdownsData.branchIds) {
            }
            if (this.state.dropdownsData.sectionId) {
            }
        });
    }

    render() {
        return (
            <>

                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: '0',
                    marginLeft: "100px",
                    marginTop: "55px",
                    overflow: 'auto',

                }}>
                    <div className='card mt-1 pt-0' style={{
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.25), 4px 4px 8px rgba(0, 0, 0, 0.15)',
                        borderRadius: "12px",
                        border: 'none',
                        width: "98%"

                    }}>
                        <BranchToSectionMultiDDPreFill setValues={this.setDropdownValues} />
                        <div className='grid m-2'>
                            <div className='col-3'>
                            <p className='filter-title'>Student Name/Enrollment Number</p>
                                <ClassetDropdown
                                    options={this.state.feeStructuresArray}
                                    value={this.state.selectedFeeStructure}
                                    optionValue='_id'
                                    optionLabel='scheduleName'
                                    onChange={(e) => { }}
                                    placeholder={(
                                        <div>
                                            <span className=''>Select Student</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='col-3'>
                            <p className='filter-title'>Gender</p>
                                <ClassetDropdown
                                    options={this.state.feeStructuresArray}
                                    value={this.state.selectedFeeStructure}
                                    optionValue='_id'
                                    optionLabel='scheduleName'
                                    onChange={(e) => { }}
                                    placeholder={(
                                        <div>
                                            <span className=''>Select Gender</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='col-3'>
                                <p className='filter-title'>Status</p>
                                <ClassetDropdown
                                    options={this.state.feeStructuresArray}
                                    value={this.state.selectedFeeStructure}
                                    optionValue='_id'
                                    optionLabel='scheduleName'
                                    onChange={(e) => { }}
                                    placeholder={(
                                        <div>
                                            <span className=''>Select Status</span>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

const mapStatesToProps = (state) => ({
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._idF
});
export default connect(mapStatesToProps, { getBranchesLatest })(Authentication(withRouter(StudentInfomationReport)));