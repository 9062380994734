import React, { Component } from 'react';
import { AddIconInButtonFull, CalenderCheckIcon, MailIcon, MinusSquareIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Divider } from 'primereact/divider';
import '../styles.scss';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import withRouter from '../../lib/withRouter';
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { getBoardsData } from '../../../store/actions';
import Authentication from '../../session';
import { baseUrlAdmin } from '../../../store/apiConstants';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'react-formio/lib/types';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { BasicLazyParams } from '../../../utile';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';
class TimeTableSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: [],
            lazyParams: BasicLazyParams,
            category: {
                description: '',
                categoryName: '',
                boardsList: [{ board: '', grade: [], classes: [] }],
            },
            enableContinousPeriods: false,
            users: [],
            payload: {},
            subjectToPeriodLimits: [{
                board: "", grade: "", gradeOptions: [], subject: [], periodsPerWeek: ""
            }],
            continousPeriods: [{
                board: "", grade: "", gradeOptions: [], subject: [], maxNumberOfContinousPeriods: ""
            }],
            isAllSubjectsEqual: false,
            isExempted: false,
            subs: [
                { Id: 'SGS', priority: 1, data: 'Substitute with Teacher in same grade and subject' },
                { Id: 'SGDS', priority: 2, data: 'Substitute with Teacher in same grade  and different subject' },
                { Id: 'SSAG', priority: 3, data: 'Substitute with Teacher in Same subject across all grades' },
                { Id: 'AGAS', priority: 4, data: 'Substitute with Teacher in any grade and any subject' },
                { Id: 'NE', priority: 5, data: 'Substitute with any non-admin employee present today' }
            ],
            // substituations: []
        };
        this.service = new Service();
    }

    async componentDidMount() {
        await this.getAdmissionTypesData();
        await this.getStaffData()
        await this.props.getBoardsData(true);
        await this.getSavedData();
    }
    onRowReorder = (e) => {
        let rowValues = e.value;
        console.log(rowValues, 'rowValuesbeforepriority');
        let newValues = rowValues.map((each, index) => {
            return { ...each, priority: index + 1 };
        });
        console.log(rowValues, 'rowValuesafterpriority');
        this.setState({
            subs: newValues
        });

        this.toast.show({ severity: 'success', summary: 'Rows Reordered', life: 3000 });
    };
    getAdmissionTypesData = async () => {
        this.setState({ isLoading: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ categories: res?.res?.data, isLoading: false });
                console.log(res.res.data);
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }
    };
    // getCurriculumData = () => {
    //     const { boardId, classId, groupId } = this.state;
    //     if (boardId && classId) {
    //         this.setState({
    //             isLoading: true,
    //             curriculumInfo: {}
    //         });
    //         const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
    //         this.service.get(url, true).then((data) => {
    //             const etag = data && data.etag;
    //             if (data && data.res && data.res.Item) {
    //                 let sortedData = sortCurriculam(data.res.Item)
    //                 this.setState({
    //                     curriculumInfo: sortedData,
    //                     isLoading: false,
    //                     subjectId: sortedData?.subjects[0]?.subjectId,
    //                     chapters: sortedData?.subjects[0]?.chapters,
    //                     // chapterId: sortedData?.subjects[0]?.chapters[0]?.chapterId,
    //                     // topics: sortedData?.subjects[0]?.chapters[0]?.topics,
    //                     etag
    //                 }, () => this.getResourcesFromSelectedTopicOrChapter(sortedData?.subjects[0]?.chapters[0], 'chapter'));
    //             } else {
    //                 this.setState({
    //                     isLoading: false,
    //                 });
    //             }
    //         }).catch(e => {
    //             this.setState({
    //                 isLoading: false
    //             });
    //         });
    //     } else {
    //         this.setState({
    //             isLoading: false,

    //         })
    //     }
    // }

    onChangeBoard = (boardIds, ind) => {
        if (boardIds && boardIds.length > 0) {
            const _classes = [];
            const selectedBoards = this.props.boards && this.props.boards.filter((board) => (boardIds.includes(board.boardId)));
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards[0].classes.forEach((item) => _classes.push({ label: `${item.className}`, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                this.setState((prevState) => {
                    const updatedSubjectToPeriodLimits = [...prevState.subjectToPeriodLimits];
                    updatedSubjectToPeriodLimits[ind] = { ...updatedSubjectToPeriodLimits[ind], board: boardIds, gradeOptions: _classes };

                    return {
                        subjectToPeriodLimits: updatedSubjectToPeriodLimits,
                        // gradesDropDownOptions: _classes,
                        gradesData: _classes
                            .filter((c) => prevState.gradesData?.includes(c.value)) // Filter the classes to get only the matching ones
                            .map((c) => c.value),
                    };
                });

            }
        }
        else {
            const _classes = []
            const selectedBoards = this.props.boards;
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards[0].classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));

                this.setState((prevState) => {
                    const updatedSubjectToPeriodLimits = [...prevState.subjectToPeriodLimits];
                    updatedSubjectToPeriodLimits[ind] = { ...updatedSubjectToPeriodLimits[ind], board: boardIds, gradeOptions: _classes };
                    return {
                        subjectToPeriodLimits: updatedSubjectToPeriodLimits,
                        // gradesDropDownOptions: _classes,
                        gradesData: [],
                    }
                });
            }
        }
    }

    onChangeClass = (classIds, ind) => {
        const obj = this.state.subjectToPeriodLimits[ind].gradeOptions.filter((grade) => grade.value === classIds);
        this.setState((prevState) => {
            const updatedSubjectToPeriodLimits = [...prevState.subjectToPeriodLimits];
            updatedSubjectToPeriodLimits[ind] = { ...updatedSubjectToPeriodLimits[ind], grade: classIds, groupId: obj[0].groupId };
            return {
                subjectToPeriodLimits: updatedSubjectToPeriodLimits,
            }
        }, () => {
            this.getCurriculumData(ind, "subjectToPeriodLimits");
        })
    };
    onChangeSubject = (subjectIds, ind) => {
        this.setState((prevState) => {
            const updatedSubjectToPeriodLimits = [...prevState.subjectToPeriodLimits];
            updatedSubjectToPeriodLimits[ind] = { ...updatedSubjectToPeriodLimits[ind], subject: subjectIds };
            return {
                subjectToPeriodLimits: updatedSubjectToPeriodLimits,
            }
        })
    }

    onChangeContinousBoard = (boardIds, ind) => {
        if (boardIds && boardIds.length > 0) {
            const _classes = [];
            const selectedBoards = this.props.boards && this.props.boards.filter((board) => (boardIds.includes(board.boardId)));
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards[0].classes.forEach((item) => _classes.push({ label: `${item.className}`, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
                this.setState((prevState) => {
                    const updatedContinousPeriods = [...prevState.continousPeriods];
                    updatedContinousPeriods[ind] = { ...updatedContinousPeriods[ind], board: boardIds, gradeOptions: _classes };

                    return {
                        continousPeriods: updatedContinousPeriods,
                        // gradesDropDownOptions: _classes,
                        gradesData: _classes
                            .filter((c) => prevState.gradesData?.includes(c.value)) // Filter the classes to get only the matching ones
                            .map((c) => c.value),
                    };
                });

            }
        }
        else {
            const _classes = []
            const selectedBoards = this.props.boards;
            if (selectedBoards && selectedBoards.length > 0) {
                selectedBoards[0].classes.forEach((item) => _classes.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));

                this.setState((prevState) => {
                    const updatedContinousPeriods = [...prevState.continousPeriods];
                    updatedContinousPeriods[ind] = { ...updatedContinousPeriods[ind], board: boardIds, gradeOptions: _classes };
                    return {
                        continousPeriods: updatedContinousPeriods,
                        // gradesDropDownOptions: _classes,
                        gradesData: [],
                    }
                });
            }
        }
    }

    onChangeContinousClass = (classIds, ind) => {
        const obj = this.state.continousPeriods[ind].gradeOptions.filter((grade) => grade.value === classIds);
        this.setState((prevState) => {
            const updatedContinousPeriods = [...prevState.continousPeriods];
            updatedContinousPeriods[ind] = { ...updatedContinousPeriods[ind], grade: classIds, groupId: obj[0]?.groupId };
            return {
                continousPeriods: updatedContinousPeriods,
            }
        }, () => {
            this.getCurriculumData(ind, "continousPeriods");
        })
    };
    onChangeContinousSubject = (subjectIds, ind) => {
        this.setState((prevState) => {
            const updatedContinousPeriods = [...prevState.continousPeriods];
            updatedContinousPeriods[ind] = { ...updatedContinousPeriods[ind], subject: subjectIds };
            return {
                continousPeriods: updatedContinousPeriods,
            }
        })
    }
    componentDidUpdate() {
        console.log(this.state);
        // console.log(this.props.boards);
    }
    getStaffData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/staff?academicYear=${academicYear}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const users = res?.res?.data?.records;
                console.log(users);
                const formatUsers = users?.map(user => ({
                    ...user,
                    formattedUsername: user.user_info.username.slice(3) // Remove first 3 letters
                }))
                this.setState({
                    users: formatUsers,
                    totalRecords: res?.res?.data?.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    getCurriculumData = (ind, cond) => {
        const { board, grade, groupId } = cond == 'continousPeriods' ? this.state.continousPeriods[ind] : this.state.subjectToPeriodLimits[ind]

        if (board && grade) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${board}/class/${grade}/group/${groupId}?etag=true`;
            this.service
                .get(url, true)
                .then((data) => {
                    console.log(data);
                    const etag = data && data.etag;
                    if (data && data.res && data.res.Item) {
                        // let sortedData = sortCurriculam(data.res.Item);
                        const filteredSubjects = data.res.Item.subjects.map(({ chapters, ...rest }) => rest);
                        if (cond === 'subjectToPeriodLimits') {
                            this.setState((prevState) => {
                                const updatedSubjectToPeriodLimits = [...prevState.subjectToPeriodLimits];
                                updatedSubjectToPeriodLimits[ind] = { ...updatedSubjectToPeriodLimits[ind], subjectOptions: filteredSubjects };
                                return {
                                    subjectToPeriodLimits: updatedSubjectToPeriodLimits,
                                    isLoading: false
                                }
                            })
                        } else {
                            this.setState((prevState) => {
                                const updatedContinousPeriods = [...prevState.continousPeriods];
                                updatedContinousPeriods[ind] = { ...updatedContinousPeriods[ind], subjectOptions: filteredSubjects };
                                return {
                                    continousPeriods: updatedContinousPeriods,
                                    isLoading: false
                                }
                            })
                        }

                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            this.setState({
                isLoading: false
            });
        }
    };
    changePriority = (newPriority, currentPriority) => {
        const subsCopy = [...this.state.subs];
        const currentIndex = subsCopy.findIndex((item) => item.priority === currentPriority);
        const newIndex = subsCopy.findIndex((item) => item.priority === newPriority);
        if (currentIndex !== -1 && newIndex !== -1) {
            const temp = subsCopy[currentIndex];
            subsCopy[currentIndex] = subsCopy[newIndex];
            subsCopy[newIndex] = temp;
            subsCopy[currentIndex].priority = currentPriority;
            subsCopy[newIndex].priority = newPriority;
            this.setState({ subs: subsCopy });
        }
    };
    onClickPlusOrMinus = (bool, index) => {
        const { subjectToPeriodLimits, isAllSubjectsEqual } = this.state;

        if (bool) {
            const last = subjectToPeriodLimits[subjectToPeriodLimits.length - 1];
            if (isAllSubjectsEqual) {
                if (!last || !last.periodsPerWeek) {
                    return this.toast.show({
                        severity: 'error',
                        summary: 'Fill all the fields before adding new details',
                        life: 3000,
                    });
                }
            } else {
                if (!last || !last.board || !last.grade || !last.subject || !last.periodsPerWeek) {
                    return this.toast.show({
                        severity: 'error',
                        summary: 'Fill all the fields before adding new details',
                        life: 3000,
                    });
                }
            }


            this.setState((prevState) => ({
                subjectToPeriodLimits: [
                    ...prevState.subjectToPeriodLimits,
                    { board: "", grade: "", subject: "", periodsPerWeek: "" },
                ],
            }));
        } else if (bool === false) {
            if (subjectToPeriodLimits.length === 1) {
                return this.toast.show({
                    severity: 'warn',
                    summary: 'At least one row must be present',
                    life: 3000,
                });
            }

            this.setState((prevState) => ({
                subjectToPeriodLimits: prevState.subjectToPeriodLimits.filter((_, i) => i !== index),
            }));
        }
    };
    onClickContinousPeriodsPlusOrMinus = async (bool, index) => {
        const { continousPeriods } = this.state;

        if (bool) {
            const last = continousPeriods[continousPeriods.length - 1];
            if (!last || !last.board || !last.grade || !last.subject || !last.maxNumberOfContinousPeriods) {
                return this.toast.show({
                    severity: 'error',
                    summary: 'Fill all the fields before adding new details',
                    life: 3000,
                });
            }

            this.setState((prevState) => ({
                continousPeriods: [
                    ...prevState.continousPeriods,
                    { board: "", grade: "", subject: "", maxNumberOfContinousPeriods: "" },
                ],
            }));
        } else if (bool === false) {
            if (continousPeriods.length === 1) {
                return this.toast.show({
                    severity: 'warn',
                    summary: 'At least one row must be present',
                    life: 3000,
                });
            }

            this.setState((prevState) => ({
                continousPeriods: prevState.continousPeriods.filter((_, i) => i !== index),
            }));
        }
    }
    componentDidUpdate() {
        console.log(this.state);
    }
    EmployeeException = (data, key) => {
        console.log(data);
        this.setState((prevState) => ({ payload: { ...prevState.payload, [key]: data } }))
    }
    onSave = async () => {
        this.setState({ isLoading: true });
        const { payload, subjectToPeriodLimits, continousPeriods } = this.state;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        const mainPayload = { ...payload, orgId: orgId, academicYear: academicYear, substitutions: this.state.subs, continousPeriods: this.state.continousPeriods, subjectToPeriodLimits: this.state.subjectToPeriodLimits }
        const url = `${baseUrlAdmin}/timetable/save-settings`;
        const lastLimits = subjectToPeriodLimits[subjectToPeriodLimits.length - 1];
        if (payload.isExempted) {
            if (!payload.substitutionException) {
                this.setState({ isLoading: false })
                return this.toast.show({
                    severity: 'error',
                    summary: 'Exception for substitutions is required',
                    life: 3000,
                });

            }
        }
        // if (payload.isAllSubjectsEqual) {
        //     if (!lastLimits || !lastLimits.periodsPerWeek) {
        //         this.setState({ isLoading: false })
        //         return this.toast.show({
        //             severity: 'error',
        //             summary: 'Fill all the fields',
        //             life: 3000,
        //         });

        //     }
        // } else {
        //     if (!lastLimits || !lastLimits.board || !lastLimits.grade || !lastLimits.subject || !lastLimits.periodsPerWeek) {
        //         this.setState({ isLoading: false })
        //         return this.toast.show({
        //             severity: 'error',
        //             summary: 'Fill all the fields',
        //             life: 3000,
        //         });

        //     }
        // }

        const lastPeriods = continousPeriods[continousPeriods.length - 1];
        if (payload.enableContinousPeriods && (!lastPeriods || !lastPeriods.board || !lastPeriods.grade || !lastPeriods.subject || !lastPeriods.maxNumberOfContinousPeriods)) {
            this.setState({ isLoading: false })
            return this.toast.show({
                severity: 'error',
                summary: 'Fill all the fields',
                life: 3000,
            });

        }
        try {
            const res = await this.service.post(url, mainPayload, true);
            if (res?.res?.status && res.status) {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }
    getSavedData = async () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        const url = `${baseUrlAdmin}/timetable/get-settings?academicYear=${academicYear}`;


        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ isLoading: false });
                const data = res?.res?.data;
                this.setState({ payload: data, subs: data?.substitutions, continousPeriods: data?.continousPeriods, subjectToPeriodLimits: data?.subjectToPeriodLimits });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }
    render() {
        const { payload, subjectToPeriodLimits, continousPeriods } = this.state;
        return (
            <div className="poppins24">
                <div className="col-12 flex justify-content-end align-items-center">
                    <Button
                        style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                        className="flex align-items-center gap-1"
                        onClick={() => {
                            this.onSave();
                        }}
                    >
                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                        <p className=" text-xl">Save</p>
                    </Button>
                </div>
                <div className="generalsetting-card">
                    <div className="flex justify-content-between" style={{ color: 'black', fontWeight: 'bolder' }}>
                        <div>
                            <p className="card-head px-3">Minimum no. of Periods per Teacher</p>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText keyfilter={"int"} value={payload.minPeriodsPerWeek} onChange={(e) => this.setState({
                                    payload: { ...this.state.payload, minPeriodsPerWeek: e.target.value }
                                })} icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Minimum Periods per week" label="Minimum Periods per week" />
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="formattedUsername"
                                    optionValue="user_id"
                                    required={false}
                                    width={'343px'}
                                    options={this.state?.users}
                                    onChange={(e) => this.EmployeeException(e.value, "minEmployeeExceptionPerWeek")}
                                    className=""
                                    value={this.state?.payload?.minEmployeeExceptionPerWeek}
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start align-items-center">
                                            <span className="">Employee Exceptions</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <p className="card-head pl-3">Maximum no. of Periods per Teacher</p>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText keyfilter={"int"} value={payload.maxPeriodsPerWeek} onChange={(e) => this.setState({
                                    payload: { ...this.state.payload, maxPeriodsPerWeek: e.target.value }
                                })} icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Maximum Periods per week" label="Maximum Periods per week" />
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="formattedUsername"
                                    optionValue="user_id"
                                    required={false}
                                    width={'343px'}
                                    options={this.state?.users}
                                    onChange={(e) => this.EmployeeException(e.value, "maxEmployeeExceptionPerWeek")}
                                    value={this.state.payload.maxEmployeeExceptionPerWeek}
                                    className=""
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Employee Exceptions</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Divider></Divider>
                    <div className="flex justify-content-between " style={{ color: 'black', fontWeight: 'bolder' }}>
                        <div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText keyfilter={"int"}
                                    icon={<MailIcon width={24} height={24} color={'#000000'} />}
                                    className="border-round-md  border-none"
                                    value={payload.minPeriodsPerDay}
                                    onChange={(e) => this.setState({
                                        payload: { ...this.state.payload, minPeriodsPerDay: e.target.value }
                                    })}
                                    placeholder="Minimum Periods per day"
                                    label="Minimum Periods per day"
                                />
                                {/* {formValidations && !formValidations.fields['nameOfTheTemplate'].isValid && <p className="p-error">{formValidations.fields['nameOfTheTemplate'].errorMsg}</p>} */}
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="formattedUsername"
                                    optionValue="user_id"
                                    required={false}
                                    width={'343px'}
                                    options={this.state?.users}
                                    value={this.state?.payload?.minEmployeeExceptionPerDay}
                                    onChange={(e) => this.EmployeeException(e.value, "minEmployeeExceptionPerDay")}
                                    className=""
                                    maxSelectedLabels={3}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Employee Exceptions</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div style={{ marginRight: '82px' }}>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetInputText keyfilter={"int"} value={payload.maxPeriodsPerDay} onChange={(e) => this.setState({
                                    payload: { ...this.state.payload, maxPeriodsPerDay: e.target.value }
                                })} icon={<MailIcon width={24} height={24} color={'#000000'} />} className="border-round-md  border-none" placeholder="Maximum Periods per day" label="Maximum Periods per day" />
                            </div>
                            <div className="col-12" style={{ padding: '1rem 1rem 0 1rem' }}>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Add Employee Exceptions"
                                    optionLabel="formattedUsername"
                                    optionValue="user_id"
                                    required={false}
                                    options={this.state?.users}
                                    width={'343px'}
                                    value={this.state?.payload?.maxEmployeeExceptionPerDay}
                                    onChange={(e) => this.EmployeeException(e.value, "maxEmployeeExceptionPerDay")}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Employee Exceptions</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="generalsetting-card">
                    <div className="flex gap-5 align-items-center px-3">
                        <p className="card-head">Subject to Period Limits</p>
                        <div className="flex gap-3 align-items-center">
                            <InputSwitch checked={payload?.isAllSubjectsEqual} onChange={() => {
                                this.setState({ payload: { ...payload, isAllSubjectsEqual: !payload.isAllSubjectsEqual } })
                            }} />
                            <p style={{ fontSize: '18px', color: 'black' }}>All Subjects are equal</p>
                        </div>
                    </div>
                    {subjectToPeriodLimits.map((period, index) => (<div className="grid px-3">
                        <div className='col-11 grid' key={index}>
                            {!payload.isAllSubjectsEqual && <div className="col-3  justify-content-center align-content-center">
                                <ClassetDropdown
                                    value={period.board}
                                    onChange={(e) => this.onChangeBoard(e.value, index)}
                                    options={this.props?.boards}
                                    label="Boards"
                                    optionLabel='boardName'
                                    required={true}
                                    optionValue='boardId'
                                    display="chip"
                                    className='dialogCardInFiel w-21rem text-lg shadow-2 pl-2'
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4 body-medium'>Select Boards</span>
                                        </div>
                                    )}
                                />
                            </div>}
                            {!payload.isAllSubjectsEqual && <div className="col-3  justify-content-center align-content-center">
                                <ClassetDropdown
                                    value={period.grade}
                                    onChange={(e) => this.onChangeClass(e.value, index)}
                                    options={period?.gradeOptions}
                                    label="Grades"
                                    optionLabel="label"
                                    required={true}
                                    className='dialogCardInFiel w-21rem text-lg shadow-2 pl-2'
                                    display="chip"
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4 body-medium'>Select Grades</span>
                                        </div>
                                    )}
                                />
                            </div>}
                            {!payload.isAllSubjectsEqual && <div className='col-3'>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Subject"
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                    required={false}
                                    value={period?.subject}
                                    width={'280px'}
                                    options={period?.subjectOptions}
                                    onChange={(e) => this.onChangeSubject(e.value, index)}
                                    className=""
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Subject</span>
                                        </div>
                                    }
                                />
                            </div>}
                            <div className='col-3'>
                                <ClassetInputText keyfilter={"int"} value={period.periodsPerWeek} onChange={(e) => {
                                    // console.log(e.target.value);
                                    this.setState((prevState) => {
                                        const updatedSubjectToPeriodLimits = [...prevState.subjectToPeriodLimits];
                                        updatedSubjectToPeriodLimits[index] = { ...updatedSubjectToPeriodLimits[index], periodsPerWeek: e.target.value };
                                        return {
                                            subjectToPeriodLimits: updatedSubjectToPeriodLimits,
                                        }
                                    })
                                }} label="Total Periods per week" icon={<MailIcon width={24} height={24} color={'#000000'} />} className="  border-none" placeholder="Total Periods" width={'280px'} />
                            </div>
                        </div>
                        {!payload.isAllSubjectsEqual &&
                            <div className="flex flex-column justify-content-center align-items-center gap-2 col-1">
                                <Button icon="pi pi-plus" aria-label="Increase"
                                    onClick={() => this.onClickPlusOrMinus(true)}
                                />
                                {this.state?.subjectToPeriodLimits?.length > 1 && <Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onClickPlusOrMinus(false, index)} />}
                            </div>}
                    </div>))}
                </div>
                <div className="generalsetting-card">
                    <div className="flex gap-5 align-items-center px-3">
                        <p className="card-head">Continous Periods</p>
                        <InputSwitch className="" id="partialPayment" checked={payload?.enableContinousPeriods} onChange={() => this.setState({ payload: { ...payload, enableContinousPeriods: !payload.enableContinousPeriods } })} />
                    </div>
                    {continousPeriods.map((period, index) => (<div className="grid px-3">
                        <div className='col-11 grid' key={index}>
                            <div className="col-3  justify-content-center align-content-center">
                                <ClassetDropdown
                                    value={period.board}
                                    onChange={(e) => this.onChangeContinousBoard(e.value, index)}
                                    options={this.props?.boards}
                                    label="Boards"
                                    optionLabel='boardName'
                                    required={true}
                                    disabled={!payload.enableContinousPeriods}
                                    optionValue='boardId'
                                    display="chip"
                                    className='dialogCardInFiel w-21rem text-lg shadow-2 pl-2'
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4 body-medium'>Select Boards</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-3  justify-content-center align-content-center">
                                <ClassetDropdown
                                    value={period.grade}
                                    onChange={(e) => this.onChangeContinousClass(e.value, index)}
                                    options={period?.gradeOptions}
                                    label="Grades"
                                    optionLabel="label"
                                    required={true}
                                    disabled={!payload.enableContinousPeriods}
                                    className='dialogCardInFiel w-21rem text-lg shadow-2 pl-2'
                                    display="chip"
                                    placeholder={(
                                        <div className="flex justify-content-start  align-items-center mt-1">
                                            <MailIcon width={20} height={16} color={'#667A85'} />
                                            <span className='text-xl ml-4 body-medium'>Select Grades</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='col-3'>
                                <ClassetMultiSelect
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    label="Subject"
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                    required={false}
                                    value={period?.subject}
                                    width={'280px'}
                                    disabled={!payload.enableContinousPeriods}
                                    options={period?.subjectOptions}
                                    onChange={(e) => this.onChangeContinousSubject(e.value, index)}
                                    className=""
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Subject</span>
                                        </div>
                                    }
                                />
                            </div>
                            <div className='col-3'>
                                <ClassetInputText keyfilter={"int"}
                                    disabled={!payload.enableContinousPeriods}
                                    value={period?.maxNumberOfContinousPeriods}
                                    onChange={(e) => {
                                        // console.log(e.target.value);
                                        this.setState((prevState) => {
                                            const updatedContinousPeriods = [...prevState.continousPeriods];
                                            updatedContinousPeriods[index] = { ...updatedContinousPeriods[index], maxNumberOfContinousPeriods: e.target.value };
                                            return {
                                                continousPeriods: updatedContinousPeriods,
                                            }
                                        })
                                    }} label="Max no.of continous periods" icon={<MailIcon width={24} height={24} color={'#000000'} />} className="  border-none" placeholder="Total Periods" width={'280px'} />
                            </div>
                        </div>
                        <div className="flex flex-column justify-content-center align-items-center gap-2 col-1">
                            <Button icon="pi pi-plus" aria-label="Increase"
                                onClick={() => this.onClickContinousPeriodsPlusOrMinus(true)}
                            />
                            {this.state?.continousPeriods?.length > 1 && <Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onClickContinousPeriodsPlusOrMinus(false, index)} />}
                        </div>
                    </div>))}
                </div>
                <div className="generalsetting-card">
                    <div className="col-12 flex flex-column gap-3">
                        <h2 style={{ color: 'black' }}>Substitutions</h2>
                        {this.state.subs.map((sub) => {
                            return (
                                <div className="p-inputgroup" style={{ height: '45px' }}>
                                    <span className="p-inputgroup-addon p-0">
                                        <Dropdown style={{ height: '45px', width: '80px', fontSize: '20px', fontWeight: '600' }}
                                            options={[1, 2, 3, 4, 5]}
                                            onChange={(e) => this.changePriority(e.target.value, sub.priority)}
                                            value={sub.priority}
                                        // checked={checked1} onChange={(e) => setChecked1(!checked1)} 
                                        />
                                    </span>
                                    <span
                                        className='pl-3'
                                        style={{ width: '100%', borderRadius: '5px', fontWeight: '600', fontSize: '16px', color: 'black', display: 'flex', alignItems: 'center', border: '2px solid lightgrey' }}
                                        id={sub.Id}

                                    >{sub.data}</span>
                                </div>
                            )
                        })}
                    </div>
                    <Divider />
                    <div className="flex justify-content-between py-5 align-items-center">
                        <div className="flex gap-5 px-3 align-items-start">
                            <InputSwitch checked={payload.isExempted} onChange={() => this.setState({ payload: { ...this.state.payload, isExempted: !payload.isExempted } })} />
                            <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                <p style={{ color: 'black' }}>Add Exception for Substitutions</p>
                                <p>Select Employees that are exempted from Substitution</p>
                            </div>
                        </div>
                        <div>
                            <ClassetMultiSelect
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                label="Add Employee Exceptions"
                                optionLabel="formattedUsername"
                                optionValue="user_id"
                                required={true}
                                width={'343px'}
                                options={this.state?.users}
                                disabled={!payload.isExempted}
                                value={payload.substitutionException}
                                onChange={(e) => this.EmployeeException(e.value, "substitutionException")}
                                className=""
                                maxSelectedLabels={3}
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Exempted Employees</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    boardsDropDownOptions: (state.dropDownOptionsData && state.dropDownOptionsData.boardsDropDownOptions) || [],
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(TimeTableSettings)));
