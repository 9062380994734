import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import React, { Component } from 'react'
import ClassetInputText from '../../../classetComponents/classetInputText'
import { CalenderIcon, CodeLinkIcon, FileAttachmentIcon, MailIcon } from '../../svgIcons'
import ClassetCalendar from '../../../classetComponents/classetCalender'
import ClassetDropdown from '../../../classetComponents/classetDropDown'
import FileUpload from '../../fileUpload'
import { getFormFields, isFormValid, onTextChange } from '../../../utile/formHelper'
import Service from '../../services'
import { baseUrlAdmin } from '../../../store/apiConstants'
const createFields = require('./editStudentInfo.json');
export default class EditStudentInfo extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFields);
        this.state = {
            formValidations: this.formFields?.formValidations,
            studentInfo: this.props?.EditData?.user_info || {},
        }
        this.service = new Service();
    }
    _onProgress = (filesInfo) => { };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onPhotoSuccess = (fileName, data) => {
        console.log(fileName);
        this.addAttachment('studentPhoto', fileName, data);
    };
    _afterFilesUploaded = (files) => { };
    onUpload() {
        this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }
    addAttachment = (fieldName, fileName, data) => {
        console.log(fileName)
        this.setState((prevState) => ({
            studentInfo: {
                ...prevState.studentInfo,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };
    EditStudentInfo = async () => {
        const user_id = this.props?.EditData?.user_id;
        this.setState({ isLoading: true })
        let data = { ...this.state.studentInfo, dateOfBirth: this.state.dateOfBirth }
        const payload = {
            user_info: data,
            user_id,
            // org_id: new ObjectId(localStorage.getItem('orgId')),
            // academicYear: localStorage.getItem('userAcademicYear')
        }
        const formStatus = isFormValid(createFields, this.formFields.formValidations, data);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            let url = `${baseUrlAdmin}/user/editUserInfo`
            try {
                const res = await this.service.post(url, payload, true);
                if (res && res.status) {
                    this.setState({ isLoading: false, isShowCreateForm: false });
                    this.props.isShowEditForm();
                    // window.location.reload();
                    this.props.filterUsersData();
                    this.toast.show({ severity: 'success', summary: 'Successfully Saved', duration: 3000 });
                }
                // console.log(res);
            } catch (err) {
                return this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });
            }
        } else {
            return this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Please Fill All the required Fields', life: 3000 });
        }
    }
    componentDidMount() {
        console.log(this.props.EditData);
        this.setState({ dateOfBirth: new Date(this.props?.EditData?.user_info?.dateOfBirth).toLocaleDateString() })
    }
    render() {
        const { studentInfo, formValidations } = this.state;
        return (
            <>
                <div className="grid col-12">
                    <div>
                        {/* <BranchToSectionDD setValues={this.setDropdownValues} /> */}
                    </div>
                    <div className="col-3">
                        <div className="mt-2">
                            <ClassetInputText
                                icon={<MailIcon />}
                                className=""
                                height="48px"
                                label={"First Name"}
                                required={true}
                                width="330px"
                                value={studentInfo.firstName}
                                placeholder={"First Name"}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'firstName', this, createFields, studentInfo, formValidations, 'studentInfo', formValidations)}
                            />
                        </div>
                    </div>
                    <div className="col-3">

                        <div className="mt-2">
                            <ClassetInputText
                                icon={<MailIcon height={22} />}
                                className=""
                                height="48px"
                                // width="330px"
                                label={"Last Name"}
                                required={true}
                                placeholder="Last Name"
                                value={studentInfo.lastName}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'lastName', this, createFields, studentInfo, formValidations, 'studentInfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error">{formValidations.fields['lastName'].errorMsg}</p>}
                    </div>
                    <div className="col-3">

                        <div className="mt-2">
                            <ClassetInputText
                                // id='feeType'
                                icon={<MailIcon height={22} />}
                                className=""
                                height="48px"
                                label={"Phone Number"}
                                // width="330px"
                                placeholder="Phone Number"
                                value={studentInfo.phone_number}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'phone_number', this, createFields, studentInfo, formValidations, 'studentInfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['phone_number'].isValid && <p className="p-error">{formValidations.fields['phone_number'].errorMsg}</p>}
                    </div>
                    <div className="col-3">

                        <label className="label mb-2 ">
                            Date Of Birth<span className="ma-required">*</span>
                        </label>

                        <div className="mt-2">
                            <ClassetCalendar
                                icon={<MailIcon height={20} />}
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Date Of Birth"
                                calendarMode="single"
                                value={new Date(this.state.dateOfBirth)}
                                onChange={(e) =>
                                    this.setState({ dateOfBirth: e.value })}
                                maxDate={new Date()}
                            />
                        </div>
                        {formValidations && !formValidations.fields['dateOfBirth'].isValid && <p className="p-error">{formValidations.fields['dateOfBirth'].errorMsg}</p>}
                    </div>
                    <div className="col-3">
                        <div className="mt-2">
                            <ClassetInputText
                                // id='feeType'
                                icon={<MailIcon height={22} />}
                                className=""
                                height="48px"
                                label={"Aadhar Number"}
                                required={true}
                                // width="330px"
                                placeholder="Aadhar Number"
                                value={studentInfo.aadharNo}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'aadharNo', this, createFields, studentInfo, formValidations, 'studentInfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['aadharNo'].isValid && <p className="p-error">{formValidations.fields['aadharNo'].errorMsg}</p>}
                    </div>
                    <div className="col-3">
                        <div className="mt-2">
                            <ClassetInputText
                                id="feeType"
                                icon={<MailIcon height={22} />}
                                label={"Contact Person Name"}
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Contact Person Name"
                                value={studentInfo.contactPersonName}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'contactPersonName', this, createFields, studentInfo, formValidations, 'studentInfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['contactPersonName'].isValid && <p className="p-error">{formValidations.fields['contactPersonName'].errorMsg}</p>}
                    </div>
                    <div className="col-2" >
                        <p className="transport-fee-form-label">
                            Student Photo<span className="ma-required">*</span>
                        </p>
                        <FileUpload
                            id={'chequePhoto'}
                            multiple={false}
                            onProgress={this._onProgress}
                            onSucess={this._onPhotoSuccess}
                            //  Reset={this.state.Reset}
                            maxFileSize={307200}
                            onError={this._onFilsUploadError}
                            accept={'images'}
                            title={'Images'}
                            afterFilesUploaded={this._afterFilesUploaded}
                            className="border-solid"
                        >
                            {' '}
                            <div className="flex items-center">
                                <ClassetInputText
                                    width={'305px'}
                                    icon={<FileAttachmentIcon width={20} height={24} color={'black'} />}
                                    className="text-xl add-vehicle-field-input"
                                    value={studentInfo.studentPhoto?.fileName}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />

                                {(studentInfo.studentPhoto?.storageLocation) && (
                                    <div className="ml-2" >
                                        <img src={studentInfo.studentPhoto?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                    </div>
                                )}

                                {/* {this.props.editUser?.user_info?.studentPhoto && (
                          <div className="ml-2">
                              <img
                                  src={this.props.editUser?.user_info?.studentPhoto?.storageLocation }
                                  alt="Uploaded Photo"
                                  width={50}
                                  height={50}
                              />
                          </div>
                      )} */}
                            </div>
                        </FileUpload>
                    </div>
                </div>

                <div className='flex justify-content-center gap-5 '>
                    <Button className='formSubmitButton px-6' style={{ border: 0 }} onClick={() => { this.EditStudentInfo() }} >
                        <p className='formSubmitButtonText'>Save</p>
                    </Button>
                    <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.props.isShowEditForm()} >
                        <p className='formCancelButtonText'>Cancel</p>
                    </Button>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
