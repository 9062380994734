import React, { Component } from 'react'
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD'
import ClassetDropdown from '../../classetComponents/classetDropDown'
import ClassetInputText from '../../classetComponents/classetInputText'
import ClassetCalendar from '../../classetComponents/classetCalender'
import { getFormFields, isFormValid, onTextChange } from '../../utile/formHelper'
import { baseUrlAdmin } from '../../store/apiConstants'
import Service from '../services'
import { BasicLazyParams } from '../../utile'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import FileUpload from '../fileUpload'
const createFields = require('./chequeinfo.json');
export default class ChequeInfo extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFields);
        this.state = {
            formValidations: this.formFields?.formValidations,
            chequeinfo: {},
            lazyParams: BasicLazyParams,
        }
        this.service = new Service()
    }

    _onProgress = (filesInfo) => { };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onPhotoSuccess = (fileName, data) => {
        this.addAttachment('chequePhoto', fileName, data);
    };
    _afterFilesUploaded = (files) => { };
    onUpload() {
        this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }
    addAttachment = (fieldName, fileName, data) => {
        console.log(fileName)
        this.setState((prevState) => ({
            chequeinfo: {
                ...prevState.chequeinfo,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };
    addCheque = async () => {
        this.setState({ isLoading: true })
        let data = { ...this.state.chequeinfo, studentId: this.state.studentId, chequeDate: this.state.chequeDate }
        const username = this.state.students?.find((e) =>
            e.value === this.state.studentId 
        );

        const payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            branchName: this.state.dropdownsData?.selectedbranchName,
            sectionName: this.state.dropdownsData?.selectedsectionName,
            boardName: this.state.dropdownsData?.selectedBoardName,
            className: this.state.dropdownsData?.selectedClassName,
            ...data,
            username: username?.label,
            chequeDDStatus: 'received',
            academicYear: localStorage.getItem('userAcademicYear')
        }
        const formStatus = isFormValid(createFields, this.formFields.formValidations, data);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }
        if (isCompleteFormValid) {
            let url = `${baseUrlAdmin}/cheque-dd-status/addchequeinfo`
            try {
                const res = await this.service.post(url, payload, true);
                if (res && res.status) {
                    this.setState({ isLoading: false, isShowCreateForm: false },()=>this.props.isShowCreateForm());
                   
                }
            } catch (err) {
                this.setState({ isLoading: false })
                return this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: err.errMessage, life: 3000 });
            }
        } else {
            return this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Please Fill All the required Fields', life: 3000 });
        }
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                termBasedData: [],
                studentId: '',
                username: '',
                user_info: {}
            },
            () => {
                this.getStudents();
            }
        );
    };
    getStudents = () => {
        this.setState({ isLoading1: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        this.service
            .post(url, payload, true)
            .then((data) => {
                const studentsData = data?.res?.data.map((each) => {
                    return {
                        label: each?.name,
                        value: each?.user_id,
                    };
                });

                if (data?.status) {
                    this.setState({
                        students: studentsData,
                        isLoading1: false
                    });
                } else {
                    this.setState({ isLoading1: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading1: false });
            });
    };
    onStudentChange = (e) => {
        this.setState({ studentId: e.value, termBasedData: [] }, () => {
            this.getTermBasedData();
        });
    };
    getTermBasedData = () => {
        this.setState({ isLoading: true, termBasedData: [] });
        let academicYear = localStorage.getItem('userAcademicYear');
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-collect/fee-types?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            classId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo
        };
        this.service
            ?.post(url, payload, true)
            .then((data) => {
                console.log(data);
                if (data?.status && data?.res?.data?.length) {
                    // const totalStudentDue=
                    this.setState({
                        termBasedData: data?.res?.data,
                        isLoading: false,
                        totalRecords: data?.res?.totalRecords,
                        partialPayment: data?.res?.partialPayment,
                        // partialPayment:false,
                        studentId: data?.res?.studentId,
                        username: data?.res?.username,
                        user_info: data?.res?.user_info,
                        totalStudentDue: Number(data?.res?.totalStudentDue)
                    });
                } else if (data?.status && !data?.res?.data?.length) {
                    this.setState({ isLoading: false, username: null, studentId: null });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'No Fee Structure Assigned to Student', life: 3000 });
                } else {
                    this.setState({ isLoading: false, username: null, studentId: null });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Error Occurred', life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    };
    render() {
        const { chequeinfo, formValidations } = this.state;
        return (
            <>
                <div className="grid col-12">
                    <div>
                        <BranchToSectionDD setValues={this.setDropdownValues} />
                    </div>
                    <div className="col-3">
                        <div className="mt-2">
                            <ClassetDropdown
                                id="paymentMode"
                                className=""
                                height="48px"
                                label={"Student"}
                                required={true}
                                width="330px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                // disabled={this.state?.searchLevel === 2}
                                onChange={(e) => this.onStudentChange(e)}
                                placeholder={
                                    <div>
                                        <span className="">Select Student</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <label className="label mb-2 ">
                            Cheque Issued To<span className="ma-required">*</span>
                        </label>

                        <div className="mt-2">
                            <ClassetInputText
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Enter Check Issued To"
                                value={chequeinfo.chequeIssuedTo}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'chequeIssuedTo', this, createFields, chequeinfo, formValidations, 'chequeinfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['chequeIssuedTo'].isValid && <p className="p-error">{formValidations.fields['chequeIssuedTo'].errorMsg}</p>}
                    </div>
                    <div className="col-3">
                        <label className="label mb-2 ">
                            Cheque No<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetInputText
                                // id='feeType'
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Enter Cheque No"
                                value={chequeinfo.chequeNo}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'chequeNo', this, createFields, chequeinfo, formValidations, 'chequeinfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['chequeNo'].isValid && <p className="p-error">{formValidations.fields['chequeNo'].errorMsg}</p>}
                    </div>
                    <div className="col-3">
                        <label className="label mb-2 ">
                            Amount<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetInputText
                                className=""
                                height="48px"
                                placeholder="Enter Amount"
                                keyfilter={"int"}
                                value={chequeinfo.amount}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'amount', this, createFields, chequeinfo, formValidations, 'chequeinfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['amount'].isValid && <p className="p-error">{formValidations.fields['amount'].errorMsg}</p>}
                    </div>
                    <div className="col-3">

                        <label className="label mb-2 ">
                            Cheque Date<span className="ma-required">*</span>
                        </label>

                        <div className="mt-2">
                            <ClassetCalendar
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Select Date"
                                calendarMode="single"
                                value={chequeinfo.chequeDate}
                                onChange={(e) =>
                                    this.setState({ chequeDate: e.value })}
                            />
                        </div>
                        {/* {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>} */}
                    </div>
                    <div className="col-3">
                        <label className="label mb-2 ">
                            Bank Name<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetInputText
                                // id='feeType'
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Enter Bank Name"
                                value={chequeinfo.bankName}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'bankName', this, createFields, chequeinfo, formValidations, 'chequeinfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['bankName'].isValid && <p className="p-error">{formValidations.fields['bankName'].errorMsg}</p>}
                    </div>
                    <div className="col-3">
                        <label className="label mb-2 ">
                            Bank Branch Name<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetInputText
                                id="feeType"
                                className=""
                                height="48px"
                                // width="330px"
                                placeholder="Bank Branch Name"
                                value={chequeinfo.bankBranchName}
                                onChange={(e) =>
                                    onTextChange(e.target.value, 'bankBranchName', this, createFields, chequeinfo, formValidations, 'chequeinfo', formValidations)}
                            />
                        </div>
                        {formValidations && !formValidations.fields['bankBranchName'].isValid && <p className="p-error">{formValidations.fields['bankBranchName'].errorMsg}</p>}
                    </div>
                    <div className="col-2" >
                        <p className="transport-fee-form-label">
                            Cheque Photo<span className="ma-required">*</span>
                        </p>
                        <FileUpload
                            id={'chequePhoto'}
                            multiple={false}
                            onProgress={this._onProgress}
                            onSucess={this._onPhotoSuccess}
                            //  Reset={this.state.Reset}
                            maxFileSize={307200}
                            onError={this._onFilsUploadError}
                            accept={'images'}
                            title={'Images'}
                            afterFilesUploaded={this._afterFilesUploaded}
                            className="border-solid"
                        >
                            {' '}
                            <div className="flex items-center">
                                <ClassetInputText
                                    width={'305px'}
                                    className="text-xl add-vehicle-field-input"
                                    value={chequeinfo.chequePhoto?.fileName}
                                    placeholder="Upload Photo"
                                    disabled={true}
                                />

                                {(chequeinfo.chequePhoto?.storageLocation) && (
                                    <div className="ml-2" >
                                        <img src={chequeinfo.chequePhoto?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                    </div>
                                )}

                                {/* {this.props.editUser?.user_info?.studentPhoto && (
                              <div className="ml-2">
                                  <img
                                      src={this.props.editUser?.user_info?.studentPhoto?.storageLocation }
                                      alt="Uploaded Photo"
                                      width={50}
                                      height={50}
                                  />
                              </div>
                          )} */}
                            </div>
                        </FileUpload>
                    </div>
                </div>

                <div className='flex justify-content-center align-items-center gap-5 '>
                    <Button className='formSubmitButton ' style={{ border: 0 }} onClick={() => { this.addCheque() }} >
                        <p className='formSubmitButtonText px-5'>Accept</p>
                    </Button>
                    <Button className='formCancelButton' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.props.isShowCreateForm()} >
                        <p className='formCancelButtonText px-5'>Cancel</p>
                    </Button>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
