import React, { Component } from 'react';
import { baseUrlAdmin, primaryColor } from '../../store/apiConstants';

import CreateTimeTable from './createTimeTable';
import TimeTableSettings from './timeTableSettings';
import TimeTableTemplate from './timeTableTemplate';
import TimeTables from './timeTable';
import Service from '../services';
import { Toast } from 'primereact/toast';
import { BasicLazyParams } from '../../utile';
import { current } from '@reduxjs/toolkit';
import LoadingComponent from '../loadingComponent';

export default class SettingsTimeTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // currentTab: 0,
            editTimeTable: [],
            timetables: [],
            editTimetableData: false,
            globalSearch: '',
            lazyParams: BasicLazyParams
        };
        this.service = new Service();
    }
    editTimeTable = (data) => {
        this.setState({
            editTimeTable: data
        });
    };
    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };

    createTimetable = (data) => {
        this.setState({
            isLoading: true
        });
        let thisObj = this;
        const url = `${baseUrlAdmin}/timetable/${this.state.editTimeTable ? 'update' : 'create'}`;
        console.log(this.state.editTimeTable ? 'update' : 'create');
        this.service[this.state.editTimeTable ? 'put' : 'post'](url, data, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            timeTableInfo: res.res.data,
                            editTimetableData: res.res.data,
                            isShowAssignTeacher: true
                        },
                        () => this.getTimeTablesTemplates()
                    );
                    this.toast.show({ severity: 'success', summary: `${this.state.editTimeTable ? 'Updated Successfully' : 'Created Successfully'}`, detail: res.errMessage, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            });
    };
    async componentDidMount() {
        await this.getTimeTablesTemplates();
    }
    getTimeTablesTemplates = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/timetable/all?academicYear=${academicYear}`;
        this.service
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            timetables: res.res.data.timeTables,
                            isLoading: false,
                            currentTab: 0
                        };
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        currentTab: 0
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                    currentTab: 0
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', life: 3000 });
                console.log(e);
            });
    };

    render() {
        return (
            <div>
                <div>
                    <div className="grid ml-1 mr-1 h-4rem poppins24">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                                <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tabheadText"
                                        onClick={() => {
                                            this.onTabChange(0);
                                        }}
                                    >
                                        Timetable
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.editTimeTable();
                                            this.onTabChange(1);
                                        }}
                                    >
                                        Timetable Template
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(2);
                                        }}
                                    >
                                        Timetable Settings
                                    </p>
                                </div>
                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p
                                        className="tab-p"
                                        onClick={() => {
                                            this.onTabChange(3);
                                        }}
                                    >
                                        Create Timetable
                                    </p>
                                </div>
                                {/* <div className={`p-col-4 p-md-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                            <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>List</p>
                        </div> */}
                            </div>
                        </div>
                    </div>
                    {this.state.currentTab == 0 && this.state.timetables && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.BOARDS_MANAGEMENT_LIST} > */}
                            <TimeTables onTabChange={this.onTabChange} sendEditData={this.editTimeTable} timetables={this.state.timetables} />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 1 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.GRADE_AND_SECTION_LIST} > */}
                            <TimeTableTemplate editData={this.state.editTimeTable} addNewTemplate={this.createTimetable} onTabChange={this.onTabChange} />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 2 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.SUBJECT_MANAGEMENT_LIST} > */}
                            <TimeTableSettings />
                            {/* </Authorizer> */}
                        </>
                    )}
                    {this.state.currentTab == 3 && (
                        <>
                            {/* <Authorizer permId={PERMISSIONS.CONTENT_MANAGEMENT_LIST} > */}
                            <CreateTimeTable templates={this.state.timetables} />
                            {/* </Authorizer> */}
                        </>
                    )}
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}
