import { DataTable } from 'primereact/datatable';
import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { BasicLazyParams } from '../../../utile';
import { AddIconInButtonFull, ArrowClockWise, ArrowLeftRight, MailIcon, Plus, RightMarkInCircle } from '../../svgIcons';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Button } from 'primereact/button';
import Service from '../../services';
import { RadioButton } from 'primereact/radiobutton';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import { getBoardsData } from '../../../store/actions';
import Authentication from '../../session';
import _, { cloneDeep } from 'lodash';
import LoadingComponent from '../../loadingComponent';


class CreateTimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lazyParams: BasicLazyParams,
            timeTableData: [],
            subjects: [],
            selectedTemplate: null,
            templates: this.props.templates || [],
            searchLevel: 0,
            isOpenCreateSubstitution: false,
            isOpenWarning: false,
            isTimeTableExistsInDB: false,
            timeTableId: null,
            warningTemplate: null,
            periodSubstitutionData: {},
            teacherCounts: [], // Track teacher counts
            subjectCounts: [], // Track subject counts
            isCountsModalOpen: false, // Modal visibility state
        };
        this.service = new Service();
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            () => {
                this.getStaffData();
                if (dropdownsData?.boardId?.length > 0 && dropdownsData?.classId?.length > 0) {
                    const filteredBoards = this.props.boards.filter((board) => board.boardId === dropdownsData.boardId);
                    if (filteredBoards.length > 0) {
                        const filteredClasses = filteredBoards[0].classes.filter((classItem) => classItem.classId === dropdownsData.classId);

                        this.setState(
                            {
                                groupId: filteredClasses[0].groups[0].groupId
                            },
                        );
                    }
                }
            }
        );
        // if (this.state.dropdownsData) {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        if ((branchId !== '' && boardId !== '' && classId !== '' && sectionId !== '')) {
            this.getTimeTableIfExists(dropdownsData);
        } else {
            this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
        }
    };
    saveTimeTable = () => {
        const subjectLimitCheck = this.checkSubjectPeriodLimits(this.state.timeTableData, this.state.settings);

        if (subjectLimitCheck.hasViolations) {
            let violationMessage = '';

            subjectLimitCheck.violations.forEach(violation => {
                if (violation.type === 'unequal_distribution') {
                    violationMessage = 'Subject periods must be equally distributed:\n';
                    violation.details.forEach(detail => {
                        violationMessage += `${detail.subjectName}: ${detail.count} periods\n`;
                    });
                } else if (violation.type === 'limit_exceeded') {
                    violationMessage += `${violation.subjectName}: ${violation.current} periods (limit: ${violation.limit})\n`;
                }
            });

            return this.toast.show({
                severity: 'error',
                summary: 'Subject Period Validation Failed',
                detail: violationMessage,
                life: 5000,
            });
        }
        const { timeTableData, selectedTemplate } = this.state;
        const { branchId, boardId, classId, sectionId, selectedbranchName, selectedBoardName, selectedClassName, selectedsectionName } = this.state.dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        const payload = {
            settings: this.state.settings,
            templateId: selectedTemplate,
            branchId,
            branchName: selectedbranchName,
            boardId,
            boardName: selectedBoardName,
            classId,
            className: selectedClassName,
            sectionId,
            sectionName: selectedsectionName,
            academicYear,
            timeTableData,
            users: this.state.users,
            orgId
        };
        this.setState({ isLoading: true })
        this.service
            .post(`${baseUrlAdmin}/timetable/savetimetable`, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.toast.show({ severity: 'success', summary: 'Time Table has been created succesfully', detail: '', life: 3000 });
                    this.setState({ timeTableId: res?.res?.data?._id, timeTableData: res?.res?.data?.timeTableData, selectedTemplate: res?.res?.data?.templateId, isTimeTableExistsInDB: true, isLoading: false }, () => {
                        this.dynamicColumns(res?.res?.data?.timeTableData)
                    });
                    // this.getTimeTableIfExists({ branchId, boardId, classId, sectionId });
                } else {
                    throw new Error(res?.errMessage || 'Some error occured');
                    // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage || "", life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message || 'error', life: 3000 });
            });
    };
    getTimeTableIfExists = async (dropdownsData) => {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        const orgId = localStorage.getItem('orgId');
        let url = `${baseUrlAdmin}/timetable/gettimetable?branchId=${branchId}&boardId=${boardId}&classId=${classId}&sectionId=${sectionId}&academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res && res.status && res.res.status) {
                if (res.res.data) {
                    const timeTableData = res.res.data;
                    this.setState({ timeTableId: res.res.data._id, timeTableData: timeTableData.timeTableData, selectedTemplate: timeTableData.templateId, isTimeTableExistsInDB: true }, () => {
                        this.dynamicColumns(timeTableData.timeTableData)
                    });
                } else {
                    this.setState({ isTimeTableExistsInDB: false, selectedTemplate: null, timeTableData: [] })
                }
            }
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    }
    getSavedData = async () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        const url = `${baseUrlAdmin}/timetable/get-settings?academicYear=${academicYear}`;


        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ isLoading: false });
                const data = res?.res?.data;
                this.setState({ settings: data });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }
    getStaffData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        let branchIds = this.state?.dropdownsData?.branchId;
        let gradeIds = this.state?.dropdownsData?.classId;
        let sectionIds = this.state?.dropdownsData?.sectionId;
        let boardIds = this.state?.dropdownsData?.boardId;

        let url;
        if (branchIds && boardIds && gradeIds && sectionIds) {
            url = `${baseUrlAdmin}/staff?branchIds=${branchIds}&boardIds=${boardIds}&gradeIds=${gradeIds}&sectionIds=${sectionIds}&academicYear=${academicYear}&limit=${10000}&page=${1}`;
            try {
                this.setState({ isLoading: true });

                const res = await this.service.get(url, true);
                if (res?.res?.status && res.status) {
                    // console.log(res?.res?.data, 'res?.res?.datares?.res?.data');

                    const users = res?.res?.data?.records;
                    console.log(users);
                    this.setState({
                        users: users,
                        totalRecords: res?.res?.data?.totalRecords,
                        isLoading: false,
                        teacherCounts: users.map(user => ({
                            teacherId: user.user_id,
                            teacherName: user.user_info.name,
                            periodsAssigned: 0
                        })) // Initialize teacher counts
                    }, () => {
                        this.getCurriculumData();
                    });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
                }
            } catch (e) {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
            }
        }


    };
    onChangeTemplate = (value) => {
        if (this.state.isTimeTableExistsInDB) {
            console.log(this.state.timeTableData);
            this.setState({ isOpenWarning: true, warningTemplate: value })
            return;
        }
        const selectedTemplateData = this.props.templates.filter((template) => template._id === value);
        if (!selectedTemplateData || !selectedTemplateData[0] || !selectedTemplateData[0].periods) return;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const newTimeTableData = {
            ...this.state.timeTableData,
            TimeTable: daysOfWeek.map((day) => ({
                day: day,
                Periods: selectedTemplateData[0].periods
            }))
        };

        this.setState({
            timeTableData: newTimeTableData,
            selectedTemplate: value
        });
        this.dynamicColumns(newTimeTableData);
    };
    componentDidMount() {
        this.getSavedData();
    }
    openCreateSubstitution = (periodData, day) => {
        const updated = { ...periodData, Day: day, subjectName: periodData?.Subject?.subjectId, Teacher: periodData?.Teacher?.user_id };
        this.setState({ isOpenCreateSubstitution: true, periodSubstitutionData: updated }, () => this.onChangeTeacher(periodData?.Teacher?.user_id));
    };
    onChangeTeacher = (value) => {
        const selectedTeacher = this.state.users.find((teacher) => teacher.user_id === value);
        if (selectedTeacher) {
            console.log(selectedTeacher);

            this.setState({
                periodSubstitutionData: {
                    ...this.state.periodSubstitutionData,
                    Teacher: value
                },
                subjects: selectedTeacher.user_info.assignedSubjects.filter(assignedSubject =>
                    this.state.gradeSubjects.some(gradeSubject => gradeSubject.subjectId === assignedSubject.subjectId)
                ) || []
            });
            console.log(this.state.subjects);
        } else {
            console.log("Teacher not found");
            this.setState({
                subjects: []
            });
        }
    };

    getCurriculumData = () => {
        const { boardId, classId } = this.state.dropdownsData;
        const { groupId } = this.state;
        if (boardId && classId) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
            this.service
                .get(url, true)
                .then((data) => {
                    console.log(data);
                    if (data && data.res && data.res.Item) {
                        this.setState({
                            gradeSubjects: data.res.Item.subjects,
                            subjects: data.res.Item.subjects,
                            isLoading: false,
                            subjectCounts: data.res.Item.subjects.map(subject => ({
                                subjectId: subject.subjectId,
                                subjectName: subject.subjectName,
                                periodsAssigned: 0
                            })) // Initialize subject counts
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            this.setState({
                isLoading: false
            });
        }
    };
    dynamicColumns = (timeTableData) => {
        if (!timeTableData || !timeTableData?.TimeTable) return [];

        const columns = [];
        columns.push(<Column key="day" field="day" header={<div style={{ color: 'black' }}>Days/Periods</div>} align={'left'} body={(rowData) => <div style={{ color: 'black' }}>{rowData.day}</div>} />);

        if (timeTableData.TimeTable.length > 0) {
            const firstDayPeriods = timeTableData.TimeTable[0].Periods;

            firstDayPeriods.forEach((_, index) => {
                columns.push(
                    <Column
                        key={`period-${index + 1}`}
                        field={`Period_${index + 1}`}
                        header={
                            <div style={{ color: 'black', textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="text-ellipsis">
                                <div>{_.name}</div>
                                <div style={{ fontSize: '9px', color: 'gray' }}>
                                    {moment(_.startTime).format('hh:mm A')} - {moment(_.endTime).format('hh:mm A')}
                                </div>
                            </div>
                        }

                        body={(rowData) => {
                            const periodData = rowData.Periods[index];
                            if (periodData?.type === 'break') {
                                return (
                                    <div style={{
                                        margin: '0',
                                        padding: '0',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <div className="break-period" style={{
                                            color: '#666', // Darker gray text
                                            fontWeight: '500', // Slightly bolder
                                            writingMode: 'horizontal-tb',
                                            textAlign: 'center',
                                            width: '100%',
                                            letterSpacing: '1px', // Spread letters slightly
                                            textTransform: 'uppercase' // Ensure uppercase
                                        }}>
                                            BREAK
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div style={{ margin: '0', padding: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
                                    <div style={{ marginBottom: '20px' }} >
                                        <span
                                            data-pr-tooltip="Edit User"
                                            onClick={() => {
                                                this.openCreateSubstitution(periodData, rowData.day);
                                            }}
                                        >
                                            {periodData?.Teacher?.name ?
                                                <ArrowLeftRight width={16} height={16} color={'#000000'} />
                                                : <Plus width={16} height={16} color={'#000000'} />
                                            }
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal',
                                            color: periodData?.Subject?.subjectName ? '#0A9EDC' : 'gray'
                                        }}
                                    >
                                        {periodData?.Subject?.subjectName
                                            ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                            : <p style={{ fontStyle: 'italic', fontWeight: '100', fontSize: 12, color: 'lightgray' }}>Not Assigned</p>}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: periodData?.Teacher?.name ? 'normal' : 'bold',
                                            color: periodData?.Teacher?.name ? 'gray' : 'black'
                                        }}
                                    >
                                        {periodData?.Teacher?.name
                                            ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                            : ''}
                                    </div>

                                </div>
                            );
                        }}
                    />
                );
            });
        }

        return columns;
    };
    handleTemplateChangeAcceptance = async () => {
        const url = `${baseUrlAdmin}/timetable/delTimeTable/${this.state.timeTableId}`
        try {
            const res = this.service.delete(url, true);
            if (res.status === 200) {
                this.toast.show({ severity: 'success', summary: 'Time Table has been deleted succesfully' });
            }
        } catch (err) {
            // console.log(err);
            this.toast.show({ severity: 'error', summary: 'Error while deleting timetable' });

        }

        this.setState({ isTimeTableExistsInDB: false, isOpenWarning: false }, () => this.onChangeTemplate(this.state.warningTemplate))
    }
    checkTeacherAvailability = (teacher, newPeriod) => {
        if (!teacher?.user_info?.myTimeTable) {
            return {
                available: true
            };
        }

        let conflictDetails = null;

        // Get all periods for the same day
        const conflictingPeriod = teacher.user_info.myTimeTable.find(timetable => {
            // Skip periods with substitution dates
            const periodList = timetable?.periodList?.filter(period => !period.substitutionDate) || [];

            // Check only periods on the same day
            const dayPeriods = periodList.filter(period => period.Day === newPeriod.Day);

            const hasConflict = dayPeriods.some(period => {
                const existingStart = moment(period.startTime);
                const existingEnd = moment(period.endTime);
                const newStart = moment(newPeriod.startTime);
                const newEnd = moment(newPeriod.endTime);

                // Convert to local time minutes for comparison
                const existingStartMins = existingStart.hours() * 60 + existingStart.minutes();
                const existingEndMins = existingEnd.hours() * 60 + existingEnd.minutes();
                const newStartMins = newStart.hours() * 60 + newStart.minutes();
                const newEndMins = newEnd.hours() * 60 + newEnd.minutes();

                // Check for any overlap
                const isOverlapping = (
                    (newStartMins < existingEndMins && newStartMins >= existingStartMins) || // New period starts during existing period
                    (newEndMins > existingStartMins && newEndMins <= existingEndMins) || // New period ends during existing period
                    (newStartMins <= existingStartMins && newEndMins >= existingEndMins) // New period completely contains existing period
                );

                if (isOverlapping) {
                    conflictDetails = {
                        timetable,
                        period
                    };
                    return true;
                }
                return false;
            });

            return hasConflict;
        });

        if (conflictingPeriod && conflictDetails) {
            return {
                available: false,
                conflict: conflictingPeriod,
                conflictingPeriod: conflictDetails.period
            };
        }

        return {
            available: true
        };
    };
    checkMaxPeriods = (teacher, newPeriod, settings) => {
        const isExceptedFromDayLimit = settings?.maxEmployeeExceptionPerDay?.includes(teacher.user_id);
        const isExceptedFromWeekLimit = settings?.maxEmployeeExceptionPerWeek?.includes(teacher.user_id);

        if (isExceptedFromDayLimit && isExceptedFromWeekLimit) {
            return {
                dayLimitExceeded: false,
                weekLimitExceeded: false,
                currentDayCount: 0,
                currentWeekCount: 0,
                maxPerDay: 'No Limit',
                maxPerWeek: 'No Limit',
                isExcepted: true
            };
        }

        let teacherDayPeriodCount = 0;
        let teacherWeekPeriodCount = 0;
        const maxPeriodsPerDay = Number(settings?.maxPeriodsPerDay) || 0;
        const maxPeriodsPerWeek = Number(settings?.maxPeriodsPerWeek) || 0;

        // Count from existing timetable (user_info.myTimeTable)
        if (teacher?.user_info?.myTimeTable?.length > 0) {
            teacher.user_info.myTimeTable.forEach(timetable => {
                const periodList = timetable?.periodList?.filter(period => !period.substitutionDate) || [];
                const dayPeriods = periodList.filter(period => period.Day === newPeriod.Day);
                teacherDayPeriodCount += dayPeriods.length;
                teacherWeekPeriodCount += periodList.length;
            });
        }

        // Count from current timetable being created
        if (this.state.timeTableData?.TimeTable) {
            const currentPeriodCount = { day: 0, week: 0 };

            this.state.timeTableData.TimeTable.forEach(dayEntry => {
                // Only count periods where this teacher is assigned
                const assignedPeriods = dayEntry.Periods.filter(period =>
                    period.type === 'class' &&
                    period.Teacher?.user_id === teacher.user_id
                );

                // Add to week count
                currentPeriodCount.week += assignedPeriods.length;

                // Add to day count if same day
                if (dayEntry.day === newPeriod.Day) {
                    currentPeriodCount.day += assignedPeriods.length;
                }
            });

            // Add current timetable counts to total
            teacherDayPeriodCount += currentPeriodCount.day;
            teacherWeekPeriodCount += currentPeriodCount.week;
        }

        // Add 1 to include the new period being assigned
        const newDayCount = teacherDayPeriodCount + 1;
        const newWeekCount = teacherWeekPeriodCount + 1;

        console.log('Period counts:', {
            day: {
                current: teacherDayPeriodCount,
                new: newDayCount,
                limit: maxPeriodsPerDay
            },
            week: {
                current: teacherWeekPeriodCount,
                new: newWeekCount,
                limit: maxPeriodsPerWeek
            }
        });

        return {
            dayLimitExceeded: !isExceptedFromDayLimit && newDayCount > maxPeriodsPerDay,
            weekLimitExceeded: !isExceptedFromWeekLimit && newWeekCount > maxPeriodsPerWeek,
            currentDayCount: teacherDayPeriodCount,
            newDayCount: newDayCount,
            currentWeekCount: teacherWeekPeriodCount,
            newWeekCount: newWeekCount,
            maxPerDay: isExceptedFromDayLimit ? 'No Limit' : maxPeriodsPerDay,
            maxPerWeek: isExceptedFromWeekLimit ? 'No Limit' : maxPeriodsPerWeek,
            isExceptedFromDayLimit,
            isExceptedFromWeekLimit
        };
    };
    checkContinuousPeriods = (timeTableData, newPeriod, newSubject) => {
        if (!this.state.settings?.enableContinousPeriods) {
            return { allowed: true };
        }

        // Find continuous period limit for this subject
        const continuousLimit = this.state.settings.continousPeriods.find(cp =>
            cp.board === this.state.dropdownsData.boardId &&
            cp.grade === this.state.dropdownsData.classId &&
            cp.subject.includes(newSubject.subjectId)
        );

        if (!continuousLimit) {
            return { allowed: true };
        }

        const maxContinuous = Number(continuousLimit.maxNumberOfContinousPeriods);

        // Find the day entry for the new period
        const dayEntry = timeTableData.TimeTable.find(day => day.day === newPeriod.Day);
        if (!dayEntry) {
            return { allowed: true };
        }

        // Find the period index
        const periodIndex = dayEntry.Periods.findIndex(p => p.periodId === newPeriod.periodId);
        if (periodIndex === -1) {
            return { allowed: true };
        }

        // Check continuous periods before this period
        let continuousCount = 1; // Start with 1 for the current period
        let i = periodIndex - 1;
        while (i >= 0) {
            const period = dayEntry.Periods[i];
            if (period.type === 'break' ||
                !period.Subject ||
                period.Subject.subjectId !== newSubject.subjectId) {
                break;
            }
            continuousCount++;
            i--;
        }

        // Check continuous periods after this period
        i = periodIndex + 1;
        while (i < dayEntry.Periods.length) {
            const period = dayEntry.Periods[i];
            if (period.type === 'break' ||
                !period.Subject ||
                period.Subject.subjectId !== newSubject.subjectId) {
                break;
            }
            continuousCount++;
            i++;
        }

        return {
            allowed: continuousCount <= maxContinuous,
            current: continuousCount,
            limit: maxContinuous
        };
    };
    onAccept = () => {
        console.log(this.state.timeTableData)
        const teacher = this.state.users.find((user) => user.user_id === this.state.periodSubstitutionData.Teacher);
        const subject = this.state.subjects.find((subject) => subject.subjectId === this.state.periodSubstitutionData.subjectName);
        if (!teacher || !subject) return this.toast.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Please select a teacher and subject',
            life: 3000,
        })
        const simplifiedSubject = {
            subjectName: subject.subjectName,
            subjectId: subject.subjectId,
            subjectCode: subject.subjectCode
        }
        const simplifiedTeacher = {
            user_id: teacher?.user_id,
            username: teacher?.user_info?.username,
            name: teacher?.user_info?.name,
        };

        const availability = this.checkTeacherAvailability(teacher, this.state?.periodSubstitutionData);

        if (!availability?.available) {
            const conflict = availability?.conflict;
            const conflictingPeriod = availability?.conflictingPeriod; // Use the actual conflicting period
            return this.toast?.show({
                severity: 'error',
                summary: 'Teacher Already Assigned',
                detail: `Teacher is busy in ${conflict?.branchName} - ${conflict?.boardName} - ${conflict?.className} - ${conflict?.sectionName}
                         ${conflictingPeriod?.name} (${moment(conflictingPeriod?.startTime).format('hh:mm A')} - ${moment(conflictingPeriod?.endTime).format('hh:mm A')})
                        Day: ${conflictingPeriod?.Day}`,
                life: 5000,
            });
        }

        const periodsCheck = this.checkMaxPeriods(teacher, this.state?.periodSubstitutionData, this.state?.settings);

        // In onAccept method
        if (periodsCheck?.dayLimitExceeded) {
            return this.toast?.show({
                severity: 'error',
                summary: 'Daily Limit Exceeded',
                detail: `Teacher has reached the maximum number of periods per day (${periodsCheck?.maxPerDay}). 
                Current: ${periodsCheck?.currentDayCount}, New total would be: ${periodsCheck?.newDayCount}`,
                life: 3000,
            });
        }

        if (periodsCheck.weekLimitExceeded) {
            return this.toast.show({
                severity: 'error',
                summary: 'Weekly Limit Exceeded',
                detail: `Teacher has reached the maximum number of periods per week (${periodsCheck.maxPerWeek}). 
                Current: ${periodsCheck.currentWeekCount}, New total would be: ${periodsCheck.newWeekCount}`,
                life: 3000,
            });
        }

        const continuousCheck = this.checkContinuousPeriods(
            this.state.timeTableData,
            this.state.periodSubstitutionData,
            subject
        );

        if (!continuousCheck.allowed) {
            return this.toast.show({
                severity: 'error',
                summary: 'Continuous Periods Limit Exceeded',
                detail: `Cannot assign ${subject.subjectName} here as it would create ${continuousCheck.current} continuous periods (limit: ${continuousCheck.limit})`,
                life: 3000,
            });
        }

        const updatedPeriodSubstitutionData = { ...this.state.periodSubstitutionData, Teacher: simplifiedTeacher, Subject: simplifiedSubject };
        delete updatedPeriodSubstitutionData.subjectName;
        const updatedTimeTable = this.state.timeTableData.TimeTable.map((dayEntry) => {
            if (dayEntry.day === this.state.periodSubstitutionData.Day) {
                return {
                    ...dayEntry,
                    Periods: dayEntry.Periods.map((period) => {
                        if (period.periodId === updatedPeriodSubstitutionData.periodId) {
                            return { ...period, ...updatedPeriodSubstitutionData };
                        }
                        return period;
                    })
                };
            }
            return dayEntry;
        });

        const updatedTimeTableData = { ...this.state.timeTableData, TimeTable: updatedTimeTable };

        // Update teacher and subject counts
        const updatedTeacherCounts = this.state.teacherCounts.map(teacher =>
            teacher.teacherId === simplifiedTeacher.user_id
                ? { ...teacher, periodsAssigned: teacher.periodsAssigned + 1 }
                : teacher
        );
        const updatedSubjectCounts = this.state.subjectCounts.map(subject =>
            subject.subjectId === simplifiedSubject.subjectId
                ? { ...subject, periodsAssigned: subject.periodsAssigned + 1 }
                : subject
        );

        this.setState(
            {
                timeTableData: updatedTimeTableData,
                isOpenCreateSubstitution: false,
                periodSubstitutionData: {},
                teacherCounts: updatedTeacherCounts,
                subjectCounts: updatedSubjectCounts
            },
            () => this.dynamicColumns(updatedTimeTableData)
        );
    };
    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value });
    };
    assignTeachersAutomatically = () => {
        const { timeTableData, users, settings, subjects } = this.state;

        // Validation checks
        if (!timeTableData?.TimeTable || !users?.length || !settings || !subjects?.length) {
            return this.toast.show({
                severity: 'error',
                summary: 'Missing Data',
                detail: 'Please ensure all required data is loaded',
                life: 3000
            });
        }

        // Deep clone the timetable
        const newTimeTable = cloneDeep(timeTableData);

        // Filter available teachers and their subjects
        const availableTeachers = users.filter(teacher =>
            teacher.user_info?.assignedSubjects?.length > 0 &&
            teacher.user_info.assignedSubjects.some(subject =>
                this.state.gradeSubjects.some(gs => gs.subjectId === subject.subjectId)
            )
        );

        // Track teacher assignments for better distribution
        const teacherAssignments = new Map();
        availableTeachers.forEach(teacher => {
            teacherAssignments.set(teacher.user_id, {
                dailyCounts: {},
                weeklyCount: 0
            });
        });

        // Get subject distribution requirements
        const subjectLimits = settings?.subjectToPeriodLimits?.filter(limit =>
            limit.board === this.state.dropdownsData.boardId &&
            limit.grade === this.state.dropdownsData.classId
        ) || [];

        // Track subject assignments
        const subjectCounts = new Map();

        // Process each day and period
        for (const dayEntry of newTimeTable.TimeTable) {
            for (const period of dayEntry.Periods) {
                // Skip if period is break or already assigned
                if (period.type === 'break' || period.Teacher) {
                    continue;
                }

                // Shuffle teachers for random distribution
                const shuffledTeachers = [...availableTeachers]
                    .sort(() => Math.random() - 0.5)
                    .sort((a, b) => {
                        const aCount = teacherAssignments.get(a.user_id).weeklyCount;
                        const bCount = teacherAssignments.get(b.user_id).weeklyCount;
                        return aCount - bCount; // Prioritize teachers with fewer assignments
                    });

                let assigned = false;
                for (const teacher of shuffledTeachers) {
                    if (assigned) break;

                    // Get teacher's eligible subjects
                    const teacherSubjects = teacher.user_info.assignedSubjects
                        .filter(subject =>
                            this.state.gradeSubjects.some(gs => gs.subjectId === subject.subjectId)
                        );

                    // Shuffle subjects
                    const shuffledSubjects = [...teacherSubjects]
                        .sort(() => Math.random() - 0.5)
                        .sort((a, b) => {
                            const aCount = subjectCounts.get(a.subjectId) || 0;
                            const bCount = subjectCounts.get(b.subjectId) || 0;
                            return aCount - bCount; // Prioritize subjects with fewer assignments
                        });

                    for (const subject of shuffledSubjects) {
                        // Check all constraints
                        const canAssign = this.canAssignSlot(
                            teacher,
                            subject,
                            period,
                            dayEntry.day,
                            teacherAssignments.get(teacher.user_id),
                            subjectCounts.get(subject.subjectId) || 0,
                            subjectLimits
                        );

                        if (canAssign) {
                            // Update assignments
                            const teacherStats = teacherAssignments.get(teacher.user_id);
                            teacherStats.dailyCounts[dayEntry.day] = (teacherStats.dailyCounts[dayEntry.day] || 0) + 1;
                            teacherStats.weeklyCount++;
                            subjectCounts.set(subject.subjectId, (subjectCounts.get(subject.subjectId) || 0) + 1);

                            // Assign period
                            period.Teacher = {
                                user_id: teacher.user_id,
                                username: teacher.user_info.username,
                                name: teacher.user_info.name
                            };
                            period.Subject = {
                                subjectId: subject.subjectId,
                                subjectName: subject.subjectName,
                                subjectCode: subject.subjectCode
                            };
                            assigned = true;
                            break;
                        }
                    }
                }
            }
        }

        // Validate final timetable
        const subjectLimitCheck = this.checkSubjectPeriodLimits(newTimeTable, settings);
        if (subjectLimitCheck.hasViolations) {
            return this.toast.show({
                severity: 'error',
                summary: 'Subject Distribution Error',
                detail: 'Unable to create a valid timetable that meets all requirements',
                life: 5000
            });
        }

        // Update state
        this.setState({
            timeTableData: newTimeTable
        }, () => {
            this.dynamicColumns(newTimeTable);
            this.toast.show({
                severity: 'success',
                summary: 'Timetable Generated',
                detail: 'Teachers and subjects have been automatically assigned',
                life: 3000
            });
        });
    };

    // Add this helper method
    canAssignSlot = (teacher, subject, period, day, teacherStats, subjectCount, subjectLimits) => {
        // Check teacher availability
        const availability = this.checkTeacherAvailability(teacher, {
            ...period,
            Day: day
        });
        if (!availability.available) return false;

        // Check period limits
        const periodsCheck = this.checkMaxPeriods(teacher, {
            ...period,
            Day: day
        }, this.state.settings);
        if (periodsCheck.dayLimitExceeded || periodsCheck.weekLimitExceeded) return false;

        // Check continuous periods
        const continuousCheck = this.checkContinuousPeriods(
            this.state.timeTableData,
            { ...period, Day: day },
            subject
        );
        if (!continuousCheck.allowed) return false;

        // Check subject limits
        const subjectLimit = subjectLimits.find(limit =>
            limit.subject.includes(subject.subjectId)
        );
        if (subjectLimit && subjectCount >= Number(subjectLimit.periodsPerWeek)) {
            return false;
        }

        return true;
    };
    checkSubjectPeriodLimits = (timeTableData, settings) => {
        const subjectCounts = {};
        const violations = [];

        // Get subject limits from settings
        const subjectLimits = settings?.subjectToPeriodLimits?.filter(limit =>
            limit.board === this.state.dropdownsData.boardId &&
            limit.grade === this.state.dropdownsData.classId
        ) || [];

        // Count periods for each subject in the timetable
        timeTableData.TimeTable.forEach(dayEntry => {
            dayEntry.Periods.forEach(period => {
                if (period.type === 'class' && period.Subject?.subjectId) {
                    const subjectId = period.Subject.subjectId;
                    subjectCounts[subjectId] = (subjectCounts[subjectId] || 0) + 1;
                }
            });
        });

        // If all subjects should be equal
        if (settings?.isAllSubjectsEqual) {
            const counts = Object.values(subjectCounts);
            if (counts.length > 0) {
                const firstCount = counts[0];
                const hasUnequalDistribution = counts.some(count => count !== firstCount);

                if (hasUnequalDistribution) {
                    // Get subject names for better error messaging
                    const subjectDetails = Object.entries(subjectCounts).map(([subjectId, count]) => {
                        const subject = this.state.subjects.find(s => s.subjectId === subjectId);
                        return {
                            subjectName: subject?.subjectName || 'Unknown Subject',
                            count: count
                        };
                    });

                    return {
                        hasViolations: true,
                        violations: [{
                            type: 'unequal_distribution',
                            message: 'Subjects must have equal number of periods',
                            details: subjectDetails
                        }]
                    };
                }
            }
        }

        // Check regular subject limits
        Object.entries(subjectCounts).forEach(([subjectId, count]) => {
            const subjectLimit = subjectLimits.find(limit => limit.subject.includes(subjectId));
            if (subjectLimit) {
                const maxAllowed = Number(subjectLimit.periodsPerWeek);
                if (count > maxAllowed) {
                    const subject = this.state.subjects.find(s => s.subjectId === subjectId);
                    violations.push({
                        type: 'limit_exceeded',
                        subjectName: subject?.subjectName || 'Unknown Subject',
                        current: count,
                        limit: maxAllowed
                    });
                }
            }
        });

        return {
            hasViolations: violations.length > 0,
            violations
        };
    };
    openCountsModal = () => {
        const { timeTableData, gradeSubjects, users } = this.state;

        // Calculate subject counts
        const subjectCounts = gradeSubjects.map(subject => {
            const periodsAssigned = timeTableData.TimeTable.reduce((count, dayEntry) => {
                return count + dayEntry.Periods.filter(period => period.Subject?.subjectId === subject.subjectId).length;
            }, 0);
            return {
                subjectId: subject.subjectId,
                subjectName: subject.subjectName,
                periodsAssigned
            };
        });

        // Calculate teacher counts
        const teacherCounts = users.map(teacher => {
            const periodsAssigned = timeTableData.TimeTable.reduce((count, dayEntry) => {
                return count + dayEntry.Periods.filter(period => period.Teacher?.user_id === teacher.user_id).length;
            }, 0);
            return {
                teacherId: teacher.user_id,
                teacherName: teacher.user_info.name,
                periodsAssigned
            };
        });

        this.setState({ subjectCounts, teacherCounts, isCountsModalOpen: true });
    };

    closeCountsModal = () => {
        this.setState({ isCountsModalOpen: false });
    };

    render() {
        const { timeTableData, dropdownsData, teacherCounts, subjectCounts, isCountsModalOpen } = this.state;

        // Check if at least one period is assigned
        const hasAssignedPeriods = timeTableData?.TimeTable?.some(dayEntry =>
            dayEntry.Periods.some(period => period.Teacher || period.Subject)
        );

        return (
            <div className="poppins24">
                <div className="col-12 flex justify-content-end align-items-center">
                    <Button onClick={() => this.saveTimeTable()} style={{ borderRadius: 10, backgroundColor: '#076EFF' }} className="flex align-items-center gap-1">
                        <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                        <p className="text-xl">Save</p>
                    </Button>
                </div>

                <div className="grid m-2">
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                    <div className=" col-3 mx-1">
                        <ClassetDropdown
                            required={true}
                            label={'TimeTableTemplate'}
                            icon={<MailIcon height={16.5} width={19.5} />}
                            placeholder={'Select TimeTable Template'}
                            options={this.state.templates}
                            value={this.state.selectedTemplate}
                            onChange={(e) => {
                                this.onChangeTemplate(e.value)
                            }}
                            optionLabel="title"
                            optionValue="_id"
                            disabled={this.state.isTimeTableExistsInDB}
                        />
                    </div>
                </div>
                <div className="flex align-items-center justify-content-between mx-5">
                    <div className="flex align-items-center gap-5">
                        <div className="flex mt-1">
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(0)} checked={this.state.searchLevel === 0} />
                            </div>
                            <div className="field ml-4" style={{ fontWeight: 'bolder', color: 'black', fontSize: '15px' }}>
                                Manual Creation
                            </div>
                        </div>
                        <div className="flex mt-1">
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                            </div>
                            <div className="field ml-4" style={{ fontWeight: 'bolder', color: 'black', fontSize: '15px' }}>
                                Automatic Creation
                            </div>
                        </div>
                        <div className="flex -mt-3">
                            {this.state.searchLevel === 1 && (
                                <Button
                                    className="p-button-rounded p-button-text"
                                    label="Refresh"
                                    style={{ backgroundColor: '#076EFF', color: 'white', borderRadius: '10px' }}
                                    icon={<ArrowClockWise width={16} height={16} color={'white'} />}
                                    tooltipOptions={{ position: 'top' }}
                                    onClick={this.assignTeachersAutomatically}
                                />
                            )}
                        </div>
                    </div>
                    {hasAssignedPeriods && (
                        <div className="flex -mt-3">
                            <Button
                                className="p-button-rounded p-button-text flex align-items-center gap-1 "
                                label="Counts"
                                style={{ backgroundColor: '#076EFF', color: 'white', borderRadius: '10px' }}
                                tooltipOptions={{ position: 'top' }}
                                onClick={this.openCountsModal}
                            >
                            </Button>
                        </div>
                    )}
                </div>

                {timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={timeTableData.TimeTable}
                        dataKey="id"
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesTextt"
                        showGridlines
                        selectionMode={'checkbox'}
                        columnResizeMode="expand"
                        // resizableColumns
                        // onPage={this.onPage}
                        size='large'

                    >
                        {this.dynamicColumns(timeTableData)}
                    </DataTable>
                )}
                {timeTableData.TimeTable === null ||
                    !dropdownsData?.branchId ||
                    !dropdownsData?.boardId ||
                    !dropdownsData?.classId ||
                    !dropdownsData?.sectionId ? (
                    <p className="mx-5" style={{ color: 'black', fontSize: '18px', fontWeight: 'bolder' }}>
                        Please select all fields
                    </p>
                ) : null}

                <Dialog
                    visible={this.state.isOpenCreateSubstitution}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }} className="">
                                    {this.state.periodSubstitutionData?.name}
                                </p>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }} className="">
                                    Day:{this.state.periodSubstitutionData?.Day}
                                </p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.onAccept();
                                }}
                            >
                                <p className="formSubmitButtonText">Accept</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isOpenCreateSubstitution: false, remarks: '' })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isOpenCreateSubstitution: false })}
                >
                    <div className="flex flex-column">
                        <div className=" col-12">
                            <ClassetDropdown
                                required={true}
                                label={'Select Teacher'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Teacher'}
                                options={this.state?.users?.map(user => ({
                                    ...user,
                                    formattedUsername: user.user_info.username.slice(3) // Remove first 3 letters
                                }))}
                                value={this.state.periodSubstitutionData.Teacher}
                                onChange={(e) =>
                                    this.onChangeTeacher(e.target.value)
                                }
                                optionLabel="formattedUsername"
                                optionValue="user_id"
                            />
                        </div>
                        <div className=" col-12">
                            <ClassetDropdown
                                required={true}
                                label={'Select Subject'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Subject'}
                                options={this.state.subjects}
                                value={this.state.periodSubstitutionData.subjectName}
                                onChange={(e) =>
                                    this.setState({
                                        periodSubstitutionData: {
                                            ...this.state.periodSubstitutionData,
                                            subjectName: e.target.value
                                        }
                                    })
                                }
                                optionLabel="subjectName"
                                optionValue="subjectId"
                            />
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isOpenWarning}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle />
                                </div>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.handleTemplateChangeAcceptance()
                                }}
                            >
                                <p className="formSubmitButtonText">Accept</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isOpenWarning: false })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isOpenCreateSubstitution: false })}
                >
                    <h1>Your forcefully changing template ?????</h1>
                </Dialog>
                <Dialog
                    visible={isCountsModalOpen}
                    style={{ width: '50vw' }}
                    header="Teacher and Subject Counts"
                    onHide={this.closeCountsModal}
                >
                    <div className="grid">
                        <div className="col-6">
                            <h3>Teacher Counts</h3>
                            <DataTable value={teacherCounts}>
                                <Column field="teacherName" header="Teacher" />
                                <Column field="periodsAssigned" header="Periods Assigned" />
                            </DataTable>
                        </div>
                        <div className="col-6">
                            <h3>Subject Counts</h3>
                            <DataTable value={subjectCounts}>
                                <Column field="subjectName" header="Subject" />
                                <Column field="periodsAssigned" header="Periods Assigned" />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(CreateTimeTable)));
