import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { BasicLazyParams } from '../../../utile';
import withRouter from '../../lib/withRouter';
import { getBoardsData } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';


const transportStatus = [
    { label: 'ASSIGNED', value: 'ASSIGNED' },
    { label: 'NOT ASSIGNED', value: 'NOT ASSIGNED' },
    { label: 'PENDING', value: 'PENDING' },
    { label: 'REJECTED', value: 'REJECTED' }
]
class AssignManagementTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assignTransportForm: false,
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            vehicleDropdown: [],
            selectedBuses: {},
            selectedStops: {},
            routeData: [],
            selectedStudentPickup: {},
            selectedStudentDrop: {},
            planId: '',
            busNo: null,
            boardId: null,
            classId: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedStudentsInfo: [],
            selectAll: false,
            selectedUsers: []
        };
        this.service = new Service();
    }

    componentDidMount() { }

    onCancel = () => {
        this.setState(
            {
                assignTransportForm: false,
                dataForAddRoutes: {}
            },
            () => this.getRoutesDataForDropdown()
        );
    };

    getRoutesDataForDropdown = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/transport-route/get-routes?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            gradeId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                const routeName = res?.res?.data?.routeData.map((routeName) => ({
                    label: routeName.routeName,
                    value: routeName._id
                }));
                this.setState({ vehicleDropdown: routeName, routeData: res?.res?.data?.routeData, isLoading: false, transportSettings: res?.res.data.transportSettings }, () => this.getAssignTransportData());
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error  d', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item,) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });

                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.branchData !== this.props.branchData) {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            };
            this.setState({
                sectionsData
            });
        }
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [],
            classId: null,
            curriculumInfo: null,
            isShowSubjects: false
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        if (selectedBoard && selectedBoard?.classes && selectedBoard?.classes?.length > 0) {
            selectedBoard?.classes.forEach((item) => _classes?.push({ label: item.className, value: item.classId, groupId: item?.groups ? item?.groups[0]?.groupId : '' }));
        }
        let selectedBoardName = this.props.boards?.find((board) => board.boardId == boardId).boardName;
        this.setState({
            classes: _classes,
            boardId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedBoardName: selectedBoardName
        });
    };

    onChangeClass = (classId) => {
        let selectedClass = this.state?.classes?.find((classd) => classd.value == classId);
        this.setState({
            classId: classId,
            selectedSubject: [],
            selectedChapter: [],
            selectedTopic: [],
            selectedClassName: selectedClass?.label,
            groupId: selectedClass.groupId
        });
    };

    getAssignTransportData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });

        let url = `${baseUrlAdmin}/transport-route/students/getforRoutes?boardId=${this.state.dropdownsData.boardId}&gradeId=${this.state.dropdownsData.classId}&sectionId=${this.state.dropdownsData.sectionId}&academicYear=${academicYear}`;

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const users = res?.res?.data?.addmissionTransport;

                const selectedBuses = {};
                const selectedStops = {};
                const selectedStudentPickup = {};
                const selectedStudentDrop = {};

                users.forEach((user, index) => {
                    selectedBuses[index] = user.transportRoute;
                    selectedStudentPickup[index] = user.pickStop;
                    selectedStudentDrop[index] = user.dropStop;
                    const route = this.state.routeData.find((route) => route._id === user.transportRoute);
                    if (route) {
                        selectedStops[index] = route.stops;
                    }
                });

                this.setState({
                    users,
                    filteredUsers: users,
                    usersInfo: users,
                    totalRecords: res?.res?.data?.totalRecords,
                    selectedBuses,
                    selectedStops,
                    selectedStudentPickup,
                    selectedStudentDrop,
                    isLoading: false
                },);
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };


    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                filteredUsers: [],
                users: [],
                selectedStudentsInfo: [],
                selectAll: false,
            },
            async () => {
                dropdownsData?.boardId &&
                    dropdownsData?.classId &&
                    dropdownsData?.branchId &&
                    dropdownsData?.sectionId &&
                    await this.getRoutesDataForDropdown();
            }
        );
    };
    onAssignTransport = (rowData) => {
        const { selectedStudentsInfo, filteredUsers, routeData } = this.state;
        const selectedUsers = selectedStudentsInfo.length > 0
            ? filteredUsers.filter(user => selectedStudentsInfo.some(selectedUser => selectedUser._id === user._id))
            : rowData ? [rowData] : [];

        if (selectedUsers.length === 0) {
            return this.toast.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please select at least one student',
                life: 3000
            });
        }

        try {

            // Create a map of route capacities
            const routeCapacities = {};
            routeData?.forEach(route => {
                if (route?._id) {
                    routeCapacities[route._id] = {
                        seatingCapacity: route.seatingCapacity || 0,
                        requestedCapacity: route.requestedCapacity || 0,
                        routeName: route.routeName
                    };
                }
            });

            // Count new requests per route
            const routeRequests = {};
            selectedUsers.forEach(user => {
                const newRouteId = user?.routeInfo?._id;
                if (!newRouteId) return;

                // Find user's existing route from usersInfo
                const existingUser = this.state.usersInfo.find(u => u._id === user._id);
                const existingRouteId = existingUser?.transportRoute;

                // If user is changing routes
                if (existingRouteId !== newRouteId) {
                    // Increment count for new route
                    routeRequests[newRouteId] = (routeRequests[newRouteId] || 0) + 1;

                    // Decrement count for old route if exists
                    if (existingRouteId) {
                        routeCapacities[existingRouteId].requestedCapacity =
                            Math.max(0, (routeCapacities[existingRouteId].requestedCapacity || 0) - 1);
                    }
                }
                // If same route but different stops, don't count towards capacity
            });

            // Check capacity for each route
            for (const routeId in routeRequests) {
                if (!routeCapacities[routeId]) continue;

                const { seatingCapacity, requestedCapacity, routeName } = routeCapacities[routeId];
                const newRequests = routeRequests[routeId];
                const availableSeats = seatingCapacity - requestedCapacity;

                if (newRequests > availableSeats) {
                    return this.toast.show({
                        severity: 'error',
                        summary: 'Capacity Exceeded',
                        detail: `Route "${routeName}" has insufficient capacity. Available: ${availableSeats}, Requested: ${newRequests}`,
                        life: 3000
                    });
                }
            }

            // Check if all selected students have at least one of pickup or drop stop, and an effective date
            const invalidAssignments = selectedUsers.filter(user => {
                const hasPickupStop = user?.selectedPickUpStop && Object.keys(user?.selectedPickUpStop || {}).length > 0;
                const hasDropStop = user?.selectedDropStop && Object.keys(user?.selectedDropStop || {}).length > 0;
                const hasEffectiveDate = !!user?.effectiveDate;
                // Either pickup or drop stop must be present, and effective date is required
                return (!hasPickupStop && !hasDropStop) || !hasEffectiveDate;
            });
            // Create locationInfo object for valid assignments which contains locationLink, dropTime and pickUpTime
            let locationInfo = {};

            selectedUsers.forEach(user => {
                // Get route information from selected bus
                const userRoute = this.state.routeData.find(route =>
                    route._id === (user.routeInfo?._id || user.transportRoute)
                );

                if (userRoute) {
                    // Extract pickup stop details if available
                    if (user.selectedPickUpStop && user.selectedPickUpStop._id) {
                        const pickupStop = userRoute?.stops?.find(stop => stop._id === user.selectedPickUpStop._id);
                        if (pickupStop && pickupStop.pickUpTime) {
                            locationInfo.pickUpTime = new Date(pickupStop.pickUpTime);
                            locationInfo.pickupLocationLink = pickupStop.locationLink;
                        }
                    }

                    // Extract drop stop details if available
                    if (user.selectedDropStop && user.selectedDropStop._id) {
                        const dropStop = userRoute?.stops?.find(stop => stop._id === user.selectedDropStop._id);
                        if (dropStop && dropStop.dropTime) {
                            locationInfo.dropTime = new Date(dropStop.dropTime);
                            locationInfo.dropLocationLink = dropStop.locationLink;
                        }
                    }

                    user.locationInfo = locationInfo
                    locationInfo = {}
                }
            });

            if (invalidAssignments.length > 0) {
                return this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please select at least one stop (pickup or drop) and effective date for all selected students',
                    life: 3000
                });
            }

            // Process valid assignments
            console.log("Valid assignments:", selectedUsers);

            this.setState({
                dataForAddRoutes: selectedUsers,
            }, this.onRaiseRequest);

        } catch (error) {
            console.error('Error in onAssignTransport:', error);
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while processing the transport assignment',
                life: 3000
            });
        }
    }; // Added missing closing brace and semicolon

    onRaiseRequest = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        const { dataForAddRoutes } = this.state;

        if (!Array.isArray(dataForAddRoutes) || dataForAddRoutes.length === 0) {
            this.setState({ isLoading: false });
            return this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'No students selected for transport assignment',
                life: 3000
            });
        }
        // Get hierarchy information
        const selectedHierarchy = {
            boardId: this.state.dropdownsData.boardId,
            gradeId: this.state.dropdownsData.classId,
            sectionId: this.state.dropdownsData.sectionId,
            branchId: this.state.dropdownsData.branchId
        };

        const requests = dataForAddRoutes.map(student => {
            // Create a deep copy to avoid modifying original data
            const dataForAddRoutes = { ...student };
            if (dataForAddRoutes?.routeInfo?.stops) {
                delete dataForAddRoutes.routeInfo.stops;
            }

            // Prepare location info
            const locationInfo = {
                pickUpTime: dataForAddRoutes.locationInfo?.pickUpTime,
                dropTime: dataForAddRoutes.locationInfo?.dropTime,
                pickupLocationLink: dataForAddRoutes.locationInfo?.pickupLocationLink,
                dropLocationLink: dataForAddRoutes.locationInfo?.dropLocationLink
            };

            return {
                ...locationInfo,
                ...dataForAddRoutes,
            };
        });



        let payload = {
            academicYear,
            dataForAddRoutes,
            ...selectedHierarchy
        };
        let url = `${baseUrlAdmin}/transport-assign-approval/assign/request`;
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({
                    isLoading: false
                });

                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: res?.res?.message,
                    life: 3000
                });
                await this.getRoutesDataForDropdown()
                await this.getAssignTransportData()
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    onSelectAllChange = (event) => {
        const { checked } = event;
        const { filteredUsers, usersInfo } = this.state;
        console.log(filteredUsers, usersInfo, "filteredUsers, usersInfo")
        // Filter out students without fee plan or with pending status
        const validStudentsForSelection = filteredUsers.filter(student =>
            student.isFeePlanAssigned === true && student.isAssigned !== "PENDING"
        );

        if (validStudentsForSelection.length === 0) {
            this.toast.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Cannot select students without transport fee plan or with pending status',
                life: 3000
            });
            return;
        }

        // Check for changes by comparing with original data
        const studentsWithChanges = validStudentsForSelection.filter(student => {
            const originalStudent = usersInfo.find(u => u._id === student._id);
            if (!originalStudent) return false;

            // Check if pickup stop, drop stop or effective date has changed
            const pickupChanged = student.selectedPickUpStop?._id && student.selectedPickUpStop?._id !== originalStudent.pickStop;
            const dropChanged = student.selectedDropStop?._id && student.selectedDropStop?._id !== originalStudent.dropStop;
            const effectiveDateChanged = student.effectiveDate !== originalStudent.effectiveDate;

            return pickupChanged || dropChanged || effectiveDateChanged;
        });

        if (studentsWithChanges.length === 0) {
            this.toast.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'No changes detected in student transport details',
                life: 3000
            });
            return;
        }

        // Only select students that have changes
        const selectedStudentsInfo = checked ? studentsWithChanges : [];

        this.setState({
            selectedStudentsInfo,
            selectAll: checked && studentsWithChanges.length === validStudentsForSelection.length
        });
    };
    onSelectionChange = (event) => {
        const hasInvalidSelection = event.value.some(student =>
            student.isFeePlanAssigned === false || student.isAssigned === "PENDING"
        );

        if (hasInvalidSelection) {
            this.toast.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Cannot select students without transport fee plan or with pending status',
                life: 3000
            });
            return;
        }

        // Compare selected students with their original data
        const hasChanges = event.value.some(selectedStudent => {
            const originalStudent = this.state.usersInfo.find(u => u._id === selectedStudent._id);
            if (!originalStudent) return false;
            const pickupChanged = selectedStudent.selectedPickUpStop?._id && selectedStudent.selectedPickUpStop?._id !== originalStudent.pickStop;
            const dropChanged = selectedStudent.selectedDropStop?._id && selectedStudent.selectedDropStop?._id !== originalStudent.dropStop;
            const effectiveDateChanged = selectedStudent.effectiveDate !== originalStudent.effectiveDate;

            return pickupChanged || dropChanged || effectiveDateChanged;
        });

        if (!hasChanges) {
            return;
        }

        this.setState({
            selectedStudentsInfo: event.value,
            selectAll: event.value.length === this.state.filteredUsers.length
        });

    };

    getValidDate = (dateValue) => {
        if (!dateValue) return null;
        const date = new Date(dateValue);
        return isNaN(date.getTime()) ? null : date;
    };

    render() {
        return (
            <>
                <div className="mt-2 flex justify-content-start align-items-center">
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                    <div className="ml-3">
                        <p className={`ma-label-s2 font-serif font-semibold text-900 text-lg`}>Status</p>
                        <ClassetMultiSelect
                            optionLabel="label"
                            optionValue="value"
                            value={this.state.statusFilter}
                            options={transportStatus}
                            onChange={(e) => {
                                const selectedStatuses = e.target.value;
                                this.setState({ statusFilter: selectedStatuses }, () => {
                                    // If no status is selected or ALL is selected, show all data
                                    if (!selectedStatuses.length) {
                                        this.getAssignTransportData();
                                    } else {
                                        // Filter locally if already fetched data
                                        const filteredUsers = this.state.users.filter(user => {
                                            // Check if user's status matches any of the selected statuses
                                            return selectedStatuses.some(status =>
                                                status === user.isAssigned ||
                                                (status === 'NOT ASSIGNED' && !user.isAssigned)
                                            );
                                        });
                                        this.setState({ filteredUsers });
                                    }
                                });
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span className="text-lg pl-1">Search by Status</span>
                                </div>
                            }
                            width="250px"
                            height="48px"
                            className="text-xl"
                        />
                    </div>
                </div>
                <div className="card datatable-crud-demo mt-2">
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        className="dataTableValuesText"
                        columnResizeMode="fit"
                        value={this.state.filteredUsers}
                        dataKey="_id"
                        paginator
                        responsiveLayout="scroll"
                        showGridlines
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        selection={this.state?.selectedStudentsInfo}
                        onSelectionChange={this.onSelectionChange}
                        selectAll={this.state.selectAll}
                        onSelectAllChange={this.onSelectAllChange}
                        selectionMode="checkbox"
                    >
                        <Column
                            selectionMode="multiple"
                            headerStyle={{ width: '3rem', textAlign: 'start', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                        />
                        <Column
                            field="name"
                            header="Student Name"
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            filterField="studentName"
                        />

                        <Column
                            field="parent"
                            header="Parent Name"
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            filterField="parent"
                        />

                        <Column
                            field="phone_number"
                            header="Contact Details"
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            filterField="contactDetails"
                        />
                        <Column
                            field="isFeePlanAssigned"
                            header="Transport Fee"
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            filterField="isFeePlanAssigned"
                            body={(rowData) =>
                                <p style={{}} >
                                    {rowData?.isFeePlanAssigned === true ? "TRUE" : "FALSE"}
                                </p>

                            }
                        />
                        <Column
                            header="Approval Status"
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            body={(rowData) => (
                                <Button
                                    className="formCancelButton"
                                    style={{
                                        backgroundColor: rowData?.isAssigned === "ASSIGNED" ? '#E1F7D8'
                                            : rowData?.isAssigned === "PENDING" ? '#FFF4A1'
                                                : '#FFECF1',
                                        borderColor: 'lightgray',
                                        borderWidth: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 15,
                                        padding: 0,
                                        height: '32px',
                                        width: '100px'
                                    }}
                                >
                                    <p className="sideHeading" style={{
                                        fontSize: 12,
                                        lineHeight: '16px',
                                        color: rowData?.isAssigned === "ASSIGNED" ? '#1E6D00'
                                            : rowData?.isAssigned === "PENDING" ? '#FFA500'
                                                : '#BF0031'
                                    }}>
                                        {rowData?.isAssigned}
                                    </p>
                                </Button>
                            )}
                        />

                        <Column
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="Bus Routes"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <ClassetDropdown
                                        optionLabel={'label'}
                                        optionValue={'value'}
                                        width={'169px'}
                                        height={'48px'}
                                        className="text-xl add-vehicle-field-input"
                                        value={this.state.selectedBuses[rowIndex] || null}
                                        options={this.state.vehicleDropdown}
                                        onChange={(e) => {
                                            const selectedRouteId = e.target.value;
                                            const currentStudent = this.state.filteredUsers[rowIndex];
                                            const originalStudent = this.state.usersInfo.find(user => user._id === currentStudent._id);
                                            const selectedRoute = this.state.routeData.find((route) => route._id === selectedRouteId);

                                            if (selectedRoute) {
                                                const seatingCapacity = selectedRoute.seatingCapacity;
                                                let requestedCapacity = selectedRoute.requestedCapacity || 0;

                                                // If student is already assigned to this route in original data
                                                if (originalStudent?.transportRoute === selectedRouteId) {
                                                    // Don't count this student in capacity check
                                                    requestedCapacity--;
                                                }

                                                if (requestedCapacity >= seatingCapacity) {
                                                    this.toast.show({
                                                        severity: 'error',
                                                        summary: 'Error',
                                                        detail: 'Bus capacity is full. Please select another route.',
                                                        life: 3000
                                                    });
                                                    return;
                                                }
                                            }

                                            const selectedBuses = { ...this.state.selectedBuses, [rowIndex]: selectedRouteId };
                                            const selectedStops = { ...this.state.selectedStops, [rowIndex]: selectedRoute ? selectedRoute.stops : [] };
                                            this.setState({ selectedBuses, selectedStops });
                                        }}
                                        disabled={rowData?.isFeePlanAssigned === false || rowData?.isAssigned === "PENDING"}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className="text-lg pl-1"> Select Bus</span>
                                            </div>
                                        }
                                    />
                                );
                            }}
                        ></Column>
                        <Column
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="PickUp Location"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <ClassetDropdown
                                        optionLabel={'label'}
                                        optionValue={'value'}
                                        width={'169px'}
                                        height={'48px'}
                                        className="text-xl add-vehicle-field-input"
                                        value={this.state?.selectedStudentPickup[rowIndex] || null}
                                        options={
                                            this.state?.selectedStops[rowIndex]?.map((stop) => {
                                                // Format the pickup time to show only time part (HH:MM AM/PM)
                                                let timeDisplay = '';
                                                if (stop.pickUpTime) {
                                                    const date = new Date(stop.pickUpTime);
                                                    if (!isNaN(date.getTime())) {
                                                        timeDisplay = date.toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        });
                                                    }
                                                }

                                                return {
                                                    label: `${stop.stopName} ${timeDisplay ? `- ${timeDisplay}` : ''}`,
                                                    value: stop._id
                                                };
                                            }) || []
                                        }
                                        disabled={rowData?.isFeePlanAssigned === false || rowData?.isAssigned === "PENDING"}
                                        onChange={(e) => {
                                            const selectedStudentPickup = { ...this.state.selectedStudentPickup, [rowIndex]: e.target.value };
                                            const routeName = this.state.routeData.find((route) => route._id === this.state.selectedBuses[rowIndex]);
                                            let selectedPickUpStop = routeName?.stops?.find((stops) => stops._id === e.target.value);
                                            this.setState((prevState) => ({
                                                selectedStudentPickup: selectedStudentPickup,
                                                filteredUsers: prevState.filteredUsers.map((user, index) =>
                                                    index === rowIndex
                                                        ? {
                                                            ...user,
                                                            routeNo: e.target.value,
                                                            routeName: routeName?.routeName,
                                                            vehicleRegistrationNo: routeName?.vehicleRegistrationNo,
                                                            vehicleName: routeName?.vehicleName,
                                                            selectedPickUpStop,
                                                            routeInfo: routeName
                                                        }
                                                        : user
                                                )
                                            }));
                                        }}
                                        placeholder={
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className="text-lg pl-1"> Select Location</span>
                                            </div>
                                        }
                                    />
                                );
                            }}
                        ></Column>
                        <Column
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            className=" "
                            header="Drop Location"
                            body={(rowData, { rowIndex }) => (
                                <ClassetDropdown
                                    optionLabel={'label'}
                                    optionValue={'value'}
                                    width={'169px'}
                                    height={'48px'}
                                    className="text-xl add-vehicle-field-input"
                                    value={this.state?.selectedStudentDrop[rowIndex] || null}
                                    disabled={rowData?.isFeePlanAssigned === false || rowData?.isAssigned === "PENDING"}
                                    options={
                                        this.state?.selectedStops[rowIndex]?.map((stop) => {
                                            // Format the pickup time to show only time part (HH:MM AM/PM)
                                            let timeDisplay = '';
                                            if (stop.dropTime) {
                                                const date = new Date(stop.dropTime);
                                                if (!isNaN(date.getTime())) {
                                                    timeDisplay = date.toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    });
                                                }
                                            }

                                            return {
                                                label: `${stop.stopName} ${timeDisplay ? `- ${timeDisplay}` : ''}`,
                                                value: stop._id
                                            };
                                        }) || []
                                    }
                                    onChange={(e) => {
                                        const selectedStudentDrop = { ...this.state.selectedStudentDrop, [rowIndex]: e.target.value };
                                        const routeName = this.state.routeData.find((route) => route._id === this.state.selectedBuses[rowIndex]);
                                        let selectedDropStop = routeName?.stops?.find((stops) => stops._id === e.target.value);
                                        this.setState((prevState) => ({
                                            selectedStudentDrop: selectedStudentDrop,
                                            filteredUsers: prevState.filteredUsers.map((user, index) =>
                                                index === rowIndex
                                                    ? {
                                                        ...user,
                                                        routeNo: e.target.value,
                                                        routeName: routeName?.routeName,
                                                        vehicleRegistrationNo: routeName?.vehicleRegistrationNo,
                                                        vehicleName: routeName?.vehicleName,
                                                        selectedDropStop,
                                                        routeInfo: routeName
                                                    }
                                                    : user
                                            )
                                        }));
                                    }}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="text-lg pl-1"> Select Location</span>
                                        </div>
                                    }
                                />
                            )}
                        />
                        <Column
                            style={{ minWidth: '150px' }}
                            headerStyle={{ textAlign: 'center', padding: '0.5rem' }}
                            bodyStyle={{ textAlign: 'start', padding: '0.5rem' }}
                            header="Effective Date"
                            body={(rowData, { rowIndex }) => (
                                <ClassetCalendar
                                    width={'169px'}
                                    height={'48px'}
                                    className="border-calendar"
                                    inputClassName="font-semibold"
                                    calendarMode={'single'}
                                    value={this.getValidDate(rowData.effectiveDate)}
                                    placeholder="Select Effective Date"
                                    disabled={rowData?.isFeePlanAssigned === false || rowData?.isAssigned === "PENDING"}
                                    // minDate={new Date()}
                                    // showIcon
                                    // yearNavigator
                                    yearRange="2000:2050"
                                    onChange={(e) => {
                                        const selectedDate = e.value;
                                        this.setState((prevState) => ({
                                            filteredUsers: prevState.filteredUsers.map((user, index) =>
                                                index === rowIndex
                                                    ? {
                                                        ...user,
                                                        effectiveDate: selectedDate,

                                                    }
                                                    : user
                                            )
                                        }));
                                    }}
                                />
                            )}
                        />
                  {/*       <Column 
                            style={{ width: "15rem", height: "6rem" }} 
                            alignHeader={"center"} 
                            headerClassName="text-color-secondary" 
                            bodyClassName='text-center' 
                            className=' ' 
                            // header="Actions" 
                            body={(rowData, { rowIndex }) => (
                                rowData?.isAssigned === "ASSIGNED" ? (
                                    <>
                                        <Button
                                            id={rowData?.key}
                                            onClick={(event) => this[rowData?.key].toggle(event)}
                                            icon="pi pi-ellipsis-v"
                                            className='p-button-text p-button-secondary ma-ml10'
                                        />
                                        <Menu 
                                            model={[
                                                {
                                                    label: 'Change Route',
                                                    command: () => {
                                                        this.setState({
                                                            showRouteChangeDialog: true,
                                                            selectedStudent: rowData,
                                                            tempSelectedRoute: null,
                                                            tempSelectedPickup: null,
                                                            tempSelectedDrop: null,
                                                            tempEffectiveDate: null
                                                        });
                                                    },
                                                },
                                                {
                                                    label: 'Unassign',
                                                    command: () => {
                                                        this.setState({
                                                            showEffectiveDateDialog: true,
                                                            selectedStudent: rowData
                                                        });
                                                    }
                                                }
                                            ]}
                                            style={{ width: "150px" }}
                                            className="h-7rem card shadow-4 flex flex-column justify-content-center align-items-center"
                                            popup={true}
                                            ref={el => this[rowData?.key] = el}
                                        />
                                    </>
                                ) : null
                            )}
                        /> */}
                    </DataTable>



                </div>
                <div className="flex justify-content-end mt-2">
                    <Button
                        label="Send for approvals"
                        disabled={this.state.selectedStudentsInfo.length === 0}
                        onClick={() => this.onAssignTransport()}
                        style={{
                            borderRadius: '8px',
                            padding: '0.75rem 1.5rem',
                            fontWeight: 600,
                            background: 'var(--components-button-primary-background-default, #076EFF)',
                            border: 'none',
                            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)'
                        }}
                    />
                </div>
                <Dialog
                    visible={this.state.showRouteChangeDialog}
                    onHide={() => this.setState({ showRouteChangeDialog: false })}
                    header="Change Route"
                    headerClassName="text-center"
                    style={{ width: '500px' }}
                >
                    <div className="p-fluid">
                        <div className="field">
                            <label className="add-vehicle-field-label">Route</label>
                            <ClassetDropdown
                                optionLabel={'label'}
                                optionValue={'value'}
                                value={this.state.tempSelectedRoute}
                                options={this.state.vehicleDropdown}
                                onChange={(e) => {
                                    const selectedRoute = this.state.routeData.find(r => r._id === e.value);
                                    this.setState({
                                        tempSelectedRoute: e.value,
                                        tempRouteStops: selectedRoute?.stops || []
                                    });
                                }}
                                placeholder="Select Route"
                            />
                        </div>

                        <div className="field">
                            <label className="add-vehicle-field-label">Pickup Stop</label>
                            <ClassetDropdown
                                optionLabel={'label'}
                                optionValue={'value'}
                                value={this.state.tempSelectedPickup}
                                options={this.state.tempRouteStops?.map(stop => ({
                                    label: stop.stopName,
                                    value: stop._id
                                }))}
                                onChange={(e) => this.setState({ tempSelectedPickup: e.value })}
                                placeholder="Select Pickup Stop"
                            />
                        </div>

                        <div className="field">
                            <label className="add-vehicle-field-label">Drop Stop</label>
                            <ClassetDropdown
                                optionLabel={'label'}
                                optionValue={'value'}
                                value={this.state.tempSelectedDrop}
                                options={this.state.tempRouteStops?.map(stop => ({
                                    label: stop.stopName,
                                    value: stop._id
                                }))}
                                onChange={(e) => this.setState({ tempSelectedDrop: e.value })}
                                placeholder="Select Drop Stop"
                            />
                        </div>

                        <div className="field">
                            <label className="add-vehicle-field-label m-0 p-0">Effective Date</label>
                            <ClassetCalendar
                                value={this.state.tempEffectiveDate}
                                onChange={(e) => this.setState({ tempEffectiveDate: e.value })}
                                placeholder="Select Date"
                            />
                        </div>

                        <div className="flex align-items-center justify-content-center mt-4">
                            <Button
                                label="Send for Approval"
                                onClick={() => {
                                    const student = {
                                        ...this.state.selectedStudent,
                                        routeInfo: this.state.routeData.find(r => r._id === this.state.tempSelectedRoute),
                                        selectedPickUpStop: this.state.tempRouteStops.find(s => s._id === this.state.tempSelectedPickup),
                                        selectedDropStop: this.state.tempRouteStops.find(s => s._id === this.state.tempSelectedDrop),
                                        effectiveDate: this.state.tempEffectiveDate
                                    };
                                    this.onAssignTransport([student]);
                                    this.setState({ showRouteChangeDialog: false });
                                }}
                                className="p-button-primary"
                                style={{ width: '200px' }}
                            />
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.showEffectiveDateDialog}
                    onHide={() => this.setState({ showEffectiveDateDialog: false })}
                    header="Select Effective Date"
                    headerClassName="text-center"
                    style={{ width: '30vw' }}
                >
                    <div className="flex flex-column align-items-center">
                        <ClassetCalendar
                            width={'250px'}
                            height={'48px'}
                            className="border-calendar mb-3"
                            inputClassName="font-semibold"
                            calendarMode={'single'}
                            value={this.state.effectiveDate}
                            placeholder="Select Effective Date"
                            yearRange="2000:2050"
                            onChange={(e) => this.setState({ effectiveDate: e.value })}
                        />
                        <div className="flex justify-content-center mt-3">
                            <Button
                                label="Confirm"
                                style={{ width: '150px' }}
                                onClick={() => { this.setState({ showEffectiveDateDialog: false }, () => this.onAssignTransport()) }}
                                className="p-button-primary my-2"
                            />
                        </div>
                    </div>
                </Dialog>
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha')
});

export default connect(mapStateToProps, { getBoardsData })(Authentication(withRouter(AssignManagementTable)));
