import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onTextChange, onDropDownChange } from '../../../utile/formHelper';
import _, { cloneDeep } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import InputTextB from '../../customComponents/inputTextB';
import Service from './../../services';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import { DeleteIconClasset, LocationMarker, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { InputSwitch } from 'primereact/inputswitch';
const createRouteFields = require('./createRoute.json');
let formFields = createRouteFields;

class CreateRoute extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createRouteFields);
        this.state = {
            route: this.props?.dataForAddRoutes?.id ? this.props?.dataForAddRoutes : this.formFields.data,
            formValidations: this.formFields.formValidations,
            stopsList: [],
            focused: false,
            inputFocused: false,
            boards: [],
            classes: [],
            academicYears: [],
            vehicleDropdowns: [],
            feeTypeDropDown: [],
            stops: [{ id: 1, name: '', lat: '', lng: '', pickUpTime: '', dropTime: '', transportFee: '' }],
            isShowCreateSuccess: false,
            hideUpdateButton: false,
        };

        this.service = new Service();
    }

    componentDidMount() {
        if (this.props.dataForAddRoutes?._id) {
            const modifiedStops = this.props.dataForAddRoutes?.stops.map((stop) => {
                return {
                    ...stop,
                    dropTime: new Date(stop.dropTime),
                    pickUpTime: new Date(stop.pickUpTime)
                };
            });
            this.setState(
                {
                    route: {
                        branchId: this.props.dataForAddRoutes.branchId,
                        vehicleId: this.props.dataForAddRoutes.vehicleId,
                        routeName: this.props.dataForAddRoutes.routeName,
                        registration_number: this.props.dataForAddRoutes.vehicleId,
                        routeNo: this.props.dataForAddRoutes.routeNo,
                        commonTransportFee: this.props.dataForAddRoutes.commonTransportFee,
                        isDifferentialRouteFee: this.props.dataForAddRoutes.isDifferentialRouteFee
                    },
                    stops: modifiedStops
                },
                () => this.getDropdownsData(true)
            );
        }
    }

    getDropdownsData = async (editMode) => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state.route.branchId
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                const vehicles = res?.res?.data?.vehicles?.map((vehicles) => ({
                    label: vehicles.vehicleName,
                    value: vehicles._id,
                    registrationNumber: vehicles.vehicleRegistrationNo
                }));
                const fee = res?.res?.data?.feePlans?.map((fee) => ({
                    label: fee.feeName,
                    value: fee._id
                }));
                this.setState({ vehicleDropdowns: vehicles, feeTypeDropDown: fee, isLoading: false }, () => {
                    if (editMode) {
                        this.setState((prevState) => {
                            return {
                                route: {
                                    ...prevState.route,
                                    vehicleId: this.props.dataForAddRoutes.vehicleId
                                },
                                isLoading: false
                            };
                        });
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'some Error', life: 3000 });
        }
    };

    isValid = (value) => value !== undefined && value !== null && value !== '';

    onCreateRoute = async () => {

        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        const fieldData = cloneDeep(this.state.route);
        if (this.props?.dataForAddRoutes?._id) {
            const stopData = this.state?.stops || [];
            let timeCheckFlag = true
            const stopsForApi = stopData?.map((stop) => {
                const pickUpTime = new Date(stop.pickUpTime);
                const dropTime = new Date(stop.dropTime);

                if (pickUpTime >= dropTime) {
                    timeCheckFlag = false
                }
                let stopObject = {
                    stopName: stop.stopName,
                    pickUpTime: pickUpTime,
                    dropTime: dropTime,
                    locationLink: stop.locationLink,
                    transportFee: stop.transportFee ? Number(stop.transportFee) : Number(this.state.route.commonTransportFee)
                };
                if (stop._id) {
                    stopObject._id = stop._id;
                }
                return stopObject
            }).filter(stop => stop !== null);
            if (!timeCheckFlag) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
                return;
            }
            stopsForApi.sort((a, b) => {
                return new Date(a.pickUpTime) - new Date(b.pickUpTime);
            });
            const allValid = stopsForApi?.every((stop) => Object.values(stop).every(this.isValid));
            if (!allValid) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter all the required fields', life: 3000 });
                return;
            }
            const payloadData = {
                routeId: this.props?.dataForAddRoutes?._id,
                stop: stopsForApi || [],
                academicYear: academicYear,
                branchId: this.props?.dataForAddRoutes?.branchId,
                commonTransportFee: this.state.route.commonTransportFee,
                isDifferentialRouteFee: this.state.route.isDifferentialRouteFee,
            };
            let url = `${baseUrlAdmin}/transport-route/stops/add`;
            try {
                
                const allFieldsFilled = stopsForApi?.every(stop =>
                    stop.stopName && stop.locationLink && stop.pickUpTime && stop.dropTime && stop.transportFee
                );
                let isTimeValid = stopsForApi?.every((stop) => {
                    return this.isPickUpTimeLessThanDropTime(stop);
                });
                if (allFieldsFilled && isTimeValid) {
                } else {
                    if (!allFieldsFilled) {

                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields in existing stops', life: 3000 });
                        throw new Error('Please fill all the fields in existing stops');

                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
                        throw new Error('PickUp time must be earlier than Drop time');

                    }
                }
                const res = await this.service.post(url, payloadData, true);
                if (res?.res?.status && res.status) {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                    this.props.onCancel();
                    this.props.getRoutesData();
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                }
            } catch (e) {
                this.setState({
                    isLoading: false
                });
                console.log(e);
                this.toast.show({ severity: 'error', summary: e.message || e, detail: 'Some error occured', life: 3000 });
            }
        } else {
            let body = cloneDeep(this.state.route);
            const stopData = this.state?.stops || [];


            const stopsForApi = stopData?.map((stop) => ({
                stopName: stop.stopName,
                pickUpTime: stop.pickUpTime,
                dropTime: stop.dropTime,
                locationLink: stop.locationLink,
                transportFee: stop.transportFee ? Number(stop.transportFee) : Number(this.state.route.commonTransportFee)
            }));
            stopsForApi.sort((a, b) => {
                return new Date(a.pickUpTime) - new Date(b.pickUpTime);
            });
            const formStatus = isFormValid(createRouteFields, this.formFields.formValidations, body);

            if (!formStatus.formValidations.isFormValid) {
                this.setState({ formValidations: formStatus.formValidations, isLoading: false });
            } else {
                const payload = {
                    vehicleId: body?.vehicleId,
                    routeName: body?.routeName,
                    stops: stopsForApi || [],
                    academicYear: academicYear,
                    branchId: body?.branchId,
                    routeNo: body?.routeNo,
                    commonTransportFee: this.state.route.commonTransportFee,
                    isDifferentialRouteFee: this.state.route.isDifferentialRouteFee,
                };

                let url = `${baseUrlAdmin}/transport-route?academicYear=${academicYear}`;
                try {
                    const { stops } = this.state;
                    const allFieldsFilled = stopsForApi.every(stop =>
                        stop.stopName && stop.locationLink && stop.pickUpTime && stop.dropTime && stop.transportFee
                    );
                    let isTimeValid = stopsForApi.every((stop) => {
                        return this.isPickUpTimeLessThanDropTime(stop);
                    });
                    if (allFieldsFilled && isTimeValid) {
                    } else {
                        if (!allFieldsFilled) {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields in existing stops', life: 3000 });
                            throw new Error('Please fill all the fields in existing stops');

                        } else {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
                            throw new Error('PickUp time must be earlier than Drop time');

                        }
                    }
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({
                            isLoading: false
                        });
                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel();
                        this.props.getRoutesData();
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    }
                } catch (e) {
                    this.setState({
                        isLoading: false
                    });
                    console.log(e);
                    this.props.toastMessage.show({ severity: 'error', summary: e.message || e, detail: 'Some error occured', life: 3000 });
                }
            }
        }
    };
    isPickUpTimeLessThanDropTime = (stop) => {
        const pickUpDate = new Date(stop.pickUpTime);
        const dropDate = new Date(stop.dropTime);

        // Extract hours and minutes
        const pickUpTime = pickUpDate.getHours() * 60 + pickUpDate.getMinutes();
        const dropTime = dropDate.getHours() * 60 + dropDate.getMinutes();

        return pickUpTime < dropTime;
    };
    generateGoogleMapsLink = (stopName) => {
        if (!stopName) return '';
        // Encode the stop name for URL
        const encodedStop = encodeURIComponent(stopName);
        return `https://www.google.com/maps/search/?api=1&query=${encodedStop}`;
    };

    extractLocationFromGoogleMapsUrl = async (url) => {
        try {
            // Check if it's a valid Google Maps URL
            if (!url.includes('google.com/maps')) {
                return '';
            }

            // Extract location from query parameter
            const queryMatch = url.match(/[?&]query=([^&]+)/);
            if (queryMatch) {
                return decodeURIComponent(queryMatch[1]);
            }

            return '';
        } catch (error) {
            console.error('Error extracting location:', error);
            return '';
        }
    };

    handleInputChange = async (index, field, value) => {
        const stops = [...this.state.stops];
        stops[index][field] = value;

        // If stop name is changed, generate Google Maps link
        if (field === 'stopName' && value) {
            const mapsLink = this.generateGoogleMapsLink(value);
            stops[index]['locationLink'] = mapsLink;
        }

        // If location link is changed, try to extract location name
        if (field === 'locationLink' && value) {
            const extractedLocation = await this.extractLocationFromGoogleMapsUrl(value);
            if (extractedLocation && !stops[index]['stopName']) {
                stops[index]['stopName'] = extractedLocation;
            }
        }

        this.setState({ stops });
    };

    addStop = () => {
        const { stops } = this.state;

        this.state.route.commonTransportFee = this.state.route.commonTransportFee || 0;


        const allFieldsFilled = stops.every(stop =>
            stop.stopName && stop.locationLink && stop.pickUpTime && stop.dropTime && stop.transportFee
        );
        let isTimeValid = stops.every((stop) => {
            return this.isPickUpTimeLessThanDropTime(stop);
        });
        if (allFieldsFilled && isTimeValid) {
            const newStop = { 
                id: this.state.stops.length + 1, 
                stopName: "", 
                locationLink: "", 
                pickUpTime: '', 
                dropTime: '', 
                transportFee: this.state.route.commonTransportFee && this.state.route.commonTransportFee !== 0 ? this.state.route.commonTransportFee : ''
            };
            this.setState((prevState) => ({
                stops: [...prevState.stops, newStop]
            }));
        } else {
            if (!allFieldsFilled) {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields in existing stops', life: 3000 });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
            }
        }
    };

    removeStop = (index) => {
        if (this.state.stops.length > 1) {
            const stops = this.state.stops.filter((_, i) => i !== index);
            this.setState({ stops });
        }
    };

    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = () => {
        this.setState({ focused: false });
    };

    handleInputFocus = () => {
        this.setState({ inputFocused: true });
    };

    handleInputBlur = () => {
        this.setState({ inputFocused: false });
    };

    handleDifferentialFeeToggle = (value) => {
        this.setState(prevState => ({
            route: {
                ...prevState.route,
                isDifferentialRouteFee: value
            },
            stops: prevState.stops.map(stop => ({
                ...stop,
                transportFee: value ? stop.transportFee : prevState.route.commonTransportFee
            }))
        }));
    };

    render() {
        const { route, formValidations } = this.state;
        const { onCancel } = this.props;
        return (
            <>
                <div className="mt-3">
                    <div className="flex align-items-center mb-3">
                        <p className="add-vehicle-field-label mr-2">Differential Route Fee</p>
                        <InputSwitch
                            className='mr-2'
                            checked={route?.isDifferentialRouteFee}
                            onChange={(e) => this.handleDifferentialFeeToggle(e.value)}
                        />
                        {!route?.isDifferentialRouteFee && (
                            <ClassetInputText
                                className="ml-2"
                                width="250px"
                                value={route?.commonTransportFee}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState(prevState => ({
                                        route: {
                                            ...prevState.route,
                                            commonTransportFee: value
                                        },
                                        stops: prevState.stops.map(stop => ({
                                            ...stop,
                                            transportFee: value
                                        }))
                                    }));
                                }}
                                placeholder="Transport Fee"
                                keyfilter="int"
                            />
                        )}
                    </div>
                    <div className="grid" style={{ gap: '0.2rem', margin: '0' }}>
                        <div className="col" style={{ padding: '0.2rem' }}>
                            <div className="flex justify-content-between align-items-center">
                                <p className="add-vehicle-field-label mb-0">
                                    Branch<span className="ma-required">*</span>
                                </p>

                            </div>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="name"
                                    optionValue="key"
                                    width="16vw"
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    value={route?.branchId}
                                    options={this.props.branchData}
                                    onChange={async (e) => {
                                        await onDropDownChange(e.target.value, 'branchId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        await this.getDropdownsData(false);
                                    }}
                                    placeholder={
                                        <div className="flex justify-content-start align-items-center">
                                            <span className="">Select Branch</span>
                                        </div>
                                    }
                                />

                            </div>
                            {formValidations && !formValidations.fields['branchId'].isValid && <p className="p-error">{formValidations.fields['branchId'].errorMsg}</p>}
                        </div>
                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Vehicle Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    onBlur={this.handleBlur}
                                    width="16vw"
                                    placeholder="Select Vehicle Name"
                                    value={route.vehicleId}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    options={this.state.vehicleDropdowns}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        onDropDownChange(selectedValue, 'vehicleId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        const selectedVehicle = this.state.vehicleDropdowns.find((vehicle) => vehicle.value === selectedValue);
                                        if (selectedVehicle) {
                                            route['registration_number'] = selectedVehicle.registrationNumber;
                                            this.setState({
                                                route: { ...route, vehicleId: selectedValue }
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['vehicleId'].isValid && <p className="p-error">{formValidations.fields['vehicleId'].errorMsg}</p>}
                        </div>

                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Route Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    onClick={this.handleInputFocus}
                                    width="16vw"
                                    onBlur={this.handleInputBlur}
                                    style={{ border: 'none' }}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    value={route.routeName}
                                    onChange={(e) => onTextChange(e.target.value, 'routeName', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Route Name"
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['routeName'].isValid && <p className="p-error">{formValidations.fields['routeName'].errorMsg}</p>}
                        </div>
                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Registered Vehicle Number<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    width="16vw"
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    value={this.props?.dataForAddRoutes?._id ? this.props?.dataForAddRoutes?.vehicleRegistrationNo : route.registration_number}
                                    disabled={true}
                                    onChange={(e) => onTextChange(e.target.value, 'registration_number', this, formFields, route, formValidations, ' route', 'formValidations')}
                                    placeholder="Registered Vehicle Number"
                                />
                            </div>
                        </div>
                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Route No<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    onClick={this.handleInputFocus}
                                    width="16vw"
                                    onBlur={this.handleInputBlur}
                                    style={{ border: 'none' }}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    value={route.routeNo}
                                    onChange={(e) => onTextChange(e.target.value, 'routeNo', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Route No"
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['routeNo'].isValid && <p className="p-error">{formValidations.fields['routeNo'].errorMsg}</p>}
                        </div>
                    </div>
                    <div style={{ height: '2px', marginTop: "5px", backgroundColor: '#B1B1B1' }}></div>

                    {this.state.stops.map((stop, index) => (
                        <div className='flex'>
                            <div key={index} className="grid lg:col-11 mx-0 mt-5 border-top-1 border-left-2 border-right-1 border-bottom-2 border-300 border-round-lg overflow-hidden">
                                {/*   <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Area Name<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText width="100%" value={stop.areaName} onChange={(e) => this.handleInputChange(index, 'areaName', e.target.value)} placeholder="Area Name" />
                                </div> */}

                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Stop Name<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText
                                        width="100%"
                                        value={stop.stopName}
                                        onChange={(e) => this.handleInputChange(index, 'stopName', e.target.value)}
                                        placeholder="Stop Name"
                                    />
                                </div>
                                <div className="col-12 md:col-2">
                                    <p className="add-vehicle-field-label">
                                        Pickup time<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText
                                        width="100%"
                                        value={
                                            stop.pickUpTime
                                                ? stop.pickUpTime instanceof Date
                                                    ? `${String(stop.pickUpTime.getHours()).padStart(2, '0')}:${String(stop.pickUpTime.getMinutes()).padStart(2, '0')}`
                                                    : stop.pickUpTime.includes('T')
                                                        ? new Date(stop.pickUpTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
                                                        : stop.pickUpTime
                                                : ''
                                        }
                                        onChange={(e) => {
                                            let timeValue = e.target.value;
                                            const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';

                                            // Handle backspace/delete
                                            if (isBackspace) {
                                                if (timeValue.endsWith(':')) {
                                                    timeValue = timeValue.slice(0, -1); // Remove the colon when backspacing
                                                }
                                            }

                                            // Allow valid HH:MM format with partial input
                                            const timeRegex = /^([01]?[0-9]|2[0-3])?(:([0-5]?[0-9])?)?$/;
                                            const fullTimeRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

                                            if (timeValue === '' || timeRegex.test(timeValue)) {
                                                // Auto-insert ":" after HH (only if not backspacing)
                                                if (timeValue.length === 2 && !timeValue.includes(':') && !isBackspace) {
                                                    timeValue += ':';
                                                }

                                                const currentValue = stop.pickUpTime instanceof Date
                                                    ? stop.pickUpTime.toISOString().slice(11, 16)
                                                    : typeof stop.pickUpTime === 'string'
                                                        ? stop.pickUpTime.slice(11, 16)
                                                        : '';

                                                // Handle minutes deletion
                                                if (currentValue && timeValue.length < currentValue.length) {
                                                    if (timeValue.includes(':') && timeValue.endsWith(':')) {
                                                        // Keep the format when deleting minutes
                                                        timeValue = timeValue;
                                                    }
                                                }

                                                // Convert HH:MM to full ISO date when valid
                                                if (fullTimeRegex.test(timeValue)) {
                                                    const [hours, minutes] = timeValue.split(':').map(Number);
                                                    const currentDate = new Date();
                                                    currentDate.setHours(hours);
                                                    currentDate.setMinutes(minutes);
                                                    currentDate.setSeconds(0);
                                                    currentDate.setMilliseconds(0);

                                                    // Convert to ISO string and send to backend
                                                    const isoString = currentDate.toISOString();
                                                    this.handleInputChange(index, 'pickUpTime', isoString);
                                                } else {
                                                    this.handleInputChange(index, 'pickUpTime', timeValue);
                                                }
                                            }
                                        }}
                                        placeholder="HH:MM (24-hour format)"
                                    />
                                </div>
                                <div className="col-12 md:col-2">
                                    <p className="add-vehicle-field-label">
                                        Drop time<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText
                                        width="100%"
                                        value={
                                            stop.dropTime
                                                ? stop.dropTime instanceof Date
                                                    ? `${String(stop.dropTime.getHours()).padStart(2, '0')}:${String(stop.dropTime.getMinutes()).padStart(2, '0')}`
                                                    : stop.dropTime.includes('T')
                                                        ? new Date(stop.dropTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
                                                        : stop.dropTime
                                                : ''
                                        }
                                        onChange={(e) => {
                                            let timeValue = e.target.value;
                                            const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';

                                            // Handle backspace/delete
                                            if (isBackspace) {
                                                if (timeValue.endsWith(':')) {
                                                    timeValue = timeValue.slice(0, -1); // Remove the colon when backspacing
                                                }
                                            }

                                            // Allow valid HH:MM format with partial input
                                            const timeRegex = /^([01]?[0-9]|2[0-3])?(:([0-5]?[0-9])?)?$/;
                                            const fullTimeRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

                                            if (timeValue === '' || timeRegex.test(timeValue)) {
                                                // Auto-insert ":" after HH (only if not backspacing)
                                                if (timeValue.length === 2 && !timeValue.includes(':') && !isBackspace) {
                                                    timeValue += ':';
                                                }

                                                // Get current value for comparison
                                                const currentValue = stop.dropTime instanceof Date
                                                    ? stop.dropTime.toISOString().slice(11, 16)
                                                    : typeof stop.dropTime === 'string'
                                                        ? stop.dropTime.slice(11, 16)
                                                        : '';

                                                // Handle minutes deletion
                                                if (currentValue && timeValue.length < currentValue.length) {
                                                    if (timeValue.includes(':') && timeValue.endsWith(':')) {
                                                        // Keep the format when deleting minutes
                                                        timeValue = timeValue;
                                                    }
                                                }

                                                // Convert HH:MM to full ISO date when valid
                                                if (fullTimeRegex.test(timeValue)) {
                                                    const [hours, minutes] = timeValue.split(':').map(Number);
                                                    const currentDate = new Date();
                                                    currentDate.setHours(hours);
                                                    currentDate.setMinutes(minutes);
                                                    currentDate.setSeconds(0);
                                                    currentDate.setMilliseconds(0);

                                                    // Convert to ISO string and send to backend
                                                    const isoString = currentDate.toISOString();
                                                    this.handleInputChange(index, 'dropTime', isoString);
                                                } else {
                                                    this.handleInputChange(index, 'dropTime', timeValue);
                                                }
                                            }
                                        }}
                                        placeholder="HH:MM (24-hour format)"
                                    />
                                </div>
                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Location Link
                                    </p>
                                    <div style={{ position: 'relative' }}>
                                        <ClassetInputText
                                            icon={<LocationMarker />}
                                            width="100%"
                                            value={stop.locationLink}
                                            onChange={(e) => this.handleInputChange(index, 'locationLink', e.target.value)}
                                            placeholder="Paste Google Maps link here"
                                            style={{
                                                color: '#0066CC',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                paddingRight: '45px', // Add padding to prevent text from going under icon
                                                textOverflow: 'ellipsis', // Add ellipsis for overflow
                                                whiteSpace: 'nowrap', // Prevent text wrapping
                                                overflow: 'hidden' // Hide overflow
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                const pastedText = e.clipboardData.getData('text');
                                                if (pastedText.includes('maps.google.com') || pastedText.includes('google.com/maps')) {
                                                    this.handleInputChange(index, 'locationLink', pastedText);
                                                } else {
                                                    this.toast.show({
                                                        severity: 'warn',
                                                        summary: 'Warning',
                                                        detail: 'Please paste a valid Google Maps link',
                                                        life: 3000
                                                    });
                                                }
                                            }}
                                            tooltip={stop.locationLink} // Show full URL on hover
                                            tooltipOptions={{ position: 'top', showDelay: 100 }}
                                        />
                                        {stop.locationLink && (
                                            <a
                                                href={stop.locationLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    position: 'absolute',
                                                    right: '12px', // Adjust position to prevent overlap
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    color: '#0066CC',
                                                    cursor: 'pointer',
                                                    zIndex: 1, // Ensure icon stays on top
                                                    backgroundColor: 'white', // Add background to icon
                                                    padding: '2px 5px',
                                                    borderRadius: '3px'
                                                }}
                                            >
                                                <i className="pi pi-external-link"></i>
                                            </a>
                                        )}
                                    </div>
                                </div>



                                {route?.isDifferentialRouteFee && (
                                    <div className="col-12 md:col-2">
                                        <p className="add-vehicle-field-label">
                                            Transport Fee<span className="ma-required">*</span>
                                        </p>
                                        <ClassetInputText
                                            width="100%"
                                            value={stop.transportFee}
                                            onChange={(e) => this.handleInputChange(index, 'transportFee', e.target.value)}
                                            placeholder="Transport Fee"
                                            keyfilter="int"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='flex align-self-center align-items-center justify-content-center mx-4 md:mt-5 lg:mt-2 pt-1' >
                                {this.props?.dataForAddRoutes?._id ? <></> : <Button style={{ borderRadius: 50, backgroundColor: '#C6CED2', border: 'none', color: '#fff' }} className=" p-3 font-semibold ml-4 mt-4" onClick={() => this.removeStop(index)}>
                                    <DeleteIconClasset height={"22"} />
                                </Button>}

                            </div>
                        </div>
                    ))}
                    <Button label="Add Stop" icon={'pi pi-plus-circle text-black text-lg '} style={{ borderRadius: 12, backgroundColor: '#F4F5F6', border: 'none', color: 'black' }} className=" p-3 font-semibold ml-4 mt-4" onClick={this.addStop} />
                </div>

                <div className="text-center mt-8">
                    <Button label={this.props.dataForAddRoutes?._id ? 'Update Route' : 'Add Route'} className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={this.onCreateRoute} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={onCancel} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {(this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(withRouter(CreateRoute));
