import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import '../style.scss';
import { MailIcon, TickBgIcon, ArrowClockWise, InputIcon, CodeLinkIcon, CalendarIcon, ArrowLeftRight, Plus } from '../../svgIcons';
import { BasicLazyParams } from '../../../utile';
import { MultiSelect } from 'primereact/multiselect';
import BoardToSectionDDCol from '../../BaseDropdownComponents/BoardToSectionDDCol';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { getBoardsData } from '../../../store/actions';
import withRouter from '../../lib/withRouter';
import Authentication from "../../../session"
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../services';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';


export class RequestSubstitution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            openCreateSubstituionDialog: false,
            searchType: 1,
            searchInput: "",
            periodSubstitutionData: {},
            Reason: ''
        }
        this.service = new Service();
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            () => {
                if (dropdownsData?.boardId?.length > 0 && dropdownsData?.classId?.length > 0) {
                    const filteredBoards = this.props.boards.filter((board) => board.boardId === dropdownsData.boardId);
                    if (filteredBoards.length > 0) {
                        const filteredClasses = filteredBoards[0].classes.filter((classItem) => classItem.classId === dropdownsData.classId);

                        this.setState(
                            {
                                groupId: filteredClasses[0].groups[0].groupId
                            },
                            () => {
                                if (dropdownsData.boardId && dropdownsData.classId && dropdownsData.branchId && dropdownsData.sectionId) {
                                    this.getTimeTableIfExists(dropdownsData)
                                }
                            }
                        );
                    }
                }
            }
        );
    };
    onChangeTeacher = (value) => {
        console.log(this.state.users);
        const selectedTeacher = this.state?.users?.find((teacher) => teacher.user_id === value);

        if (selectedTeacher) {
            console.log(selectedTeacher);

            this.setState({
                periodSubstitutionData: {
                    ...this.state.periodSubstitutionData,
                    substitutionTeacher: { user_id: selectedTeacher.user_id, username: selectedTeacher.username, name: selectedTeacher.user_info.name },
                },
                subjects: selectedTeacher.user_info.assignedSubjects || []
            });
        } else {
            console.log("Teacher not found");
            this.setState({
                subjects: []
            });
        }
    };
    raiseRequestSubstitution = async () => {
        const raisedById = localStorage.getItem('userId');
        const raisedByusername = localStorage.getItem('username');
        const academicYear = localStorage.getItem('userAcademicYear');
        const { branchId, boardId, classId, sectionId, selectedBoardName, selectedbranchName, selectedClassName, selectedsectionName } = this.state.dropdownsData;
        const { _id } = this.state.timeTableData;
        console.log(this.state.periodSubstitutionData);

        if (this.state.Reason.length === 0) return this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Reason is Mandatory", life: 3000 });
        if (!this.state.periodSubstitutionData?.substitutionTeacher) return this.toast.show({ severity: 'error', summary: 'Teacher should not be empty', life: 3000 });
        if (!this.state.periodSubstitutionData?.substitutionSubject) return this.toast.show({ severity: 'error', summary: 'Subject should not be empty', life: 3000 });
        // console.log(this.state.Reason);
        const payload = {
            ...this.state.periodSubstitutionData,
            raisedById,
            raisedByusername,
            templateId: this.state.selectedTemplate,
            branchId,
            boardId,
            classId,
            sectionId,
            branchName: selectedbranchName,
            boardName: selectedBoardName,
            className: selectedClassName,
            sectionName: selectedsectionName,
            timeTableId: this.state?.timeTableId,
            reason: this.state.Reason,
            academicYear,
            isApprove: false,
            isClosed: false,
        }
        try {
            let { date, dropdownsData } = this.state;
            const url = `${baseUrlAdmin}/timetable/raiserequest`
            const res = await this.service.post(url, payload, true);
            if (res.status) {
                this.toast.show({ severity: 'success', summary: 'Successfully Request Raised', life: 3000 });
                this.setState({ openCreateSubstituionDialog: false })
                this.getTimeTableIfExists(dropdownsData, date);
            } else {
                this.toast.show({ severity: 'error', summary: 'ERROR', detail: res?.message, life: 3000 });
            }
        } catch (err) {
            this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Some error occurred', life: 3000 });
            console.log(err);
        }
    }
    getStaffData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        console.log(this.state.dropdownsData);
        // let branchIds = Array.isArray(this.state?.dropdownsData?.branchId) ? this.state.dropdownsData.branchId.join(',') : '';
        // let boardIds = Array.isArray(this.state?.dropdownsData?.boardId) ? this.state.dropdownsData.boardId.join(',') : '';
        // let gradeIds = Array.isArray(this.state?.dropdownsData?.classId) ? this.state.dropdownsData.classId.join(',') : '';
        // let sectionIds = Array.isArray(this.state?.dropdownsData?.sectionId) ? this.state.dropdownsData.sectionId.join(',') : '';
        let branchIds = this.state?.dropdownsData?.branchId;
        let gradeIds = this.state?.dropdownsData?.classId;
        let sectionIds = this.state?.dropdownsData?.sectionId;
        let boardIds = this.state?.dropdownsData?.boardId;

        let url;
        if (branchIds || boardIds || gradeIds || sectionIds) {
            url = `${baseUrlAdmin}/staff?branchIds=${branchIds}&boardIds=${boardIds}&gradeIds=${gradeIds}&sectionIds=${sectionIds}&academicYear=${academicYear}&limit=${10000}&page=${1}`;
        } else {
            url = `${baseUrlAdmin}/staff?academicYear=${academicYear}&limit=${10000}&page=${1}`;
        }

        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                // console.log(res?.res?.data, 'res?.res?.datares?.res?.data');

                const users = res?.res?.data?.records;
                console.log(users);
                this.setState({
                    users: users,
                    totalRecords: res?.res?.data?.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: '', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };
    getTimeTableIfExists = async (dropdownsData, date) => {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        // if date is not there send new date
        if (!date) {
            date = new Date();
            date.setHours(0, 0, 0, 0); // Normalize date
        }
        let url = `${baseUrlAdmin}/timetable/getsubstimetable?branchId=${branchId}&boardId=${boardId}&classId=${classId}&sectionId=${sectionId}&academicYear=${academicYear}&date=${date}`;
        if (branchId && boardId && classId && sectionId) {
            this.setState({ isLoading: true, date: date });
            try {
                await this.service.get(url, true).then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.data) {
                            const timeTableData = res.res.data;
                            this.setState({ timeTableId: res.res.data._id, timeTableData: timeTableData.timeTableData, selectedTemplate: timeTableData.templateId, isTimeTableExistsInDB: true, isLoading: false }, () => {
                                this.dynamicColumns(timeTableData.timeTableData)
                            });
                        } else {
                            this.setState({ timeTableId: null, timeTableData: [], selectedTemplate: null, isTimeTableExistsInDB: true, isLoading: false }, () => {
                                this.dynamicColumns({})
                            });
                            this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                        }
                    } else {
                        throw new Error(res?.errMessage);
                    }
                }).catch((err) => {
                    this.setState({ timeTableId: null, timeTableData: [], selectedTemplate: null, isTimeTableExistsInDB: true, isLoading: false }, () => {
                        this.dynamicColumns({})
                    });
                    this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                });

            } catch (err) {
                console.log(err);
                this.setState({ timeTableId: null, timeTableData: [], selectedTemplate: null, isTimeTableExistsInDB: true, isLoading: false }, () => {
                    this.dynamicColumns({})
                });
            }

        }

    }
    dynamicColumns = (timeTableData) => {
        if (!timeTableData || !timeTableData?.TimeTable || !this.state.date) {
            return []
        };

        const columns = [];
        const selectedDateObj = new Date(this.state.date);
        selectedDateObj.setHours(0, 0, 0, 0); // Normalize date

        // Get Monday as the start of the week (Handles Sunday properly)
        const startOfWeek = new Date(selectedDateObj);
        const dayIndex = selectedDateObj.getDay();
        startOfWeek.setDate(selectedDateObj.getDate() - ((dayIndex === 0 ? 7 : dayIndex) - 1));

        columns.push(
            <Column
                key="day"
                field="day"
                header={<div style={{ color: 'black' }}>Days/Periods</div>}
                align={'center'}
                body={(rowData, index) => {
                    return (
                        <div style={{ color: 'black' }}>
                            <p>
                                {rowData.day}
                            </p>
                            <p style={{ fontSize: '12px', color: 'gray' }}>
                                {rowData.date}
                            </p>
                        </div>
                    );
                }}
            />
        );

        if (timeTableData.TimeTable.length > 0) {
            const firstDayPeriods = timeTableData.TimeTable[0].Periods;

            firstDayPeriods.forEach((_, index) => {
                columns.push(
                    <Column
                        key={`period-${index + 1}`}
                        field={`Period_${index + 1}`}
                        header={<div style={{ color: 'black' }}>{_.name}</div>}
                        body={(rowData) => {
                            const periodData = rowData.Periods[index];

                            if (periodData?.type === 'break') {
                                return (
                                    <div style={{
                                        margin: '0', padding: '0', display: 'flex',
                                        flexDirection: 'column', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="break-period" style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                            BREAK
                                        </div>
                                    </div>
                                );
                            }

                            return (
                                <div style={{
                                    margin: '0', padding: '0', display: 'flex',
                                    flexDirection: 'column', justifyContent: 'space-between'
                                }}>
                                    {
                                        periodData?.isSubstitution ? <>
                                            <div style={{ marginBottom: '20px' }} >

                                            </div>
                                            <div style={{ textAlign: 'center', fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal', color: periodData?.Teacher?.status == 'pending' ? 'orange' : 'green' }}>
                                                {periodData?.Subject?.subjectName
                                                    ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                                    : <p style={{ fontStyle: 'italic' }}>Not Assigned</p>}
                                            </div>
                                            <div style={{ textAlign: 'center', fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal', color: periodData?.Teacher?.status == 'pending' ? 'orange' : 'green', fontSize: '12px' }}>
                                                {periodData?.Teacher?.name
                                                    ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                                    : ''}
                                            </div>

                                        </> :

                                            <>
                                                {!rowData.isDisabled &&
                                                    <div style={{ marginBottom: '20px' }} >
                                                        <span
                                                            data-pr-tooltip="Edit User"
                                                            onClick={() => {
                                                                this.openCreateSubstitution(periodData, rowData.day, rowData.date,);
                                                            }}
                                                        >
                                                            {periodData?.Teacher?.name ? (
                                                                <ArrowLeftRight width={16} height={16} color={'#000000'} />
                                                            ) : (
                                                                <></>
                                                                // <Plus width={16} height={16} color={'#000000'} />
                                                            )}
                                                        </span>
                                                    </div>}

                                                <div style={{ textAlign: 'center', fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal', color: periodData?.Subject?.subjectName ? '#0A9EDC' : 'lightgray' }}>
                                                    {periodData?.Subject?.subjectName
                                                        ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                                        : <p style={{ fontStyle: 'italic' }}>Not Assigned</p>}
                                                </div>
                                                <div style={{ textAlign: 'center', fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal', color: periodData?.Teacher?.name ? 'gray' : 'black', fontSize: '12px' }}>
                                                    {periodData?.Teacher?.name
                                                        ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                                        : ''}
                                                </div>
                                            </>
                                    }

                                </ div>
                            );
                        }}
                    />
                );
            });
        }

        return columns;
    };




    getSubstitutionTeachers = async (subjectInfo, teacherInfo, date, periodDetails) => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let payload = {
            "academicYear": academicYear,
            boardId: this.state?.dropdownsData?.boardId,
            classId: this.state?.dropdownsData?.classId,
            branchId: this.state?.dropdownsData?.branchId,
            sectionId: this.state?.dropdownsData?.sectionId,
            subjectInfo: subjectInfo,
            teacher: teacherInfo,
            date: date,
            periodId: periodDetails?.periodId,
            day: periodDetails?.Day,
            startTime : periodDetails?.startTime,
            endTime : periodDetails?.endTime,
        }
        let url = `${baseUrlAdmin}/staff/substitution-staff`;
        try {
            this.setState({ isLoading: true })
            const res = await this.service.post(url, payload, true);
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.length > 0) {
                    this.setState({ isLoading: false, users: res.res.data, });
                    if (res.res.data.length > 0) {
                        const caseFrom = res.res.caseFrom || 'No additional details available';
                        this.toast.show({ severity: 'info', summary: 'Got Teachers:', detail: caseFrom || '', life: 5000 });
                    }

                } else {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                }
            }
        } catch (err) {
            console.log(err);
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'ERROR', detail: '', life: 3000 });
        }

    }
    openCreateSubstitution = async (periodData, day, date) => {
        const updated = { ...periodData, Day: day, date: date };
        await this.getSubstitutionTeachers(periodData?.Subject, periodData?.Teacher, date, updated)
        this.setState({ openCreateSubstituionDialog: true, periodSubstitutionData: updated });
    };

    render() {
        const { timeTableData, dropdownsData } = this.state;

        const getNextMondayFromSaturday = () => {
            if (!timeTableData?.TimeTable || timeTableData.TimeTable.length === 0) return null;

            // Find Saturday's date from the timetable
            const saturdayEntry = timeTableData.TimeTable.find(entry => entry.day === "Saturday");
            if (!saturdayEntry || !saturdayEntry.date) return null;

            const saturdayDate = new Date(saturdayEntry.date);
            const nextMonday = new Date(saturdayDate);
            nextMonday.setDate(saturdayDate.getDate() + 2); // Add 2 days to get Monday
            nextMonday.setHours(0, 0, 0, 0); // Normalize date
            return nextMonday;
        };

        const getPreviousMondayFromMonday = () => {
            if (!this.state.date) return null;

            const currentMonday = new Date(this.state.date);
            const previousMonday = new Date(currentMonday);
            previousMonday.setDate(currentMonday.getDate() - 7); // Subtract 7 days to get the previous Monday
            previousMonday.setHours(0, 0, 0, 0); // Normalize date
            return previousMonday;
        };

        const isNavigationButtonsVisible = dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && timeTableData?.TimeTable;

        return (
            <div className="m-2">
                <div className="flex flex-column m-2 mb-6">
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                    {isNavigationButtonsVisible && timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                        <div className="flex justify-content-end mt-2 gap-2">
                            <Button
                                label="Previous Week"
                                className="p-button-outlined"
                                onClick={() => {
                                    const previousMonday = getPreviousMondayFromMonday();
                                    if (previousMonday) {
                                        this.setState({ date: previousMonday });
                                        this.getTimeTableIfExists(dropdownsData, previousMonday);
                                    } else {
                                        this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Unable to calculate previous week's Monday", life: 3000 });
                                    }
                                }}
                            />
                            <Button
                                label="Next Week"
                                className="p-button-outlined"
                                onClick={() => {
                                    const nextMonday = getNextMondayFromSaturday();
                                    if (nextMonday) {
                                        this.setState({ date: nextMonday });
                                        this.getTimeTableIfExists(dropdownsData, nextMonday);
                                    } else {
                                        this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Unable to calculate next week's Monday", life: 3000 });
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>


                {
                    timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                        <>
                            <div className="flex align-items-center mt-2">
                                <div className="flex align-items-center mr-4">
                                    <div style={{ width: '16px', height: '16px', backgroundColor: 'orange', marginRight: '8px', borderRadius: 100 }}></div>
                                    <span className="text-gray-600">Substitution Request Pending</span>
                                </div>
                                <div className="flex align-items-center">
                                    <div style={{ width: '16px', height: '16px', backgroundColor: 'green', marginRight: '8px', borderRadius: 100 }}></div>
                                    <span className="text-gray-600">Substitution Request Approved</span>
                                </div>
                            </div>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={timeTableData.TimeTable}
                                dataKey="id"
                                lazy
                                responsiveLayout="scroll"
                                className="dataTableValuesTextt"
                                showGridlines
                                selectionMode={'checkbox'}
                                columnResizeMode="expand"
                                resizableColumns
                                onPage={this.onPage}
                                size='large'
                            >
                                {this.dynamicColumns(timeTableData)}
                            </DataTable>
                        </>

                    )
                }
                <Dialog
                    visible={this.state.openCreateSubstituionDialog}
                    draggable={false}
                    closeOnEscape={false}
                    position={"right"}
                    className='ma-alert-box w-3 mr-0'
                    style={{ width: "300px", height: "600px" }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (<div className='mt-5'>
                            <h2 className="headin text-center" style={{ color: 'black' }}>Request Substitution</h2>
                        </div>)
                    }}
                    footer={() => (
                        <div className="mt-3" style={{ display: 'flex' }}>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => this.raiseRequestSubstitution()} >
                                <p className='text-xl ml-2'>Request Substitution</p>
                            </Button>
                            <Button style={{ fontWeight: 'semibold', color: 'black', borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { this.setState({ openCreateSubstituionDialog: false }) }}>
                                <p className='text-xl'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ openCreateSubstituionDialog: false })}
                >
                    <div className='flex flex-column justify-content-center mx-3'>

                        <div className='mt-3'>
                            <ClassetTextArea
                                style={{ height: "119px" }}
                                label={'Reason for Substitution'}
                                required={true}
                                placeholder="Reason for Substitution"
                                onChange={(e) => this.setState({ Reason: e.target.value })}
                            />

                        </div>

                        <div className='mt-3' >
                            <ClassetInputText
                                label={'Period Number'}
                                id='title'
                                icon={<MailIcon />}
                                placeholder="Period Number"
                                value={this.state?.periodSubstitutionData?.name}
                                disabled={true}
                            />
                        </div>
                        <div className="mt-3">
                            <ClassetInputText
                                label={'Date of the week '}
                                id='title'
                                value={this.state?.periodSubstitutionData?.date}
                                icon={<MailIcon />}
                                disabled={true}
                                placeholder="Date of the week"
                            />
                        </div>
                        <div className='mt-3' >
                            <ClassetInputText
                                label={'Day of the week '}
                                id='title'
                                value={this.state?.periodSubstitutionData?.Day}
                                icon={<MailIcon />}
                                disabled={true}
                                placeholder="Day of the week"
                            />
                        </div>
                        <div className="flex flex-column">
                            <div className="mt-3">
                                <ClassetDropdown
                                    required={true}
                                    label={'Select Teacher'}
                                    icon={<MailIcon height={16.5} width={19.5} />}
                                    placeholder={'Select Teacher'}
                                    options={this.state.users}
                                    value={this.state?.periodSubstitutionData?.substitutionTeacher?.user_id}
                                    onChange={(e) =>
                                        this.onChangeTeacher(e.target.value)
                                    }
                                    optionLabel="user_info.name"
                                    optionValue="user_id"
                                />
                            </div>
                            <div className="mt-3">
                                <ClassetDropdown
                                    required={true}
                                    label={'Select Subject'}
                                    icon={<MailIcon height={16.5} width={19.5} />}
                                    placeholder={'Select Subject'}
                                    options={this.state.subjects}
                                    value={this.state?.periodSubstitutionData?.substitutionSubject?.subjectId}
                                    onChange={(e) => {
                                        const sub = this.state.subjects.find((sub) => sub.subjectId === e.target.value);
                                        this.setState({
                                            periodSubstitutionData: {
                                                ...this.state.periodSubstitutionData,
                                                substitutionSubject: { subjectId: sub.subjectId, subjectName: sub.subjectName }
                                            }
                                        })
                                    }

                                    }
                                    optionLabel="subjectName"
                                    optionValue="subjectId"
                                />
                            </div>
                        </div>


                        {/* <div className="mt-3">

                            <BoardToSectionDDCol setValues={this.setDropdownValues} boardDisable={true} classDisable={true} branchDisable={true} sectionDisable={true} />
                        </div> */}
                    </div>
                </Dialog>



                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {/* <Toast ref={(ee) => this.toastDailog = ee} position="bottom-center" /> */}

                {this.state.isLoading && <LoadingComponent />}
            </div >
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(RequestSubstitution)));