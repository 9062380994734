import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { retrieveUserFromLocalStorage, authUser } from '../../store/actions/signInActions';
import { getUserData, clearUserData } from '../../store/actions/userDataActions';
import withRouter from '../lib/withRouter';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

export default function (ComposedComponent) {
  function Authentication(props) {
      const checkAuth = useCallback(async () => {
          if (!props.authenticated) {
              try {
                  const res = await retrieveUserFromLocalStorage(props.dispatch);
                  if (res) {
                      props.authUser(res);
                    //   await props.getUserData();
                  }
              } catch (e) {
                  props.navigate('/login');
              }
          }
      }, [props.authenticated, props.dispatch, props.authUser, props.getUserData, props.navigate]);

      useEffect(() => {
          checkAuth();
      }, [checkAuth]);

      // Prevent unnecessary re-renders
      const memoizedComponent = React.useMemo(() => {
          return <ComposedComponent {...props} />;
      }, [props]);
     
      return (
          <>
              {memoizedComponent}
          </>
      );
  }

  function mapStateToProps(state) {
      return {
          authenticated: state.auth && state.auth.authenticated,
          userData: state.userData && state.userData.data && state.userData.data,
          userDataSuccess: state.userData && state.userData.userDataSuccess && state.userData.userDataSuccess,
          permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds
      };
  }

  const mapDispatchToProps = (dispatch) => {
      return {
          authUser: (data) => dispatch(authUser(data)),
          getUserData: () => dispatch(getUserData()),
          clearUserData: () => dispatch(clearUserData()),
          dispatch
      };
  };

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Authentication));
}