import React, { useState } from 'react';
import { format } from 'date-fns';
import { classNames } from 'primereact/utils';

const CustomTimePicker = ({ value, onChange = () => {}, label, required, placeholder, icon, height, width, labelClassName, formHelperError }) => {
    const [focused, setFocused] = useState(false);
    const defaultHeight = height ? height : "48px";
    const defaultWidth = width ? width : "343px";

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleTimeChange = (event) => {
        const time = event.target.value;
        const [hours, minutes] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        onChange(date.toISOString());
    };

    return (
        <>
            <label htmlFor={label} className={`ma-label-s2 font-serif font-semibold text-900 text-lg ${labelClassName}`}>
                {label} {required && <span className="ma-required">*</span>}
            </label>
            <div className="flex">
                <div
                    style={{ height: defaultHeight, width: defaultWidth }}
                    className={classNames(
                        'p-inputgroup',
                        'custom-input-group',
                        `border-300 shadow-2 border-round-lg flex justify-content-center align-items-center ${formHelperError && 'border-red-400 border-2'}`,
                        { 'custom-input-focused': focused }
                    )}
                    onClick={handleFocus}
                >
                    {icon && (
                        <span className="p-inputgroup-addon px-3">
                            {icon}
                        </span>
                    )}
                    <input
                        type="time"
                        value={value && !isNaN(new Date(value)) ? format(new Date(value), 'HH:mm') : ''} // Ensure value is valid
                        onChange={handleTimeChange}
                        placeholder={placeholder}
                        className="time-picker-input"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{ border: 'none', boxShadow: 'none', width: '100%', outline: 'none' }}
                    />
                </div>
            </div>
        </>
    );
};

export default CustomTimePicker;
