import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';

let selectedBoardClasses;
let filteredSections;
class BranchToClassDD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardId: null,
            classId: [],
            groupId: null,
            branchId: null,
            sectionId: null,
            boards: [],
            classes: [],
            curriculumInfo: {},
            selectedrowData: this.props?.rowData
        };
        this.service = new Service()
    }

    componentDidMount() {
        this.getData();
        // Initialize with saved values if available
        if (this.props.initialValues) {
            const { branchId, boardId, classId } = this.props.initialValues;
            this.setState({
                branchId,
                boardId,
                classId: classId || []
            }, () => {
                if (branchId) this.onChangeBranch(branchId);
                if (boardId) this.onChangeBoard(boardId);
                if (classId) this.onChangeClass(classId);
            });
        }
    }

    componentDidUpdate() {


    }

    getData = () => {
        this.props.getBranchesLatest();
        this.props.getBoardsData(true)
    }

    onChangeBranch = (branchId) => {
        this.setState({
            boards: [], classes: [], sections: [],
        });
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId)?.name;
        let filteredBoards = this.props.boards.filter(board => board.assignedBranches.includes(branchId));
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            boards: filteredBoards,
            classes: [],
            boardId: '',
            selectedBoardName: '',
            classId: [],
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            sections: [],
            sectionId: '',
            selectedsectionName: '',
        }, () => this.setDropdownsData());
    };

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], sections: [],
        });
        const selectedBoard = this.state.boards && this.props.boards.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard.classes,
            boardId,
            selectedBoardName: selectedBoard.boardName,
            classId: [],
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            sections: [],
            sectionId: '',
            selectedsectionName: '',

        }, () => this.setDropdownsData());
    };

    onChangeClass = (classId) => {
        let selectedClass = this.state.classes?.filter((classd) => classId.includes(classd.classId));

        let filteredSections = selectedClass?.flatMap((classd) => classd.sections.filter((sec) => sec.branchId == this.state.branchId));

        this.setState({
            classId: classId,
            selectedClass: selectedClass,
            selectedClassName: selectedClass.map((classd) => classd.className).join(', '),
            sections: filteredSections,
            sectionId: '',
            selectedsectionName: '',
        }, () => this.setDropdownsData());
    };
    setDropdownsData = () => {
        let {
            boardId,
            classId,
            branchId,
            sectionId,
            selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        } = this.state
        let returnData = {
            boardId, classId, branchId, sectionId, selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        }
        this.props.setValues(returnData)
    }



    render() {
        return (
            <div>
                <div >
                    <div className='grid '>
                        <div className="col-12">
                            <div className="row-3 my-2">
                                <ClassetDropdown required={true} label={'Branch'}  placeholder={'Select Branch'} options={this.props?.branchData}
                                    value={this.state.branchId}
                                    // disabled={this.state.selectedrowData?.fmbranchId}
                                    disabled={this.props?.disable >= 1}
                                    onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                    optionValue="key" />
                            </div>
                            <div className="row-3 my-2">
                                <ClassetDropdown required={true} label={'Board'}  placeholder={'Select Board'} options={this.state?.boards} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                    optionValue="boardId"
                                    disabled={this.props?.disable >= 2}

                                // disabled={this.state.selectedrowData?.fmboardId}
                                />
                            </div>
                            <div className="row-3 my-2">
                                <div className="p-field">
                                    <ClassetMultiSelect
                                        label="Class"
                                        value={this.state.classId}
                                        options={this.state.classes.map((classd) => ({ 
                                            label: classd.className, 
                                            value: classd.classId 
                                        }))}
                                        onChange={(e) => this.onChangeClass(e.value)}
                                        disabled={!this.state.boardId}
                                        placeholder="Select Classes"
                                        className="w-100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToClassDD);