import React, { Component } from 'react'
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ClassetEditIcon, DeleteIcon } from '../../../svgIcons';
import { InputSwitch } from "primereact/inputswitch";
import { baseUrlAdmin } from '../../../../store/apiConstants';
import Service from '../../../../services';
import AddExam from './addExam';
import LoadingComponent from '../../../loadingComponent';
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ExamTemplates = [
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    },
    {
        templateName: "Template1",
        totalExams: "4",
        totalStudents: 10,
        creationDate: '24-6-2023',
        status: true
    }
]
const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'title': { value: '', matchMode: 'contains' },
        'subDomain': { value: '', matchMode: 'contains' },
        // 'tenureEndDate': { value: '', matchMode: 'contains' },
        // 'isActive': { value: '', matchMode: 'contains' },
    }
}
export class ExamTemplate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            templates: [],
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            isEdit: false,
            editData: ''
        }
        this.service = new Service();
    }


    gettemplates = () => {
        const academicYear = localStorage.getItem("userAcademicYear");
        this.setState({
            isLoading: true,
            templates: [],
            totalRecords: 0
        });
        let { lazyParams, globalSearch } = this.state;

        let url = `${baseUrlAdmin}/examtemplate/getTemplates?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&academicYear=${academicYear}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;

        this.service.post(url, lazyParams.filters, true).then(res => {

            if (res && res.status && res.res.success) {
                this.setState((prevState) => {
                    return {
                        templates: res.res.data.examTemplates,
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    changeStatus = (e, rowData) => {
        if (rowData?._id) {
            this.setState({
                isLoading: true,
            });
            if (e.value) {
                rowData.status = "Active"
            } else {
                rowData.status = "Inactive"
            }
            const payload = rowData;
            let url = `${baseUrlAdmin}/examtemplate/updatetemplate`;
            this.service.put(url, payload, true).then(res => {
                if (res.status && res?.res?.success) {
                    this.toast.show({ severity: 'success', summary: 'Status updated succesfully', detail: "", life: 3000 });
                    this.setState({
                        exam: {},
                        isLoading: false,
                    });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: "", life: 3000 });
                    this.setState({
                        isLoading: false,
                    });
                }
            }).catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
        }
    }

    deleteTemplate = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to delete this template?',
            header: 'Delete Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: () => {
                this.setState({ isLoading: true });

                const url = `${baseUrlAdmin}/examtemplate/${rowData._id}`;
                this.service.delete(url, true)
                    .then(res => {
                        if (res.status && res?.res?.status) {
                            this.toast.show({
                                severity: 'success',
                                summary: 'Template Deleted',
                                detail: 'Template was successfully deleted',
                                life: 3000
                            });
                            this.gettemplates(); // Refresh the list
                        } else {
                            this.setState({ isLoading: false });
                            this.toast.show({
                                severity: 'error',
                                summary: 'Delete Failed',
                                detail: res?.errMessage || 'Error deleting template',
                                life: 3000
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ isLoading: false });
                        this.toast.show({
                            severity: 'error',
                            summary: 'Delete Failed',
                            detail: error.message || 'Error deleting template',
                            life: 3000
                        });
                    });
            }
        });
    };

    componentDidMount() {

        this.gettemplates();
        this.props.onEdit('');
    }


    onEdit = (rowData) => {
        this.props.switchTab('1');
        this.props.onEdit(rowData);
    }

    render() {
        return (
            <>
                <div className='px-5 py-3' >
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.templates}
                        dataKey="id"
                        paginator
                        rows={10}
                        // showGridlines
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        responsiveLayout="scroll"
                    // header={header}

                    >
                        <Column field="name" header="Template Name" className="datatable-cell" sortable />
                        <Column field="description" header="Description" className="datatable-cell" sortable />
                        <Column field="totalNoOfQuestions" header="Total Number of Questions" className="datatable-cell" sortable />
                        <Column field="totalMarkedQuestions" header="Total Marked Questions" className="datatable-cell" sortable />

                        <Column body={(rowData) => {
                            return (
                                <div className="ma-status">

                                    <InputSwitch
                                        onChange={(e) => { this.changeStatus(e, rowData) }}
                                        checked={rowData.status == 'Active'} />
                                </div>
                            )
                        }} field="status" header="Status" />
                        <Column
                            style={{ width: "15rem", height: "4rem" }}
                            alignHeader={"center"}
                            sortable
                            headerClassName="datatable-header"
                            bodyClassName='text-center'
                            header="Actions"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <div className="flex justify-content-center align-items-center">
                                        <div
                                            onClick={() => { this.onEdit(rowData) }}
                                            className="cursor-pointer mr-3"
                                        >
                                            <ClassetEditIcon width={32} height={32} />
                                        </div>
                                        <div
                                            onClick={() => { this.deleteTemplate(rowData) }}
                                            className="cursor-pointer"
                                        >
                                            <DeleteIcon width={32} height={32} />
                                        </div>
                                    </div>
                                )
                            }}
                        />

                    </DataTable>

                </div>

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }

                <ConfirmDialog />
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

                {
                    this.state.isEdit && <AddExam editData={this.state.editData} />
                }
            </>
        )
    }
}

export default ExamTemplate