
import React, { Component } from 'react'
import withRouter from '../lib/withRouter';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Service from '../services'
import { BasicLazyParams } from '../../utile';
import { getBranchesLatest } from '../../store/actions';
import { Button } from 'primereact/button';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetCalendar from '../../classetComponents/classetCalender';
import BranchToSectionMultiDDTillSecReports from '../BaseDropdownComponents/BranchToSectionMultiDDTillSecReports';
import LoadingComponent from '../loadingComponent';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';
import { baseUrlAdmin } from '../../store/apiConstants';

const dateRangeOptions = [
    { value: 1, label: "Today" },
    { value: 2, label: "This Week" },
    { value: 3, label: "This Month" },
    { value: 4, label: "Academic Session" },
    { value: 5, label: "Custom" },
]

class FeeConcessionReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownsData: {},
            lazyParams: BasicLazyParams
        }
        this.service = new Service()
    }

    componentDidMount() {
        this.props.getBranchesLatest(true)
        this.getFeeConcessionReportInfo()

    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
        }, () => this.state.dropdownsData?.sectionIds && this.getFeeConcessionReportInfo())
    }

    getFeeConcessionReportInfo = async () => {
        let { lazyParams, globalSearch, filterData } = this.state;
        this.setState({
            isLoading: true,
        });
        let url = `${baseUrlAdmin}/financeReports/feeConcessionReport?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;
        let payload = {
            academicYear: localStorage.getItem('userAcademicYear'),
            boardIds: this.state.dropdownsData.boardIds,
            classIds: this.state.dropdownsData.classIds,
            branchIds: this.state.dropdownsData.branchIds,
            sectionIds: this.state.dropdownsData.sectionIds,
            dateRangeType: this.state.dateRangeType,
        }
        this.service.post(url, payload, true).then(data => {
            if (data.status) {
                if (data?.res?.status) {

                    this.setState({
                        feeConcessionReport: data?.res?.data,
                        totalRecords: data?.res?.totalRecords,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, () => this.getFeeConcessionReportInfo());
    };


    render() {
        return (
            <>
                <div className='card mt-0 pt-0' style={{
                    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.25), 4px 4px 8px rgba(0, 0, 0, 0.15)',
                    borderRadius: "12px",
                    border: 'none',
                }}>
                    <BranchToSectionMultiDDTillSecReports setValues={this.setDropdownValues} />
                    <div className="grid m-2 ml-0">
                        <div className="col-3">
                            <label className="add-vehicle-field-label">Date Range</label>
                            <ClassetDropdown
                                options={dateRangeOptions}
                                value={this.state.dateRangeType}
                                onChange={(e) => {
                                    this.setState({ dateRangeType: e.target.value });
                                }}
                                style={{ border: '10px solid #E6E8EA', }}
                                placeholder={(
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className=''>Select Range</span>
                                    </div>
                                )}
                            />


                        </div>
                        {this.state.dateRangeType == 5 &&
                            <>
                                <div className="col-3">
                                    <label className='label'>From Date</label>
                                    <ClassetCalendar
                                        calendarMode="single"
                                        className="text-xl"
                                        value={this.state.chequeFromDate}
                                        placeholder="Select From Date"
                                        onChange={(e) => {
                                            this.setState({ chequeFromDate: e.value }, () => {
                                                this.state.chequeFromDate !== "" && this.state.chequeToDate !== "" && this.getChequeInfo();
                                            })
                                        }}
                                    />

                                </div>
                                <div className="col-3">
                                    <label className='label'>To Date</label>
                                    <ClassetCalendar
                                        calendarMode="single"
                                        className="text-xl"
                                        value={this.state.chequeToDate}
                                        placeholder="Select To Date"
                                        onChange={(e) => {
                                            this.setState({ chequeToDate: e.value }, () => {
                                                this.state.chequeFromDate !== "" && this.state.chequeToDate !== "" && this.getChequeInfo();
                                            })
                                        }}
                                    />
                                </div>
                            </>
                        }
                        <div className="col-3 mt-5 flex justify-content-end align-items-center gap-5 ms-auto" style={{ maxWidth: 'fit-content' }}>
                            <Button label="Download Report" style={{ width: "137px", height: "40px", padding: "10px", borderRadius: "10px", border: '1px solid #E6E8EA', backgroundColor: "#F4F5F6", color: "#000", }} />
                            <Button label="View Analysis" className="p-button-primary"
                                style={{ width: "137px", height: "40px", padding: "10px", borderRadius: "10px", border: '1px solid #E6E8EA', backgroundColor: "#076EFF", color: "#fff", }} />
                        </div>

                    </div>
                </div>


                <div>
                    <DataTable
                        value={this.state.feeConcessionReport}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesText"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >
                      {/*   <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Student Id" alignHeader={"center"}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <p>
                                        {rowData?.enrollmentId}
                                    </p>
                                )
                            }} /> */}
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="firstName" field="" headerClassName="text-color-secondary" bodyClassName='text-center' header="Student Name" alignHeader={"center"}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <p>
                                        {rowData.studentName}
                                    </p>
                                )
                            }}
                        />
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="className" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Class-Section" alignHeader={"center"}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <p>
                                        {rowData?.className + "-" + rowData?.sectionName}
                                    </p>
                                )
                            }} />
                        <Column
                            style={{ width: "15rem", height: "6rem" }}
                            headerClassName="text-color-secondary"
                            bodyClassName='text-center'
                            header="Concession Date"
                            alignHeader={"center"}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <p>
                                        {rowData?.concessionDate ? moment(rowData.concessionDate).format('DD/MM/YYYY') : '-'}
                                    </p>
                                )
                            }}
                        />
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Concession Amount" alignHeader={"center"}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <p>
                                        {rowData?.concessionAmount}
                                    </p>
                                )
                            }}
                        />

                        <Column style={{ width: "15rem", height: "6rem" }} filterField="receiptNo" field="concessionType" headerClassName="text-color-secondary" bodyClassName='text-center' header="Reason" alignHeader={"center"} />


                    </DataTable>
                </div>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._idF
});
export default connect(mapStateToProps, {
    getBranchesLatest
})(Authentication(withRouter(FeeConcessionReport)));