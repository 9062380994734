import React, { Component } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import Authentication from '../../../session';
import { connect } from 'react-redux';
import withRouter from '../../../lib/withRouter';
import { getBoardsData } from '../../../../store/actions';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import AdhocList from './adhocList';
import "./styles.scss"
import CreateAdhoc from '../../Examination/createTest/index';
import { baseUrlAdmin } from '../../../../store/apiConstants';

export class TestsandExam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editExamId: null,
            editExamType: null
        }
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData(true);
        }

        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });

            // Fetch exam data for editing
            this.service.get(`${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/AdhocMockTest`, true)
                .then((data) => {
                    if (data && data.res && data.res.data) {
                        const examData = data.res.data;

                        // Set all necessary state values for dropdowns
                        this.setState({
                            exam: examData,
                            boardId: examData.boardId,
                            classId: examData.classId,
                            isLoading: false,
                            selectedExamQuestions: this.formQuestionsFromExam(examData)
                        }, () => {
                            // After setting initial exam data, load the related dependent data

                            // 1. Load classes for this board
                            if (examData.boardId) {
                                const selectedBoard = this.props.boards &&
                                    this.props.boards.find(board => board.boardId === examData.boardId);

                                if (selectedBoard && selectedBoard.classes) {
                                    this.setState({
                                        classes: selectedBoard.classes,
                                        boardName: selectedBoard.boardName
                                    });
                                }
                            }

                            // 2. Load subjects for this class
                            if (examData.boardId && examData.classId) {
                                this.getCurriculumData(true);
                            }
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Failed to load exam data',
                            life: 3000
                        });
                    }
                })
                .catch(e => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: e.message || 'Failed to load exam data',
                        life: 3000
                    });
                });
        }
    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;

        if (boardId && classId) {
            this.setState({
                isLoading: true
            });

            this.service.get(`${baseUrlAdmin}/curriculum/getByGrades?boardId=${boardId}&classId=${classId}${groupId ? `&groupId=${groupId}` : ''}`, true)
                .then((data) => {
                    if (data && data.status && data.res.data && data.res.data.length > 0) {
                        const subjects = data.res.data;

                        if (isEdit) {
                            // If in edit mode, match subjects with the exam's subjects
                            const { exam } = this.state;

                            if (exam && exam.subjects) {
                                // Set subject IDs based on the loaded exam data
                                setTimeout(() => {
                                    const updatedExam = { ...exam };

                                    // Make sure subject references are updated
                                    if (updatedExam.subjects && updatedExam.subjects.length > 0) {
                                        updatedExam.subjects.forEach(subj => {
                                            const matchedSubject = subjects.find(s => s.subjectId === subj.subjectId);
                                            if (matchedSubject) {
                                                subj.subjectName = matchedSubject.subjectName;
                                            }
                                        });
                                    }

                                    this.setState({
                                        exam: updatedExam
                                    });
                                }, 300);
                            }
                        }

                        this.setState({
                            subjects,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                })
                .catch(e => {
                    this.setState({
                        isLoading: false
                    });
                });
        } else {
            if (isEdit) {
                this.toast.show({
                    severity: 'warn',
                    summary: 'Missing Data',
                    detail: 'Board or class information is missing',
                    life: 3000
                });
            }
        }
    };

    onExamEditSuccess = () => {
        this.setState({
            editExamType: null,
            editExamId: null,
        });

        this.toast.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Exam updated successfully',
            life: 3000
        });
    }

    onEditExam = (examId) => {
        console.log("Edit exam clicked with data:", examId); // Debug logging

        if (!examId) {
            console.error("Invalid exam data received:", examId);
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not edit exam: Invalid exam data',
                life: 3000
            });
            return;
        }

        this.setState({
            editExamId: examId,
            editExamType: 'AdhocMockTest'
        });
    }

    render() {
        const { editExamId, editExamType } = this.state;

        return (
            <>
                <div className='tests-exam-container'>
                    <div className='mt-4'>
                        {/* Show edit form if in edit mode */}
                        {this.state.editExamId ? (
                            <CreateAdhoc
                                onCreateSuccess={this.onExamEditSuccess}
                                editExamId={this.state.editExamId}
                                editExamType="AdhocMockTest"
                            />
                        ) : (
                            <AdhocList
                                editExamType="AdhocMockTest"
                                onEditExam={this.onEditExam}
                            />
                        )}
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    userInfo: state.currentUserPerms?.userInfo || {},
});

export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(TestsandExam)));
