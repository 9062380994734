import * as ActionTypes from '../actionTypes';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoIdentityCredentials,
  WebIdentityCredentials
} from 'amazon-cognito-identity-js';
import { userPool, USERPOOL_ID } from './awsProfile';
import { getAdminUserPermission } from './loggedInUserPermissionActions';
import { getOrgData } from './sp_orgActions';
import AWS from 'aws-sdk';
import { getAcademicSessionsDropDowns, getBoardsDropDowns } from './getDropDownDetailsAction';

const dispatchRequestSignIn = (data) => ({
  type: ActionTypes.REQUEST_SIGN_IN,
  data
});

const dispatchReceivedSignIn = (data) => ({
  type: ActionTypes.RECEIVED_SIGN_IN,
  data
});

const dispatchErrorSignIn = (err) => ({
  type: ActionTypes.ERROR_SIGN_IN,
  err
});

const dispatchClearSignInData = () => ({
  type: ActionTypes.CLEAR_SIGN_IN_DATA
});

const dispatchAuthenticateUser = (userData) => ({
  type: ActionTypes.AUTH_USER,
  userData
});

export const authUser = (userData) => (dispatch) => dispatch(dispatchAuthenticateUser(userData));

export const clearSignInData = () => dispatchClearSignInData();

export const userSignIn = (mobileNo, password) => (dispatch) => {
  // dispatch(dispatchRequestSignIn({ mobileNo, password }));
  const p = new Promise((res, rej) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: mobileNo,
      Password: password
    });
    const userData = {
      Username: mobileNo,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    authenticateUser(cognitoUser, authenticationDetails)
      .then(() => {

       // dispatch(getOrgData())
        dispatch(getAdminUserPermission(cognitoUser.getUsername()));
        return buildUserObject(cognitoUser);
      })
      .then((userProfileObject) => {
        res(userProfileObject);
        dispatch(dispatchAuthenticateUser(userProfileObject));
        dispatch(dispatchReceivedSignIn(userProfileObject));
      })
      .catch((err) => {
        dispatch(dispatchErrorSignIn(err));
        rej(err);
      });
  });
  return p;

  function authenticateUser(cognitoUser, authenticationDetails) {
    const p = new Promise((res, rej) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {

          localStorage.setItem('user_token', result.accessToken.jwtToken);
          localStorage.setItem('Bearer', result.idToken.jwtToken);
          const loginsObj = {
            [USERPOOL_ID]: result.getIdToken().getJwtToken()
          };

          res();
        },
        onFailure: function (err) {
          rej(err);
        }
      });
    });
    return p;
  }
};

function buildUserObject(cognitoUser) {
  const p = new Promise((res, rej) => {
    cognitoUser.getUserAttributes(function (err, result) {
      if (err) {
        rej(err);
      }
      let userProfileObject = {};
      if (!result) return;
      for (let i = 0; i < result?.length; i++) {
        if (result[i].getName().indexOf('custom:') >= 0) {
          let name = result[i].getName().slice(7, result[i].getName()?.length);
          userProfileObject[name] = result[i].getValue();
        } else {
          userProfileObject[result[i].getName()] = result[i].getValue();
        }
      }
      if (userProfileObject && userProfileObject.name) {
        localStorage.setItem('name', userProfileObject.name);
      }
      if (userProfileObject && userProfileObject.phone_number) {
        localStorage.setItem('phone_number', userProfileObject.phone_number);
      }
      if (userProfileObject && userProfileObject.boardId) {
        localStorage.setItem('boardId', userProfileObject.boardId);
      }
      if (userProfileObject && userProfileObject.isAdmin) {
        localStorage.setItem('isAdmin', userProfileObject.isAdmin);
      }
      if (userProfileObject && userProfileObject.picture) {
        localStorage.setItem('picture', userProfileObject.picture);
      }
      if (userProfileObject && userProfileObject.role) {
        localStorage.setItem('currentUserRole', userProfileObject.role);
      }
      if (userProfileObject && userProfileObject.email) {
        localStorage.setItem('emailId', userProfileObject.email);
      }
       if (userProfileObject && userProfileObject.orgId) {
        localStorage.setItem('orgId', userProfileObject.orgId);
      }
      if (userProfileObject && userProfileObject.sub) {
        localStorage.setItem('userId', userProfileObject.sub);
      }
      // console.log("userProfileObjectuserProfileObject",userProfileObject)
      res(userProfileObject);
    });
    if (cognitoUser && cognitoUser.username) {
      localStorage.setItem('username', cognitoUser.username);
    }
  });
  return p;
}

let isAuthenticating = false;
let authPromise = null;

export const retrieveUserFromLocalStorage = async (dispatch) => {
  if (isAuthenticating) {
    return authPromise; // Return existing promise if authentication is in progress
  }
  
  isAuthenticating = true;
  authPromise = new Promise((res, rej) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(async function (err, session) {
        try {
          if (err) {
            throw err;
          }

          if (session && session.isValid()) {
            localStorage.setItem('user_token', session.getAccessToken().getJwtToken());
            localStorage.setItem('Bearer', session.getIdToken().getJwtToken());
            
            // Batch dispatch actions
            await Promise.all([
              dispatch(getOrgData()),
              dispatch(getBoardsDropDowns()),
              dispatch(getAcademicSessionsDropDowns()),
              dispatch(getAdminUserPermission(cognitoUser.getUsername()))
            ]);

            const userObject = await buildUserObject(cognitoUser);
            res(userObject);
          } else {
            throw new Error('Session invalid');
          }
        } catch (error) {
          rej(error);
        } finally {
          isAuthenticating = false;
          authPromise = null;
        }
      });
    } else {
      isAuthenticating = false;
      authPromise = null;
      rej('No user found in localStorage');
    }
  });

  return authPromise;
};
