import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { cloneDeep } from 'lodash';

class BranchToSectionMultiDDTillSecCol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardIds: null,
            classIds: null,
            groupId: null,
            branchIds: null,
            sectionIds: null,
            boards: [],
            classes: [],
            sections: [],
            curriculumInfo: {},
            selectedrowData: this.props?.dropdownsData

        };
        this.service = new Service()
    }

    componentDidMount() {
        console.log("selectedrowDataselectedrowData", this.props?.dropdownsData)
        this.getData()
    }

    

    getData = () => {
        this.props.getBranchesLatest();
        this.props.getBoardsData(true)
    }

    onChangeBranch = (branchIds) => {

        this.setState({
            boards: [], classes: [], sections: [],
        });

        let filteredBoards = cloneDeep(this.props.boards.filter(board => {
            return board.assignedBranches.some(branchId => branchIds.includes(branchId));
        }))

        filteredBoards.forEach((board) => {
            board?.classes?.forEach((classs) => {
                classs.className = board?.boardName + ' -- ' + classs.className
                classs.sections.forEach((sec) => sec.sectionName = classs.className + ' -- ' + sec.sectionName)
            })
        })

        this.setState({
            branchIds: branchIds,
            boards: filteredBoards,
            classes: [],
            boardIds: '',
            classIds: '',
            branches: [],
            sections: [],
            sectionIds: [],
        });
    };

    onChangeBoard = (boardIds) => {
        this.setState({
            classes: [], sections: [],
        });

        let filteredClasses = [];

        this.state.boards.forEach(board => {
            if (boardIds.includes(board.boardId)) {
                const updatedClasses = board.classes.map(classs => ({
                    ...classs,
                    className: `${board.boardName} - ${classs.className}` 
                }));
                filteredClasses.push(...updatedClasses);
            }
        });

        this.setState({
            classes: filteredClasses,
            boardIds,
            classIds: '',
            branches: [],
            sections: [],
            sectionIds: [],

        }, () => this.setDropdownsData());
    };

    onChangeClass = (classIds) => {
        this.setState({
            sections: [],
        });
        let filterClasses1 = this.state.classes?.filter((classs) =>
            classIds?.includes(classs?.classId)
        )
        let lsecs = []
        filterClasses1?.map((fClass) => {
            let secc = fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId))
            lsecs = [...lsecs, ...secc]
        })
        this.setState({
            classIds: classIds,
            sections: lsecs,
            sectionIds: [],
        }, () => this.setDropdownsData());
    };

    onChangeSection = (sectionIdstt) => {

        console.log(sectionIdstt, "sectionIdssectionIds")

        this.setState({
            sectionIds: sectionIdstt,
        }, () => this.setDropdownsData());
    };

    setDropdownsData = () => {

        let filteredClasses = [];
        this.props.boards.forEach(board => {
            if (this.state?.boardIds.includes(board.boardId)) {
                filteredClasses.push(...board.classes);
            }
        });

        let lsecs = [];
        filteredClasses.forEach((fClass) => {
            const secc = fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId));
            lsecs = [...lsecs, ...secc];
        });

        let {
            boardIds,
            classIds,
            branchIds,
            sectionIds,
        } = this.state;
        let branchNames, sectionNames = []
        if (sectionIds?.length) {
            branchNames = branchIds?.map((e) => this.props.branchData?.find(f => e == f.key).name)
            sectionNames = sectionIds?.map((e) => lsecs?.find(f => e == f.sectionId)?.sectionName)
        }



        this.props.setValues({
            boardIds, classIds, branchIds, sectionIds, branchNames, sectionNames
        })
    }



    updateSections() {
        let filteredClasses = [];
        this.props.boards.forEach(board => {
            if (this.state?.boardIds.includes(board.boardId)) {
                filteredClasses.push(...board.classes);
            }
        });

        this.setState({ classes: filteredClasses });

        let lsecs = [];
        filteredClasses.forEach((fClass) => {
            const secc = fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId));
            lsecs = [...lsecs, ...secc];
        });

        // Set the state to lsecs
        this.setState({ sections: lsecs });
    }

    render() {


        // let filteredClasses = [];

        // this.props.boards.forEach(board => {
        //     if (this.state?.boardIds?.includes(board.boardId)) {
        //         filteredClasses.push(...board.classes);
        //     }
        // });
        // let lsecs = []
        // filteredClasses?.map((fClass) => {
        //     let secc = fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId))
        //     lsecs.push(...secc)
        //     // console.log(secc,"seccseccseccseccsecc")
        //     // lsecs = [...lsecs, ...secc]
        // })

        // console.log(filteredClasses, "filteredClasses", lsecs)

        return (
            <div>
                <div >
                    <div className='grid ml-1' style={{ display: 'flex', gap: '8px' }}>
                        <div className='col-12'>
                            <div className="row-3 ">
                                <ClassetMultiSelect required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props.branchData}

                                    value={this.state.branchIds} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                    optionValue="key" />
                            </div>
                            <div className="row-3 ">
                                <ClassetMultiSelect label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props?.boards} value={this.state.boardIds} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                    optionValue="boardId" />
                            </div>
                            <div className="row-3 ">
                                <ClassetMultiSelect label={'Grade'} icon={<GradesIcon />} placeholder={'Select (Board - Grade)'} options={this.state.classes}
                                    value={this.state.classIds} onChange={(e) => this.onChangeClass(e.value)} optionLabel="className"
                                    optionValue="classId" />
                            </div>
                            <div className="row-3 ">
                                <ClassetMultiSelect label={'Section'} icon={<GradesIcon />} placeholder={'Select (Board - Grade - Section)'} options={this.state.sections}
                                    value={this.state.sectionIds} onChange={(e) => this.onChangeSection(e.value)} optionLabel="sectionName"
                                    optionValue="sectionId" />
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToSectionMultiDDTillSecCol);