import React, { Component } from 'react'
import './styles.scss'
import { Button } from 'primereact/button';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { PlainCalenderIcon, MailIcon, CalenderCheckIcon, RightMarkInCircle, CodeLinkIcon, CalenderIcon } from '../svgIcons';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import { getBoardsData } from "../../store/actions/sp_boardsActions.js";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import ClassetCalendar from '../../classetComponents/classetCalender';
import withRouter from '../lib/withRouter.js';
import { connect } from 'react-redux';
import Authentication from '../../session/index.js'
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import moment from 'moment';
import LoadingComponent from '../loadingComponent/index.js';
import { Toast } from 'primereact/toast';
import BranchToSectionDD from '../BaseDropdownComponents/BranchToSectionDD.js';
import ClassetInputText from '../../classetComponents/classetInputText.js';
import ChequeInfo from './ChequeInfo.js';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards.js';
const dateRangeOptions = [
    { value: 1, label: "Today" },
    { value: 2, label: "This Week" },
    { value: 3, label: "This Month" },
    { value: 4, label: "Academic Session" },
    { value: 5, label: "Custom" },
]

const checkStatusOptions = [
    { value: "realised", label: "Realized" },
    { value: "bounced", label: "Bounced" },
    { value: "represented", label: "Presented" },
    { value: "received", label: "Received" },
    { value: "expired", label: "Expired" },
    { value: "returned", label: "Returned" }
]

const searchOptions = [
    { value: 1, label: "Student Name" },
    { value: 2, label: "Enrollment Number" }
]


class ChequeOrDDStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchType: 1,
            chequeinfo: {},
            searchInput: "",
            chequeStatusData: [],
            datesRange: null,
            isLoading: false,
            isAssignBtnFeeCategory: true,
            isAssignSaveFeeCategory: false,
            isAssignViewFeeCategory: false,
            isShowCreateForm: false,
            depositDate: new Date(),
            dateRangeType: 1,
            checkStatusType: "received",
            chequeFromDate: "",
            chequeToDate: "",
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCalenderDialogOpen: false,
            updateDateType: "",
            selectedProducts: null,
            updatedToRow: {},
            isCreateUser: false,
            isBranchView: false,
            isOpenDialogBoxToCreateGroup: false,
            groupName: "",
            branchName: "",
            groupHead: "",
            contactForGroup: "",
            descForGroup: "",
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            availableOptions: checkStatusOptions,


        }
        this.service = new Service()
    }
    getChequeInfo = () => {
        this.setState({
            isLoading: true,
        });
        let url = `${baseUrlAdmin}/cheque-dd-status/getchequeinfo`;
        const { checkStatusType, dateRangeType, chequeFromDate, chequeToDate } = this.state
        let payload = {
            academicYear: localStorage.getItem('userAcademicYear'),
            branchId: this.state?.dropdownsData?.branchId || "",
            boardId: this.state?.dropdownsData?.boardId || "",
            classId: this.state?.dropdownsData?.classId || "",
            sectionId: this.state?.dropdownsData?.sectionId || "",
            chequeDDStatus: checkStatusType || "",
            dateRange: dateRangeType,
            fromDate: chequeFromDate,
            toDate: chequeToDate
        }
        this.service.post(url, payload, true).then(data => {
            if (data.status) {
                if (data?.res?.status) {

                    this.setState({
                        chequeStatusData: data.res.data || [],
                        cheuqeGetData: data.res.data || [],
                        totalRecords: data?.res?.totalRecords,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    handleAssignFeeCatergory = () => {
        this.setState({ isAssignBtnFeeCategory: false, isAssignSaveFeeCategory: true, isAssignViewFeeCategory: false })
    }

    handleClickAssignFeeSave = () => {
        this.setState({ isAssignBtnFeeCategory: false, isAssignSaveFeeCategory: false, isAssignViewFeeCategory: true })
    }
    componentDidMount() {
        console.log(this.props)
        this.getChequeInfo()
        // this.props.getBoardsData(true);
    }

    saveConfirmationDialog = (rowData) => {
        let selectedRowData = this.state.cheuqeGetData?.find(el => el._id == rowData._id)
        let size = Object.keys(selectedRowData).length
        if (size && selectedRowData?.chequeDDStatus !== rowData?.chequeDDStatus) {
            this.setState({
                isSaveConfirmationDialog: true,
                dataTosave: rowData
            })
        } else {
            this.toast?.show({ severity: 'error', summary: 'Cannot be saved please change the status', detail: 'Validation failed' });
        }



    }



    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getChequeInfo);
    };

    onHandleChequeStatus = (rowData) => {
        this.setState({
            isLoading: true,
        });
        let url = `${baseUrlAdmin}/cheque-dd-status/updatechequeinfo`;
        let payload = {
            _id: rowData?._id,
            chequeDDStatus: rowData?.chequeDDStatus,
            depositDate: rowData?.depositDate,
        }
        let flag = false
        flag = (rowData?.chequeDDStatus && rowData?.depositDate) ? true : false

        if (flag) {
            this.service.put(url, payload, true).then(data => {
                if (data.status) {
                    if (data?.res?.status) {
                        this.setState({
                            isLoading: false,
                            isShowCreateForm: false,
                            isSaveConfirmationDialog: false
                        }, () => this.setIsShowCreateForm())
                        this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                    } else {
                        this.setState({ isLoading: false, })
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({ isLoading: false })
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                }
            });
        } else {
            this.setState({
                isLoading: false,
            });
            this.toast?.show({ severity: 'error', summary: 'Please select deposit date and stauts', detail: 'Validation failed' });

        }

    }
    /*     onStatusChange = (rowData, value) => {
            this.setState((prevState) => {
                const updatedItems = prevState.chequeStatusData.map(item => {
                    if (item._id === rowData?._id) {
                        const updatedItem = { ...item, chequeDDStatus: value };
                        return updatedItem;
                    }
                    return item;
                });
                return { chequeStatusData: updatedItems };
            });
        } */
    handleChangeClass = (e) => {

        const newClassId = e.value;
        this.setState({ classId: newClassId, chequeStatusData: [] }, () => {
            this.getChequeInfo();
        });
    }

    onHandleDepositDate = () => {
        let rowData = this.state.selectedRow
        let value = this.state.depositDate
        this.setState((prevState) => {
            const updatedItems = prevState.chequeStatusData.map(item => {
                if (item._id === rowData?._id) {
                    const updatedItem = { ...item, depositDate: value };

                    return updatedItem;
                }
                return item;
            });
            return { chequeStatusData: updatedItems, isCalenderDialogOpen: !prevState?.isCalenderDialogOpen };
        });
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                termBasedData: [],
                studentId: '',
                username: '',
                user_info: {}
            },
            () => {
                this.getStudents();
            }
        );
    };
    getStudents = () => {
        this.setState({ isLoading1: true });
        let academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        this.service
            .post(url, payload, true)
            .then((data) => {
                const studentsData = data?.res?.data.map((each) => {
                    return {
                        label: each?.name,
                        value: each?.user_id
                    };
                });

                if (data?.status) {
                    this.setState({
                        students: studentsData,
                        isLoading1: false
                    });
                } else {
                    this.setState({ isLoading1: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading1: false });
            });
    };
    onStudentChange = (e) => {
        this.setState({ studentId: e.value, termBasedData: [] }, () => {
            this.getTermBasedData();
        });
    };
    getTermBasedData = () => {
        this.setState({ isLoading: true, termBasedData: [] });
        let academicYear = localStorage.getItem('userAcademicYear');
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-collect/fee-types?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            classId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo
        };
        this.service
            ?.post(url, payload, true)
            .then((data) => {
                if (data?.status && data?.res?.data?.length) {
                    // const totalStudentDue=
                    this.setState({
                        termBasedData: data?.res?.data,
                        isLoading: false,
                        totalRecords: data?.res?.totalRecords,
                        partialPayment: data?.res?.partialPayment,
                        // partialPayment:false,
                        studentId: data?.res?.studentId,
                        username: data?.res?.username,
                        user_info: data?.res?.user_info,
                        totalStudentDue: Number(data?.res?.totalStudentDue)
                    });
                } else if (data?.status && !data?.res?.data?.length) {
                    this.setState({ isLoading: false, username: null, studentId: null });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'No Fee Structure Assigned to Student', life: 3000 });
                } else {
                    this.setState({ isLoading: false, username: null, studentId: null });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Error Occurred', life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    };
    setIsShowCreateForm = () => {
        this.setState({ isShowCreateForm: false }, () => this.getChequeInfo())
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                termBasedData: [],
                studentId: '',
                username: '',
                user_info: {}
            },
            () => {
                this.getChequeInfo();
            }
        );
    };
    getNextOptions(currentStage) {
        let nextOptions = [];

        switch (currentStage) {
            case "received":
                nextOptions = ["received", "represented", "returned"];
                break;
            case "represented":
                nextOptions = ["represented", "realised", "bounced"];
                break;
            case "bounced":
                nextOptions = ["bounced", "represented", "returned", "expired"];
                break;
            case "expired":
                nextOptions = ["expired", "returned"];
                break;
            case "returned":
                nextOptions = ["returned"];
                break;
            case "realised":
                nextOptions = ["realised"];
                break;
            default:
                nextOptions = [];
        }

        return nextOptions
    }
    onStatusChange = (rowData, value) => {
        let checkCurrentStatus = this.state.cheuqeGetData.find(el => el._id === rowData?._id)
        
        const nextOptions = this.getNextOptions(checkCurrentStatus.chequeDDStatus);
        if (nextOptions.length > 0 && !nextOptions.includes(value)) {
            return this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Invalid selection! Please choose a valid next status.', life: 3000 });
        }
        this.setState((prevState) => {
            const updatedItems = prevState.chequeStatusData.map(item => {
                if (item._id === rowData?._id) {
                    const updatedItem = { ...item, chequeDDStatus: value };
                    return updatedItem;
                }
                return item;
            });
            return { chequeStatusData: updatedItems };
        });
    };


    render() {
        return (
            <div className=" ml-4">
                <div className='flex'>
                    <Button className='ml-auto mr-5 formSubmitButton' onClick={() => this.setState({ isShowCreateForm: true })} label='Add Cheque'></Button>
                </div>
                <BranchToSectionDD setValues={this.setDropdownValues} />
                <div className='grid m-2'>
                    {/* <div className="col-3">
                        <div className='flex w-10'>
                            <div className="col-12 ">
                                <label className='label'>Branch</label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        id='boardId'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.props.branchData}
                                        optionLabel="name"
                                        optionValue="key"
                                        value={this.state.branchId}
                                        onChange={(e) => { this.setState({ branchId: e.value }) }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Branch</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-3">
                        <div className='flex w-10'>
                            <div className="col-12 ">
                                <label className='label'>Board</label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        id='boardId'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.props.boards}
                                        optionLabel="boardName"
                                        optionValue="boardId"
                                        value={this.state.boardId}
                                        onChange={(e) => { this.setState({ boardId: e.value }) }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Board</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-3">
                        <div className='flex w-10'>
                            <div className="col-12 ">
                                <label className='label'>Grade</label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        id='classId'
                                        icon={<MailIcon />}
                                        className="text-xl"
                                        height="48px"
                                        width="368px"
                                        options={this.props.boards.find((board) => board.boardId === this.state?.boardId)?.classes}
                                        optionLabel="className"
                                        optionValue="classId"
                                        value={this.state.classId}
                                        onChange={this.handleChangeClass}

                                        placeholder={(
                                            <div>
                                                <span className='text-xl'>Select Grade</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}


                    <div className="col-3">
                        <div className='flex'>
                            <div className="col-12 ">
                                <label className='label'>Cheque Status<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        id='checkStatusType'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="390px"
                                        options={checkStatusOptions}
                                        value={this.state.checkStatusType}
                                        onChange={(e) => {
                                            this.setState({ checkStatusType: e.value, chequeStatusData: [] }, () => {
                                                this.getChequeInfo();
                                            })
                                        }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Select Cheque Status</span>
                                            </div>
                                        )}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='flex'>
                            <div className="col-12 ">
                                <label className='label'>Date Range Type<span className='ma-required'>*</span></label>
                                <div className='mt-2'>
                                    <ClassetDropdown
                                        id='checkStatusType'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={dateRangeOptions}
                                        value={this.state.dateRangeType}
                                        onChange={(e) => {
                                            this.setState({ dateRangeType: e.target.value, chequeStatusData: [] }, () => {
                                                this.state.dateRangeType !== 5 && this.getChequeInfo();
                                            });
                                        }}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center mt-1">

                                                <span className='text-xl ml-4 body-medium'>Select Type</span>
                                            </div>
                                        )}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                    {this.state.dateRangeType == 5 &&
                        <>
                            <div className="col-3">
                                <div className='flex w-10'>
                                    <div className="col-12 ">
                                        <label className='label'>From Date</label>
                                        <div className='mt-2'>
                                            <ClassetCalendar
                                                calendarMode="single"
                                                className="text-xl"
                                                height="48px"
                                                width="368px"
                                                icon={<CalenderCheckIcon />}
                                                value={this.state.chequeFromDate}
                                                placeholder="Select From Date"
                                                onChange={(e) => {
                                                    this.setState({ chequeFromDate: e.value }, () => {
                                                        this.state.chequeFromDate !== "" && this.state.chequeToDate !== "" && this.getChequeInfo();
                                                    })
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='flex w-10'>
                                    <div className="col-12 ">
                                        <label className='label'>To Date</label>
                                        <div className='mt-2'>
                                            <ClassetCalendar
                                                calendarMode="single"
                                                className="text-xl"
                                                height="48px"
                                                width="368px"
                                                icon={<CalenderCheckIcon />}
                                                value={this.state.chequeToDate}
                                                placeholder="Select To Date"
                                                onChange={(e) => {
                                                    this.setState({ chequeToDate: e.value }, () => {
                                                        this.state.chequeFromDate !== "" && this.state.chequeToDate !== "" && this.getChequeInfo();
                                                    })
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>
                <div className=" datatable-crud-demo mt-5 mx-4">
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        value={this.state.chequeStatusData}
                        dataKey="id"
                        paginator
                        lazy
                        responsiveLayout="scroll"
                        className="dataTableValuesText"
                        showGridlines
                        columnResizeMode="expand"
                        resizableColumns
                        // selection={this.state.selectedProducts} 
                        // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))} 
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    // header={header}
                    >

                        {/*   <Column style={{ width: "15rem", height: "6rem" }} filterField="enrollmentNo" field="enrollmentNo" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Enrollment No" alignHeader={"center"} /> */}
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Username" alignHeader={"center"} />
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="chequeNo" field="chequeNo" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Cheque No" alignHeader={"center"}
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <p>
                                        {rowData?.chequeNo ?? rowData?.ddNo}
                                    </p>
                                )
                            }}
                        />
                        <Column style={{ width: "17rem", height: "6rem" }} filterField="bankName" field="bankName" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Bank Name" alignHeader={"center"} />
                        <Column style={{ width: "15rem", height: "6rem" }} filterField="chequeDate" field="chequeDate" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Cheque Date" alignHeader={"center"} body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex justify-content-center">
                                    {moment(rowData.chequeDate).format('DD/MM/YYYY')}
                                </div>
                            )
                        }} />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Deposite Date" body={(rowData, { rowIndex }) => {
                            return (

                                // rowData?.depositDate ?
                                //     <span>
                                //         {moment(rowData.depositDate).format('DD/MM/YYYY')}
                                //     </span>
                                //     :
                                //     <div onClick={() => { this.setState({ isCalenderDialogOpen: true, selectedRow: rowData }) }} >
                                //         <PlainCalenderIcon width={16} height={16} color={'black'} />
                                //     </div>
                                <div className='flex justify-content-center'>
                                    <span>
                                        {rowData?.depositDate && moment(rowData.depositDate).format('DD/MM/YYYY')}
                                    </span>
                                    <div className='ml-2 mt-1' onClick={() => { this.setState({ isCalenderDialogOpen: true, selectedRow: rowData }) }} >
                                        <PlainCalenderIcon width={20} height={20} color={'black'} />
                                    </div>
                                </div>
                            )
                        }} ></Column>

                        <Column style={{ width: "15rem", height: "6rem" }} filterField="amountPaid" field="" sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Amount" alignHeader={"center"} body={(rowData) => {
                            console.log(rowData, "aa")
                            return (
                                <div className="flex justify-content-center">
                                    {rowData.amount}
                                </div>
                            )
                        }} />
                        <Column style={{ width: "20rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Status" body={(rowData,) => {
                            return (
                                <div className='flex justify-content-center'>
                                    <ClassetDropdown
                                        optionLabel='label'
                                        optionValue="value"
                                        width={"175px"}
                                        height={"48px"}
                                        className='text-xl add-vehicle-field-input pl-2'
                                        value={rowData?.chequeDDStatus}
                                        options={checkStatusOptions}
                                        onChange={(e) => this.onStatusChange(rowData, e.value)}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center">
                                                <span className='text-xl'>Status</span>
                                            </div>
                                        )}
                                        disabled={rowData?.chequeDDStatus === "realised" || rowData?.chequeDDStatus === "returned"}
                                    />
                                </div>
                            )
                        }} ></Column>


                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' className=' ' header="Actions" body={(rowData, { rowIndex }) => {
                            return (
                                <Button
                                    label="Save"
                                    className='tableSaveBtn'
                                    // onClick={() => rowData?.despositDate !== "" && rowData?.chequeDDStatus !== "" && this.onHandleChequeStatus(rowData)}
                                    onClick={() => rowData?.despositDate !== "" && rowData?.chequeDDStatus !== "" && this.saveConfirmationDialog(rowData)}

                                />
                            );

                        }} ></Column>


                    </DataTable>

                </div>
                <Dialog
                    blockScroll={true}
                    visible={this.state.isCalenderDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className='calenderBoxSize'
                    dismissableMask={false}
                    closable={false}
                    footer={() => (
                        <div className='flex justify-content-center mb-3'>
                            <Button label="Cancel" className='calenderCancelBtn mr-5 px-7' onClick={() => this.setState({ isCalenderDialogOpen: false })} />
                            <Button label='Apply' className='calenderApplyBtn   px-7' onClick={() => this.onHandleDepositDate()} />
                        </div>
                    )}
                    onHide={() => {
                        this.setState({
                            isCalenderDialogOpen: false,
                        })
                    }}>
                    <div className="flex flex-column justify-content-center border-300 border-bottom-1 mt-1">

                        <Calendar
                            className="pb-5 body-medium"
                            value={this.state.depositDate}
                            onChange={(e) => this.setState({ depositDate: e.value })}
                            inline
                            showIcon={false}
                            maxDate={new Date()}
                        />
                    </div>


                </Dialog>

                <Dialog
                    visible={this.state.isSaveConfirmationDialog}
                    draggable={false}
                    closeOnEscape={false}
                    className='w-3'
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (<div className='mt-2 formHeadingInter'>
                            <RightMarkInCircle />
                            <p style={{ fontWeight: 'bold', fontSize: 20 }}>Save to confirm changes</p>
                        </div>)
                    }}
                    footer={() => (
                        <div className='flex justify-content-center '>
                            <Button className='formSubmitButton px-6' style={{ border: 0 }} onClick={() => { this.onHandleChequeStatus(this.state.dataTosave) }} >
                                <p className='formSubmitButtonText'>Save</p>
                            </Button>
                            <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isSaveConfirmationDialog: false })} >
                                <p className='formCancelButtonText'>Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isSaveConfirmationDialog: false })}
                >
                </Dialog>

                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowCreateForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    dismissableMask={false}
                    closable={true}
                    style={{
                        width: '90%',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    header={() => {
                        return <h2 className="transport-header py-2">Add Cheque</h2>;
                    }}
                    // footer={() => (

                    // )}
                    onHide={() => {
                        this.setState({
                            isShowCreateForm: false,
                            newPassword: null
                        });
                    }}
                >
                    <ChequeInfo isShowCreateForm={this.setIsShowCreateForm} />

                </Dialog>
                {this.state.isLoading && <LoadingComponent />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </div>
        )
    }
}



const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(ChequeOrDDStatus)));

