import React, { Component } from "react";  // Change this line
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import './styles.scss';
import { MultiSelect } from "primereact/multiselect";
import ClassetDropdown from "../../../../classetComponents/classetDropDown";
import { MailIcon } from "../../../svgIcons";
import ClassetInputText from "../../../../classetComponents/classetInputText";
import ClassetTextArea from "../../../../classetComponents/classetTextArea";
import ClassetMultiSelect from "../../../../classetComponents/classetMultiSelect";
import { getFormFields, isFormValid, onNumberChange, onTextChange } from "../../../../utile/formHelper";
import { baseUrlAdmin, questionType } from "../../../../store/apiConstants";
import Service from "../../../services";
import { Toast } from "primereact/toast";
import { isDigit } from "../../../../utile";
import uuidv4 from 'uuid/v4';
import LoadingComponent from '../../../loadingComponent';
import { parse } from "uuid";

// import Service from './../../services';
const getSubjectsDrpDwn = () => {
    let subjectsCount = [];

    for (let i = 1; i <= 10; i++) {
        subjectsCount.push({ label: i + ' Subject' + (i > 1 ? 's' : ''), value: i })

    }

    return subjectsCount

}

const getSectionDrpDwn = () => {
    let sectionCount = [];

    for (let i = 1; i <= 10; i++) {
        sectionCount.push({ label: i + ' Section' + (i > 1 ? 's' : ''), value: i })

    }

    return sectionCount

}
const totalSections = [
    { value: 1, label: 'One(1)' },
    { value: 2, label: 'Two(2)' },
    { value: 3, label: 'Three(3)' },
    { value: 4, label: 'Four(4)' }
]


const totalSubjects = [
    { value: 1, label: 'Subject(1)' },
    { value: 2, label: 'Subject(2)' },
    { value: 3, label: 'Subject(3)' },
    { value: 4, label: 'Subject(4)' }
]

const QuestionTypesrr = [
    { value: 1, label: 'SCCT' },
    { value: 2, label: 'MCCT' },
    { value: 3, label: 'Integer' },
    { value: 4, label: 'Numerical' },
    { value: 5, label: 'Fill in the Balnk' },
    { value: 6, label: 'Passage SCCT' },
    { value: 7, label: 'Passage MCCT' },
    { value: 8, label: 'Matrix Matching' },
    { value: 9, label: 'True/False' },
    { value: 10, label: 'Audio Based' },
    { value: 11, label: 'Video Based' },
    { value: 12, label: 'Subjective - VSAQ' },
    { value: 13, label: 'Subjective - SAQ' },
    { value: 14, label: 'Subjective - LAQ' },
]
const QuestionTypes = [
    { value: 'SCCT', label: 'SCCT' },
    { value: 'MCCT', label: 'MCCT' },
    { value: 'Integer', label: 'Integer' },
    { value: 'Numerical', label: 'Numerical' },
    { value: 'Fill in the Blank', label: 'Fill in the Blank' },
    { value: 'Passage SCCT', label: 'Passage SCCT' },
    { value: 'Passage MCCT', label: 'Passage MCCT' },
    { value: 'Matrix Matching', label: 'Matrix Matching' },
    { value: 'True/False', label: 'True/False' },
    { value: 'Audio Based', label: 'Audio Based' },
    { value: 'Video Based', label: 'Video Based' },
    { value: 'Subjective - VSAQ', label: 'Subjective - VSAQ' },
    { value: 'Subjective - SAQ', label: 'Subjective - SAQ' },
    { value: 'Subjective - LAQ', label: 'Subjective - LAQ' },
];

const tempType = [
    { label: 'Subjective', value: 'Subjective' },
    { label: 'Objective', value: 'Objective' },
]


const createExamFields = require('./addExam.json');
let formFields = createExamFields;
export class AddExam extends Component {
    constructor(props) {
        super(props)

        this.formFields = getFormFields(formFields, this.props.editExamData || {});

        this.state = {
            isLoading: false,
            exam: this.props?.editData?._id ? this.props?.editData : this.formFields?.data,
            formValidations: this.formFields.formValidations,
            tab: '1',
            totalSections: totalSections,
            totalSubjects: totalSubjects,
            sections: [],
            selectedSubject: 0,
            QuestionTypes: QuestionTypes,
            selectedQuestionType: [],
            subjects: [],
            selectedSections: null,
            questionTypes: [],
            activeIndex: {}, // Change this to an object instead of array
        }
        this.service = new Service();
        this.toast = React.createRef();

    }
    handleSelect = (item) => {
        this.setState({ selectedQuestionType: [...item] });
    }
    validateSectionFields = (section) => {
        const errors = {};

        if (!section.sectionName || section.sectionName.trim() === '') {
            errors.sectionName = "Section Name is required.";
        }

        if (!section.totalQuestions || isNaN(section.totalQuestions) || parseInt(section.totalQuestions) <= 0) {
            errors.totalQuestions = "Total Questions must be a valid number greater than 0.";
        }

        if (!section.totalMarkedQuestions || isNaN(section.totalMarkedQuestions) || parseInt(section.totalMarkedQuestions) < 0) {
            errors.totalMarkedQuestions = "Marked Questions must be a valid number.";
        }

        // Add Question Type validation
        if (!section.questionTypes || section.questionTypes.length === 0) {
            errors.questionTypes = "At least one Question Type must be selected.";
        }

        // Add Marking Scheme validation
        if (section.markingScheme && section.markingScheme.length > 0) {
            const markingSchemeErrors = [];

            section.markingScheme.forEach((scheme, index) => {
                const schemeErrors = {};

                if (!scheme.mandatoryQuestions || scheme.mandatoryQuestions.trim() === '') {
                    schemeErrors.mandatoryQuestions = `Mandatory Questions required for ${scheme.questionType}`;
                }

                if (!scheme.positiveMarking || scheme.positiveMarking.trim() === '') {
                    schemeErrors.positiveMarking = `Right Mark required for ${scheme.questionType}`;
                }

                if (Object.keys(schemeErrors).length > 0) {
                    markingSchemeErrors[index] = schemeErrors;
                }
            });

            if (markingSchemeErrors.length > 0) {
                errors.markingScheme = markingSchemeErrors;
            }
        }

        return errors;
    };

    isTemplateAdditionalValid = () => {

        let isError = false;
        let { exam, formValidations } = this.state;
        // Create a deep copy of exam to work with
        let updatedExam = JSON.parse(JSON.stringify(exam));
        if (updatedExam.totalMarkedQuestions > updatedExam.totalNoOfQuestions) {
            formValidations.fields.totalMarkedQuestions = {
                ...formValidations.fields.totalMarkedQuestions,
                isValid: false,
                errorMsg: '"Marked questions" should be less than "Number Of Questions"'
            }
        }

        const subjectsTotalQuestionCount = updatedExam.subjects.reduce((acc, curr) => {
            acc += parseInt(curr.totalQuestions, 10);
            return acc;
        }, 0);

        if (!isError) {
            if (parseInt(updatedExam.totalNoOfQuestions) != subjectsTotalQuestionCount) {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: false,
                    errorMsg: `"Total Questions" of all subjects don't match "Total Number Of Questions"`
                }
                isError = true;
            } else {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: true,
                    errorMsg: ``
                }

            }
        }
        // const questionTypeValues = template.questionType.map((q) => q.value);
        let totalMarkedQuestionsCount = 0

        updatedExam.subjects = updatedExam.subjects?.map((subject) => {
            let sectiontotalMarkedQuestionsCount = 0;
            let sectionTotalQuestionsCount = 0;
            subject.sections = subject.sections?.map((section) => {
                // Initialize errors array if it doesn't exist
                if (!section.totalQuestionsErrs) {
                    section.totalQuestionsErrs = [];
                }

                let markingSchemeCount = 0;
                sectiontotalMarkedQuestionsCount += parseInt(section.totalMarkedQuestions);
                sectionTotalQuestionsCount += parseInt(section.totalQuestions);
                let questionTypeTotalMandatoryQuestionsCount = 0;

                section.markingScheme.forEach((schema) => {
                    questionTypeTotalMandatoryQuestionsCount += parseInt(schema.mandatoryQuestions);
                    schema.totalQuestions = parseInt(schema.optionalQuestions ? schema.optionalQuestions : 0) +
                        parseInt(schema.mandatoryQuestions ? schema.mandatoryQuestions : 0);
                    markingSchemeCount += schema.totalQuestions
                });

                // Store current errors
                const currentErrors = [...section.totalQuestionsErrs];

                // Check for total questions mismatch
                if (parseInt(section.totalQuestions) !== markingSchemeCount) {
                    isError = true;
                    if (!currentErrors.includes('Sum of all questions types is not equal to "Total Questions" of Section')) {
                        currentErrors.push('Sum of all questions types is not equal to "Total Questions" of Section');
                    }
                }

                // Check for marked questions mismatch
                if (parseInt(section.totalMarkedQuestions) !== questionTypeTotalMandatoryQuestionsCount) {
                    isError = true;
                    if (!currentErrors.includes('Sum of all mandatory questions is not equal to "Marked Questions" of Section')) {
                        currentErrors.push('Sum of all mandatory questions is not equal to "Marked Questions" of Section');
                    }
                }

                // Update errors
                section.totalQuestionsErrs = currentErrors;
                if (!isError) {
                    delete section.totalQuestionsErrs;
                }

                return section;
            });

            if (!subject.totalQuestionsErrs) {
                subject.totalQuestionsErrs = [];
            }

            const currentSubjectErrors = [...subject.totalQuestionsErrs];

            if (parseInt(subject.totalQuestions) !== sectionTotalQuestionsCount) {
                isError = true;
                if (!currentSubjectErrors.includes('Sum of "Total Questions" of sections is not equal to "Total Questions" of Subject')) {
                    currentSubjectErrors.push('Sum of "Total Questions" of sections is not equal to "Total Questions" of Subject');
                }
            }

            if (parseInt(subject.totalMarkedQuestions) !== sectiontotalMarkedQuestionsCount) {
                isError = true;
                if (!currentSubjectErrors.includes('Sum of "Marked Questions" of sections is not equal to "Marked Questions" of Subject')) {
                    currentSubjectErrors.push('Sum of "Marked Questions" of sections is not equal to "Marked Questions" of Subject');
                }
            }

            // Update subject errors
            subject.totalQuestionsErrs = currentSubjectErrors;
            totalMarkedQuestionsCount += sectiontotalMarkedQuestionsCount;
            if (!isError) {
                delete subject.totalQuestionsErrs;
            }

            return subject;
        });

        if (!isError) {
            if (totalMarkedQuestionsCount != parseInt(updatedExam.totalMarkedQuestions, 10)) {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: false,
                    errorMsg: `"Mandatory" count of all subjects doesn't match "Total Marked Questions"`
                }
                isError = true;
            } else {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: true,
                    errorMsg: ``
                }
            }

        }

        this.setState({
            formValidations,
            exam: updatedExam
        })

        return {
            isError,
            updatedExam
        };
    }

    onSubmitClick = () => {
        console.log('Starting onSubmitClick...'); // Debug - Entry point

        if (this.state.isLoading) {
            console.log('Submit blocked - Already loading');
            return;
        }

        this.setState({ isLoading: true });

        try {
            // Basic validation
            if (!this.state.exam?.subjects?.length) {
                console.log('Validation failed - No subjects');
                this.setState({ isLoading: false });
                this.toast?.show({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'Atleast one Subject has to be selected',
                    life: 3000
                });
                return;
            }

            const academicYear = localStorage.getItem("userAcademicYear");
            console.log('Building exam object with academic year:', academicYear);

            let exam = {
                ...this.state.exam,
                totalSubjects: this.state.exam?.subjects?.length || 0,
                status: "Active",
                subjects: this.state.exam?.subjects?.map(subject => ({
                    ...subject,
                    totalSections: subject?.sections?.length || 0,
                    id: uuidv4(),
                    subjectId: uuidv4(),
                    sections: subject?.sections?.map(section => ({
                        ...section,
                        errors: this.validateSectionFields(section)
                    }))
                })) || [],
                academicYear: academicYear
            };

            // Validate total questions
            const totalQuestionsAllowed = this.state.exam.totalNoOfQuestions || 0;
            const totalQuestionsInSubjects = exam.subjects.reduce((sum, subject) =>
                sum + (Number(subject.totalQuestions) || 0), 0);

            console.log('Validating total questions:', {
                allowed: totalQuestionsAllowed,
                inSubjects: totalQuestionsInSubjects
            });

            if (totalQuestionsInSubjects > totalQuestionsAllowed) {
                console.log('Total questions validation failed');
                this.setState({ isLoading: false });
                this.toast?.show({
                    severity: 'error',
                    summary: 'Total Questions Exceeded',
                    detail: `Total questions in subjects (${totalQuestionsInSubjects}) cannot exceed Exam Total Questions (${totalQuestionsAllowed})`,
                    life: 3000
                });
                return;
            }

            // Validate marked questions
            const totalMarkedQuestionsAllowed = this.state.exam.totalMarkedQuestions || 0;
            const totalMarkedQuestionsInSubjects = exam.subjects.reduce((sum, subject) =>
                sum + (Number(subject.totalMarkedQuestions) || 0), 0);

            console.log('Validating marked questions:', {
                allowed: totalMarkedQuestionsAllowed,
                inSubjects: totalMarkedQuestionsInSubjects
            });

            if (totalMarkedQuestionsInSubjects > totalMarkedQuestionsAllowed) {
                console.log('Marked questions validation failed');
                this.setState({ isLoading: false });
                this.toast?.show({
                    severity: 'error',
                    summary: 'Total Marked Questions Exceeded',
                    detail: `Total marked questions in subjects (${totalMarkedQuestionsInSubjects}) cannot exceed Exam Total Marked Questions (${totalMarkedQuestionsAllowed})`,
                    life: 3000
                });
                return;
            }

            // Subject and section validation
            console.log('Starting subject and section validation');
            const subjectValidationErrors = exam.subjects.some((subject, index) => {
                const totalQuestionsInSections = subject.sections?.reduce((sum, section) =>
                    sum + (Number(section.totalQuestions) || 0), 0);
                const totalMarkedQuestionsInSections = subject.sections?.reduce((sum, section) =>
                    sum + (Number(section.totalMarkedQuestions) || 0), 0);

                console.log(`Validating subject ${index + 1}:`, {
                    totalQuestions: subject.totalQuestions,
                    inSections: totalQuestionsInSections,
                    totalMarked: subject.totalMarkedQuestions,
                    markedInSections: totalMarkedQuestionsInSections
                });

                if (totalQuestionsInSections > Number(subject.totalQuestions)) {
                    console.log(`Subject ${index + 1} total questions validation failed`);
                    this.setState({ isLoading: false });
                    this.toast?.show({
                        severity: 'error',
                        summary: `Subject ${index + 1} Validation`,
                        detail: `Total questions in sections (${totalQuestionsInSections}) do not match subject total questions (${subject.totalQuestions})`,
                        life: 3000
                    });
                    return true;
                }

                if (totalMarkedQuestionsInSections > Number(subject.totalMarkedQuestions)) {
                    console.log(`Subject ${index + 1} marked questions validation failed`);
                    this.setState({ isLoading: false });
                    this.toast?.show({
                        severity: 'error',
                        summary: `Subject ${index + 1} Validation`,
                        detail: `Total marked questions in sections (${totalMarkedQuestionsInSections}) do not match subject total marked questions (${subject.totalMarkedQuestions})`,
                        life: 3000
                    });
                    return true;
                }
                return false;
            });

            if (subjectValidationErrors) {
                console.log('Subject validation failed');
                return;
            }

            // Form validation
            console.log('Starting form validation');
            const formStatus = isFormValid(formFields, this.formFields.formValidations, exam);
            const validationResult = this.isTemplateAdditionalValid();
            const additionalValidationError = validationResult.isError;
            exam = validationResult.updatedExam || exam;

            console.log('Validation results:', {
                formValid: formStatus.formValidations.isFormValid,
                additionalValidationError
            });

            if (!formStatus.formValidations.isFormValid || additionalValidationError) {
                console.log('Form validation failed');
                this.setState({
                    formValidations: formStatus.formValidations,
                    isLoading: false
                });
                return;
            }

            // Section validation
            console.log('Starting section validation');
            const hasErrors = exam.subjects?.some(subject => {
                const sectionErrors = subject.sections?.some(section => {
                    const errorCount = Object.keys(section.errors || {})?.length;
                    console.log('Section errors:', {
                        sectionName: section.sectionName,
                        errorCount,
                        errors: section.errors
                    });
                    return errorCount > 0;
                });
                return sectionErrors;
            });

            console.log('Section validation result:', { hasErrors });

            if (hasErrors) {
                console.log('Section validation failed - showing error toast');
                this.setState({
                    exam,
                    isLoading: false
                });
                this.toast?.show({
                    severity: 'error',
                    summary: 'Please fill the required fields',
                    detail: 'One or more sections have validation errors',
                    life: 3000
                });
                return;
            }

            // API call
            const isEdit = this.props.editData?._id;
            const url = isEdit
                ? `${baseUrlAdmin}/examtemplate/updatetemplate`
                : `${baseUrlAdmin}/examtemplate/savetemplate`;

            console.log('Making API call:', {
                url,
                method: isEdit ? 'put' : 'post',
                payload: exam
            });

            this.service[isEdit ? 'put' : 'post'](url, exam, true)
                .then(res => {
                    console.log('API Response:', res);
                    if (res.status && res?.res?.success) {
                        this.toast?.show({
                            severity: 'success',
                            summary: `${isEdit ? 'Updated' : 'Created'} successfully`,
                            detail: "",
                            life: 3000
                        });
                        this.setState({ exam: {}, isLoading: false }, () => {
                            this.props?.switchTab('2');
                        });
                    } else {
                        throw new Error(res?.errMessage || 'API call failed');
                    }
                })
                .catch(error => {
                    console.error('API Error:', error);
                    this.setState({ isLoading: false });
                    this.toast?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: error.message || 'Failed to save template',
                        life: 3000
                    });
                });

        } catch (error) {
            console.error('Submit Error:', error);
            this.setState({ isLoading: false });
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'An unexpected error occurred',
                life: 3000
            });
        }
    };


    // Add a helper function to validate numeric input
    validateNumericInput = (value) => {
        if (!value) return '';
        const numericValue = value.replace(/[^0-9]/g, '');
        return numericValue;
    };

    // Update the question handlers with numeric validation
    handleTotalQuestionsChange = (index, value) => {
        const numericValue = this.validateNumericInput(value);

        this.setState(prevState => {
            const newSubjects = [...prevState.exam.subjects];
            const subject = newSubjects[index];
            subject.totalQuestions = numericValue;

            // Validate subject level
            const subjectError = this.validateTemplateQuestions(
                subject.totalQuestions,
                subject.totalMarkedQuestions
            );

            // Calculate total questions for all subjects
            const subjectsTotalQuestions = newSubjects.reduce((sum, sub) =>
                sum + (parseInt(sub.totalQuestions) || 0), 0);

            // Validate against template totals
            const templateError = subjectsTotalQuestions > parseInt(prevState.exam.totalNoOfQuestions)
                ? "Sum of subjects' total questions exceeds template total"
                : null;

            // Validate sections against new subject total
            const sectionsError = this.validateSectionsTotal(
                subject.sections,
                numericValue,
                subject.totalMarkedQuestions
            );

            subject.errors = {
                ...subject.errors,
                totalQuestions: subjectError || '',
                sections: sectionsError || ''
            };

            // Update form level validations - Important for clearing the error
            const newValidations = { ...prevState.formValidations };
            newValidations.fields.subjects = {
                isValid: !templateError,
                errorMsg: templateError || ''
            };

            return {
                exam: { ...prevState.exam, subjects: newSubjects },
                formValidations: newValidations
            };
        });
    };

    handleMarkedTotalQuestionsChange = (index, value) => {
        const numericValue = this.validateNumericInput(value);

        this.setState(prevState => {
            const newSubjects = [...prevState.exam.subjects];
            const subject = newSubjects[index];
            subject.totalMarkedQuestions = numericValue;

            // Validate subject level
            const subjectError = this.validateTemplateQuestions(
                subject.totalQuestions,
                numericValue
            );

            // Validate against template totals
            const templateError = this.validateSubjectsTotal(
                newSubjects,
                prevState.exam.totalNoOfQuestions,
                prevState.exam.totalMarkedQuestions
            );

            // Validate sections against new subject marked total
            const sectionsError = this.validateSectionsTotal(
                subject.sections,
                subject.totalQuestions,
                numericValue
            );

            subject.errors = {
                totalMarkedQuestions: subjectError || templateError || '',
                sections: sectionsError || ''
            };

            return {
                exam: { ...prevState.exam, subjects: newSubjects }
            };
        });
    };

    // Update handleSubjectsChange to retain existing subject data
    handleSubjectsChange = (value) => {
        const numberOfSubjects = parseInt(value, 10);

        this.setState((prevState) => {
            const existingSubjects = prevState.exam.subjects || [];
            let newSubjects = [];

            // If increasing subjects, keep existing and add new
            if (numberOfSubjects > existingSubjects.length) {
                newSubjects = [
                    ...existingSubjects,
                    ...Array(numberOfSubjects - existingSubjects.length).fill().map(() => ({
                        totalQuestions: '',
                        totalMarkedQuestions: '',
                    }))
                ];
            }
            // If decreasing subjects, keep only the first n subjects
            else {
                newSubjects = existingSubjects.slice(0, numberOfSubjects);
            }

            return {
                exam: {
                    ...prevState.exam,
                    subjects: newSubjects,
                }
            };
        });
    };

    // Update handleSubjectLevelSectionChange to manage activeIndex
    handleSubjectLevelSectionChange = (index, value) => {
        const numberOfSections = parseInt(value, 10);

        this.setState((prevState) => {
            const newSubjects = [...prevState.exam.subjects];
            const existingSections = newSubjects[index].sections || [];
            let newSections = [];

            // If increasing sections, keep existing and add new
            if (numberOfSections > existingSections.length) {
                newSections = [
                    ...existingSections,
                    ...Array(numberOfSections - existingSections.length).fill().map(() => ({
                        sectionName: '',
                        totalQuestions: '',
                        totalMarkedQuestions: '',
                        sectionInstruction: '',
                        questionTypes: []
                    }))
                ];
            }
            // If decreasing sections, keep only the first n sections
            else {
                newSections = existingSections.slice(0, numberOfSections);
            }

            newSubjects[index].sections = newSections;

            // Update activeIndex for this subject
            const newActiveIndex = { ...prevState.activeIndex };
            newActiveIndex[index] = newActiveIndex[index] || [];

            return {
                exam: {
                    ...prevState.exam,
                    subjects: newSubjects,
                },
                sections: {
                    ...prevState.sections,
                    [index]: numberOfSections,
                },
                activeIndex: newActiveIndex
            };
        });
    };

    // Add this method to the AddExam class
    validateSubjectFields = (subject) => {
        const errors = {};

        if (!subject.totalQuestions || subject.totalQuestions.trim() === '') {
            errors.totalQuestions = "Total Questions is required";
        } else if (isNaN(subject.totalQuestions) || parseInt(subject.totalQuestions) <= 0) {
            errors.totalQuestions = "Total Questions must be a valid number greater than 0";
        }

        if (!subject.totalMarkedQuestions || subject.totalMarkedQuestions.trim() === '') {
            errors.totalMarkedQuestions = "Total Marked Questions is required";
        } else if (isNaN(subject.totalMarkedQuestions) || parseInt(subject.totalMarkedQuestions) < 0) {
            errors.totalMarkedQuestions = "Total Marked Questions must be a valid number";
        }

        if (parseInt(subject.totalMarkedQuestions) > parseInt(subject.totalQuestions)) {
            errors.totalMarkedQuestions = "Total Marked Questions cannot exceed Total Questions";
        }

        return errors;
    };

    // Add these validation helper functions right after the class declaration
    validateTemplateQuestions = (totalQuestions, markedQuestions) => {
        if (!totalQuestions || !markedQuestions) return null;
        return parseInt(markedQuestions) > parseInt(totalQuestions)
            ? "Marked questions cannot exceed total questions"
            : null;
    };

    validateSubjectsTotal = (subjects, templateTotal, templateMarked) => {
        if (!subjects?.length) return null;

        const subjectsTotalQuestions = subjects.reduce((sum, subject) =>
            sum + (parseInt(subject.totalQuestions) || 0), 0);
        const subjectsTotalMarked = subjects.reduce((sum, subject) =>
            sum + (parseInt(subject.totalMarkedQuestions) || 0), 0);

        if (subjectsTotalQuestions > parseInt(templateTotal)) {
            return "Sum of subjects' total questions exceeds template total";
        }
        if (subjectsTotalMarked > parseInt(templateMarked)) {
            return "Sum of subjects' marked questions exceeds template marked total";
        }
        return null;
    };

    validateSectionsTotal = (sections, subjectTotal, subjectMarked) => {
        if (!sections?.length) return null;

        const sectionsTotalQuestions = sections.reduce((sum, section) =>
            sum + (parseInt(section.totalQuestions) || 0), 0);
        const sectionsTotalMarked = sections.reduce((sum, section) =>
            sum + (parseInt(section.totalMarkedQuestions) || 0), 0);

        if (sectionsTotalQuestions > parseInt(subjectTotal)) {
            return "Sum of sections' total questions exceeds subject total";
        }
        if (sectionsTotalMarked > parseInt(subjectMarked)) {
            return "Sum of sections' marked questions exceeds subject marked total";
        }
        return null;
    };

    // Update the existing onNumberChange handler calls for template total and marked questions
    handleTemplateQuestionsChange = (value, field) => {
        const numericValue = this.validateNumericInput(value);

        this.setState(prevState => {
            const newExam = { ...prevState.exam };
            newExam[field] = numericValue;

            // Validate template level
            const templateError = this.validateTemplateQuestions(
                newExam.totalNoOfQuestions,
                newExam.totalMarkedQuestions
            );

            // Validate subjects against new template values
            const subjectsError = this.validateSubjectsTotal(
                newExam.subjects,
                newExam.totalNoOfQuestions,
                newExam.totalMarkedQuestions
            );

            const newValidations = { ...prevState.formValidations };
            newValidations.fields[field] = {
                isValid: !templateError,
                errorMsg: templateError || ''
            };

            // Always update subjects validation status
            newValidations.fields.subjects = {
                isValid: !subjectsError,
                errorMsg: subjectsError || ''
            };

            // Update individual subject errors
            if (newExam.subjects) {
                newExam.subjects = newExam.subjects.map(subject => ({
                    ...subject,
                    errors: this.validateSubjectFields(subject)
                }));
            }

            return {
                exam: newExam,
                formValidations: newValidations
            };
        });
    };

    handleSectionQuestionsChange = (subjectIndex, sectionIndex, value, field) => {
        const numericValue = this.validateNumericInput(value);

        this.setState(prevState => {
            const newSubjects = [...prevState.exam.subjects];
            const subject = newSubjects[subjectIndex];
            const section = subject.sections[sectionIndex];
            section[field] = numericValue;

            // Clear existing errors first
            if (section.errors) {
                delete section.errors[field];
                if (Object.keys(section.errors).length === 0) {
                    delete section.errors;
                }
            }

            // Re-validate marked vs total questions
            if (section.totalQuestions && section.totalMarkedQuestions) {
                if (parseInt(section.totalMarkedQuestions) > parseInt(section.totalQuestions)) {
                    section.errors = {
                        ...(section.errors || {}),
                        totalMarkedQuestions: "Marked questions cannot exceed total questions"
                    };
                }
            }

            // Calculate and validate section totals
            const sectionsTotalQuestions = subject.sections.reduce((sum, sec) =>
                sum + (parseInt(sec.totalQuestions) || 0), 0);
            const sectionsTotalMarked = subject.sections.reduce((sum, sec) =>
                sum + (parseInt(sec.totalMarkedQuestions) || 0), 0);

            // Validate against subject totals
            const sectionsError = (field === 'totalQuestions' && sectionsTotalQuestions > parseInt(subject.totalQuestions))
                ? "Sum of sections' total questions exceeds subject total"
                : (field === 'totalMarkedQuestions' && sectionsTotalMarked > parseInt(subject.totalMarkedQuestions))
                    ? "Sum of sections' marked questions exceeds subject marked total"
                    : null;

            if (sectionsError) {
                section.errors = {
                    ...(section.errors || {}),
                    [field]: sectionsError
                };
            }

            // Re-validate all marking scheme entries when section marked questions change
            if (field === 'totalMarkedQuestions' && section.markingScheme) {
                const totalMandatoryQuestions = section.markingScheme.reduce((sum, scheme) =>
                    sum + (parseInt(scheme.mandatoryQuestions) || 0), 0);

                if (totalMandatoryQuestions > parseInt(numericValue)) {
                    section.errors = {
                        ...(section.errors || {}),
                        markingScheme: section.markingScheme.map((scheme, idx) => ({
                            mandatoryQuestions: "Sum of mandatory questions cannot exceed section's marked questions"
                        }))
                    };
                } else if (section.errors?.markingScheme) {
                    delete section.errors.markingScheme;
                    if (Object.keys(section.errors).length === 0) {
                        delete section.errors;
                    }
                }
            }

            return {
                exam: { ...prevState.exam, subjects: newSubjects }
            };
        });
    };

    handleMarkingSchemeChange = (subjectIndex, sectionIndex, markIndex, value, field) => {
        const numericValue = this.validateNumericInput(value);

        this.setState(prevState => {
            const newSubjects = [...prevState.exam.subjects];
            const subject = newSubjects[subjectIndex];
            const section = subject.sections[sectionIndex];
            const markingScheme = section.markingScheme[markIndex];

            markingScheme[field] = numericValue;

            // Clear existing marking scheme errors first
            if (section.errors?.markingScheme?.[markIndex]) {
                delete section.errors.markingScheme[markIndex];
                if (section.errors.markingScheme.every(err => !err)) {
                    delete section.errors.markingScheme;
                    if (Object.keys(section.errors).length === 0) {
                        delete section.errors;
                    }
                }
            }

            // Validate total questions (mandatory + optional) against section total
            const mandatoryQuestions = parseInt(markingScheme.mandatoryQuestions) || 0;
            const optionalQuestions = parseInt(markingScheme.optionalQuestions) || 0;
            const totalQuestions = mandatoryQuestions + optionalQuestions;
            const sectionTotalQuestions = parseInt(section.totalQuestions) || 0;

            if (totalQuestions > sectionTotalQuestions) {
                if (!section.errors) section.errors = {};
                if (!section.errors.markingScheme) section.errors.markingScheme = [];

                section.errors.markingScheme[markIndex] = {
                    ...(section.errors.markingScheme[markIndex] || {}),
                    questionTotal: `Sum of mandatory (${mandatoryQuestions}) and optional (${optionalQuestions}) questions cannot exceed section's total questions (${sectionTotalQuestions})`
                };
            }

            // Re-validate total mandatory questions
            if (field === 'mandatoryQuestions') {
                const totalMandatoryQuestions = section.markingScheme.reduce((sum, scheme) =>
                    sum + (parseInt(scheme.mandatoryQuestions) || 0), 0);

                if (totalMandatoryQuestions > parseInt(section.totalMarkedQuestions)) {
                    if (!section.errors) section.errors = {};
                    if (!section.errors.markingScheme) section.errors.markingScheme = [];

                    section.errors.markingScheme[markIndex] = {
                        mandatoryQuestions: "Sum of mandatory questions cannot exceed section's marked questions"
                    };
                }
            }

            return {
                exam: { ...prevState.exam, subjects: newSubjects }
            };
        });
    };

    render() {
        const { exam, formValidations } = this.state


        return (
            <>
                <div className="px-6 py-3 grid">
                    <div className="grid" >
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Name of the Template<span className="ma-required">*</span></label>
                            <div className=''>
                                <div >

                                    <ClassetInputText
                                        icon={<MailIcon width={24} height={24} color={"black"} />}
                                        // width={'320px'}
                                        className=' border-round-md groupFormInputText  border-none '
                                        value={this.props?.editData?._id ? exam?.name : exam?.templateName}
                                        onChange={(e) => { onTextChange(e.target.value, 'name', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                        placeholder="Template Name" />
                                    {formValidations && !formValidations.fields['name'].isValid && <p className="p-error text-lg ">{formValidations.fields['name'].errorMsg}</p>}

                                </div>
                                {/* <i className="pi pi-info-circle font-bold text-xl ml-1"></i> */}
                            </div>
                        </div>

                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800"> Template Type<span className="ma-required">*</span></label>
                            <div className=''>
                                <div >

                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={"black"} />}
                                        options={tempType}
                                        value={exam?.examType}
                                        // required={true}
                                        className=' border-round-md groupFormInputText  border-none '
                                        onChange={(e) => { onTextChange(e.target.value, 'examType', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                        placeholder="Template Type" />
                                </div>
                            </div>
                            {formValidations && !formValidations.fields['examType'].isValid && <p className="p-error text-lg ">{formValidations.fields['examType'].errorMsg}</p>}
                        </div>
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Questions<span className="ma-required">*</span></label>
                            <div className=''>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className={`border-round-md groupFormInputText border-none ${formValidations.fields['totalNoOfQuestions'].isValid ? '' : 'p-invalid'}`}
                                    value={this.props?.editData?._id ? exam?.totalNoOfQuestions : exam?.totalQuestions}
                                    onChange={(e) => this.handleTemplateQuestionsChange(e.target.value, 'totalNoOfQuestions')}
                                    placeholder="Total Questions"
                                />
                                {!formValidations.fields['totalNoOfQuestions'].isValid &&
                                    <small className="p-error">{formValidations.fields['totalNoOfQuestions'].errorMsg}</small>
                                }
                            </div>
                        </div>
                        <div className=" col-3">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Marked Questions<span className="ma-required">*</span></label>
                            <div className=''>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className={`border-round-md groupFormInputText border-none ${formValidations.fields['totalMarkedQuestions'].isValid ? '' : 'p-invalid'}`}
                                    value={exam?.totalMarkedQuestions}
                                    onChange={(e) => this.handleTemplateQuestionsChange(e.target.value, 'totalMarkedQuestions')}
                                    placeholder="Marked Questions"
                                />
                                {!formValidations.fields['totalMarkedQuestions'].isValid &&
                                    <small className="p-error">{formValidations.fields['totalMarkedQuestions'].errorMsg}</small>
                                }
                            </div>
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Template Description<span className="ma-required">*</span></label>
                            <ClassetTextArea
                                title="description"
                                width="656px"
                                height="119px"
                                labelClassName="mb-2"
                                className="text-lg"
                                value={exam?.description}
                                onChange={(e) => { onTextChange(e.target.value, 'description', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                placeholder="Description"
                                rows={3}
                            />

                            {formValidations && !formValidations.fields['description'].isValid && <p className="p-error text-lg">{formValidations.fields['description'].errorMsg}</p>}
                        </div>
                        <div className="col-6 mt-2">
                            <label htmlFor="description" className="block font-serif text-lg font-bold text-800 mb-2">Exam Instructions<span className="ma-required">*</span></label>

                            <ClassetTextArea
                                title="description"
                                width="656px"
                                height="119px"
                                labelClassName="mb-2"
                                className="text-lg"
                                value={exam?.instructions}
                                onChange={(e) => { onTextChange(e.target.value, 'instructions', this, createExamFields, exam, formValidations, 'exam', 'formValidations') }}
                                placeholder="Exam Instructions"
                                rows={3}
                            />
                            {formValidations && !formValidations.fields['instructions'].isValid && <p className="p-error text-lg">{formValidations.fields['instructions'].errorMsg}</p>}
                        </div>
                        <div className="col-3 mt-2">
                            <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Subjects<span className="ma-required">*</span></label>
                            <div className='flex'>
                                <div>

                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={getSubjectsDrpDwn()}

                                        placeholder="Total Subjects"
                                        value={exam?.subjects?.length}
                                        onChange={(e) => this.handleSubjectsChange(e.target.value)}
                                    />
                                    {formValidations && !formValidations.fields['subjects'].isValid && <p className="p-error text-lg">{formValidations.fields['subjects'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {exam?.subjects?.length > 0 && (
                    <div className="ml-5">
                        {exam?.subjects?.map((subject, subjectIndex) => (
                            <div key={subjectIndex} id={subjectIndex}>
                                <div className="grid mb-0 col-10 gap-1">
                                    <div className="col-1 p-0">
                                        <label className="block font-semibold truncate">Subject {subjectIndex + 1}</label>
                                    </div>
                                    <div className="col-3 p-0">
                                        <label htmlFor="title" className="block font-serif text-lg font-bold text-800">
                                            Total Questions<span className="ma-required">*</span>
                                        </label>
                                        <ClassetInputText
                                            icon={<MailIcon width={24} height={24} color={"black"} />}
                                            className={`h-3rem border-round-md border-none text-lg pl-3 w-8 ${subject.errors?.totalQuestions ? 'p-invalid' : ''}`}
                                            placeholder="Total Questions"
                                            value={subject?.totalQuestions}
                                            onChange={(e) => { this.handleTotalQuestionsChange(subjectIndex, e.target.value) }}
                                        />
                                        {subject.errors?.totalQuestions && (
                                            <small className="p-error">{subject.errors.totalQuestions}</small>
                                        )}
                                    </div>

                                    <div className="col-3 p-0">
                                        <label htmlFor="title" className="block font-serif text-lg font-bold text-800">
                                            Total Marked Questions<span className="ma-required">*</span>
                                        </label>
                                        <ClassetInputText
                                            icon={<MailIcon width={24} height={24} color={"black"} />}
                                            className={`h-3rem border-round-md border-none text-lg pl-3 w-8 ${subject.errors?.totalMarkedQuestions ? 'p-invalid' : ''}`}
                                            placeholder="Total Marked Questions"
                                            value={subject?.totalMarkedQuestions}
                                            onChange={(e) => { this.handleMarkedTotalQuestionsChange(subjectIndex, e.target.value) }}
                                        />
                                        {subject.errors?.totalMarkedQuestions && (
                                            <small className="p-error">{subject.errors.totalMarkedQuestions}</small>
                                        )}
                                    </div>
                                    <div className="col-2 p-0">
                                        <label htmlFor="title" className="block font-serif text-lg font-bold text-800">Total Sections<span className="ma-required">*</span></label>
                                        <ClassetDropdown
                                            options={getSectionDrpDwn()}
                                            value={subject?.sections?.length || ''}

                                            onChange={(e) => {
                                                this.handleSubjectLevelSectionChange(subjectIndex, e.value);
                                            }}
                                            placeholder="Total Sections"
                                        />
                                    </div>
                                </div>
                                {subject.totalQuestionsErrs &&
                                    [...new Set(subject.totalQuestionsErrs)].map((error, index) => (
                                        <p key={index} className="p-error">{error}</p>
                                    ))}
                                <br />
                                {subject.sections && subject.sections?.length > 0 && (
                                    <Accordion
                                        className="section-accordion -ml-3 -mt-4 mb-3"
                                        activeIndex={this.state.activeIndex[subjectIndex] || []}
                                        multiple
                                        onTabChange={(e) => {
                                            this.setState(prevState => ({
                                                activeIndex: {
                                                    ...prevState.activeIndex,
                                                    [subjectIndex]: e.index // Use e.index instead of e.value
                                                }
                                            }));
                                        }}
                                    >
                                        {subject?.sections?.map((section, sectionIndex) => (
                                            <AccordionTab key={sectionIndex} header={<span>Section {sectionIndex + 1}</span>}>
                                                <div className="grid">
                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Section Name<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetInputText
                                                            icon={<MailIcon width={24} height={"black"} />}
                                                            className='border-round-md groupFormInputText border-none'
                                                            placeholder="Section Name"
                                                            value={section.sectionName}
                                                            onChange={(e) => {
                                                                const newSections = [...subject.sections];
                                                                // const errors = this.validateSectionFields(newSections[sectionIndex]);
                                                                // newSections[sectionIndex].errors = errors;
                                                                newSections[sectionIndex].sectionName = e.target.value;
                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                        />
                                                        {/* {section.errors?.sectionName && (
                                                            <span className="error-message" style={{ color: 'red' }}>{section.errors.sectionName}</span>
                                                        )} */}
                                                    </div>

                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Total Questions<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetInputText
                                                            icon={<MailIcon width={24} height={"black"} />}
                                                            className={`border-round-md groupFormInputText border-none ${section.errors?.totalQuestions ? 'p-invalid' : ''}`}
                                                            placeholder="Total Questions"
                                                            value={section.totalQuestions}
                                                            onChange={(e) => {
                                                                this.handleSectionQuestionsChange(subjectIndex, sectionIndex, e.target.value, 'totalQuestions');
                                                            }}
                                                        />
                                                        {section.errors?.totalQuestions &&
                                                            <small className="p-error">{section.errors.totalQuestions}</small>
                                                        }
                                                    </div>

                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Marked Questions<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetInputText
                                                            icon={<MailIcon width={24} height={"black"} />}
                                                            className={`border-round-md groupFormInputText border-none ${section.errors?.totalMarkedQuestions ? 'p-invalid' : ''}`}
                                                            placeholder="Marked Questions"
                                                            value={section.totalMarkedQuestions}
                                                            onChange={(e) => {
                                                                this.handleSectionQuestionsChange(subjectIndex, sectionIndex, e.target.value, 'totalMarkedQuestions');
                                                            }}
                                                        />
                                                        {section.errors?.totalMarkedQuestions &&
                                                            <small className="p-error">{section.errors.totalMarkedQuestions}</small>
                                                        }
                                                    </div>
                                                    <div className="col-3">
                                                        <label className="block font-serif font-bold text-800">
                                                            Question Types<span className="ma-required">*</span>
                                                        </label>
                                                        <ClassetMultiSelect
                                                            icon={<MailIcon />}
                                                            placeholder={<span className='text-lg ml-4'>Select Question Types</span>}
                                                            value={section.questionTypes}
                                                            className={section.errors?.questionTypes ? 'p-invalid' : ''}
                                                            onChange={(e) => {
                                                                const newQuestionTypes = e.value;
                                                                const markingScheme = newQuestionTypes.map(type => ({
                                                                    questionType: type,
                                                                    mandatoryQuestions: '',
                                                                    optionalQuestions: '',
                                                                    positiveMarking: '',
                                                                    negativeMarking: '',
                                                                    partialMarking: ''
                                                                }));

                                                                const newSections = [...subject.sections];
                                                                newSections[sectionIndex].questionTypes = newQuestionTypes;
                                                                newSections[sectionIndex].markingScheme = markingScheme;

                                                                // Clear any existing marking scheme errors when question types change
                                                                if (newSections[sectionIndex].errors) {
                                                                    delete newSections[sectionIndex].errors.markingScheme;
                                                                    // If there are no other errors, remove the errors object
                                                                    if (Object.keys(newSections[sectionIndex].errors).length === 0) {
                                                                        delete newSections[sectionIndex].errors;
                                                                    }
                                                                }

                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                            options={QuestionTypes}
                                                        />
                                                        {section.errors?.questionTypes &&
                                                            <small className="p-error">{section.errors.questionTypes}</small>
                                                        }
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="block font-serif font-bold text-800">
                                                            Section Instructions
                                                        </label>
                                                        <InputTextarea
                                                            className='h-7rem border-300 shadow-2 w-full border-round-md pt-2 border-none text-lg pl-3'
                                                            placeholder="Section Instructions"
                                                            rows={3}
                                                            value={section.sectionInstruction}
                                                            onChange={(e) => {
                                                                const newSections = [...subject.sections];
                                                                newSections[sectionIndex].sectionInstruction = e.target.value;
                                                                this.setState((prevState) => {
                                                                    const newSubjects = [...prevState.exam.subjects];
                                                                    newSubjects[subjectIndex].sections = newSections;
                                                                    return { exam: { ...prevState.exam, subjects: newSubjects } };
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                                {section.totalQuestionsErrs &&
                                                    [...new Set(section.totalQuestionsErrs)].map((error, index) => (
                                                        <p key={index} className="p-error">{error}</p>
                                                    ))}
                                                <div className="mt-3">
                                                    {section.questionTypes?.length > 0 && section.markingScheme && section.markingScheme.map((mark, markIndex) => (
                                                        <div key={mark.questionType} className="question-type-row">
                                                            <div className="col-12 grid my-0 py-1">
                                                                <div className="question-type-label col-1 mt-3 border-round-md truncate text-lg">{mark.questionType}</div>
                                                                <div className="col-3">
                                                                    <ClassetInputText
                                                                        placeholder="Mandatory Questions"
                                                                        value={mark.mandatoryQuestions}
                                                                        className={section.errors?.markingScheme?.[markIndex]?.mandatoryQuestions ? 'p-invalid' : ''}
                                                                        onChange={(e) => {
                                                                            this.handleMarkingSchemeChange(subjectIndex, sectionIndex, markIndex, e.target.value, 'mandatoryQuestions');
                                                                        }}
                                                                    />
                                                                    {section.errors?.markingScheme?.[markIndex]?.mandatoryQuestions &&
                                                                        <small className="p-error">{section.errors.markingScheme[markIndex].mandatoryQuestions}</small>
                                                                    }
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Optional Questions"
                                                                        value={mark.optionalQuestions}
                                                                        onChange={(e) => {
                                                                            this.handleMarkingSchemeChange(subjectIndex, sectionIndex, markIndex, e.target.value, 'optionalQuestions');
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Right Mark"
                                                                        value={mark.positiveMarking}
                                                                        className={section.errors?.markingScheme?.[markIndex]?.positiveMarking ? 'p-invalid' : ''}
                                                                        onChange={(e) => {
                                                                            this.handleMarkingSchemeChange(subjectIndex, sectionIndex, markIndex, e.target.value, 'positiveMarking');
                                                                        }}
                                                                    />
                                                                    {section.errors?.markingScheme?.[markIndex]?.positiveMarking &&
                                                                        <small className="p-error">{section.errors.markingScheme[markIndex].positiveMarking}</small>
                                                                    }
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Negative Mark"
                                                                        value={mark.negativeMarking}
                                                                        onChange={(e) => {
                                                                            this.handleMarkingSchemeChange(subjectIndex, sectionIndex, markIndex, e.target.value, 'negativeMarking');
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <ClassetInputText
                                                                        placeholder="Partial Mark"
                                                                        value={mark.partialMarking}
                                                                        onChange={(e) => {
                                                                            this.handleMarkingSchemeChange(subjectIndex, sectionIndex, markIndex, e.target.value, 'partialMarking');
                                                                        }}
                                                                    />
                                                                </div>
                                                                {section.errors?.markingScheme?.[markIndex]?.questionTotal && (
                                                                    <small className="p-error block">
                                                                        {section.errors.markingScheme[markIndex].questionTotal}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </AccordionTab>


                                        ))}
                                    </Accordion>
                                )}


                            </div>
                        ))}
                    </div>
                )}



                <div style={{ marginTop: '70px' }} className="text-center ml-3">
                    <div className="button-wrapper">

                        <Button className='submitButton'
                            onClick={this.onSubmitClick}

                        >
                            <p className='submitButtonText'>{`${this.props.editData?._id ? 'Update' : 'Save'}`}</p>
                        </Button>

                        <Button
                            className="cancelButton"

                            onClick={() => { this.props?.switchTab('2'); }}
                        >
                            <p className='cancelButtonText'>Cancel</p>

                        </Button>

                    </div>
                </div>

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }

                <Toast ref={(el) => this.toast = el} position="bottom-right" />


            </>
        )
    }
}

export default AddExam