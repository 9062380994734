import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Component } from 'react';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../../loadingComponent';
import { BasicLazyParams, convertUtc } from '../../../utile';
import { AddIcon2, AddUserIcon, ClassetEditIcon, CodeLinkIcon, EyeIconClasset, InfoIcon, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import { v4 as uuidv4 } from 'uuid';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { Tooltip } from 'primereact/tooltip';
import withRouter from '../../lib/withRouter';
import { connect } from 'react-redux';
import { getBranchesLatest } from '../../../store/actions';


class FeeStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCreateFeeStructureDialog: false,
            feeTypes: [], // Ensure this is initialized as an empty array
            branchId: this.props.branchData.length > 0 ? this.props.branchData[0].key : '',
            lazyParams: BasicLazyParams,
            totalRecords: 0,
            isLoading: false,
            structureName: null,
            selectedFeeTypes: [],
            filteredFeeTypes: [],// New state to store filtered fee types
            openViewFeeStructureDialog: false,
            viewFeeStructure: {}
        }
        this.service = new Service();
    }
    async componentDidMount() {
        await this.props.getBranchesLatest(true);
        this.getFeeStructure();
    }
    handleViewRequest = (feeStructure) => {
        this.setState({
            viewFeeStructure: feeStructure,
            openViewFeeStructureDialog: true
        });
    };
    onHandleFeeStructureDialog = () => {
        this.setState({
            openCreateFeeStructureDialog: true
        }, () => this.getFeeTypes());
    };
    onHideCreateFeeStructureDialog = () => {
        this.setState({
            openCreateFeeStructureDialog: false,
            structureName: '',
            feeTypes: [],
            filteredFeeTypes: [],
            selectedFeeTypes: [],
            isEdit: false
        });
    };
    onHideViewFeeStructureDialog = () => {
        this.setState({
            openViewFeeStructureDialog: false,
            structureName: '',
            feeTypes: [],
            isView: false
        });
    };


    getFeeTypes = async () => {
        try {
            this.setState({ isLoading: true });
            const academicYear = localStorage.getItem('userAcademicYear');

            if (!academicYear) {
                this.toast?.show({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Academic year not found',
                    life: 3000
                });
                return;
            }

            const payload = { academicYear };
            const url = `${baseUrlAdmin}/fee-types/info`;

            const response = await this.service.post(url, payload, true);

            if (response?.status && Array.isArray(response?.res?.data?.response)) {
                const feeTypes = response.res.data.response || [];
                const groupedData = [
                    {
                        label: 'One Time Fees',
                        code: 'OTF',
                        items: []
                    },
                    {
                        label: 'Installment Fees',
                        code: 'INS',
                        items: []
                    }
                ];
                feeTypes.forEach(fee => {
                    const transformedFee = {
                        label: fee.feeType,
                        value: fee._id,
                        // admissionTypes: fee.admissionTypes,
                        // admissionType:fee.admissionType,
                        // isgst: fee.isgst,
                        // isOneTime:fee.isOneTime ? true : false
                        ...fee
                    };

                    // Add to appropriate group based on isOneTime flag
                    if (fee.isOneTime) {
                        groupedData[0].items.push(transformedFee);
                    } else {
                        groupedData[1].items.push(transformedFee);
                    }
                });
                this.setState({
                    isLoading: false,
                    feeTypesGroupedData: groupedData,
                    feeTypes: feeTypes,
                    totalRecords: feeTypes.length || 0
                });

                if (feeTypes.length === 0) {
                    this.toast?.show({
                        severity: 'info',
                        summary: 'Information',
                        detail: 'No fee types found for the selected academic year',
                        life: 3000
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                })
                throw new Error(response?.message || 'Failed to fetch fee types');
            }
        } catch (error) {
            console.error('Error fetching fee types:', error);
            this.setState({ feeTypes: [] });

            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'An error occurred while fetching fee types',
                life: 3000
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }
    onEdit = async (each) => {
        if (each.isUsed) {
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: `Fee Structure already assigned to students, you can't Update.`,
                life: 3000
            });
        } else {
            // First get fee types to ensure we have the latest data
            await this.getFeeTypes();

            // Map the existing fee types to just their IDs for the multi-select
            const selectedFeeTypeIds = each.feeTypes.map(feeType => feeType._id);

            // Set the state with both the full fee type objects and their IDs
            this.setState({
                openCreateFeeStructureDialog: true,
                structureName: each.structureName,
                filteredFeeTypes: each.feeTypes.map((feeType, index) => ({
                    ...feeType,
                    priority: index + 1
                })),
                selectedFeeTypes: selectedFeeTypeIds,
                isEdit: true,
                editId: each._id
            }, () => {
                console.log('Edit mode initialized with:', {
                    selectedFeeTypes: selectedFeeTypeIds,
                    filteredFeeTypes: each.feeTypes
                });
            });
        }
    };

    handleFeeTypeSelection = (e) => {
        const selectedIds = e.target.value;

        // Check if transport fee is being selected or was previously selected
    /*     const transportFee = this.state.feeTypes.find(fee => fee.isTransportFeeType && selectedIds.includes(fee._id));
        const hadTransportFee = this.state.filteredFeeTypes.some(fee => fee.isTransportFeeType);

        // If trying to select transport fee along with other fees
        if (transportFee && selectedIds.length > 1) {
            this.toast?.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Transport fee cannot be combined with other fee types',
                life: 3000
            });
            return;
        }

        // If trying to add other fees when transport fee is selected
        if (hadTransportFee && selectedIds.length > 1) {
            this.toast?.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Other fee types cannot be added when transport fee is selected',
                life: 3000
            });
            return;
        } */

        // Process the selection if validation passes
        const existingItems = this.state.filteredFeeTypes
            .filter(item => selectedIds.includes(item._id));

        const newItemIds = selectedIds.filter(id =>
            !this.state.filteredFeeTypes.some(existing => existing._id === id)
        );

        const newItems = this.state.feeTypes
            .filter(feeType => newItemIds.includes(feeType._id))
            .map(feeType => ({
                ...feeType,
                priority: Math.max(...existingItems.map(item => item.priority || 0), 0) + 1,
                totalFee: feeType.totalFee || 0,
                isOneTime: feeType.isOneTime || false
            }));

        const filteredTypes = [...existingItems, ...newItems]
            .sort((a, b) => (a.priority || 0) - (b.priority || 0));

        this.setState({
            selectedFeeTypes: selectedIds,
            filteredFeeTypes: filteredTypes
        });
    };

    onPage = (event) => {
        this.setState({
            lazyParams: {
                ...this.state.lazyParams,
                first: event.first,
                rows: event.rows
            }
        }, this.getFeeStructure);
    }
    onRowReorder = (e) => {
        // Get the source and target indices
        const sourceIndex = e.dragIndex;
        const targetIndex = e.dropIndex;

        // Create a new array from current filteredFeeTypes
        let reorderedData = [...this.state.filteredFeeTypes];

        // Remove the item from source position and insert at target position
        const [removed] = reorderedData.splice(sourceIndex, 1);
        reorderedData.splice(targetIndex, 0, removed);

        // Reassign priorities after reordering
        reorderedData = reorderedData.map((item, index) => ({
            ...item,
            priority: index + 1
        }));

        this.setState({
            filteredFeeTypes: reorderedData
        }, () => {
            console.log('Multi-level reorder complete:', reorderedData);
            this.toast.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Row order updated successfully',
                life: 3000
            });
        });
    };

    handleTotalFeeChange = (e, rowData) => {
        const updatedFeeTypes = this.state.filteredFeeTypes?.map((feeType) => {
            if (feeType._id === rowData._id) {
                return { ...feeType, totalFee: Number(e.target.value) };
            }
            return feeType;
        });
        this.setState({ filteredFeeTypes: updatedFeeTypes });
    };

    onhandleFeeStructure = async () => {
        try {
            // Validation checks
            if (!this.state.branchId) {
                this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please select a branch', life: 3000 });
                return;
            }

            if (!this.state.structureName?.trim()) {
                this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter structure name', life: 3000 });
                return;
            }

            if (!this.state.filteredFeeTypes?.length) {
                this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please select at least one fee type', life: 3000 });
                return;
            }

            const invalidFeeTypes = this.state.filteredFeeTypes.filter(fee => !fee.totalFee && !fee.isTransportFeeType);
            if (invalidFeeTypes.length > 0) {
                this.toast?.show({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Please enter total fee for all selected fee types',
                    life: 3000
                });
                return;
            }

            this.setState({ isLoading: true });

            const payload = {
                academicYear: localStorage.getItem('userAcademicYear'),
                branchId: this.state.branchId,
                structureName: this.state.structureName,
                feeTypes: this.state.filteredFeeTypes
            };

            let response;
            if (this.state.isEdit && this.state.editId) {
                // Update existing structure
                payload._id = this.state.editId;
                const url = `${baseUrlAdmin}/fee-category/`;
                response = await this.service.put(url, payload, true);
            } else {
                // Create new structure
                const url = `${baseUrlAdmin}/fee-category/`;
                response = await this.service.post(url, payload, true);
            }

            if (response?.status) {
                this.toast?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Fee structure ${this.state.isEdit ? 'updated' : 'created'} successfully`,
                    life: 3000
                });
                this.onHideCreateFeeStructureDialog();
                this.getFeeStructure(); // Refresh the list
            } else {
                throw new Error(response?.message || `Failed to ${this.state.isEdit ? 'update' : 'create'} fee structure`);
            }
        } catch (error) {
            console.error('Error handling fee structure:', error);
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || `An error occurred while ${this.state.isEdit ? 'updating' : 'creating'} fee structure`,
                life: 3000
            });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    getFeeStructure = () => {
        const lazyParams = this.state.lazyParams
        this.setState({
            isLoading: true
        });
        const pageNumber = Math.floor(lazyParams.first / lazyParams.rows) + 1;
        let url = `${baseUrlAdmin}/fee-category?academicYear=${localStorage.getItem('userAcademicYear')}&branchId=${this.state.branchId}&limit=${lazyParams.rows}&page=${pageNumber}&search=${this.state.globalSearch}`
        this.service.get(url, true).then((data) => {
            if (data?.status && data?.res?.data?.response
            ) {
                this.setState({
                    feeStructureInfo: data?.res?.data?.response,
                    structureTotalRecords: data?.res?.data?.totalRecords || 0,
                    isLoading: false
                })
            } else {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Getting Fee Category', life: 3000 });
                this.setState({
                    // feeCategory: data?.res?.data?.response,
                    isLoading: false
                })

            }
        })

    }
    render() {
        return (
            <>

                <div className="grid m-2">
                    <div className="col-12 flex justify-content-between align-items-center">
                        <div >
                            <p className="add-vehicle-field-label">
                                Branch<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    optionLabel="name"
                                    optionValue="key"
                                    width="16vw"
                                    value={this.state.branchId}
                                    options={this.props.branchData}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    onChange={async (e) => { this.setState({ branchId: e.target.value }, () => this.getFeeStructure()) }}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Select Branch</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <Authorizer permId={PERMISSIONS.CREATE_FEE_STRUCTURE}>
                            <Button onClick={this.onHandleFeeStructureDialog} className="addButtonn" disabled={this.state.branchId ? false : true}>
                                <p className="">Add Fee Structure</p>
                                <AddIcon2 />
                            </Button>
                        </Authorizer>
                    </div>
                </div>


                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.feeStructureInfo}
                    dataKey="id"
                    paginator
                    lazy
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.structureTotalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fee Types"
                    globalFilter={this.state.globalFilter}
                    responsiveLayout="scroll"

                >
                    <Column
                        header="S.No."
                        className="datatable-cel"
                        body={(rowData, index) => {
                            return (
                                <>
                                    <p>{index.rowIndex + 1}</p>
                                </>
                            );
                        }}
                    />
                    <Column field="structureName" header="Structure Name" className="datatable-cel" />
                    <Column
                        header="Total Fee"
                        className="datatable-cel"
                        body={(rowData) => {
                            const totalAmount = rowData.feeTypes.reduce((sum, feeType) => sum + (feeType.totalFee || 0), 0);
                            const formattedAmount = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalAmount);
                            return (
                                <p>{formattedAmount}</p>
                            );
                        }}
                    />
                    <Column
                        header="Total No of Fee Types"
                        className="datatable-cel"
                        body={(rowData) => {
                            return (
                                <p>{rowData.feeTypes.length}</p>
                            );
                        }}
                    />
                    {/* <Column
                        className=" "
                        header=""
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <Authorizer permId={PERMISSIONS.UPDATE_FEE_TYPE}>
                                        <div className="flex">
                                            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                            <span
                                                data-pr-tooltip="Edit Fee Type"
                                                data-pr-position=""
                                                className={`ma-ml10 awl${rowIndex}`}
                                                onClick={() => {
                                                    this.onEdit(rowData);
                                                }}
                                            >
                                                <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                            </span>
                                            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                        </div>
                                    </Authorizer>
                                </>
                            );
                        }}
                    ></Column> */}
                    {/* <Column
                        className=" "
                        header="Actions"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex cursor-pointer">

                                    <span
                                        data-pr-tooltip="Edit Fee Type"
                                        data-pr-position=""
                                        className={`ma-ml10 awl${rowIndex}`}
                                        onClick={() => {
                                            this.onEdit(rowData);
                                        }}
                                    >
                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                    </span>

                                    <span
                                        data-pr-tooltip="View Fee Structure"
                                        data-pr-position=""
                                        className={`ma-ml10 uy${rowIndex}`}
                                        onClick={() => {
                                            this.handleViewRequest(rowData);
                                        }}
                                    >
                                        <i className="pi pi-eye" style={{ fontSize: '1.5rem', color: 'black' }}></i>
                                    </span>
                                </div>
                            );
                        }}
                    ></Column> */}
                    <Column
                        className=" "
                        header="Actions"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex cursor-pointer">
                                    <div onClick={() => {
                                        this.onEdit(rowData);
                                    }}>
                                        <ClassetEditIcon width={28} height={28} color={'black'} />
                                    </div>
                                    <div onClick={() => {
                                        this.handleViewRequest(rowData);
                                    }} style={{ marginLeft: '10px' }}>
                                        <i className="pi pi-eye" style={{ fontSize: '1.5rem', color: 'black' }}></i>
                                    </div>
                                </div>
                            );
                        }}
                    ></Column>

                </DataTable>

                <Dialog
                    isOpen={this.state.openCreateFeeStructureDialog}
                    visible={this.state.openCreateFeeStructureDialog}
                    draggable={false}
                    onHide={this.onHideCreateFeeStructureDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mb-2 p-2">
                                <h3 style={{ textAlign: 'center', margin: 0, fontSize: '1.5rem', fontWeight: '600' }}>Fee Structure for {this.props.branchData.find(branch => branch.key === this.state.branchId)?.name}</h3>
                            </div>
                        );
                    }}
                    footer={() => <div className="flex justify-content-center mb-4 mt-4 p-2"></div>}
                >

                    <div className="mb-5 ml-5 mr-5 p-2">
                        <div className="grid mb-3">
                            <div className="col-4">
                                <label className="label">
                                    Structure Name<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Structure Name"
                                        value={this.state.structureName}
                                        onChange={(e) => this.setState({ structureName: e.target.value, structureNameErrorMsg: true })}
                                    />
                                </div>
                            </div>
                            <div className="col-4 ">
                                <label className="label">
                                    Fee Types<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetMultiSelect
                                        id="feeTypes"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state.feeTypesGroupedData}
                                        value={this.state.selectedFeeTypes}
                                        onChange={this.handleFeeTypeSelection}
                                        optionLabel="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        placeholder={
                                            <div>
                                                <span className="">Select Fee Types</span>
                                            </div>
                                        }
                                        itemTemplate={(option) => {
                                            const backgroundColor = option.isOneTime ? '#abd5f5' : '#ADD8E6'; // Example colors
                                            return (
                                                <div style={{ backgroundColor, padding: '5px', borderRadius: '3px' }}>
                                                    {option.label}
                                                </div>
                                            );
                                        }}
                                        optionGroupTemplate={(option) => {
                                            const backgroundColor = option.code === 'OTF' ? '#abd5f5' : '#ADD8E6'; // Example colors
                                            return (
                                                <div style={{ backgroundColor, padding: '5px', borderRadius: '3px' }}>
                                                    {option.label}
                                                </div>
                                            );
                                        }}
                                    />

                                </div>
                            </div>
                        </div>

                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.filteredFeeTypes}
                            dataKey="feeTypeId"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            reorderableRows
                            onRowReorder={this.onRowReorder}
                            
                            removableSort
                        >
                            <Column rowReorder style={{ width: '3em', cursor: 'move' }} />
                            <Column
                                style={{ width: '3em' }}
                                header="Priority"
                                field="priority"
                                className="datatable-cel"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                        </>
                                    );
                                }}
                            />

                            <Column field="feeType" header="Fee Type" className="datatable-cel" />
                            <Column
                                header="1 time / installment"
                                className="datatable-cel"
                                field="admissionType"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <div>
                                                {rowData?.isOneTime ? 'One Time' : 'Installment'}
                                            </div>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                header="Admission Category"
                                className="datatable-cel"
                                field="admissionType"
                                body={(rowData, index) => {
                                    return (
                                        <>

                                            <div>
                                                {rowData?.admissionTypes?.map((label, index) => (
                                                    <div key={index}>{label}</div>
                                                ))}
                                            </div>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                header="Total Fee"
                                className="datatable-cel"
                                field=""
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <ClassetInputText
                                                className="text-xl"
                                                height="48px"
                                                width="227px"
                                                value={rowData.totalFee}
                                                onChange={(e) => this.handleTotalFeeChange(e, rowData)}
                                                placeholder="Total Fee"
                                                keyfilter="int"
                                                disabled={rowData.isTransportFeeType}
                                            />
                                        </>
                                    );
                                }}
                            />
                        </DataTable>

                        <div className="flex justify-content-center mb-4 mt-4 p-2">
                            <Button label="Add " className="p-button-primary submitButtonn mr-4 button text-lg " onClick={this.onhandleFeeStructure} />
                            <Button className="cancelButtonn w-2" onClick={this.onHideCreateFeeStructureDialog}>
                                <p className="cancelButtonnText">Cancel</p>
                            </Button>
                        </div>

                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.openViewFeeStructureDialog}
                    draggable={false}
                    closeOnEscape={true}
                    style={{
                        width: '50%',
                        maxWidth: '600px',
                        margin: '0 auto'
                    }}
                    dismissableMask={true}
                    closable={true}
                    header={() => (
                        <div style={{ padding: '1rem', borderBottom: '1px solid #dee2e6' }}>
                            <h3 style={{ textAlign: 'center', margin: 0, fontSize: '1.5rem', fontWeight: '600' }}>
                                View Fee Structure Details
                            </h3>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button label="Close" className="confirmDialogCancelBtn px-6" onClick={() => this.setState({ openViewFeeStructureDialog: false, viewFeeStructure: {} })} />
                        </div>
                    )}
                    onHide={() => this.setState({ openViewFeeStructureDialog: false, viewFeeStructure: {} })}
                >
                    <div style={{ padding: '1.5rem' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Structure Name
                                </label>
                                <p style={{ margin: 0 }}>{this.state.viewFeeStructure.structureName}</p>
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Branch Name
                                </label>
                                <p style={{ margin: 0 }}>{this.props.branchData.find(branch => branch.key === this.state.viewFeeStructure.branchId)?.name || ''}</p>
                            </div>
                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            <label style={{
                                display: 'block',
                                marginBottom: '0.5rem',
                                fontWeight: '500'
                            }}>
                                Fee Types
                            </label>
                            {this.state.viewFeeStructure.feeTypes?.map((feeType, index) => (
                                <div key={index} style={{ marginBottom: '1rem', padding: '1rem', border: '1px solid #dee2e6', borderRadius: '4px' }} className='flex justify-between'>
                                    <div style={{ marginBottom: '1rem' }}>
                                        <label style={{
                                            display: 'block',
                                            marginBottom: '0.5rem',
                                            fontWeight: '500'
                                        }}>
                                            Fee Type
                                        </label>
                                        <p style={{ margin: 0 }}>{feeType.feeType}</p>
                                    </div>
                                    {/* <div style={{ marginBottom: '1rem' }}>
                                        <label style={{
                                            display: 'block',
                                            marginBottom: '0.5rem',
                                            fontWeight: '500'
                                        }}>
                                            Admission Category
                                        </label>
                                        <p style={{ margin: 0 }}>{feeType.admissionType?.join(', ')}</p>
                                    </div> */}
                                    <div>
                                        <label style={{
                                            display: 'block',
                                            marginBottom: '0.5rem',
                                            fontWeight: '500'
                                        }}>
                                            Total Fee
                                        </label>
                                        <p style={{ margin: 0 }}>{feeType.totalFee}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Dialog>


                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </>
        )
    }

}
const mapStateToProps = (state) => ({
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id
});
export default connect(mapStateToProps, {
    getBranchesLatest
})(withRouter(FeeStructure));
