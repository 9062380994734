import React, { Component } from 'react';
import { DeleteIconClasset, MailIcon } from '../../svgIcons';
import { Button } from 'primereact/button';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import { Toast } from 'primereact/toast';
import uuidv4 from 'uuid/v4';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns'; 
import CustomTimePicker from '../../common/CustomTimePicker';
import classNames from 'classnames';

const createFormFields = require('./timeTableTemplate.json');
const formFields = createFormFields;
const Durations = Array.from({ length: 91 }, (_, index) => index + 30).map((l) => {
    return { label: `${l} minutes`, value: `${l}` };
});

const BreakDurations = Array.from({ length: 20 }, (_, index) => index * 5 + 5).map((l) => {
    return { label: `${l} minutes`, value: `${l}` };
});

const Periods = Array.from({ length: 16 }, (_, index) => index + 1).map((l) => {
    return { label: `${l}`, value: `${l}` };
});

const BreakTypes = [
    { label: 'Interval', value: 'Interval' },
    { label: 'Lunch', value: 'Lunch' },
    { label: 'Snacks', value: 'Snacks' },
    { label: 'Assembly', value: 'Assembly' }
];

export default class TimeTableTemplate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFormFields);

        this.state = {
            breaks: { breakDetails: [{ breakType: '', breakDuration: '', breakTime: '' }] },
            tableData: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            breakDuration: 0,
            showModal: false,
            previewData: null,
            timePickerFocused: false,
            timePickerError: false,
        };
    }

    componentDidMount() {
        console.log(this.props.editData);
        if (this.props.editData) {
            console.log(this.props.editData);
            let cnt = 0;
            const updatedBreaks = this.props.editData.periods
                .filter((period) => period.type === 'break')
                .map((breakPeriod, index) => {
                    cnt = cnt + 1;
                    const breakTimeIndex = this.props.editData.periods.findIndex((period) => period === breakPeriod);
                    const updatedBreakPeriod = {
                        ...breakPeriod,
                        breakType: breakPeriod.name,
                        breakDuration: breakPeriod.breakDuration,
                        breakTime: cnt > 1 ? `${breakTimeIndex - cnt + 1}` : `${breakTimeIndex}`
                    };

                    return updatedBreakPeriod;
                });
            const editTableData = {
                title: this.props.editData.title,
                periodStartTime: this.props.editData.periodStartTime,
                periodEndTime: this.props.editData.periodEndTime,
                noOfPeriodsPerDay: this.props.editData.noOfPeriodsPerDay,
                duration: this.props.editData.duration,
                _id: this.props.editData._id
            };
            this.setState({
                tableData: editTableData,
                breaks: { breakDetails: updatedBreaks }
            });
        }
    }

    calculateEndTime = (startTime, totalPeriods, periodLength, breakDetails) => {
        let start = new Date(startTime);
        let totalTime = totalPeriods * periodLength;

        // Ensure breakDetails is an array before iterating
        (Array.isArray(breakDetails) ? breakDetails : []).forEach((breakDetail) => {
            totalTime += parseInt(breakDetail.breakDuration || 0, 10);
        });

        start.setMinutes(start.getMinutes() + totalTime);
        return start;
    };

    calculatePeriodEndTime = (startTime, duration) => {
        let start = new Date(startTime);
        start.setMinutes(start.getMinutes() + parseInt(duration, 10));
        return start;
    };

    generatePeriods = () => {
        const { periodStartTime, noOfPeriodsPerDay, duration } = this.state.tableData;
        const { breakDetails } = this.state.breaks;

        // Create a map of break times to their details
        const breakMap = new Map();
        breakDetails?.forEach((breakDetail) => {
            const breakTimeIndex = parseInt(breakDetail.breakTime, 10);
            breakMap.set(breakTimeIndex, {
                breakType: breakDetail.breakType,
                breakDuration: parseInt(breakDetail.breakDuration, 10),
            });
        });

        let currentStartTime = new Date(periodStartTime);
        let periods = [];
        let periodCounter = 1;

        for (let i = 0; i < noOfPeriodsPerDay + breakMap.size; i++) {
            // Check if a break occurs before the current period
            if (breakMap.has(periodCounter - 1)) {
                const { breakType, breakDuration } = breakMap.get(periodCounter - 1);
                let breakEndTime = this.calculatePeriodEndTime(currentStartTime, breakDuration);
                periods.push({
                    name: breakType,
                    startTime: currentStartTime.toISOString(),
                    endTime: breakEndTime.toISOString(),
                    type: 'break',
                    breakDuration: breakDuration,
                });
                currentStartTime = new Date(breakEndTime); // Update start time after break
            }

            // Add the period if we haven't exceeded the number of periods
            if (periodCounter <= noOfPeriodsPerDay) {
                let periodEndTime = this.calculatePeriodEndTime(currentStartTime, duration);
                periods.push({
                    name: `Period ${periodCounter}`,
                    startTime: currentStartTime.toISOString(),
                    endTime: periodEndTime.toISOString(),
                    type: 'class',
                    periodId: uuidv4(),
                });
                currentStartTime = new Date(periodEndTime); // Update start time for next period/break
                periodCounter++;
            }

            // Check if a break occurs after the last period
            if (periodCounter > noOfPeriodsPerDay && breakMap.has(noOfPeriodsPerDay)) {
                const { breakType, breakDuration } = breakMap.get(noOfPeriodsPerDay);
                let breakEndTime = this.calculatePeriodEndTime(currentStartTime, breakDuration);
                periods.push({
                    name: breakType,
                    startTime: currentStartTime.toISOString(),
                    endTime: breakEndTime.toISOString(),
                    type: 'break',
                    breakDuration: breakDuration,
                });
                breakMap.delete(noOfPeriodsPerDay); // Avoid duplicate breaks
            }
        }

        return periods;
    };

    onPeriodLengthChange = (len, breakDetails = this.state.breaks.breakDetails) => {
        if (!this.state.tableData.periodStartTime) {
            return this.toast.show({ severity: 'error', summary: 'Fill the Start Time first', life: 3000 });
        }
        if (!this.state.tableData.noOfPeriodsPerDay) {
            return
            // return this.toast.show({ severity: 'error', summary: 'Fill How many periods first', life: 3000 });
        }
        if (!len) return;

        const startTime = this.state.tableData.periodStartTime;
        const totalPeriods = this.state.tableData.noOfPeriodsPerDay;
        const endTime = this.calculateEndTime(startTime, totalPeriods, len, breakDetails);

        onTextChange(endTime.toISOString(), 'periodEndTime', this, createFormFields, this.state.tableData, this.state.formValidations, 'tableData', 'formValidations', {}, () => {
            onDropDownChange(len, 'duration', this, createFormFields, this.state.tableData, this.state.formValidations, 'tableData', 'formValidations');
        });
    };

    addBreaks = () => {
        const { breaks } = this.state;
        const len = breaks.breakDetails.length;
        const lastBreak = breaks.breakDetails[len - 1];
        const lastlastBreak = breaks.breakDetails[len - 2];

        if (!lastBreak || !lastBreak.breakType || !lastBreak.breakDuration || !lastBreak.breakTime) {
            return this.toast.show({ severity: 'error', summary: 'Fill all the fields before adding a new break', life: 3000 });
        }
        if (lastlastBreak && lastBreak.breakTime === lastlastBreak.breakTime) {
            return this.toast.show({ severity: 'error', summary: 'Only one break should be added', life: 3000 });
        }
        const { tableData } = this.state;
        // const endTime = this.calculateEndTime(tableData.periodStartTime, tableData.noOfPeriodsPerDay, tableData.duration, breaks.breakDetails[len - 1].breakDuration);
        // onTextChange(endTime.toISOString(), 'periodEndTime', this, createFormFields, tableData, this.state.formValidations, 'tableData', 'formValidations');

        this.setState((prevState) => ({
            breaks: {
                ...prevState.breaks,
                breakDetails: [...prevState.breaks.breakDetails, { breakType: '', breakDuration: '', breakTime: '' }]
            }
        }));
    };

    generateBreakTimes = (noOfPeriodsPerDay) => {
        return Array.from({ length: noOfPeriodsPerDay - 1 }, (_, index) => index + 1).map((l) => {
            return { label: `${l}`, value: `${l}` };
        });
    };

    removeBreaks = (i) => {
        const { breaks, tableData } = this.state;
        if (breaks.breakDetails.length > 1) {
            let removeDuration = this.state.breaks.breakDetails[i].breakDuration || 0;
            const updatedBreaks = breaks.breakDetails.filter((_, index) => index !== i);
            const totalBreakDuration = updatedBreaks.reduce((acc, breakDetail) => {
                return acc + (parseInt(breakDetail.breakDuration, 10) || 0);
            }, 0);

            this.setState({ breaks: { ...breaks, breakDetails: updatedBreaks } });
            this.onPeriodLengthChange(tableData.duration, totalBreakDuration);
        }
    };

    handleBreakChange = (value, field, index) => {
        const updatedBreaks = this.state.breaks.breakDetails.map((breakDetail, idx) => {
            if (idx === index) {
                return { ...breakDetail, [field]: value };
            }
            return breakDetail;
        });

        this.setState(
            (prevState) => ({
                breaks: { ...prevState.breaks, breakDetails: updatedBreaks }
            }),
            () => {
                this.onPeriodLengthChange(this.state.tableData.duration, updatedBreaks);
            }
        );
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.tableData.noOfPeriodsPerDay !== this.state.tableData.noOfPeriodsPerDay ||
            prevState.tableData.periodStartTime !== this.state.tableData.periodStartTime ||
            prevState.breaks.breakDetails !== this.state.breaks.breakDetails
        ) {
            this.onPeriodLengthChange(this.state.tableData.duration, this.state.breaks.breakDetails);
        }
    }

    addTimeTableTemplate = () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        const { breaks } = this.state;
        const len = breaks.breakDetails.length;
        const lastBreak = breaks.breakDetails[len - 1];
        const lastlastBreak = breaks.breakDetails[len - 2];
        if (!lastBreak || !lastBreak.breakType || !lastBreak.breakDuration || !lastBreak.breakTime) {
            return this.toast.show({ severity: 'error', summary: 'Fill all the fields before adding a new break', life: 3000 });
        }
        if (lastlastBreak && lastBreak.breakTime === lastlastBreak.breakTime) {
            return this.toast.show({ severity: 'error', summary: 'Only one break should be added', life: 3000 });
        }

        const data = this.generatePeriods();
        const timeTableTemplateData = {
            title: this.state.tableData.title,
            periods: data,
            academicYear,
            periodStartTime: this.state.tableData.periodStartTime,
            periodEndTime: this.state.tableData.periodEndTime,
            noOfPeriodsPerDay: this.state.tableData.noOfPeriodsPerDay,
            duration: this.state.tableData.duration,
            ...(this.props.editData ? { _id: this.props.editData._id } : {})
        };
        this.props.addNewTemplate(timeTableTemplateData);
    };

    previewTimeTable = () => {
        const { tableData, breaks } = this.state;

        // Validate required fields
        if (!tableData.title) {
            return this.toast.show({ severity: 'error', summary: 'Template Name is required', life: 3000 });
        }
        if (!tableData.periodStartTime) {
            return this.toast.show({ severity: 'error', summary: 'Start Time is required', life: 3000 });
        }
        if (!tableData.noOfPeriodsPerDay) {
            return this.toast.show({ severity: 'error', summary: 'Total Periods Per Day is required', life: 3000 });
        }
        if (!tableData.duration) {
            return this.toast.show({ severity: 'error', summary: 'Periods Length is required', life: 3000 });
        }

        // Validate breaks
        for (let i = 0; i < breaks.breakDetails.length; i++) {
            const breakDetail = breaks.breakDetails[i];
            if (!breakDetail.breakType || !breakDetail.breakDuration || !breakDetail.breakTime) {
                return this.toast.show({
                    severity: 'error',
                    summary: `Break ${i + 1} is incomplete. Fill all fields.`,
                    life: 3000
                });
            }
        }

        // Generate preview data if all validations pass
        const data = this.generatePeriods();
        const timeTableTemplateData = {
            title: tableData.title,
            periods: data,
            academicYear: localStorage.getItem('userAcademicYear'),
            periodStartTime: tableData.periodStartTime,
            periodEndTime: tableData.periodEndTime,
            noOfPeriodsPerDay: tableData.noOfPeriodsPerDay,
            duration: tableData.duration
        };
        this.setState({ previewData: timeTableTemplateData, showModal: true });
    };

    saveTemplate = () => {
        this.setState({ showModal: false });
        const { previewData } = this.state;
        if (this.props.editData?._id) {

            previewData._id = this.props.editData ? this.props.editData._id : null;
        }
        this.props.addNewTemplate(this.state.previewData);
    };

    handleTimePickerFocus = () => {
        this.setState({ timePickerFocused: true });
    };

    handleTimePickerBlur = () => {
        const { tableData } = this.state;
        if (!tableData.periodStartTime) {
            this.setState({ timePickerError: true });
        } else {
            this.setState({ timePickerError: false });
        }
        this.setState({ timePickerFocused: false });
    };

    render() {
        const { tableData, formValidations, showModal, previewData } = this.state;

        // Helper function to format time
        const formatTime = (isoString) => {
            return isoString ? format(new Date(isoString), 'hh:mm a') : '';
        };

        return (
            <div className="poppins24">
                <div className="grid mt-5 px-7">
                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetInputText
                            icon={<MailIcon width={24} height={24} color={'#000000'} />}
                            className="border-round-md border-none"
                            label="Name of the Template"
                            required={true}
                            value={tableData.title}
                            onChange={(e) => {
                                onTextChange(e.target.value, 'title', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder="Template Name"
                        />
                        {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <CustomTimePicker
                            required={true}
                            value={tableData.periodStartTime}
                            onChange={(value) => {
                                onTextChange(value, 'periodStartTime', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            label="Start Time"
                            placeholder="Start Time"
                            inputStyle={{ border: 'none', boxShadow: 'none', outline: 'none' }} // Added outline: 'none'
                            className={classNames(
                                'p-inputgroup',
                                'custom-input-group',
                                `border-300 shadow-2 border-round-lg flex justify-content-center align-items-center ${this.state.timePickerError ? 'border-red-400 border-2' : ''}`,
                                { 'custom-input-focused': this.state.timePickerFocused }
                            )}
                            onFocus={this.handleTimePickerFocus}
                            onBlur={this.handleTimePickerBlur}
                        />
                        {this.state.timePickerError && <p className="p-error">Start Time is required</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            label={'Total Periods Per Day'}
                            required={true}
                            options={Periods}
                            value={tableData.noOfPeriodsPerDay}
                            onChange={(e) => {
                                onDropDownChange(e.target.value, 'noOfPeriodsPerDay', this, createFormFields, tableData, formValidations, 'tableData', 'formValidations');
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span>Total Periods Per Day</span>
                                </div>
                            }
                        />
                        {formValidations && !formValidations.fields['noOfPeriodsPerDay'].isValid && <p className="p-error">{formValidations.fields['noOfPeriodsPerDay'].errorMsg}</p>}
                    </div>

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="label"
                            optionValue="value"
                            label={'Periods Length'}
                            required={true}
                            options={Durations}
                            value={tableData.duration}
                            onChange={(e) => {
                                this.onPeriodLengthChange(e.target.value);
                            }}
                            placeholder={
                                <div className="flex justify-content-start align-items-center">
                                    <span>Periods Length</span>
                                </div>
                            }
                        />
                        {formValidations && !formValidations.fields['duration'].isValid && <p className="p-error">{formValidations.fields['duration'].errorMsg}</p>}
                    </div>

                    {/* <div className="xl:col-4 lg:col-4 md:col-6 col-12">
                        <ClassetCalendar value={tableData.periodEndTime ? new Date(tableData.periodEndTime) : null} calendarMode="single" label="End Time" placeholder="End Time" timeOnly disabled={true} />
                        {formValidations && !formValidations.fields['periodEndTime'].isValid && <p className="p-error">{formValidations.fields['periodEndTime'].errorMsg}</p>}
                    </div> */}

                    <div className="xl:col-4 lg:col-4 md:col-6 col-12" onClick={(e) => e.preventDefault()} onFocus={(e) => e.preventDefault()} style={{ pointerEvents: 'none' }}>
                        <CustomTimePicker
                            value={tableData.periodEndTime}
                            label="End Time"
                            placeholder="End Time"
                            inputStyle={{ border: 'none', boxShadow: 'none', outline: 'none', pointerEvents: 'none' }} // Added pointerEvents: 'none'
                            className={classNames(
                                'p-inputgroup',
                                'custom-input-group',
                                'border-300 shadow-2 border-round-lg flex justify-content-center align-items-center',
                                { 'custom-input-disabled': true }
                            )}
                            disabled={true}
                            // onClick={(e) => e.preventDefault()} // Prevent interaction
                            // onFocus={(e) => e.preventDefault()} // Prevent focus
                        />
                    </div>

                    <div className="col-12">
                        <p className="text-3xl" style={{ textDecoration: 'underline', fontWeight: 'bolder', color: 'black' }}>
                            Add Breaks
                        </p>
                        {this.state.breaks.breakDetails.map((obj, index) => (
                            <div className="grid py-0 -mt-2" key={index}>
                                <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        optionLabel="label"
                                        optionValue="value"
                                        label={'Type of Break'}
                                        required={true}
                                        options={BreakTypes}
                                        value={obj.breakType}
                                        onChange={(e) => this.handleBreakChange(e.target.value, 'breakType', index)}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span>Type of Break</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="xl:col-4 lg:col-3 md:col-4 col-12">
                                    <ClassetDropdown
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        options={BreakDurations}
                                        optionLabel="label"
                                        optionValue="value"
                                        label={'Break Length'}
                                        required={true}
                                        value={obj.breakDuration}
                                        onChange={(e) => this.handleBreakChange(e.target.value, 'breakDuration', index)}
                                        placeholder={
                                            <div className="flex justify-content-start align-items-center">
                                                <span>Break Length</span>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className="grid xl:col-4 lg:col-4 md:col-4 col-12">
                                    <div className="col-10">
                                        <ClassetDropdown

                                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                            value={obj.breakTime}
                                            options={this.generateBreakTimes(this.state.tableData.noOfPeriodsPerDay)}
                                            label="Break After Period"
                                            optionLabel="label"
                                            optionValue="value"
                                            required={true}
                                            onChange={(e) => this.handleBreakChange(e.target.value, 'breakTime', index)}
                                            placeholder={
                                                <div className="flex justify-content-start align-items-center">
                                                    <span>Break After Period</span>
                                                </div>
                                            }
                                        />
                                    </div>

                                    <div className="col-2 pl-4 pt-5 flex justify-content-start align-items-center cursor-pointer" onClick={() => this.removeBreaks(index)}>
                                        <DeleteIconClasset width={22} height={22} />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="mt-2 flex justify-content-between align-content-center">
                            <Button className="mt-2" style={{ borderRadius: 10, backgroundColor: '#F4F5F6', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', color: 'black' }} onClick={this.addBreaks}>
                                <p className="text-xl">Add Break</p>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center align-items-center mt-8">
                    <Button
                        label={this.props?.editData ? 'Edit Time Table Template' : 'Add Time Table Template'}
                        className="confirmDialogAcceptBtn px-6 mr-5 w-21rem"
                        onClick={this.previewTimeTable}
                    />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={() => this.props.onTabChange(0)} />
                </div>

                <Dialog
                    header="Preview Time Table Template"
                    visible={showModal}
                    style={{ width: '80vw' }}
                    onHide={() => this.setState({ showModal: false })}
                >
                    {previewData && (
                        <div>
                            <h3>Title: {previewData.title}</h3>
                            <DataTable value={previewData.periods} responsiveLayout="scroll">
                                <Column field="name" header="Name"></Column>
                                <Column
                                    field="startTime"
                                    header="Start Time"
                                    body={(rowData) => formatTime(rowData.startTime)}
                                ></Column>
                                <Column
                                    field="endTime"
                                    header="End Time"
                                    body={(rowData) => formatTime(rowData.endTime)}
                                ></Column>
                                <Column field="type" header="Type"></Column>
                                <Column field="breakDuration" header="Break Duration"></Column>
                            </DataTable>
                            <div className="flex justify-content-end mt-4">
                                <Button label="Accept" className="p-button-success mr-2" onClick={this.saveTemplate} />
                                <Button label="Cancel" className="p-button-secondary" onClick={() => this.setState({ showModal: false })} />
                            </div>
                        </div>
                    )}
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" o />
            </div>
        );
    }
}
