import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { Link, useNavigate } from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import Authentication from './components/session/index'
import { connect, useDispatch } from 'react-redux';
import { API_ENDPOINT } from './service/config';
import withRouter from './components/lib/withRouter';
import { Dropdown } from 'primereact/dropdown';
import ClassetDropdown from './classetComponents/classetDropDown';
import { userSignOut, setLanguage } from './store/actions';
import { ProgressSpinner } from 'primereact/progressspinner';




const AppTopbar = (props) => {
    const notificationsItemClassName = classNames('notifications-item', { 'active-menuitem': props.topbarNotificationMenuActive });
    const id = localStorage.getItem("userAcademicYear")
    const navigate = useNavigate();
    const optionId = id ? id : props?.selectedOption;
    const dropdownItemClassName = classNames('profile-item', { 'active-menuitem': props.academicYearOptions });
    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });
    const dispatch = useDispatch()
    const logoutApi = () => {
        // dispatch(userSignOut())
        // let url = 'fir/logout'
        // fetch(
        //     API_ENDPOINT + url,
        //     {
        //         method: 'PUT',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Authorization: 'Bearer ' + localStorage.getItem('Bearer'),
        //         },
        //     },
        // )
        //     .then(res => res.json())
        //     .then(response => {
        //         if (response?.status) {
        //             props.userSignOut()
        //         } else {
        //             props.userSignOut()
        //         }
        //     })
        //     .catch(e => {
        //         console.log('e  ', e);
        //         props.userSignOut()
        //     });

    }
    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>

                <Link to="/">
                    <img id="logo-horizontal" className="horizontal-logo" src="macademy-logo2.jpg" alt="diamond-layout" />
                </Link>

                <span className="topbar-separator"></span>

                <div className="layout-breadcrumb viewname"
                // style={{ textTransform: 'uppercase' }}
                >
                    <AppBreadcrumb meta={props.meta} />
                </div>

                <img id="logo-mobile" className="mobile-logo" src="macademy-logo2.jpg" alt="diamond-layout" />
            </div>

            <AppMenu
                model={props.menu}
                menuMode={props.menuMode}
                active={props.menuActive}
                mobileMenuActive={props.staticMenuMobileActive}
                onMenuClick={props.onMenuClick}
                onMenuitemClick={props.onMenuitemClick}
                onRootMenuitemClick={props.onRootMenuitemClick}
            ></AppMenu>

            <div className="layout-mask modal-in"></div>

            <div className="topbar-right">
                <ul className="topbar-menu">
                    {/* <li className="search-item">
                        <button type="button" className="p-link" onClick={props.onSearchClick}>
                            <i className="pi pi-search"></i>
                        </button>
                    </li> */}
                    {/* <li className={notificationsItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarNotification}>
                            <i className="pi pi-bell"></i>
                            <span className="topbar-badge">5</span>
                        </button>
                        <ul className="notifications-menu fade-in-up">
                            <li role="menuitem">
                                <button type="button" className="p-link" tabIndex="0">
                                    <i className="pi pi-shopping-cart"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">New Order</div>
                                        <div className="notification-detail">
                                            You have <strong>3</strong> new orders.
                                        </div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-check-square"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Withdrawn Completed</div>
                                        <div className="notification-detail">Funds are on their way.</div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-chart-line"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Monthly Reports</div>
                                        <div className="notification-detail">New reports are ready.</div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-comments"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Comments</div>
                                        <div className="notification-detail">
                                            <strong>2</strong> new comments.
                                        </div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-exclamation-circle"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Chargeback Request</div>
                                        <div className="notification-detail">
                                            <strong>1</strong> to review.
                                        </div>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </li> */}
                    <li className={dropdownItemClassName}>
                        {/* <select
                            style={{ width: "95px", height: "35px" }}
                            value={props.selectedOption}
                            onChange={(event) => props.onChangeDropOption(event)}
                            className="p-link"
                        >
                            {Array.isArray(props?.academicYearOptions) ? (
                                props?.academicYearOptions.map((year, index) => (
                                    <option
                                        key={index}
                                        className="text-black-alpha-90 font-semibold"
                                        value={year.academicCode} // assuming each year object has a 'value' property
                                    >
                                        {year.academicCode}
                                    </option>
                                ))
                            ) : (
                                <option className="text-black-alpha-90 font-semibold" value="error">
                                </option>
                            )}
                        </select> */}
                        {props?.academicYearOptions?.length ? <ClassetDropdown
                            options={props?.academicYearOptions}
                            optionLabel="academicCode"
                            width={'195px'}
                            height={'35px'}
                            optionValue='_id'
                            fromScreen="NavTopBar"
                            className="p-link"
                            value={optionId}
                            onChange={(event) => props.onChangeDropOption(event.target.value)}
                        /> : <ProgressSpinner style={{ width: '34px', height: '34px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />}
                    </li>
                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <img src="profilew.jpg" alt="diamond-layout" className="profile-image" />
                            <span className="profile-name">{localStorage.getItem('name')?.toUpperCase() || ''}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            {/* <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-user"></i>
                                    <span>Profile</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-cog"></i>
                                    <span>Settings</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-calendar"></i>
                                    <span>Calendar</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-inbox"></i>
                                    <span>Inbox</span>
                                </button>
                            </li> */}
                            <li>
                                <button type="button" onClick={() => { navigate('/login'); dispatch(userSignOut()); }} className="p-link">
                                    <i className="pi pi-power-off"></i>
                                    <span >Logout</span>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li className="right-sidebar-item">
                        {/* <button type="button" className="p-link" onClick={props.onRightMenuButtonClick}>
                            <i className="pi pi-align-right"></i>
                        </button> */}
                    </li>
                </ul>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.user?.user,
});
const mapDispatchToProps = (dispatch) => ({
    userSignOut: () => dispatch(userSignOut()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Authentication(withRouter(AppTopbar)));