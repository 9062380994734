import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { FinanceStudentProfile } from '../../svgIcons';

const StudentView = () => {
    const location = useLocation();
    const { rowData } = location?.state || {};

    useEffect(() => {
        console.log("Received Student Data:", rowData);
    }, []);

    return (
        <div className="flex justify-content-between align-items-start p-5">
            <div style={{ fontWeight: 'bolder', fontSize: '17px', flex: 0.3, color: 'black' }}>
                <div>
                    <p style={{ fontWeight: 'bolder', fontSize: '25px', marginBottom: '20px' }}>Student Information</p>
                    <div className="mt-1" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <div className="studentProfileAlignment">
                            <p>Student Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.firstName} {rowData?.lastName}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Father's Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.contactPersonName}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Enrollment Id</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.enrollmentId}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Admission Category</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.categoryName}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>DOB</p>
                            <p>:</p>
                            <p>{new Date(rowData?.user_info?.dateOfBirth).toLocaleDateString()}</p>
                        </div>

                        <div className="studentProfileAlignment">
                            <p>Contact No</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.parentContactNo}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Email Id</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.email}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Status</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.status}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Branch Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.branchName}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Gender</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.gender}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Admission Date</p>
                            <p>:</p>
                            <p>{new Date(rowData?.user_info?.admissionDate).toLocaleDateString()}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Branch Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.branchName}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Board Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.["custom:board"]}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Grade Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.["custom:grade"]}</p>
                        </div>
                        <div className="studentProfileAlignment">
                            <p>Section Name</p>
                            <p>:</p>
                            <p>{rowData?.user_info?.sectionName}</p>
                        </div>
                    </div>
                </div>
            </div>
            <FinanceStudentProfile width={300} />
        </div>
    );
};

export default StudentView;
