import React, { Component } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import IndividualUsageAnalytics from '../../analytics/usageAnalytics/individualUsageAnalytics';
import { ClassetEditIcon, MailIcon, WarningIcon } from '../../svgIcons';
import withRouter from '../../lib/withRouter';
import CreateVehicle from './createVehicle';
import { getBranchesLatest } from '../../../store/actions';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { Menu } from 'primereact/menu';
import MoreInfo from './MoreInfo';

class VehicleManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isShowUserInfo: false,
            isShowCreateForm: false,
            isCreateUser: false,
            editUser: null,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            branchId: '',
            selectedVehicle: {}
        };

        this.service = new Service();
    }

    componentDidMount() {
        this.props.getBranchesLatest(true);
    }

    onEditUserClick = (editUser) => {
        const driver = editUser?.staffDetails.filter((staff) => staff.designation === 'DRIVER').map((staff) => staff._id);
        const attender = editUser?.staffDetails.filter((staff) => staff.designation === 'ATTENDER').map((staff) => staff._id);

        this.setState({
            editUser: { ...editUser, acNonAC: editUser.acNonAC ? 1 : 2 },
            isShowCreateForm: true
        });
    };
    onHandleMoreInfo = (rowData) => {
        this.setState({ selectedVehicle: rowData, isShowDetailsForm: true });
    };

    onSuccessToast = () => {
        this.toast.show({ severity: 'success', summary: 'Success', detail: 'More Details Updated Successfully', life: 3000 });
    }


    onCreateOrEditSuccess = () => {
        if (this.state.editUser) {
            this.setState({ isCreateUser: false, editUser: false }, () => {
                this.filterUsersData();
            });
        } else {
            this.setState({ isCreateUser: false, editUser: false });
        }
    };

    onCancel = () => {
        this.setState({
            isShowCreateSuccess: false,
            editUser: null,
            isShowCreateForm: false
        });
    };

    getVehiclesData = async () => {
        if (!this.state.branchId) {
            this.setState({ users: [] })
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please select branch', life: 3000 });
            return;
        }
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/transport-vehicle/get-vehicle-info?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state.branchId
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ users: res?.res?.data?.vehicles, isLoading: false });
            } else {
                this.setState({
                    isLoading: false,
                    branchId: ''
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }
    };

    changeGpsStatus = async (e, rowData) => {
        this.setState({ isLoading: true });
        const payload = {
            _id: rowData?._id
        };
        let url = `${baseUrlAdmin}/transport-vehicle/updategps`;
        try {
            const res = await this.service.put(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ isLoading: false });
                this.getVehiclesData();
                this.toast.show({ severity: 'success', summary: 'Gps Update', detail: 'Gps Status Updated Successfully', life: 3000 });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }
    };
    onCancelMoreInfo = () => {
        this.setState({ selectedVehicle: {}, isShowDetailsForm: false }, () => this.getVehiclesData());
    };
    render() {
        const { editUser } = this.state;
        return (
            <>
                <>
                    <div className="flex justify-content-between  align-items-center lg:mt-4 md:mt-6 mb-2">
                        <div className="col-span-1 ">
                            <p className="add-vehicle-field-label">
                                Branch<span className="ma-required">*</span>
                            </p>
                            <div className="flex justify-content-start  align-items-center">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="name"
                                    optionValue="key"
                                    width={250}
                                    height={48}
                                    value={this.state.branchId}
                                    options={this.props.branchData}
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                branchId: e.target.value
                                            },
                                            () => this.getVehiclesData()
                                        )
                                    }
                                    className="w-full"
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Select Branch</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <Authorizer permId={PERMISSIONS.CREATE_VEHICLE_MANAGEMENT}>
                            <Button
                                icon={'pi pi-plus-circle text-lg px-1 mt-1'}
                                className="add-vehicle-btn pl-4"
                                onClick={() => {
                                    this.setState({ isShowCreateForm: true });
                                }}
                            >
                                Add Vehicle
                            </Button>
                        </Authorizer>
                    </div>
                    <div className="card datatable-crud-demo ma-m30">
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            className="dataTableValuesText"
                            columnResizeMode="expand"
                            value={this.state.users}
                            dataKey="id"
                            paginator
                            responsiveLayout="scroll"
                            rows={10}
                            scrollable
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        >
                            <Column


                                sortable
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                header="S.No."
                                className="datatable-cell"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                sortable
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                filterField="vehicleRegistrationNo"
                                field="vehicleRegistrationNo"
                                header="Vehicle Number"
                            />
                            <Column sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="vehicleType" field="vehicleType" header="Vehicle Type" />
                            <Column sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="vehicleName" field="vehicleName" header="Vehicle Name" />
                            <Column
                                sortable
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                filterField="vehicleCategory"
                                field="vehicleCategory"
                                header="Vehicle Owned"
                            />
                            <Column

                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                body={(rowData) => {
                                    return (
                                        <div className="">
                                            <InputSwitch
                                                onChange={(e) => {
                                                    this.changeGpsStatus(e, rowData);
                                                }}
                                                checked={rowData.isGps}
                                            />
                                        </div>
                                    );
                                }}
                                field="isGps"
                                header="Gps"
                            />
                            <Column
                                bodyClassName="text-center"
                                className='text-center'
                                headerClassName="text-color-secondary"
                                filterField="seatingCapacity"
                                field="seatingCapacity"
                                header="Seating Capacity"
                            />
                            <Column className='text-center' sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="fuelType" field="fuelType" header="Fuel" />

                            <Column


                                sortable
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                header="Actions"
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            <Button id={rowData?._id} onClick={(event) => this[rowData?._id].toggle(event)} icon="pi pi-ellipsis-v" className=" p-button-text p-button-secondary ma-ml10" />
                                            <Menu
                                                model={[
                                                    {
                                                        label: 'Edit Vehicle',
                                                        command: () => {
                                                            this.onEditUserClick(rowData);
                                                        }
                                                    },
                                                    {
                                                        label: 'More Details',
                                                        command: () => this.onHandleMoreInfo(rowData)
                                                    }
                                                ]}
                                                style={{ width: '95px' }}
                                                className="h-7rem card shadow-4 flex flex-column justify-content-center align-items-center"
                                                popup={true}
                                                ref={(el) => (this[rowData?._id] = el)}
                                            />
                                        </>
                                    );
                                }}
                            ></Column>
                        </DataTable>
                    </div>
                </>

                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowCreateForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    style={{
                        width: '90%',
                        maxWidth: '1310px',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return <h2 className="transport-header py-2">{!this.state?.editUser ? 'Add' : 'Edit'} Vehicle</h2>;
                    }}
                    onHide={() => {
                        this.setState({
                            isShowCreateForm: false,
                            newPassword: null,
                            editUser: null
                        });
                    }}
                >
                    <div className="">
                        <CreateVehicle onCancel={this.onCancel} editData={editUser} getVehicleData={this.getVehiclesData} toastMessage={this.toast} />
                    </div>
                </Dialog>

                {/* ENTER DETAILS DIALOG */}
                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowDetailsForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    dismissableMask={false}
                    closable={true}
                    style={{
                        width: '90%',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    header={() => {
                        return <h2 className="transport-header py-2">More Details</h2>;
                    }}
                    onHide={() => {
                        this.setState({
                            isShowDetailsForm: false,
                            selectedVehicle: {}
                        }, () => this.onCancelMoreInfo());
                    }}
                >
                    <MoreInfo vehicleInfo={this.state.selectedVehicle} onCancelMoreInfo={() => this.onCancelMoreInfo()} getVehicleData={this.getVehiclesData} onSuccessToast={this.onSuccessToast} />
                </Dialog>
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        []
});

export default connect(mapStateToProps, {
    getBranchesLatest
})(withRouter(VehicleManagement));
