import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import Service from '../../../../services';
import { BasicLazyParams, exportToCSV, FileTypes, warningDailogInit } from '../../../../../utile';
import {
    getBoardsData,
    getBranchesLatest
} from '../../../../../store/actions';
import _ from 'lodash';
import Authentication from '../../../../session';
import { connect } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../../../store/apiConstants';
import LoadingComponent from '../../../../loadingComponent';
import { InputText } from 'primereact/inputtext';
import * as XLSX from 'xlsx';
import withRouter from '../../../../lib/withRouter';
import './adhocExamResultUpload.scss';
import { userAssignedBoards } from '../../../../../store/selectors/userAssignedBoards';

const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};



class AdhocExamResultUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBranchId: null,
            sectionIds: [],
            selectedTab: 1,
            data: [],
            boardId: props.uploadResultExam?.boardId,
            classId: props.uploadResultExam?.classId,
            sections: [], // Available sections for selected branch
            availableBranches: [], // Branches filtered by board and class
            selectedClass: null, // Store selected class data
            isUploading: false,  // Add this new state property
        };
        this.fileUploadRef = React.createRef();
        this.service = new Service();
    }

    componentDidMount() {
        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            this.initializeDropdowns();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.branchData !== this.props.branchData) {
            this.initializeDropdowns();
        }
    }

    initializeDropdowns = () => {
        const { boardId, classId } = this.state;
        const { branchData } = this.props;

        if (!branchData || !boardId || !classId) return;

        const selectedBoard = this.props.boards.find(
            (board) => board?.boardId === boardId
        );

        if (selectedBoard) {
            const selectedClass = selectedBoard.classes.find(
                (classd) => classd.classId === classId
            );

            // Store selected class and its branches
            if (selectedClass?.branches?.length) {
                this.setState({
                    availableBranches: selectedClass.branches,
                    selectedClass: selectedClass // Store full class data
                });
            }
        }
    };

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        //   console.log(items)
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: [],
            selectedSection: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onChangeBranch = (branchId) => {
        const { selectedClass } = this.state;

        // Get sections for this branch from selected class
        const sections = selectedClass?.sections?.filter(
            section => section.branchId === branchId
        ) || [];

        this.setState({
            selectedBranchId: branchId,
            sections: sections,
            sectionIds: [] // Reset section selection when branch changes
        });
    };

    sanitizeFileName = (name) => {
        return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    };

    exportCSV = (items) => {
        if (!items || !items.length) return;

        // Get headers from first item
        const headers = Object.keys(items[0]);

        // Convert items to CSV format
        const csvContent = [
            // Headers row
            headers.join(','),
            // Data rows
            ...items.map(item =>
                headers.map(header => {
                    // Handle empty values and escape commas
                    const cell = item[header] || '';
                    return cell.toString().includes(',') ? `"${cell}"` : cell;
                }).join(',')
            )
        ].join('\n');

        // Create blob and download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        // Format filename: examname_result_DDMMYYYY
        const examName = this.sanitizeFileName(this.props.uploadResultExam.examName);
        const dateStr = moment().format('DDMMYYYY');
        const fileName = `${examName}_result_${dateStr}.csv`;

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    changeTab = (tab) => {
        this.setState({
            selectedTab: tab,

        });
    }

    onMultiChange = (value) => {
        console.log('Selected sections:', value); // For debugging
        this.setState({
            sectionIds: value
        });
    }


    downloadSheet = () => {

        if (this.state.sectionIds && this.state.sectionIds.length) {


            this.setState({
                isLoading: true
            })


            let thisObj = this;
            const url = `${baseUrlForLongRunningTask}/examination/adhoc-offline-upload-users`;
            this.service.post(url, { examId: this.props.uploadResultExam.examId, sectionIds: this.state.sectionIds }, true).then(res => {
                if (res && res.status) {
                    if (res?.res?.data?.length) {
                        thisObj.setState({
                            isLoading: false,
                            data: res.res.data
                        }, () => {
                            thisObj.exportCSV(res.res.data); // Direct call with data
                        });
                        thisObj.toast.show({ severity: 'success', summary: 'Success', detail: 'Exam assigned students found, please fill the sheet and upload.', life: 3000 });
                    } else {
                        thisObj.setState({
                            isLoading: false
                        })
                        thisObj.toast.show({ severity: 'error', summary: 'No students', detail: 'Exam is not assigned to any students or there are no students in the selected sections', life: 3000 });
                    }



                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })




        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Select Section Ids', life: 3000 });
        }





        //this.exportCSV(this.state.items);
    }


    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }



            this.setState({ users: newJsonSheet });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };

    uploadExamResults = async () => {
        if (this.state.users && this.state.users.length) {
            // Set both loading states
            this.setState({
                isLoading: true,
                isUploading: true
            });

            try {
                const url = `${baseUrlForLongRunningTask}/examination/upload-adhoc-results`;
                const res = await this.service.post(url, { users: this.state.users }, true);

                if (res && res.status) {
                    this.toast.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Uploaded results successfully.',
                        life: 3000
                    });

                    this.setState({
                        isLoading: false,
                        isUploading: false,
                        users: [],
                        data: []
                    });

                    this.fileUploadRef.current.value = null;
                } else {
                    this.setState({
                        isLoading: false,
                        isUploading: false
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.errMessage || 'Upload failed',
                        life: 3000
                    });
                }
            } catch (e) {
                this.setState({
                    isLoading: false,
                    isUploading: false
                });
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Some error occurred',
                    life: 3000
                });
                console.error(e);
            }
        } else {
            this.toast.show({
                severity: 'error',
                summary: 'Invalid',
                detail: 'Please upload users',
                life: 3000
            });
        }
    }


    render() {
        const { selectedTab } = this.state;
        const { uploadResultExam } = this.props;
        //  console.log(this.state, 'ddddd')
        return (
            <>
                <Dialog
                    blockScroll={true}
                    style={{ width: '80%', height: '90vh' }}
                    header="Upload Offline Exam Results"
                    modal
                    draggable={false}
                    onHide={this.props.onHide}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    className="adhoc-exam-upload"
                >
                    <div className="exam-tabs">
                        <div
                            className={`tab-item ${selectedTab === 1 ? 'active' : ''}`}
                            onClick={() => this.changeTab(1)}
                        >
                            Download Students
                        </div>
                        <div
                            className={`tab-item ${selectedTab === 2 ? 'active' : ''}`}
                            onClick={() => this.changeTab(2)}
                        >
                            Upload Results
                        </div>
                    </div>

                    {selectedTab === 1 && (
                        <div className="download-section">
                            <h3 className="exam-name">{uploadResultExam?.examName}</h3>
                            <div className="exam-details">
                                <p><strong>Board:</strong> <span className="highlight-text">{uploadResultExam?.boardName}</span></p>
                                <p><strong>Grade:</strong> <span className="highlight-text">{uploadResultExam?.className}</span></p>
                            </div>
                            <div className="dropdowns-container">
                                <Dropdown
                                    className="ma-mr20 ma-mt10 ma-w200"
                                    value={this.state.selectedBranchId}
                                    optionLabel="branchName"
                                    optionValue="branchId"
                                    options={this.state.availableBranches}
                                    onChange={(e) => this.onChangeBranch(e.value)}
                                    placeholder="Select Branch"
                                />
                                <MultiSelect
                                    className="ma-m-lr10 ma-w200"
                                    value={this.state.sectionIds}
                                    onChange={(e) => this.onMultiChange(e.value)}
                                    options={this.state.sections}
                                    optionLabel="sectionName"     // Changed from "name" to "sectionName"
                                    optionValue="sectionId"      // Changed from "key" to "sectionId"
                                    placeholder="Select Sections"
                                    disabled={!this.state.selectedBranchId}
                                />
                            </div>
                            <Button
                                label="Download Sheet"
                                icon="pi pi-download"
                                className="p-button-primary"
                                onClick={this.downloadSheet}
                                disabled={!this.state.sectionIds.length}
                            />
                        </div>
                    )}

                    {selectedTab === 2 && (
                        <div className="upload-section">
                            <p className="spread-title">Users Spreadsheet</p>
                            <div className="file-upload-container">
                                <InputText
                                    value={this.state.fileSelected}
                                    placeholder="No file selected"
                                    disabled
                                />
                                <label htmlFor="file" className="select-file">Select File</label>
                                <input
                                    type="file"
                                    id="file"
                                    hidden
                                    accept={SheetJSFT}
                                    ref={this.fileUploadRef}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <Button
                                label="Upload Results"
                                icon="pi pi-upload"
                                className="upload-button p-button-primary"
                                onClick={this.uploadExamResults}
                                disabled={!this.state.users?.length || this.state.isUploading}  // Add isUploading check
                                loading={this.state.isUploading}  // Add loading state to show spinner
                            />
                        </div>
                    )}
                </Dialog>

                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </ >
        )
    }
}




const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(AdhocExamResultUpload)));