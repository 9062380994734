import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import Authentication from './../../session';
import { getBranchesLatest } from '../../../store/actions';
import { RadioButton } from 'primereact/radiobutton';
import ClassetStudentSearch from '../../../classetComponents/classetStudentSearch';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { CancelRed, DownloadIcon, GreenCheck, MailIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import moment from 'moment';
import { BasicLazyParams } from '../../../utile';
import { debounce } from 'lodash';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { Dialog } from 'primereact/dialog';
import '../styles.scss';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import BranchToSectionApprovalHierarchy from '../../BaseDropdownComponents/BranchToSectionApprovalHierarchy';

const searchTypeOptions = [
    { label: 'Student Name', value: 'studentName' },
    { label: 'Enrollment Number', value: 'enrollmentNumber' }
];

const feeConcessionTypesOptions = [
    { value: 'merit', label: 'Merit-based' },
    { value: 'sports', label: 'Sports' },
    { value: 'financial', label: 'Financial Aid' },
    { value: 'alumni', label: 'Alumni' },
    { value: 'staff', label: 'Staff Child' }
];

class FeeConcessionApprovals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lazyParams: BasicLazyParams,
            searchType: 1,
            searchInput: '',
            studentInfo: [],
            raisedFeeConcessionsData: {},
            studentId: null,
            searchLevel: 1,
            feeConcessionTypesOptions: feeConcessionTypesOptions,
            searchType: searchTypeOptions[0].value,
            viewConcessionsDetails: false,
            remarks: ''
        };
        this.debouncedApiCall = debounce(this.getStudentsInfo, 500);
        this.service = new Service();
    }

    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                studentId: null
            },
            () => {
                this.getStudentsInfo();
            }
        );
    };

    getStudents = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        // let { lazyParams } = this.state;
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        if (payload?.sectionId) {
            this.service
                .post(url, payload, true)
                .then((data) => {
                    // console.log(data, "datastudent")
                    const studentsData = data?.res?.data.map((each) => {
                        return {
                            label: each?.name,
                            value: each?.user_id
                        };
                    });

                    if (data?.status) {
                        this.setState({
                            students: studentsData,
                            isLoading: false
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isLoading: false });
        }
    };
    onHandleViewFeeConcessions = (data) => {
        this.setState({
            viewConcessionsDetails: true,
            raisedFeeConcessionsData: data
        });
    };
    onHandleConcessionClose = () => {
        this.setState(
            {
                viewConcessionsDetails: false,
                raisedFeeConcessionsData: {},
                remarks: ''
            },
            () => this.getStudentsInfo()
        );
    };
    onShowDetailedReport = () => {
        this.setState({
            isLoading: true
        });
    };
    onHandleApprovedConcessions = () => {
        this.setState({ isLoading: true });
        const { raisedFeeConcessionsData, remarks } = this.state;
        // handle every object has approved status
        let isAllConcessionsChecked = true;

        if (raisedFeeConcessionsData?.concessionsRaised?.length) {
            for (const el of raisedFeeConcessionsData.concessionsRaised.filter(e => e?.isClosed == false || e?.isClosed == undefined)) {
                if (!el.hasOwnProperty('isConcessionApproved')) {
                    isAllConcessionsChecked = false;
                    break;
                }
            }
        }
        if (!isAllConcessionsChecked) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Please approve/reject all the fee types', detail: 'Please approve/reject all the fee types', life: 3000 });
            return;
        }

        let payload = {
            _id: raisedFeeConcessionsData?._id,
            user_id: raisedFeeConcessionsData?.user_id,
            isClosed: true,
            concessionsRaised: raisedFeeConcessionsData?.concessionsRaised,
            remarks: remarks,
            branchId: raisedFeeConcessionsData?.branchId,
            handledBy: this.state.raisedFeeConcessionsData?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == this.state.raisedFeeConcessionsData?.branchId)
        };
        let url = `${baseUrlAdmin}/concession-approval/update-concession-approvals`;
        this.service
            .put(url, payload, true)
            .then((res) => {
                if (res?.status && res?.res?.status) {
                    this.setState(
                        {
                            viewConcessionsDetails: false,
                            raisedFeeConcessionsData: {},
                            remarks: '',
                            isLoading: false
                        }
                    );
                    // window.location.reload()
                    this.getStudentsInfo();
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });

                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    };

    handleSetSearchValue = (value) => {
        this.setState(
            {
                searchValue: value,
                dropdownsData: { ...this.state.dropdownsData, boardId: '', branchId: '', classId: '', sectionId: '' }
            },
            () => {
                this.debouncedApiCall();
            }
        );
    };
    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value, studentId: '', searchValue: '', studentsData: [] });
    };

    onStudentChange = (e) => {
        this.setState({ studentId: e.value }, () => {
            this.getStudentsInfo();
        });
    };

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == 'studentName') {
                this.setState({ isUserNameSearch: true });
            } else {
                this.setState({ isUserEnrollSearch: true });
            }
        });
    };

    getStudentsInfo = () => {
        let { lazyParams, globalSearch, filterData } = this.state;
        this.setState({ isLoading: true, studentInfo: [] });
        const url = `${baseUrlAdmin}/concession-approval/get-concession-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        let academicYear = localStorage.getItem('userAcademicYear');
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchIds,
            boardId: this.state?.dropdownsData?.boardIds,
            gradeId: this.state?.dropdownsData?.classIds,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo,
            assignedHeirarchy: this.state?.dropdownsData?.heirarchy
        };

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res?.data && res.res?.data?.length) {
                        this.setState({
                            isLoading: false,
                            studentInfo: res.res.data,
                            totalRecords: res.res.totalRecords
                        });
                    } else if (res.res?.data?.length == 0) {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'success', summary: 'No records found.', detail: '', life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };
    onHandleFeeConcessionApprove = (rowData, status) => {
        this.setState((prevState) => {
            const updatedItems = prevState.raisedFeeConcessionsData?.concessionsRaised?.map((item) => {
                if (item._id === rowData?._id) {
                    return { ...item, isConcessionApproved: status };
                }
                return item;
            });
            let feeInfo = prevState.raisedFeeConcessionsData;
            return { raisedFeeConcessionsData: { ...feeInfo, concessionsRaised: updatedItems } };
        });
    };

    render() {
        return (
            <div className="ma-main">
                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BranchToSectionApprovalHierarchy selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} approvalType={'feeConcession'} />
                        </div>
                    </div>

                    <div style={{ marginLeft: '100px' }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>

                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    {/* <div className="col-12 ml-5">
                        <label className="label">
                            Student<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id="student"
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                //     onChange={(e) => {this.setState({studentId:e.value})
                                //     this.getTermBasedData()

                                // }}
                                onChange={this.onStudentChange}
                                placeholder={
                                    <div>
                                        <span className="">Select Student</span>
                                    </div>
                                }
                            />
                        </div>
                    </div> */}
                </div>

                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.studentInfo}
                    dataKey="id"
                    // paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column
                        alignHeader={'center'}
                        sortable
                        bodyClassName="text-center"
                        header="Student Name"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex justify-content-center">
                                    {rowData?.firstName}
                                    {rowData?.lastName}
                                </div>
                            );
                        }}
                    ></Column>
                    <Column filterField="enrollmentId" field="enrollmentId" sortable bodyClassName="text-center" header="Enrollment No" alignHeader={'center'} body={(rowData)=>{
                        return(
                            <div className="flex justify-content-center">
                                {rowData?.username.slice(3)}
                            </div>
                        )
                    } } />
                    <Column filterField="contactPersonName" field="contactPersonName" sortable bodyClassName="text-center" header="Contact Person" alignHeader={'center'} />
                    <Column filterField="parentContactNo" field="parentContactNo" sortable bodyClassName="text-center" header="Phone Number" alignHeader={'center'} />
                    <Column filterField="totalFee" field="totalFee" sortable bodyClassName="text-center" header="Total Fee" alignHeader={'center'} />
                    <Column filterField="concessionRaisedAmt" field="concessionRaisedAmt" sortable bodyClassName="text-center" header="Concession" alignHeader={'center'} />
                    <Column
                        alignHeader={'center'}
                        sortable
                        bodyClassName="text-center"
                        header="Details"
                        body={(rowData, { rowIndex }) => {
                            return <Button id={rowData?._id} onClick={() => this.onHandleViewFeeConcessions(rowData)} icon="pi pi-ellipsis-v" className=" p-button-text p-button-secondary ma-ml10" />;
                        }}
                    ></Column>
                </DataTable>

                <Dialog
                    isopen={this.state.viewConcessionsDetails}
                    visible={this.state.viewConcessionsDetails}
                    className="w-11"
                    draggable={false}
                    onHide={this.onHandleConcessionClose}
                    closable={true}
                    header={() => {
                        console.log(this.state);
                        return (
                            <div className="mt-2 mb-4">
                                <h3 className="formhead text-center ">Approve Raised Concessions</h3>
                            </div>
                        );
                    }}
                    footer={() => <div className="flex justify-content-center align-items-center mb-4"></div>}
                >
                    <>
                        <div className="grid m-2">
                            <div className="col-4 ">
                                <label className="ma-label-s2 font-serif font-semibold text-900 text-lg">Student Name</label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="studentName"
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        className=""
                                        height="48px" 
                                        width="368px"
                                        value={`${this.state?.raisedFeeConcessionsData?.firstName}` + " " + `${this.state?.raisedFeeConcessionsData?.lastName}`}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-4 ">
                                <label className="ma-label-s2 font-serif font-semibold text-900 text-lg">Enrollment No</label>
                                <div className="mt-2">
                                    <ClassetInputText id="enrollmentNo" icon={<MailIcon width={20} height={16} color={'#667A85'} />} height="48px" width="368px" value={this.state?.raisedFeeConcessionsData?.enrollmentId} disabled={true} />
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="ma-label-s2 font-serif font-semibold text-900 text-lg">Grade</label>
                                <div className="mt-2">
                                    <ClassetInputText id="grade" icon={<MailIcon width={20} height={16} color={'#667A85'} />} height="48px" width="368px" value={this.state?.raisedFeeConcessionsData?.gradeName} disabled={true} />
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="ma-label-s2 font-serif font-semibold text-900 text-lg">Section</label>
                                <div className="mt-2">
                                    <ClassetInputText id="section" icon={<MailIcon width={20} height={16} color={'#667A85'} />} height="48px" width="368px" value={this.state?.raisedFeeConcessionsData?.sectionName} disabled={true} />
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="ma-label-s2 font-serif font-semibold text-900 text-lg">Board</label>
                                <div className="mt-2">
                                    <ClassetInputText id="board" icon={<MailIcon width={20} height={16} color={'#667A85'} />} height="48px" width="368px" value={this.state?.raisedFeeConcessionsData?.boardName} disabled={true} />
                                </div>
                            </div>
                        </div>

                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state?.raisedFeeConcessionsData?.concessionsRaised?.filter(e => e?.isClosed == false || e?.isClosed == undefined)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                        >
                            <Column filterField="feeType" field="feeType" sortable bodyClassName="text-center" header="Fee Type" alignHeader={'center'} />
                            <Column
                                filterField="feeTermName"
                                sortable
                                bodyClassName="text-center"
                                header="Fee Term"
                                alignHeader={'center'}
                                body={(rowData, index) => {
                                    return <p>{`${rowData?.paymentTerm}`}</p>;
                                }}
                            />
                            <Column filterField="termWiseActualValue" field="termWiseActualValue" sortable bodyClassName="text-center" header="Term wise Actual Fee" alignHeader={'center'} />
                            <Column filterField="concession" field="concession" sortable bodyClassName="text-center" header="Past Concession" alignHeader={'center'} />
                            <Column filterField="amountPaid" field="amountPaid" sortable bodyClassName="text-center" header="Paid Fee" alignHeader={'center'} />
                            <Column bodyClassName="text-center" header="Concession Type" alignHeader={'center'} field="concessionType" />
                            <Column
                                filterField="concessionValue"
                                field="termWisePercentageOrValue"
                                sortable
                                bodyClassName="text-center"
                                header="Concession Value"
                                alignHeader={'center'}
                                body={(rowData, index) => {
                                    return <p>{rowData?.currentConAmt || 0}</p>;
                                }}
                            />
                            <Column
                                filterField="totalDue"
                                field=""
                                sortable
                                bodyClassName="text-center"
                                header="Total Due"
                                alignHeader={'center'}
                                body={(rowData, index) => {
                                    let dueAmount = Number(rowData?.dueAmount) || 0;
                                    let conAmt = Number(rowData?.currentConAmt) || 0;
                                    let finalDue = Number(dueAmount - conAmt).toFixed(2); // (Calculating dueAmount from backend substracting ->   termFee - pastConcession - amountPaid  )
                                    return <p>{finalDue}</p>;
                                }}
                            />
                            <Column
                                style={{ width: '15rem', height: '6rem' }}
                                alignHeader={'center'}
                                sortable
                                headerClassName="text-color-secondary"
                                bodyClassName="text-center"
                                className=" "
                                header="Approve / Reject"
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            {rowData?.isConcessionApproved == undefined && (
                                                <div className="flex justify-content-evenly">
                                                    <div className="cursor-pointer" onClick={() => this.onHandleFeeConcessionApprove(rowData, true)}>
                                                        <GreenCheck />
                                                    </div>

                                                    <div className="cursor-pointer" onClick={() => this.onHandleFeeConcessionApprove(rowData, false)}>
                                                        <CancelRed />
                                                    </div>
                                                </div>
                                            )}

                                            {rowData?.isConcessionApproved == true && (
                                                <div className="cursor-pointer">
                                                    <Button className="approve-btn" style={{ backgroundColor: '#7CDD56' }}>
                                                        Approved
                                                    </Button>
                                                </div>
                                            )}
                                            {rowData?.isConcessionApproved == false && (
                                                <div className="cursor-pointer">
                                                    <Button className="approve-btn" style={{ backgroundColor: '#BF0031' }}>
                                                        Rejected
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    );
                                }}
                            ></Column>
                        </DataTable>

                        <div className=" mx-2 my-3">
                            <ClassetTextArea placeholder="Remarks" className="" width={'98%'} height={'150px'} value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                        <div className="flex justify-content-center  align-items-center mt-4">
                            <Button label="Confirm" className="submitButtonn mr-4 button text-lg " onClick={this.onHandleApprovedConcessions} />
                            <Button className="cancelButtonn " onClick={() => this.onHandleConcessionClose()}>
                                <p className="cancelButtonnText">Cancel</p>
                            </Button>
                        </div>
                    </>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

export default withRouter(Authentication(FeeConcessionApprovals));
