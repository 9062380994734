import React, { Component } from 'react'
import { getBranchesLatest } from '../../store/actions'
import withRouter from '../lib/withRouter'
import { connect } from 'react-redux'
import Authentication from '../../session';
import Service from '../services'
import { BasicLazyParams } from '../../utile';
import BranchToSectionMultiDDPreFill from '../BaseDropdownComponents/BranchToSectionMultiDDPreFill';
import { baseUrlAdmin } from '../../store/apiConstants';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../loadingComponent';
import { Toast } from 'primereact/toast';
class FeeDueReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      dropdownsData: {},
      feeType: [],
      lazyParams: BasicLazyParams,
      feeDueReport: [],
      totalRecords: 0
    }
    this.service = new Service()
  }

  async componentDidMount() {
  }


  setDropdownValues = (dropdownsData) => {
    const branchChanged = this.state.dropdownsData?.branchIds !== dropdownsData.branchIds;
    this.setState({
      dropdownsData,
      branchChanged
    }, () => {
      if (this.state.branchChanged && this.state.dropdownsData.branchIds) {
        this.getFeeStructures();
      }
      if (this.state.dropdownsData.sectionId) {
        this.getFeeDueReportInfo();
      }
    });
  }

  getFeeDueReportInfo = async () => {
    let { globalSearch } = this.state;
    this.setState({
      isLoading: true,
    });
    let url = `${baseUrlAdmin}/financeReports/feeDueReport?search=${globalSearch || ''}`;
    let payload = {
      academicYear: localStorage.getItem('userAcademicYear'),
      branchIds: this.state.dropdownsData?.branchIds,
      sectionIds: this.state.dropdownsData?.sectionId,
      boardIds: this.state.dropdownsData?.boardIds,
      classIds: this.state.dropdownsData?.classIds,
      feeStructureId: this.state.selectedFeeStructure,
      feeTypes: this.state.feeType,
      installmentId: this.state.selectedPaymentTerms,
    }
    this.service.post(url, payload, true).then(data => {
      if (data.status) {
        if (data?.res?.status) {

          this.setState({
            feeDueReport: data?.res?.data,
            totalRecords: data?.res?.totalRecords || (data?.res?.data?.length || 0),
            isLoading: false
          })
        } else {
          this.setState({
            isLoading: false
          })
        }
      } else {
        this.setState({
          isLoading: false
        })
      }
    });
  }

  onPage = (event) => {
    this.setState({
      lazyParams: {
        ...this.state.lazyParams,
        first: event.first,
        rows: event.rows
      }
    });
  }

  getFeeStructures = async () => {
    this.setState({
      isLoading: true
    });
    let academicYear = localStorage.getItem('userAcademicYear');
    let payload = {
      academicYear: academicYear,
      branchId: this.state.dropdownsData.branchIds
    }
    let url = `${baseUrlAdmin}/fee-structure/reports`;
    this.service.post(url, payload, true).then((data) => {
      if (data.status) {
        if (data.res.status && data?.res?.data) {
          this.setState({
            feeStructuresArray: data?.res?.data?.response || [],
            isLoading: false
          },);
        } else {
          this.setState({
            isLoading: false
          });
        }
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  };
  getFeeInstallments = async () => {
    const academicYear = localStorage.getItem('userAcademicYear');
    this.setState({ isLoading: true });
    let url = `${baseUrlAdmin}/feeInstallments/${this.state?.planId}?academicYear=${academicYear}`;
    try {
      const res = await this.service.get(url, true);
      if (res?.res?.status && res.status) {
        this.setState({ paymentTerms: res?.res?.data, isLoading: false });
      } else {
        this.setState({ isLoading: false });
        this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
      }
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
      this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
    }
  };

  dynamicColumns = (feeTypes, selectedFeeTypes) => {
    if (selectedFeeTypes && selectedFeeTypes.length) {
      feeTypes = feeTypes.filter(each => selectedFeeTypes.includes(each.id));
    }
    let feeInfo = feeTypes;

    let columns = [];

    feeInfo.forEach((each, index) => {
      // Column for Total Amount
      columns.push(
        <Column
          key={`total-${each.feeType}-${index}`}
          style={{ width: "15rem", height: "6rem" }}
          filterField={`total-${each.feeType}`}
          headerClassName="text-color-secondary"
          bodyClassName='text-center'
          header={`Total ${each.feeType}`}
          alignHeader={"center"}
          body={(rowData) => {
            const feeTypeData = rowData.feeTypes?.find(fee => fee.feeType === each.feeType);
            return <p>{feeTypeData?.termWiseActualValue || 0}</p>;
          }}
        />
      );

      // Column for Due Amount
      columns.push(
        <Column
          key={`due-${each.feeType}-${index}`}
          style={{ width: "15rem", height: "6rem" }}
          filterField={`due-${each.feeType}`}
          headerClassName="text-color-secondary"
          bodyClassName='text-center'
          header={`${each.feeType} Due`}
          alignHeader={"center"}
          body={(rowData) => {
            const feeTypeData = rowData.feeTypes?.find(fee => fee.feeType === each.feeType);
            const dueAmount = (feeTypeData?.termWiseActualValue || 0) - (feeTypeData?.amountPaid || 0);
            return <p>{dueAmount}</p>;
          }}
        />
      );
    });

    return columns;
  }
  render() {
    return (
      <>
        <div className='card mt-0 pt-0' style={{
          boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.25), 4px 4px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: "12px",
          border: 'none',
        }}
        >
          <BranchToSectionMultiDDPreFill setValues={this.setDropdownValues} />
          <div className="grid m-2">
            <div className="col-3 ">
              <label className='label'>Fee Structure</label>
              <div className="mt-2">
                <ClassetDropdown
                  options={this.state.feeStructuresArray}
                  value={this.state.selectedFeeStructure}
                  optionValue='_id'
                  optionLabel='scheduleName'
                  onChange={(e) => {
                    const selectedStructure = this.state.feeStructuresArray.find(each => each._id === e.target.value);
                    const feeTypes = selectedStructure?.stepOne?.map(each => ({
                      id: each.feeTypeId,
                      feeType: each.feeType
                    })) || [];

                    this.setState({
                      selectedFeeStructure: e.target.value,
                      feeTypes: feeTypes,
                      feeType: [],
                      planId: selectedStructure?.planId
                    }, () => {
                      this.getFeeDueReportInfo();
                      if (this.state.planId) {
                        this.getFeeInstallments();
                      }
                    });
                  }}
                  placeholder={(
                    <div>
                      <span className=''>Select Fee Structure</span>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="col-3 ">
              <label className='label'>Fee Types</label>
              <div className="mt-2">
                <ClassetMultiSelect
                  options={this.state.selectedFeeStructure && this.state.feeTypes}
                  value={this.state.feeType}
                  optionValue='id'
                  optionLabel='feeType'
                  onChange={(e) => this.setState({ feeType: e.target.value }, () => this.getFeeDueReportInfo())}
                  placeholder={(
                    <div>
                      <span className=''>Select Fee Type</span>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="col-3 ">
              <label className='label'>Installments</label>
              <div className="mt-2">
                <ClassetMultiSelect
                  options={this.state.paymentTerms}
                  value={this.state.selectedPaymentTerms}
                  optionValue='installmentId'
                  optionLabel='name'
                  onChange={(e) => this.setState({ selectedPaymentTerms: e.target.value }, () => this.getFeeDueReportInfo())}
                  placeholder={(
                    <div>
                      <span className=''>Select Fee Installments</span>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="col-3 mt-5 flex justify-content-end align-items-center gap-5 ms-auto" style={{ maxWidth: 'fit-content' }}>
              <Button label="Download Report" style={{ width: "137px", height: "40px", padding: "10px", borderRadius: "10px", border: '1px solid #E6E8EA', backgroundColor: "#F4F5F6", color: "#000", }} />
              <Button label="View Analysis" className="p-button-primary"
                style={{ width: "137px", height: "40px", padding: "10px", borderRadius: "10px", border: '1px solid #E6E8EA', backgroundColor: "#076EFF", color: "#fff", }} />
            </div>
          </div>
        </div>
        <div>
          <DataTable
            value={this.state.feeDueReport}
            dataKey="id"
            paginator
            responsiveLayout="scroll"
            className="dataTableValuesText"
            columnResizeMode="expand"
            resizableColumns
            scrollable
            onPage={this.onPage}
            first={this.state.lazyParams.first}
            rows={this.state.lazyParams.rows}
            totalRecords={this.state.totalRecords}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            globalFilter={this.state.globalFilter}
          >
            <Column
              style={{ width: "12rem", height: "6rem" }}
              filterField="username"
              field="username"
              headerClassName="text-color-secondary"
              bodyClassName="text-center"
              header="Student Id"
              alignHeader="center"
              body={(rowData) => <p>{rowData?.username.slice(3)}</p>}
            />
            <Column
              filterField="firstName"
              headerClassName="text-color-secondary"
              header="Student Name"
              body={(rowData) => (
                <div >
                  {(rowData?.firstName && rowData?.lastName)
                    ? `${rowData?.firstName} ${rowData?.lastName}`
                    : rowData.given_name}
                </div>
              )}
            />
            <Column

              style={{ width: "12rem", height: "6rem" }}
              filterField="installmentTitle"
              field="installmentTitle"
              headerClassName="text-color-secondary"
              bodyClassName="text-center"
              header="Fee Term"
              alignHeader="center"
            />
            <Column
              style={{ width: "12rem", height: "6rem" }}
              filterField="className"
              field="username"
              headerClassName="text-color-secondary"
              bodyClassName="text-center"
              header="Class-Section"
              alignHeader="center"
              body={(rowData) => (
                <p>{`${rowData?.className}-${rowData?.sectionName}`}</p>
              )}
            />
            <Column
              style={{ width: "12rem", height: "6rem" }}
              filterField="totalTermAmount"
              field="totalTermAmount"
              headerClassName="text-color-secondary"
              bodyClassName="text-center"
              header="Total Fee"
              alignHeader="center"
            />
            <Column
              style={{ width: "12rem", height: "6rem" }}
              filterField="totalAmountPaid"
              field="totalAmountPaid"
              headerClassName="text-color-secondary"
              bodyClassName="text-center"
              header="Fee Paid"
              alignHeader="center"
            />
            <Column
              style={{ width: "12rem", height: "6rem" }}
              filterField="username"
              field="username"
              headerClassName="text-color-secondary"
              bodyClassName="text-center"
              header="Due Fee"
              alignHeader="center"
              body={(rowData) => (
                <p>{rowData?.totalTermAmount - rowData?.totalAmountPaid}</p>
              )}
            />
            {this.state.selectedFeeStructure && this.dynamicColumns(this.state.feeTypes, this.state.feeType)}
          </DataTable>
        </div>
        {this.state.isLoading && <LoadingComponent />}
        <Toast ref={(el) => this.toast = el} position="bottom-right" />

      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.boardsData.loading,
  isBranchLoading: state.branchDataLatest.isLoading,
  branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
  branchData:
    (state.branchDataLatest &&
      state.branchDataLatest.data &&
      state.branchDataLatest.data.data.filter((each) => {
        if (each.level == 1) {
          return { ...each };
        }
      })) ||
    [],
  _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._idF
});
export default connect(mapStateToProps, {
  getBranchesLatest
})(Authentication(withRouter(FeeDueReport)));
