import { Button } from 'primereact/button';
import { Component } from 'react';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../../loadingComponent';
import { BasicLazyParams, convertUtc } from '../../../utile';
import { AddIcon2, AddUserIcon, ClassetEditIcon, CodeLinkIcon, InfoIcon, MailIcon, PlainCalenderIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import moment from 'moment';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { Tooltip } from 'primereact/tooltip';
import withRouter from '../../lib/withRouter';
import { connect } from 'react-redux';
import { getBranchesLatest } from '../../../store/actions';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { v4 as uuidv4 } from 'uuid';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';

class FeeSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCreateFeeScheduleDialog: false,
            feeTypes: [], // Ensure this is initialized as an empty array
            lazyParams: BasicLazyParams,
            totalRecords: 0,
            isLoading: false,
            branchId: this.props.branchData.length > 0 ? this.props.branchData[0].key : '',
            selectedFeeTypes: [],
            filteredFeeTypes: [],
            isFeePlanBased: true,
            scheduleName: '',
            isEdit: false,
            editId: '',
            FeeScheduleInfo: [],
            feeStructureInfo: [],
            globalFilter: '',
            plansData: [],
            selectedFeeStructure: null,
            selectedFeePlan: null,
            installmentData: [],
            oneTimeFees: [],
            scheduleInfo: [],
            openViewFeeScheduleDialog: false,
            viewFeeSchedule: {}
        }
        this.service = new Service();
    }
    handleViewRequest = (scheduleData) => {
        this.setState({
            viewFeeSchedule: scheduleData,
            openViewFeeScheduleDialog: true
        });
    };
    getFeeScheduleData = () => {
        let lazyParams = this.state.lazyParams;
        if (this.state.branchId) {
            this.setState({ isLoading: true });
            const academicYear = localStorage.getItem('userAcademicYear');
            // Calculate page number from first and rows
            const pageNumber = Math.floor(lazyParams.first / lazyParams.rows) + 1;
            let url = `${baseUrlAdmin}/fee-structure?academicYear=${academicYear}&branchId=${this.state.branchId}&limit=${lazyParams.rows}&page=${pageNumber}&search=${this.state.globalSearch}`;

            this.service.get(url, true)
                .then((response) => {
                    if (response?.status && response?.res?.data?.response) {
                        this.setState({
                            scheduleInfo: response?.res?.data?.response,
                            totalRecords: response?.res?.data?.totalRecords || 0,
                            isLoading: false
                        });
                    } else {
                        this.toast?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: response?.message || 'Failed to fetch fee structure',
                            life: 3000
                        });
                        this.setState({ scheduleInfo: [], isLoading: false });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching fee structure:', error);
                    this.toast?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: error.message || 'An error occurred while fetching fee structure',
                        life: 3000
                    });
                    this.setState({ isLoading: false, scheduleInfo: [] });
                });
        }
    };

    async componentDidMount() {
        await this.props.getBranchesLatest(true);
        this.getFeeStructure();
        this.getInstallmentsList();
        this.getFeeScheduleData();
    }

    getInstallmentsList = async () => {
        const AcademicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        if (this.state.branchId === '') {
            this.setState({ isLoading: false });
        } else {
            let url = `${baseUrlAdmin}/feeInstallments?academicYear=${AcademicYear}&branchId=${this.state.branchId}`;
            try {
                const res = await this.service.get(url, true);
                if (res?.res?.status && res.status) {
                    this.setState({ plansData: res?.res?.data, isLoading: false });
                } else {
                    const error = 'Some error occurred';
                    this.setState({ error, isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
                }
            } catch (e) {
                console.log(e);
                this.setState({ e, isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
            }
        }
    };

    getFeeStructure = () => {
        if (this.state.branchId) {
            this.setState({ isLoading: true });
            let url = `${baseUrlAdmin}/fee-category?academicYear=${localStorage.getItem('userAcademicYear')}&branchId=${this.state.branchId}`
            this.service.get(url, true).then((data) => {
                if (data?.status && data?.res?.data?.response) {
                    this.setState({
                        feeStructureInfo: data?.res?.data?.response,
                        // totalRecords: data?.res?.data?.totalRecords || 0,
                        isLoading: false
                    })
                } else {
                    this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Getting Fee Category', life: 3000 });
                    this.setState({ feeStructureInfo: [], isLoading: false })
                }
            }).catch((error) => {
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Getting Fee schedule', life: 3000 });
                this.setState({ isLoading: false, feeStructureInfo: [], })
            })
        }
    }

    onHandleFeeScheduleDialog = () => {
        this.setState({ openCreateFeeScheduleDialog: true, isFeePlanBased: true });
    };

    onHideCreateFeeScheduleDialog = () => {
        this.setState({
            openCreateFeeScheduleDialog: false,
            scheduleName: '',
            filteredFeeTypes: [],
            selectedFeeTypes: [],
            isEdit: false,
            selectedFeeStructure: null,
            selectedFeePlan: null,
            installmentData: [],
        });
    };

    getFeeTypes = async () => {
        try {
            this.setState({ isLoading: true });
            const academicYear = localStorage.getItem('userAcademicYear');
            if (!academicYear) {
                this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Academic year not found', life: 3000 });
                return;
            }
            const payload = { academicYear };
            const url = `${baseUrlAdmin}/fee-types/info`;
            const response = await this.service.post(url, payload, true);
            if (response?.status && Array.isArray(response?.res?.data?.response)) {
                const allFeeTypes = response.res.data.response || [];
                const filteredFeeTypes = allFeeTypes.filter(feeType =>
                    feeType.isOneTime
                );

                this.setState({
                    isLoading: false,
                    feeTypes: filteredFeeTypes,
                    originalFeeTypes: filteredFeeTypes,
                    // totalRecords: filteredFeeTypes.length || 0
                });

                if (filteredFeeTypes.length === 0) {
                    this.toast?.show({
                        severity: 'info',
                        summary: 'Information',
                        detail: 'No one-time or transport fee types found for the selected academic year',
                        life: 3000
                    });
                }
            } else {
                this.setState({ isLoading: false })
                throw new Error(response?.message || 'Failed to fetch fee types');
            }
        } catch (error) {
            console.error('Error fetching fee types:', error);
            this.setState({ feeTypes: [] });
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'An error occurred while fetching fee types',
                life: 3000
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    onEdit = async (scheduleData, isView) => {

        try {
            if (scheduleData.isFeeScheduleAssined && !isView) {
                throw new Error('Fee Schedule already assigned to students, you can\'t Update.');
            }

            if (scheduleData?.planId) {
                const selectedFeePlan = this.state.plansData.find(plan => plan._id === scheduleData.planId);
                if (!selectedFeePlan) {
                    throw new Error('Associated fee plan not found');
                }
                const feeStructure = {
                    _id: scheduleData.feeCategory,
                    feeTypes: scheduleData.stepOne?.map(fee => ({
                        ...fee,
                        isOneTime: scheduleData.stepTwo.some(
                            stepTwoFee =>
                                stepTwoFee.feeTypeId === fee.feeTypeId &&
                                stepTwoFee.isOneTime // Only check isOneTime flag
                        )
                    }))
                };
                const installmentData = selectedFeePlan.installments?.map((inst, index) => {
                    const installmentFees = scheduleData.stepTwo.filter(fee =>
                        fee.installmentId === inst.installmentId &&
                        !fee.isOneTime
                    );

                    return {
                        installment: `Installment ${index + 1}`,
                        installmentId: inst.installmentId,
                        dueDate: new Date(inst.date),
                        feeTypes: scheduleData.stepOne
                            // Filter out one-time fees from stepOne
                            .filter(fee => !scheduleData.stepTwo.some(
                                stepTwoFee =>
                                    stepTwoFee.feeTypeId === fee.feeTypeId &&
                                    stepTwoFee.isOneTime // Only check isOneTime flag
                            ))
                            ?.map(feeType => {
                                const matchingFee = installmentFees.find(f => f.feeTypeId === feeType.feeTypeId);
                                return {
                                    ...feeType,
                                    ...(matchingFee?.isTransportFeeType && { isTransportFeeType: true }),
                                    percentage: matchingFee?.termWisePercentageOrValue || 0,
                                    calculatedFee: matchingFee?.termWiseActualValue || 0
                                };
                            })
                    };
                });

                // Extract one-time fees
                const oneTimeFees = scheduleData.stepTwo
                    ?.filter(fee => fee.isOneTime) // Only check isOneTime flag
                    ?.map(fee => {
                        const originalFeeType = scheduleData.stepOne.find(
                            f => f.feeTypeId === fee.feeTypeId
                        );
                        return {
                            ...originalFeeType,
                            ...fee,
                            percentage: 100,
                            calculatedFee: fee.termWiseActualValue,
                            dueDate: new Date(fee.dueDate),
                            isOneTime: true
                        };
                    });

                // Set state with transformed data
                this.setState({
                    openCreateFeeScheduleDialog: isView ? false : true,
                    scheduleName: scheduleData.scheduleName,
                    selectedFeeStructure: feeStructure,
                    selectedFeePlan,
                    installmentData,
                    oneTimeFees,
                    isEdit: true,
                    editId: scheduleData._id,
                    branchId: scheduleData?.branchId,
                    isFeePlanBased: scheduleData?.planId ? true : false,
                    openViewFeeScheduleDialog: isView ? true : false,
                    viewFeeSchedule: scheduleData
                }, () => !scheduleData?.planId && this.getFeeTypes);
            } else {

                const oneTimeFees = scheduleData.stepTwo
                    ?.map(fee => {
                        const originalFeeType = scheduleData.stepOne.find(
                            f => f.feeTypeId === fee.feeTypeId
                        );
                        return {
                            ...originalFeeType,
                            ...fee,
                            percentage: 100,
                            calculatedFee: fee.termWiseActualValue,
                            dueDate: new Date(fee.dueDate),
                            isOneTime: true
                        };
                    });
                this.setState({
                    openCreateFeeScheduleDialog: isView ? false : true,
                    scheduleName: scheduleData.scheduleName,
                    oneTimeFees,
                    isEdit: true,
                    editId: scheduleData._id,
                    branchId: scheduleData?.branchId,
                    isFeePlanBased: scheduleData?.planId ? true : false,
                    selectedFeeTypes: scheduleData.stepOne?.map(fee => fee.feeTypeId),
                    filteredFeeTypes: scheduleData.stepTwo?.map(fee => ({
                        ...fee,
                        dueDate: new Date(fee.dueDate)
                    })),
                    openViewFeeScheduleDialog: isView ? true : false,
                    viewFeeSchedule: scheduleData
                }, () => { this.getFeeTypes(); });


            }






        } catch (error) {
            console.error('Error in onEdit:', error);
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to load fee schedule for editing',
                life: 3000
            });
        }
    };

    handleFeeTypeSelection = (e) => {
        const selectedIds = e.target.value;
        const transportFee = this.state.originalFeeTypes.find(fee => fee.isTransportFeeType && selectedIds.includes(fee._id));
        const hadTransportFee = this.state.filteredFeeTypes.some(fee => fee.isTransportFeeType);
        if (transportFee && selectedIds.length > 1) {
            this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Transport fee cannot be combined with other fee types', life: 3000 });
            return;
        }
        if (hadTransportFee && selectedIds.length > 1) {
            this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Other fee types cannot be added when transport fee is selected', life: 3000 });
            return;
        }
        const existingItems = this.state.filteredFeeTypes.filter(item => selectedIds.includes(item._id));
        const newItemIds = selectedIds.filter(id => !this.state.filteredFeeTypes.some(existing => existing._id === id));
        const newItems = this.state.originalFeeTypes.filter(feeType => newItemIds.includes(feeType._id))?.map(feeType => ({
            ...feeType,
            priority: Math.max(...existingItems?.map(item => item.priority || 0), 0) + 1,
            totalFee: feeType.totalFee || 0
        }));
        const filteredTypes = [...existingItems, ...newItems].sort((a, b) => (a.priority || 0) - (b.priority || 0));
        this.setState({ selectedFeeTypes: selectedIds, filteredFeeTypes: filteredTypes });
    };

    onPage = (event) => {
        this.setState({ lazyParams: { ...this.state.lazyParams, first: event.first, rows: event.rows } }, this.getFeeScheduleData);
    }

    sortStepTwoData = (data) => {
        return [...data].sort((a, b) => {
            // First compare by priority
            if (a.priority !== b.priority) {
                return a.priority - b.priority;
            }
            // If priorities are equal, compare by term
            return a.term - b.term;
        });
    };


    checkDatesValidity = (dates) => {
        const currentDate = moment().startOf('day');
        for (let date of dates) {
            if (moment(date).isBefore(currentDate)) {
                return false;
            }
        }
        return true;
    };

    // Modify the onhandleFeeScheduleFeePlanBased method
    onhandleFeeScheduleFeePlanBased = async () => {
        try {
            // Basic validations
            if (!this.state.branchId) {
                this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please select a branch', life: 3000 });
                return;
            }
            if (!this.state.scheduleName?.trim()) {
                this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please select schedule name', life: 3000 });
                return;
            }

            if (this.state.isFeePlanBased) {
                // Plan-based validations
                if (!this.state.selectedFeeStructure) {
                    this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please select a fee structure', life: 3000 });
                    return;
                }
                if (!this.state.selectedFeePlan) {
                    this.toast?.show({ severity: 'warn', summary: 'Warning', detail: 'Please select a fee plan', life: 3000 });
                    return;
                }

                // Collect all dates to validate
                const datesToValidate = [
                    ...this.state.installmentData?.map(inst => inst.dueDate),
                    ...this.state.oneTimeFees?.map(fee => fee.dueDate)
                ];

                // Validate dates
                // if (!this.checkDatesValidity(datesToValidate)) {
                //     throw new Error('Due dates cannot be in the past');
                // }

                // Additional validation for fee totals
                const feeTypeTotals = new Map();

                // Check transport fee percentages across installments
                const transportFeeTypes = this.state.installmentData?.[0]?.feeTypes?.filter(fee => fee.isTransportFeeType);

                if (transportFeeTypes?.length > 0) {
                    transportFeeTypes.forEach(transportFee => {
                        let totalPercentage = 0;

                        // Sum up percentages for this transport fee across all installments
                        this.state.installmentData.forEach(installment => {
                            const matchingFee = installment.feeTypes.find(fee =>
                                fee.feeTypeId === transportFee.feeTypeId &&
                                fee.isTransportFeeType
                            );
                            totalPercentage += matchingFee?.percentage || 0;
                        });

                        // Check if total percentage equals 100
                        if (totalPercentage !== 100) {
                            throw new Error(`Transport fee ${transportFee.feeType} total percentage must equal 100%. Current total: ${totalPercentage}%`);
                        }
                    });
                }

                // Calculate totals from installments
                this.state.installmentData.forEach(installment => {
                    installment.feeTypes.forEach(feeType => {
                        let feeTypeId = feeType._id ?? feeType.feeTypeId
                        const currentTotal = feeTypeTotals.get(feeTypeId) || 0;
                        feeTypeTotals.set(feeTypeId, currentTotal + (feeType.calculatedFee || 0));
                    });
                });




                // Add one-time fees to totals
                this.state.oneTimeFees.forEach(feeType => {
                    let feeTypeId = feeType._id ?? feeType.feeTypeId
                    feeTypeTotals.set(feeTypeId, feeType.calculatedFee);
                });

                // Validate each fee type's total matches the expected total
                const invalidFeeTypes = [];
                this.state.selectedFeeStructure.feeTypes.forEach(feeType => {
                    let feeTypeId = feeType._id ?? feeType.feeTypeId
                    const calculatedTotal = feeTypeTotals.get(feeTypeId) || 0;
                    if (calculatedTotal !== feeType.totalFee) {
                        invalidFeeTypes.push({
                            feeType: feeType.feeType,
                            expectedTotal: feeType.totalFee,
                            actualTotal: calculatedTotal
                        });
                    }
                });

                if (invalidFeeTypes.length > 0) {
                    const errorMessages = invalidFeeTypes?.map(fee =>
                        `${fee.feeType}: Expected ₹${fee.expectedTotal} but got ₹${fee.actualTotal}`
                    ).join('\n');

                    this.toast?.show({
                        severity: 'error',
                        summary: 'Fee Totals Mismatch',
                        detail: 'The following fee types have incorrect totals:\n' + errorMessages,
                        life: 3000,
                        sticky: true
                    });
                    return;
                }

                // Process step one (Fee structure details)
                const stepOne = this.state.selectedFeeStructure.feeTypes?.map((feeType, index) => ({
                    feeType: feeType.feeType,
                    admissionType: feeType.admissionType || [],
                    feeTypeId: feeType._id ?? feeType?.feeTypeId,
                    admissionTypes: feeType.admissionTypes || [],
                    priority: index + 1,
                    totalFee: feeType.totalFee
                }));

                // Process step two (Installments and one-time fees)
                let stepTwo = [];

                // Add regular installment fees
                this.state.installmentData.forEach((installment, installmentIndex) => {
                    installment.feeTypes.forEach(feeType => {
                        if (feeType.percentage > 0) {
                            stepTwo.push({
                                feeType: feeType.feeType,
                                feeTypeId: feeType._id ?? feeType?.feeTypeId,
                                term: installmentIndex + 1,
                                paymentTerm: `Installment ${installmentIndex + 1}`,
                                totalFee: feeType.totalFee,
                                isDifferentialFee: feeType.isDifferentialFee || false,
                                isTransportFeeType: feeType.isTransportFeeType || false,
                                admissionType: feeType.admissionType || [],
                                priority: feeType.priority || 1,
                                termWisePercentageOrValue: feeType.percentage,
                                termWiseActualValue: feeType.calculatedFee,
                                installmentId: installment.installmentId,
                                dueDate: installment.dueDate,
                                isOneTime: false
                            });
                        }
                    });
                });

                this.state.oneTimeFees.forEach(feeType => {
                    const existingInstallment = stepTwo.find(fee => fee.dueDate && fee.isOneTime && moment(fee.dueDate).isSame(feeType.dueDate, 'day'));

                    const installmentId = existingInstallment ? existingInstallment.installmentId : uuidv4();

                    this.state.oneTimeFees.forEach(fee => {
                        if (moment(fee.dueDate).isSame(feeType.dueDate, 'day')) {
                            fee.installmentId = installmentId;
                        }
                    });

                    stepTwo.push({
                        feeType: feeType.feeType,
                        feeTypeId: feeType._id ?? feeType?.feeTypeId,
                        isOneTime: true,
                        paymentTerm: "One Time",
                        totalFee: feeType.totalFee,
                        isDifferentialFee: feeType.isDifferentialFee || false,
                        isTransportFeeType: feeType.isTransportFeeType || false,
                        admissionType: feeType.admissionType || [],
                        priority: feeType.priority,
                        termWisePercentageOrValue: 100,
                        termWiseActualValue: feeType.totalFee,
                        installmentId: installmentId,
                        dueDate: feeType.dueDate,
                        term: 1
                    });
                });
                const payload = {
                    branchId: this.state.branchId,
                    scheduleName: this.state.scheduleName,
                    feeCategory: this.state.selectedFeeStructure._id,
                    academicYear: localStorage.getItem('userAcademicYear'),
                    status: true,
                    planId: this.state.selectedFeePlan._id,
                    stepOne,
                    stepTwo: this.sortStepTwoData(stepTwo)
                }

                // Log the payload
                console.log('Fee Schedule Payload:',
                    payload,
                );
                if (this.state.isEdit) {
                    this.setState({ isLoading: true });
                    const url = `${baseUrlAdmin}/fee-structure/update`;
                    payload._id = this.state.editId;
                    try {
                        const response = await this.service.put(url, payload, true);

                        if (response?.status && response?.res?.status) {
                            this.toast?.show({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Fee schedule updated successfully',
                                life: 3000
                            });
                            this.onHideCreateFeeScheduleDialog();
                            this.getFeeScheduleData(); // Refresh the list
                        } else {
                            throw new Error(response?.res?.message || 'Failed to update fee schedule');
                        }
                    } catch (apiError) {
                        console.error('API Error:', apiError);
                        this.toast?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: apiError.message || 'Failed to update fee schedule',
                            life: 3000
                        });
                    } finally {
                        this.setState({ isLoading: false });
                    }
                } else {
                    this.setState({ isLoading: true });
                    const url = `${baseUrlAdmin}/fee-structure/`;

                    try {
                        const response = await this.service.post(url, payload, true);

                        if (response?.status && response?.res?.status) {
                            this.toast?.show({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Fee schedule created successfully',
                                life: 3000
                            });
                            this.onHideCreateFeeScheduleDialog();
                            this.getFeeScheduleData(); // Refresh the list
                        } else {
                            throw new Error(response?.res?.message || 'Failed to create fee schedule');
                        }
                    } catch (apiError) {
                        console.error('API Error:', apiError);
                        this.toast?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: apiError.message || 'Failed to create fee schedule',
                            life: 3000
                        });
                    } finally {
                        this.setState({ isLoading: false });
                    }
                }

            }
        } catch (error) {
            console.error('Error in onhandleFeeSchedule:', error);
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'An error occurred while creating fee schedule',
                life: 3000
            });
        }
    };

    // Modify the onSaveNoFeePlanBased method
    onSaveNoFeePlanBased = async () => {
        try {
            // Basic validations
            if (!this.state.branchId) {
                throw new Error('Branch not selected');

            }
            if (!this.state.scheduleName?.trim()) {
                throw new Error('Schedule name not provided');

            }
            if (this.state.selectedFeeTypes?.length === 0) {
                throw new Error('No fee types selected');

            }
            this.state.filteredFeeTypes.forEach((feeType) => {
                if (feeType.totalFee === 0 && feeType?.isTransportFeeType === false) {
                    throw new Error('Total fee not provided for a fee type');

                }
                if (feeType.dueDate === null || feeType.dueDate === undefined) {
                    throw new Error('Due date not provided for a fee type');

                }
                if (feeType.totalFee && feeType.dueDate) {
                    if (feeType.totalFee < 0) {
                        throw new Error('Total fee cannot be negative');

                    }
                    if (!moment(feeType.dueDate).isValid()) {
                        throw new Error('Invalid due date');

                    }
                    if (moment(feeType.dueDate).isBefore(moment().startOf('day'))) {
                        throw new Error('Due date cannot be in the past');

                    } else {
                        feeType.installmentId = uuidv4();
                        feeType.term = 1;

                    }
                }
            });

            // Collect all dates to validate
            const datesToValidate = this.state.filteredFeeTypes?.map(fee => fee.dueDate);

            // Validate dates
            // if (!this.checkDatesValidity(datesToValidate)) {
            //     throw new Error('Due dates cannot be in the past');
            // }

            const oneTimeFeesGroupedByDate = {};
            this.state.filteredFeeTypes.forEach(feeType => {
                if (feeType.isOneTime) {
                    const dueDateKey = moment(feeType.dueDate).format('YYYY-MM-DD');
                    if (!oneTimeFeesGroupedByDate[dueDateKey]) {
                        oneTimeFeesGroupedByDate[dueDateKey] = uuidv4();
                    }
                    feeType.installmentId = oneTimeFeesGroupedByDate[dueDateKey];
                }
            });

            let payload = {
                branchId: this.state.branchId,
                scheduleName: this.state.scheduleName,
                academicYear: localStorage.getItem('userAcademicYear'),
                status: true,
                stepOne: this.state.filteredFeeTypes?.map((feeType) => {
                    return {
                        feeType: feeType.feeType,
                        admissionType: feeType.admissionType || [],
                        feeTypeId: feeType._id,
                        admissionTypes: feeType.admissionTypes || [],
                        priority: feeType.priority || 1,
                        totalFee: feeType.totalFee,
                        feeTypeId: feeType._id ?? feeType.feeTypeId
                    }
                }),
                stepTwo: this.state.filteredFeeTypes?.map((feeType) => {
                    return {
                        feeType: feeType.feeType,
                        feeTypeId: feeType._id,
                        isOneTime: feeType.isOneTime,
                        paymentTerm: "One Time",
                        totalFee: feeType.totalFee,
                        isDifferentialFee: feeType.isDifferentialFee || false,
                        isTransportFeeType: feeType.isTransportFeeType || false,
                        admissionType: feeType.admissionType || [],
                        admissionTypes: feeType.admissionTypes || [],
                        priority: feeType.priority,
                        termWisePercentageOrValue: 100,
                        termWiseActualValue: feeType.totalFee,
                        installmentId: feeType.installmentId,
                        dueDate: feeType.dueDate,
                        term: 1,
                        feeTypeId: feeType._id ?? feeType.feeTypeId
                    }
                })
            };
            if (this.state.isEdit) {
                this.setState({ isLoading: true });
                const url = `${baseUrlAdmin}/fee-structure/update`;
                payload._id = this.state.editId;
                try {
                    const response = await this.service.put(url, payload, true);

                    if (response?.status && response?.res?.status) {
                        this.toast?.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Fee schedule updated successfully',
                            life: 3000
                        });
                        this.onHideCreateFeeScheduleDialog();
                        this.getFeeScheduleData(); // Refresh the list
                    } else {
                        throw new Error(response?.res?.message || 'Failed to update fee schedule');
                    }
                } catch (apiError) {
                    console.error('API Error:', apiError);
                    this.toast?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: apiError.message || 'Failed to update fee schedule',
                        life: 3000
                    });
                } finally {
                    this.setState({ isLoading: false });
                }
            } else {
                this.setState({ isLoading: true });
                const url = `${baseUrlAdmin}/fee-structure/`;

                try {
                    const response = await this.service.post(url, payload, true);

                    if (response?.status && response?.res?.status) {
                        this.toast?.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Fee schedule created successfully',
                            life: 3000
                        });
                        this.onHideCreateFeeScheduleDialog();
                        this.getFeeScheduleData(); // Refresh the list
                    } else {
                        throw new Error(response?.res?.message || 'Failed to create fee schedule');
                    }
                } catch (apiError) {
                    console.error('API Error:', apiError);
                    this.toast?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: apiError.message || 'Failed to create fee schedule',
                        life: 3000
                    });
                } finally {
                    this.setState({ isLoading: false });
                }
            }
        }
        catch (error) {
            console.error('Error in onhandleFeeSchedule:', error);
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'An error occurred while creating fee schedule',
                life: 3000
            });
        }
    }


    onRowReorder = (e) => {
        const sourceIndex = e.dragIndex;
        const targetIndex = e.dropIndex;
        let reorderedData = [...this.state.filteredFeeTypes];
        const [removed] = reorderedData.splice(sourceIndex, 1);
        reorderedData.splice(targetIndex, 0, removed);
        const isAscending = this.checkDueDatesAscending(reorderedData);
        if (!isAscending) {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Due dates must be in ascending order. Please reorder accordingly.', life: 3000 });
            return;
        } else {
            reorderedData = reorderedData?.map((item, index) => ({ ...item, priority: index + 1 }));
            this.setState({ filteredFeeTypes: reorderedData }, () => {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Row order updated successfully', life: 3000 });
            });
        }

    };

    checkDueDatesAscending = (feeTypes) => {
        for (let i = 0; i < feeTypes.length - 1; i++) {
            const dateA = moment(feeTypes[i].dueDate);
            const dateB = moment(feeTypes[i + 1].dueDate);
            if (dateA.isAfter(dateB)) {
                return false;
            }
        }
        return true;
    };

    handleTotalFeeChange = (e, rowData) => {
        const updatedFeeTypes = this.state.filteredFeeTypes?.map((feeType) => {
            if (feeType._id === rowData._id) {
                return { ...feeType, totalFee: Number(e.target.value) };
            }
            return feeType;
        });
        this.setState({ filteredFeeTypes: updatedFeeTypes });
    };

    handleDateChange = (value, rowData) => {
        const updatedFeeTypes = this.state.filteredFeeTypes?.map((feeType) => {
            let f_id = feeType._id || feeType.feeTypeId
            let r_id = rowData._id || rowData.feeTypeId
            if (f_id === r_id) {
                return { ...feeType, dueDate: convertUtc(value) };
            }
            return feeType;
        });
        const sortedFeeTypes = this.sortFeeTypesByDate(updatedFeeTypes);
        const prioritizedFeeTypes = sortedFeeTypes?.map((feeType, index) => ({
            ...feeType,
            priority: index + 1
        }));
        this.setState({ filteredFeeTypes: prioritizedFeeTypes });
    }

    sortFeeTypesByDate = (feeTypes) => {
        return feeTypes.sort((a, b) => {
            const dateA = moment(a.dueDate);
            const dateB = moment(b.dueDate);
            if (dateA.isBefore(dateB)) return -1;
            if (dateA.isAfter(dateB)) return 1;
            return 0;
        });
    }

    handleFeeStructureChange = (e) => {
        const selectedFeeStructure = this.state.feeStructureInfo.find(structure => structure._id === e.target.value);
        this.setState({ selectedFeeStructure, }, () => { this.handleFeePlanChange({ target: { value: this.state.selectedFeePlan?._id } }); });
    }

    getInstallmentIdForDate = (dueDate) => {
        if (!this.state.selectedFeePlan?.installments) {
            return uuidv4();
        }

        // First check if this date matches any existing installment dates
        const matchingInstallment = this.state.selectedFeePlan.installments.find(inst =>
            inst?.date && moment(inst.date).format('YYYY-MM-DD') === moment(dueDate).format('YYYY-MM-DD')
        );

        if (matchingInstallment) {
            return matchingInstallment.installmentId;
        }

        // If no matching installment found, check if other one-time fees have this date
        const oneTimeFeesGrouped = {};
        this.state.oneTimeFees?.forEach(fee => {
            if (!fee?.dueDate) return;

            const dateKey = moment(fee.dueDate).format('YYYY-MM-DD');
            if (!oneTimeFeesGrouped[dateKey]) {
                oneTimeFeesGrouped[dateKey] = {
                    installmentId: fee.installmentId || uuidv4(),
                    fees: []
                };
            }
            oneTimeFeesGrouped[dateKey].fees.push(fee);
        });

        const dateKey = moment(dueDate).format('YYYY-MM-DD');
        return oneTimeFeesGrouped[dateKey]?.installmentId || uuidv4();
    };

    handleFeePlanChange = (e) => {
        const selectedFeePlan = this.state.plansData.find(plan => plan._id === e.target.value);

        if (!selectedFeePlan || !this.state.selectedFeeStructure) {
            return;
        }

        // Ensure installments exist
        if (!selectedFeePlan.installments || selectedFeePlan.installments.length === 0) {
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Selected fee plan has no installments defined',
                life: 3000
            });
            return;
        }

        // Separate one-time fees from regular fees
        const oneTimeFees = this.state.selectedFeeStructure.feeTypes.filter(fee => fee.isOneTime);
        const regularFees = this.state.selectedFeeStructure.feeTypes.filter(fee => !fee.isOneTime);

        // Map installment data using the installments array from selectedFeePlan
        const installmentData = selectedFeePlan.installments?.map((installment, index) => ({
            installment: `Installment ${index + 1}`,
            installmentId: installment.installmentId,
            dueDate: new Date(installment.date || Date.now()), // Fallback to current date if no date
            feeTypes: regularFees?.map(feeType => ({
                ...feeType,
                percentage: 0,
                calculatedFee: 0
            }))
        }));

        // Initialize one-time fees without installmentId and dueDate
        const oneTimeFeeSection = oneTimeFees?.map(feeType => ({
            ...feeType,
            percentage: 100,
            calculatedFee: feeType.totalFee
        }));

        this.setState({
            selectedFeePlan,
            installmentData,
            oneTimeFees: oneTimeFeeSection
        });
    }

    // Add method to handle one-time fee due date changes
    handleOneTimeDueDateChange = (value, feeType) => {
        let feeTypeId = feeType._id ?? feeType.feeTypeId
        const updatedOneTimeFees = this.state.oneTimeFees?.map(fee => {
            let feeTypeIdInteranal = fee._id ?? fee.feeTypeId
            if (feeTypeIdInteranal === feeTypeId) {
                const installmentId = this.getInstallmentIdForDate(value);
                return {
                    ...fee,
                    dueDate: value,
                    installmentId: installmentId
                };
            }
            return fee;
        });
        this.setState({ oneTimeFees: updatedOneTimeFees });
    };

    // Remove handleInstallmentDateChange since we're not allowing manual date changes anymore

    handlePercentageChange = (e, installmentIndex, rowData) => {
        try {
            if (rowData.isOneTime) {
                this.toast?.show({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Cannot modify percentage for one-time fees',
                    life: 3000
                });
                return;
            }

            const percentage = Number(e.target.value);
            if (percentage < 0 || percentage > 100) {
                this.toast?.show({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Percentage must be between 0 and 100',
                    life: 3000
                });
                return;
            }

            const updatedInstallmentData = [...this.state.installmentData];
            const currentInstallment = updatedInstallmentData[installmentIndex];

            if (!currentInstallment || !currentInstallment.feeTypes) {
                console.error('Invalid installment data');
                return;
            }

            // Find the specific fee type and update only that one
            const feeTypeIndex = currentInstallment.feeTypes.findIndex(fee =>
                fee.feeType === rowData.feeType &&
                fee.feeTypeId === rowData.feeTypeId
            );

            if (feeTypeIndex === -1) {
                console.error('Fee type not found');
                return;
            }

            // Calculate the fee amount based on percentage
            const calculatedFee = Math.round((rowData.totalFee * percentage) / 100);

            currentInstallment.feeTypes[feeTypeIndex] = {
                ...currentInstallment.feeTypes[feeTypeIndex],
                percentage: percentage,
                calculatedFee: calculatedFee
            };

            this.setState({ installmentData: updatedInstallmentData });
        } catch (error) {
            console.error('Error in handlePercentageChange:', error);
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update percentage',
                life: 3000
            });
        }
    };

    handleCalculatedFeeChange = (e, installmentIndex, rowData) => {
        try {
            if (rowData.isOneTime) {
                this.toast?.show({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Cannot modify calculated fee for one-time fees',
                    life: 3000
                });
                return;
            }

            // Get and validate the input value
            const newCalculatedFee = Number(e.target.value);
            if (newCalculatedFee < 0 || newCalculatedFee > rowData.totalFee) {
                this.toast?.show({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Calculated fee cannot exceed total fee',
                    life: 3000
                });
                return;
            }

            const updatedInstallmentData = [...this.state.installmentData];
            const currentInstallment = updatedInstallmentData[installmentIndex];

            if (!currentInstallment || !currentInstallment.feeTypes) {
                console.error('Invalid installment data');
                return;
            }

            // Find the specific fee type using feeTypeId
            const feeTypeIndex = currentInstallment.feeTypes.findIndex(fee =>
                fee.feeType === rowData.feeType &&
                fee.feeTypeId === rowData.feeTypeId
            );

            if (feeTypeIndex === -1) {
                console.error('Fee type not found');
                return;
            }

            // Calculate percentage based on the new calculated fee
            const percentage = Math.round((newCalculatedFee * 100) / rowData.totalFee);

            // Update the fee type with new values
            currentInstallment.feeTypes[feeTypeIndex] = {
                ...currentInstallment.feeTypes[feeTypeIndex],
                percentage: percentage,
                calculatedFee: newCalculatedFee
            };

            this.setState({ installmentData: updatedInstallmentData });
        } catch (error) {
            console.error('Error in handleCalculatedFeeChange:', error);
            this.toast?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update calculated fee',
                life: 3000
            });
        }
    };

    calculateTotalEnteredAmount = (feeType) => {
        let totalEntered = 0;

        // Get all installment fees for this specific fee type
        this.state.installmentData.forEach(installment => {
            const matchingFee = installment.feeTypes.find(
                fee => fee.feeType === feeType.feeType && fee.feeTypeId === feeType.feeTypeId
            );

            if (matchingFee && matchingFee.calculatedFee) {
                totalEntered += Number(matchingFee.calculatedFee);
            }
        });

        return totalEntered;
    };

    getAmountColor = (enteredAmount, totalAmount) => {
        if (enteredAmount === 0) return "#000000"; // Default black
        if (enteredAmount === totalAmount) return "#2196F3"; // Blue
        return "#FF0000"; // Red
    };

    renderFeeTypeSummary = () => {
        if (!this.state.selectedFeeStructure?.feeTypes) {
            return null;
        }

        // Filter out one-time fees from summary
        const installmentFeeTypes = this.state.selectedFeeStructure.feeTypes.filter(fee =>
            fee && !fee.isOneTime
        );

        if (installmentFeeTypes.length === 0) {
            return null;
        }

        return (
            <div className="mb-4 p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
                <h4 className="mb-3">Installment Fee Summary</h4>
                <div className="flex flex-wrap gap-3">
                    {installmentFeeTypes?.map(feeType => {
                        if (!feeType) return null;

                        const enteredAmount = this.calculateTotalEnteredAmount(feeType);
                        const amountColor = this.getAmountColor(enteredAmount, feeType.totalFee || 0);

                        return (
                            <div
                                key={feeType.feeTypeId}
                                className="flex align-items-center p-3"
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: '6px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    minWidth: '200px'
                                }}
                            >
                                <div className="flex-column">
                                    <div className="font-semibold mb-2">
                                        {feeType.feeType}
                                    </div>
                                    <div className="flex align-items-center gap-2">
                                        <span style={{ color: amountColor }}>
                                            ₹{enteredAmount}
                                        </span>
                                        <span>/</span>
                                        <span style={{ color: '#4CAF50' }}>
                                            ₹{feeType.totalFee || 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    clearSelectedStates = () => {
        this.setState({
            scheduleName: '',
            selectedFeeTypes: [],
            filteredFeeTypes: [],
            selectedFeeStructure: null,
            selectedFeePlan: null,
            installmentData: [],
            oneTimeFees: []
        });
    };
    onHandleAssignDialog = (each) => {
        this.setState({
            feeStructureAssignDialog: true,
            feeStructureName: each?.scheduleName,
            feeStructureDetails: each
        });
    };
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            () => {
                this.getFeeCategoryAssignInfo(dropdownsData.branchId, dropdownsData.boardId, dropdownsData.classId, dropdownsData.sectionId);
            }
        );
    };
    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        this.setState({
            isLoading: true
        });
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let payload = {
            branchId: branchId,
            boardId: boardId,
            classId: classId,
            sectionId: sectionId,
            feeStructureId: this.state.feeStructureDetails._id
        };
        payload.academicYear = AcademicYear;
        let url = `${baseUrlAdmin}/fee-structure/assignInfo`;
        this.service.post(url, payload, true).then((data) => {
            if (data.status) {
                this.setState({
                    // openCreateFeeCategoryDialog: false
                    categoryAssignInfo: data?.res?.data,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while fetching the fee Category Assigninformation', life: 3000 });
            }
        });
    };
    onHideAssignDialog = () => {
        this.setState({
            feeStructureAssignDialog: false,
            categoryAssignInfo: [],
            selectedCategoryAssignInfo: [],
            selectAll: false
        });
    };
    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedCategoryAssignInfo: this.state.categoryAssignInfo,
                selectAll: true
            });
        } else {
            this.setState({
                selectedCategoryAssignInfo: [],
                selectAll: false
            });
        }
    };
    onSelectionChange = (event) => {
        const value = event.value;
        let selectedAll = value.length === this.state.categoryAssignInfo.length;
        this.setState({
            selectedCategoryAssignInfo: event.value,
            selectAll: selectedAll
        });
    };
    feeStructureAssign = () => {
        this.setState({ isLoading: true });
        let inputKey = this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId';
        let feeTypes = [];
        this.state.feeStructureDetails?.stepOne?.map((each) => feeTypes.push(each?.feeTypeId));

        let assignInfo = this.state.selectedCategoryAssignInfo?.map((each) => {
            return {
                [inputKey]: each[inputKey]
            };
        });
        if (this.state.selectedCategoryAssignInfo?.length) {
            let payload = {
                branchId: this.state.dropdownsData.branchId,
                boardId: this.state.dropdownsData.boardId,
                classId: this.state.dropdownsData.classId,
                sectionId: this.state.dropdownsData.sectionId,
                academicYear: this.state.feeStructureDetails.academicYear,
                feeCategoryId: this.state.feeStructureDetails.feeCategory,
                feeStructureId: this.state.feeStructureDetails._id,
                feeTypes: feeTypes,
                assignedInfo: assignInfo
            };
            if (this.state.feeStructureDetails.planId) {
                payload.planId = this.state.feeStructureDetails.planId
            }
            let url = `${baseUrlAdmin}/fee-structure/assign`;
            this.setState({ isLoading: true });
            this.service
                .post(url, payload, true)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState(
                            {
                                isLoading: false,
                                feeStructureAssignDialog: false,
                                feeStructureDetails: '',
                                selectedCategoryAssignInfo: '',
                                selectAll: false,
                                categoryAssignInfo: ''
                            },
                            () => {
                                this.getFeeScheduleData();
                            }
                        );
                        this.toast.show({ severity: 'info', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: err.message, life: 3000 });
                });
        } else {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: 'Please select Atleast one record', life: 3000 });
        }

    };

    getValidDate = (dateValue) => {
        if (!dateValue) return null;
        const date = new Date(dateValue);
        return isNaN(date.getTime()) ? null : date;
    };

    render() {
        return (
            <>
                <div className="grid m-2">
                    <div className="col-12 flex justify-content-between align-items-center">
                        <div>
                            <p className="add-vehicle-field-label">
                                Branch<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    optionLabel="name"
                                    optionValue="key"
                                    width="16vw"
                                    value={this.state.branchId}
                                    options={this.props.branchData}
                                    onChange={async (e) => {
                                        this.setState({ branchId: e.target.value }, () => {
                                            this.getFeeStructure();
                                            this.getInstallmentsList();
                                            this.getFeeScheduleData();
                                        })
                                    }}
                                    placeholder={
                                        <div className="flex justify-content-start  align-items-center">
                                            <span className="">Select Branch</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <Authorizer permId={PERMISSIONS.CREATE_FEE_STRUCTURE}>
                            <Button onClick={this.onHandleFeeScheduleDialog} className="addButtonn" disabled={this.state.branchId ? false : true}>
                                <p className="">Add Fee Schedule</p>
                                <AddIcon2 />
                            </Button>
                        </Authorizer>
                    </div>
                </div>

                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.scheduleInfo}
                    dataKey="id"
                    paginator
                    lazy
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fee Schedules"
                    globalFilter={this.state.globalFilter}
                    responsiveLayout="scroll"
                    sortable
                >
                    <Column
                        header="S.No."
                        className="datatable-cel"
                        body={(rowData, index) => {
                            return (
                                <>
                                    <p>{index.rowIndex + 1}</p>
                                </>
                            );
                        }}
                    />
                    <Column field="scheduleName" header="Schedule Name" className="datatable-cel" sortable />
                    {/* <Column
                        className=" "
                        header=""
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <Authorizer permId={PERMISSIONS.UPDATE_FEE_TYPE}>
                                        <div className="flex">
                                            <>
                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                                <span
                                                    data-pr-tooltip="Edit Fee Type"
                                                    data-pr-position=""
                                                    className={`ma-ml10 awl${rowIndex}`}
                                                    onClick={() => {
                                                        this.onEdit(rowData);
                                                    }}
                                                >
                                                    <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                </span>
                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                            </>

                                            <div className=" ml-3 w-full " onClick={(e) => this.onHandleAssignDialog(rowData)}>
                                                <Authorizer permId={PERMISSIONS.ASSIGN_FEE_STRUCTURE}>
                                                    <AddUserIcon width={32} height={32} color={'#000000'} />
                                                </Authorizer>
                                            </div>

                                        </div>
                                    </Authorizer>
                                </>
                            );
                        }}
                    ></Column> */}

                    <Column
                        header="Plan / No Plan"
                        className="datatable-cel"
                        body={(rowData, index) => {
                            return (
                                <>
                                    <p>{rowData?.planId ? "Plan" : "No Plan"}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        className=" "
                        header="Actions"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex cursor-pointer">

                                    <span
                                        data-pr-tooltip="Edit Fee Type"
                                        data-pr-position=""
                                        className={`ma-ml10 awl${rowIndex}`}
                                        onClick={() => {
                                            this.onEdit(rowData);
                                        }}
                                    >
                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                    </span>

                                    <span
                                        data-pr-tooltip="View Fee Schedule"
                                        data-pr-position=""
                                        className={`ma-ml10 ml-3 mt-2 uy${rowIndex}`}
                                        onClick={() => {
                                            this.onEdit(rowData, true);
                                        }}
                                    >
                                        <i className="pi pi-eye" style={{ fontSize: '1.5rem', color: 'black' }}></i>
                                    </span>
                                    <div className=" ml-3 w-full " onClick={(e) => this.onHandleAssignDialog(rowData)}>
                                        <Authorizer permId={PERMISSIONS.ASSIGN_FEE_STRUCTURE}>
                                            <AddUserIcon width={32} height={32} color={'#000000'} />
                                        </Authorizer>
                                    </div>
                                </div>
                            );
                        }}
                    ></Column>
                </DataTable>

                <Dialog
                    isOpen={this.state.openCreateFeeScheduleDialog}
                    visible={this.state.openCreateFeeScheduleDialog}
                    draggable={false}
                    onHide={this.onHideCreateFeeScheduleDialog}
                    closable={true}
                    style={{
                        width: '80vw',
                        maxWidth: '1400px',
                        margin: '0 auto'
                    }}
                    contentStyle={{
                        height: 'auto',
                        overflow: 'auto'
                    }}
                    className="fee-schedule-dialog"
                    header={() => {
                        return (
                            <div className="p-2">
                                <h3 className="text-center formhead ">Fee Schedule</h3>
                            </div>
                        );
                    }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                >
                    <div style={{ padding: '1rem', overflow: 'auto' }}>
                        <div className="">
                            {/* Fee Schedule Type Selection */}
                            <div className="grid mb-3">
                                <div className="col-12">
                                    <Button
                                        className={`p-button-outlined p-button-primary`}
                                        label={this.state.isFeePlanBased ? "Switch to Custom Fee Types" : "Switch to Pre-defined Fee Plan"}
                                        icon={this.state.isFeePlanBased ? "pi pi-list" : "pi pi-calendar"}
                                        onClick={() => {
                                            this.setState({
                                                isFeePlanBased: !this.state.isFeePlanBased
                                            }, () => {
                                                !this.state.isFeePlanBased && this.getFeeTypes()
                                                this.clearSelectedStates();
                                            });
                                        }}
                                        style={{
                                            height: '48px',
                                            fontWeight: 500,
                                            borderRadius: '8px'
                                        }}
                                    />
                                    <small className="block mt-2 text-gray-600">
                                        {this.state.isFeePlanBased
                                            ? "Currently using pre-defined fee plan. Click to switch to custom fee types."
                                            : "Currently using custom fee types. Click to switch to pre-defined fee plan."}
                                    </small>
                                </div>
                            </div>

                            {/* Form Fields based on Fee Plan Selection */}
                            {this.state.isFeePlanBased ? (
                                <div className="grid">
                                    <div className="col-4">
                                        <label className="label">
                                            Schedule Name<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetInputText
                                                className=""
                                                height="48px"
                                                width="100%"
                                                placeholder="Schedule Name"
                                                value={this.state.scheduleName}
                                                onChange={(e) => this.setState({ scheduleName: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="label">
                                            Fee Structure<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                optionLabel="structureName"
                                                optionValue="_id"
                                                width="w-full"
                                                value={this.state.selectedFeeStructure?._id}
                                                options={this.state.feeStructureInfo}
                                                onChange={this.handleFeeStructureChange}
                                                placeholder="Select Fee Structure"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="label">
                                            Fee Plan<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                disabled={!this.state.selectedFeeStructure}
                                                optionLabel="planName"
                                                optionValue="_id"
                                                width="w-full"
                                                value={this.state.selectedFeePlan?._id}
                                                options={this.state.plansData}
                                                onChange={this.handleFeePlanChange}
                                                placeholder="Select Fee Plan"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="grid">
                                    <div className="col-6">
                                        <label className="label">
                                            Schedule Name<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetInputText
                                                className=""
                                                height="48px"
                                                width="368px"
                                                placeholder="Schedule Name"
                                                value={this.state.scheduleName}
                                                onChange={(e) => this.setState({ scheduleName: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label className="label">
                                            Fee Types<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetMultiSelect
                                                icon={<MailIcon />}
                                                id="feeTypes"
                                                className=""
                                                height="48px"
                                                width="368px"
                                                options={this.state.originalFeeTypes}
                                                optionValue="_id"
                                                optionLabel="feeType"
                                                value={this.state.selectedFeeTypes}
                                                onChange={this.handleFeeTypeSelection}
                                                placeholder="Select Fee Types"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!this.state.isFeePlanBased ? (
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.filteredFeeTypes}
                                    dataKey="feeTypeId"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                    responsiveLayout="scroll"
                                    reorderableRows
                                    onRowReorder={this.onRowReorder}

                                    removableSort
                                >
                                    <Column rowReorder style={{ width: '3em', cursor: 'move' }} />
                                    <Column
                                        style={{ width: '3em' }}
                                        header="Priority"
                                        field="priority"
                                        className="datatable-cel"
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                                </>
                                            );
                                        }}
                                    />
                                    <Column field="feeType" header="Fee Type" className="datatable-cel" />
                                    {/* <Column
                                        header="Admission Category"
                                        className="datatable-cel"
                                        field="admissionType"
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        {rowData?.admissionTypes?.map((label, index) => (
                                                            <div key={index}>{label}</div>
                                                        ))}
                                                    </div>
                                                </>
                                            );
                                        }}
                                    /> */}
                                    <Column
                                        header="Total Fee"
                                        className="datatable-cel"
                                        field=""
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <ClassetInputText
                                                        className="text-xl"
                                                        height="48px"
                                                        width="227px"
                                                        value={rowData.totalFee}
                                                        onChange={(e) => this.handleTotalFeeChange(e, rowData)}
                                                        placeholder="Total Fee"
                                                        keyfilter="int"
                                                        disabled={rowData.isTransportFeeType}
                                                    />
                                                </>
                                            );
                                        }}
                                    />
                                    <Column
                                        header="Due Date"
                                        className="datatable-cel"
                                        field=""
                                        body={(rowData, index) => {
                                            return (
                                                <ClassetCalendar
                                                    icon={<PlainCalenderIcon width={24} height={24} color={'grey'} />}
                                                    className="border-calendar"
                                                    width={'343px'}
                                                    height={'48px'}
                                                    inputClassName="font-semibold"
                                                    calendarMode={'single'}
                                                    // minDate={new Date()}
                                                    value={this.getValidDate(rowData.dueDate)}
                                                    onChange={(e) => this.handleDateChange(e.value, rowData)}
                                                    placeholder="Select Due Date"
                                                    // yearNavigator
                                                    yearRange="2000:2050"
                                                />
                                            );
                                        }}
                                    />
                                </DataTable>
                            ) : (
                                this.state.selectedFeePlan && this.state.selectedFeeStructure && (
                                    <div className="mt-4">
                                        {/* Add Fee Type Summary */}
                                        {this.renderFeeTypeSummary()}

                                        {/* One-time fees section */}
                                        {this.state.oneTimeFees?.length > 0 && (
                                            <div className="mb-4">
                                                <h4 className="text-xl font-semibold mb-2">One Time Fees</h4>
                                                <DataTable value={this.state.oneTimeFees} responsiveLayout="scroll">
                                                    <Column field="feeType" header="Fee Type" />
                                                    <Column field="totalFee" header="Total Fee" />
                                                    {/* <Column
                                                        header="Percentage"
                                                        body={() => <span>100%</span>}
                                                    /> */}
                                                    {/* <Column field="calculatedFee" header="Calculated Fee" /> */}
                                                    <Column
                                                        header="Due Date"
                                                        body={(rowData) => (
                                                            <ClassetCalendar
                                                                icon={<PlainCalenderIcon width={24} height={24} color={'grey'} />}
                                                                className="border-calendar"
                                                                width={'280px'}
                                                                height={'48px'}
                                                                inputClassName="font-semibold"
                                                                calendarMode={'single'}
                                                                value={this.getValidDate(rowData.dueDate)}
                                                                onChange={(e) => this.handleOneTimeDueDateChange((e.value), rowData)}
                                                                placeholder="Select Due Date"
                                                                // minDate={new Date()}
                                                                // yearNavigator
                                                                yearRange="2000:2050"
                                                            />
                                                        )}
                                                    />
                                                </DataTable>
                                            </div>
                                        )}

                                        {this.state.installmentData?.map((installment, installmentIndex) => {
                                            // Find matching installment from selected plan
                                            const planInstallment = this.state.selectedFeePlan?.installments?.find(
                                                i => i.installmentId === installment.installmentId
                                            );

                                            return (
                                                <div key={installmentIndex} className="mb-4">
                                                    <div className="flex align-items-center gap-3 mb-2">
                                                        <h4 className="m-0">
                                                            {installment.installment} - {planInstallment ? moment(planInstallment.date).format('DD/MM/YYYY') : 'Date not set'}
                                                        </h4>
                                                    </div>
                                                    <DataTable value={installment.feeTypes} responsiveLayout="scroll">
                                                        <Column field="feeType" header="Fee Type" />
                                                        <Column field="totalFee" header="Total Fee" />
                                                        <Column
                                                            header="Percentage"
                                                            body={(rowData) => (
                                                                <ClassetInputText
                                                                    value={rowData.percentage || 0}
                                                                    onChange={(e) => this.handlePercentageChange(e, installmentIndex, rowData)}
                                                                    placeholder="Percentage"
                                                                    keyfilter="num"
                                                                    min={0}
                                                                    max={100}
                                                                    className="p-inputtext-sm"
                                                                    style={{ width: '100px' }}
                                                                />
                                                            )}
                                                        />
                                                        <Column
                                                            header="Calculated Fee"
                                                            body={(rowData) => (
                                                                <ClassetInputText
                                                                    value={rowData.calculatedFee || 0}
                                                                    onChange={(e) => this.handleCalculatedFeeChange(e, installmentIndex, rowData)}
                                                                    placeholder="Calculated Fee"
                                                                    disabled={rowData?.isTransportFeeType}
                                                                    keyfilter="num"
                                                                    min={0}
                                                                    max={rowData.totalFee}
                                                                    className="p-inputtext-sm"
                                                                    style={{ width: '100px' }}
                                                                />
                                                            )}
                                                        />
                                                    </DataTable>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )
                            )}

                            <div className="flex justify-content-center mb-4 mt-4 p-2">
                                <Button label={this.state.isEdit ? "Update" : "Save"} className="p-button-primary submitButtonn mr-4 button text-lg " onClick={this.state.isFeePlanBased ? this.onhandleFeeScheduleFeePlanBased : this.onSaveNoFeePlanBased} />
                                <Button className="cancelButtonn w-2" onClick={this.onHideCreateFeeScheduleDialog}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>

                            {this.state.isLoading && <LoadingComponent />}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    isOpen={this.state.feeStructureAssignDialog}
                    visible={this.state.feeStructureAssignDialog}
                    style={{ width: '80vw', height: '80vh' }}
                    draggable={false}
                    onHide={this.onHideAssignDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-4">
                                <h3 className="text-center formhead">Fee Structure Assign</h3>
                            </div>
                        );
                    }}
                    footer={() => <div></div>}
                >
                    <div className="p-5">
                        <div className="">
                            <div className="grid mb-2 ">
                                <BranchToSectionDD setValues={this.setDropdownValues} />

                                <div className="col-3 ml-1">
                                    <ClassetInputText
                                        icon={<CodeLinkIcon />}
                                        id="categoryName"
                                        className="text-xl "
                                        // height="48px"
                                        // width="262px"
                                        placeholder="Structure Name"
                                        value={this.state?.feeStructureName}
                                        infoIcon={<InfoIcon />}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.categoryAssignInfo}
                            dataKey={this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId'}
                            paginator
                            rows={10}
                            // showGridlines
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            // globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"
                            // header={header}
                            sortable
                            selection={this.state?.selectedCategoryAssignInfo}
                            onSelectionChange={this.onSelectionChange}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.onSelectAllChange}
                            selectionMode="checkbox"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                            <Column field="branchName" header="Brach Name" className="datatable-cel" sortable />
                            <Column field="boardName" header="Board Name" className="datatable-cel" sortable />
                            <Column field="className" header="Grade Name" className="datatable-cel" sortable />
                            {this.state?.dropdownsData?.classId ? <Column field="sectionName" header="Section Name" className="datatable-cel" sortable /> : <></>}
                            {this.state?.dropdownsData?.sectionId ? <></> : <Column field="totalStudents" header="Total Students" className="datatable-cel" sortable />}
                            {this.state?.dropdownsData?.sectionId ? <></> : <Column field="unAssignedCount" header="Unassigned Students" className="datatable-cel" sortable />}
                            {this.state?.dropdownsData?.sectionId ? (
                                <Column
                                    field="username"
                                    header="Students"
                                    className="datatable-cel"
                                    body={(rowData) => {
                                        return <p>{rowData?.username?.slice(3)}</p>;
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </DataTable>
                        <div className="flex justify-content-center mb-4 mt-4">
                            <Button className="submitButtonn w-2 " onClick={this.feeStructureAssign}>
                                <p className="submitButtonnText">Assign</p>
                            </Button>
                            <Button className="cancelButtonn w-2 ml-3" onClick={this.onHideAssignDialog}>
                                <p className="cancelButtonnText">Cancel</p>
                            </Button>
                        </div>
                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                    <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                </Dialog>
                <Dialog
                    visible={this.state.openViewFeeScheduleDialog}
                    draggable={false}
                    closeOnEscape={true}
                    style={{
                        width: '80vw',
                        maxWidth: '1400px',
                        margin: '0 auto'
                    }}
                    dismissableMask={true}
                    closable={true}
                    header={() => (
                        <div style={{ padding: '1rem', borderBottom: '1px solid #dee2e6' }}>
                            <h3 style={{ textAlign: 'center', margin: 0, fontSize: '1.5rem', fontWeight: '600' }}>
                                View Fee Schedule Details
                            </h3>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button label="Close" className="confirmDialogCancelBtn px-6" onClick={() => this.setState({
                                openViewFeeScheduleDialog: false, viewFeeSchedule: {}, scheduleName: '', isEdit: false, editId: '', openCreateFeeScheduleDialog: false,
                                scheduleName: '',
                                filteredFeeTypes: [],
                                selectedFeeTypes: [],
                                isEdit: false,
                                selectedFeeStructure: null,
                                selectedFeePlan: null,
                                installmentData: [],
                            })} />
                        </div>
                    )}
                    onHide={() => this.setState({
                        openViewFeeScheduleDialog: false, viewFeeSchedule: {}, scheduleName: '', isEdit: false, editId: '', openCreateFeeScheduleDialog: false,
                        scheduleName: '',
                        filteredFeeTypes: [],
                        selectedFeeTypes: [],
                        isEdit: false,
                        selectedFeeStructure: null,
                        selectedFeePlan: null,
                        installmentData: [],
                    })}
                >
                    <div style={{ padding: '1rem', overflow: 'auto' }}>
                        <div className="">
                            {this.state.isFeePlanBased ? (
                                <div className="grid">
                                    <div className="col-4">
                                        <label className="label">
                                            Schedule Name<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetInputText
                                                disabled={true}
                                                className=""
                                                height="48px"
                                                width="100%"
                                                placeholder="Schedule Name"
                                                value={this.state.scheduleName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="label">
                                            Fee Structure<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                disabled={true}

                                                optionLabel="structureName"
                                                optionValue="_id"
                                                width="w-full"
                                                value={this.state.selectedFeeStructure?._id}
                                                options={this.state.feeStructureInfo}
                                                placeholder="Select Fee Structure"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="label">
                                            Fee Plan<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetDropdown
                                                disabled={true}
                                                optionLabel="planName"
                                                optionValue="_id"
                                                width="w-full"
                                                value={this.state.selectedFeePlan?._id}
                                                options={this.state.plansData}
                                                placeholder="Select Fee Plan"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="grid">
                                    <div className="col-6">
                                        <label className="label">
                                            Schedule Name<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetInputText
                                                disabled={true}

                                                className=""
                                                height="48px"
                                                width="368px"
                                                placeholder="Schedule Name"
                                                value={this.state.scheduleName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label className="label">
                                            Fee Types<span className="ma-required">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <ClassetMultiSelect
                                                disabled={true}

                                                icon={<MailIcon />}
                                                id="feeTypes"
                                                className=""
                                                height="48px"
                                                width="368px"
                                                options={this.state.originalFeeTypes}
                                                optionValue="_id"
                                                optionLabel="feeType"
                                                value={this.state.selectedFeeTypes}
                                                placeholder="Select Fee Types"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!this.state.isFeePlanBased ? (
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.filteredFeeTypes}
                                    dataKey="feeTypeId"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                    responsiveLayout="scroll"
                                >
                                    <Column
                                        style={{ width: '3em' }}
                                        header="Priority"
                                        field="priority"
                                        className="datatable-cel"
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                                </>
                                            );
                                        }}
                                    />
                                    <Column field="feeType" header="Fee Type" className="datatable-cel" />
                                    <Column
                                        header="Total Fee"
                                        className="datatable-cel"
                                        field=""
                                        body={(rowData, index) => {
                                            return (
                                                <>
                                                    <ClassetInputText
                                                        className="text-xl"
                                                        height="48px"
                                                        width="227px"
                                                        value={rowData.totalFee}
                                                        placeholder="Total Fee"
                                                        keyfilter="int"
                                                        disabled={true}
                                                    />
                                                </>
                                            );
                                        }}
                                    />
                                    <Column
                                        header="Due Date"
                                        className="datatable-cel"
                                        field=""
                                        body={(rowData, index) => {
                                            return (
                                                <ClassetCalendar
                                                    disabled={true}

                                                    icon={<PlainCalenderIcon width={24} height={24} color={'grey'} />}
                                                    className="border-calendar"
                                                    width={'343px'}
                                                    height={'48px'}
                                                    inputClassName="font-semibold"
                                                    calendarMode={'single'}
                                                    // minDate={new Date()}
                                                    value={this.getValidDate(rowData.dueDate)}
                                                    placeholder="Select Due Date"
                                                    yearNavigator
                                                    yearRange="2000:2050"
                                                />
                                            );
                                        }}
                                    />
                                </DataTable>
                            ) : (
                                this.state.selectedFeePlan && this.state.selectedFeeStructure && (
                                    <div className="mt-4">
                                        {/* Add Fee Type Summary */}
                                        {this.renderFeeTypeSummary()}

                                        {/* One-time fees section */}
                                        {this.state.oneTimeFees?.length > 0 && (
                                            <div className="mb-4">
                                                <h4 className="text-xl font-semibold mb-2">One Time Fees</h4>
                                                <DataTable value={this.state.oneTimeFees} responsiveLayout="scroll">
                                                    <Column field="feeType" header="Fee Type" />
                                                    <Column field="totalFee" header="Total Fee" />
                                                    {/* <Column
                                                        header="Percentage"
                                                        body={() => <span>100%</span>}
                                                    /> */}
                                                    {/* <Column field="calculatedFee" header="Calculated Fee" /> */}
                                                    <Column
                                                        header="Due Date"
                                                        body={(rowData) => (
                                                            <ClassetCalendar
                                                                disabled={true}

                                                                icon={<PlainCalenderIcon width={24} height={24} color={'grey'} />}
                                                                className="border-calendar"
                                                                width={'280px'}
                                                                height={'48px'}
                                                                inputClassName="font-semibold"
                                                                calendarMode={'single'}
                                                                value={this.getValidDate(rowData.dueDate)}
                                                                placeholder="Select Due Date"
                                                                // minDate={new Date()}
                                                                // showIcon
                                                                yearNavigator
                                                                yearRange="2000:2050"
                                                            />
                                                        )}
                                                    />
                                                </DataTable>
                                            </div>
                                        )}

                                        {this.state.installmentData?.map((installment, installmentIndex) => {
                                            // Find matching installment from selected plan
                                            const planInstallment = this.state.selectedFeePlan?.installments?.find(
                                                i => i.installmentId === installment.installmentId
                                            );

                                            return (
                                                <div key={installmentIndex} className="mb-4">
                                                    <div className="flex align-items-center gap-3 mb-2">
                                                        <h4 className="m-0">
                                                            {installment.installment} - {planInstallment ? moment(planInstallment.date).format('DD/MM/YYYY') : 'Date not set'}
                                                        </h4>
                                                    </div>
                                                    <DataTable value={installment.feeTypes} responsiveLayout="scroll">
                                                        <Column field="feeType" header="Fee Type" />
                                                        <Column field="totalFee" header="Total Fee" />
                                                        <Column
                                                            header="Percentage"
                                                            body={(rowData) => (
                                                                <ClassetInputText
                                                                    disabled={true}

                                                                    value={rowData.percentage || 0}
                                                                    placeholder="Percentage"
                                                                    keyfilter="num"
                                                                    min={0}
                                                                    max={100}
                                                                    className="p-inputtext-sm"
                                                                    style={{ width: '100px' }}
                                                                />
                                                            )}
                                                        />
                                                        <Column
                                                            header="Calculated Fee"
                                                            body={(rowData) => (
                                                                <ClassetInputText
                                                                    disabled={true}

                                                                    value={rowData.calculatedFee || 0}
                                                                    placeholder="Calculated Fee"
                                                                    keyfilter="num"
                                                                    min={0}
                                                                    max={rowData.totalFee}
                                                                    className="p-inputtext-sm"
                                                                    style={{ width: '100px' }}
                                                                />
                                                            )}
                                                        />
                                                    </DataTable>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )
                            )}
                            {this.state.isLoading && <LoadingComponent />}
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level === 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id
});

export default connect(mapStateToProps, {
    getBranchesLatest
})(withRouter(FeeSchedule));

<style>
    {`
    .fee-schedule-dialog .p-dialog-content {
        overflow: auto !important;
    }
    
    .fee-schedule-dialog {
        max-height: 90vh;
    }
`}
</style>

