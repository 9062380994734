import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import Service from '../../../services';
import Authentication from '../../../session';
import withRouter from '../../../lib/withRouter';
import { getBoardsData, getBranchesLatest } from '../../../../store/actions';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import BoardToSectionDDNew from '../../../BaseDropdownComponents/BoardToSectionDDNew';

class AssignExamToSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedStudents: [],
            categoryAssignInfo: [],
            selectAll: false,
            allRowsSelected: false,
            dropdownsData: null,
            preSelectedIds: [] // Add this
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.props.getBoardsData(true);
        this.props.getBranchesLatest(true);

        // Pre-select items if exam was previously assigned
        const { examToAssign } = this.props;
        if (examToAssign?.assignType && examToAssign?.assignedSectionsOrStudents?.length > 0) {
            this.setState({
                preSelectedIds: examToAssign.assignedSectionsOrStudents
            });
        }
    }

    componentDidUpdate(prevProps) {
        // If boards data just loaded
        if (!prevProps.boards?.length && this.props.boards?.length > 0) {
            // Set initial values for board and class which are disabled
            this.setDropdownValues({
                boardId: this.props.examToAssign?.boardId,
                classId: this.props.examToAssign?.classId
            });
        }
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
        }, () => {
            this.getFeeCategoryAssignInfo(
                dropdownsData?.branchId,
                dropdownsData?.boardId,
                dropdownsData?.classId,
                dropdownsData?.sectionId
            );
        });
    }

    getFeeCategoryAssignInfo = (branchId, boardId, classId, sectionId) => {
        // Only proceed if we have boardId and classId
        if (!boardId || !classId) return;

        this.setState({ isLoading: true });

        const AcademicYear = localStorage.getItem('userAcademicYear');
        const payload = {
            branchId,
            boardId,
            classId,
            sectionId,
            academicYear: AcademicYear
        };

        this.service.post(`${baseUrlAdmin}/fee-structure/assignInfo`, payload, true)
            .then(data => {
                if (data?.status) {
                    const categoryInfo = data?.res?.data;

                    // Pre-select rows if we have preSelectedIds and this is a branch selection
                    let selectedStudents = [];
                    if (this.state.preSelectedIds?.length > 0 && branchId) {
                        console.log('Pre-selection Debug:');
                        console.log('PreSelectedIds:', this.state.preSelectedIds);
                        console.log('Available sections:', categoryInfo.map(item => ({
                            sectionId: item.sectionId,
                            sectionName: item.sectionName
                        })));

                        // For sections assignment
                        if (this.props.examToAssign.assignType === 'sections' && !sectionId) {
                            selectedStudents = categoryInfo.filter(item => {
                                const isSelected = this.state.preSelectedIds.includes(item.sectionId);
                                if (isSelected) {
                                    console.log(`Found matching section: ${item.sectionName} (${item.sectionId})`);
                                }
                                return isSelected;
                            });
                        }
                        // For users assignment
                        else if (this.props.examToAssign.assignType === 'users' && sectionId) {
                            selectedStudents = categoryInfo.filter(item => {
                                const isSelected = this.state.preSelectedIds.includes(item.user_id);
                                if (isSelected) {
                                    console.log(`Found matching user: ${item.username} (${item.user_id})`);
                                }
                                return isSelected;
                            });
                        }

                        console.log('Final selected items:', selectedStudents);
                    }

                    this.setState({
                        categoryAssignInfo: categoryInfo,
                        selectedStudents,
                        selectAll: selectedStudents.length === categoryInfo.length,
                        isLoading: false
                    });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to fetch assignment info',
                        life: 3000
                    });
                }
            });
    }

    onAssignStudents = (users, assignType) => {
        const { dropdownsData } = this.state;
        const { examToAssign } = this.props;

        // Remove section validation since it's optional
        if (!dropdownsData?.classId) {
            this.toast.show({
                severity: 'error',
                summary: 'Please select a Grade',
                detail: "Grade is mandatory",
                life: 3000
            });
            return;
        }

        const payload = {
            assignIds: users,
            curriculum: {
                boardId: dropdownsData.boardId,
                classId: dropdownsData.classId
            },
            examType: examToAssign.examType,
            assignType: assignType,
            examId: examToAssign.examId
        };

        this.setState({ isLoading: true });

        this.service.post(`${baseUrlAdmin}/examination/assigntosection`, payload, true)
            .then(res => {
                if (res.status && res?.res?.success) {
                    this.toast.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: "Exam assigned successfully",
                        life: 3000
                    });

                    if (this.props.onAssignSuccess) {
                        this.props.onAssignSuccess();
                    }
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.errMessage || 'Failed to assign exam',
                        life: 3000
                    });
                }
            })
            .catch(e => {
                this.setState({ isLoading: false });
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.message,
                    life: 3000
                });
            });
    }

    render() {
        return (
            <Dialog
                visible={true}
                style={{ width: '96%', height: '96vh' }}
                modal
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={false}
                className='add-a-resource-dialog'
                header="Assign Exam"
                onHide={this.props.onClose}
            >
                <div className="p-5">
                    <div className=''>
                        <div className="grid mb-2">
                            <BoardToSectionDDNew
                                setValues={this.setDropdownValues}
                                defaultValues={{
                                    boardId: this.props.examToAssign?.boardId,
                                    classId: this.props.examToAssign?.classId
                                }}
                                disabledFields={{
                                    board: true,
                                    grade: true,
                                    branch: false,
                                    section: false
                                }}
                            />
                        </div>
                    </div>

                    <DataTable
                        value={this.state.categoryAssignInfo}
                        dataKey={this.state?.dropdownsData?.sectionId ? 'user_id' : this.state?.dropdownsData?.classId ? 'sectionId' : 'classId'}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        responsiveLayout="scroll"
                        sortable
                        selection={this.state.selectedStudents}
                        onSelectionChange={(e) => {
                            this.setState({
                                selectedStudents: e.value,
                                selectAll: e.value.length === this.state.categoryAssignInfo.length
                            });
                        }}
                        selectAll={this.state.selectAll}
                        onSelectAllChange={(e) => {
                            const allSelected = e.checked;
                            this.setState({
                                selectAll: allSelected,
                                selectedStudents: allSelected ? [...this.state.categoryAssignInfo] : []
                            });
                        }}
                        selectionMode="checkbox"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                        <Column field="branchName" header="Branch Name" className="datatable-cel" sortable />
                        {this.state?.dropdownsData?.branchId && !this.state?.dropdownsData?.sectionId &&
                            <Column field="sectionName" header="Section Name" className="datatable-cel" sortable />
                        }
                        {!this.state?.dropdownsData?.sectionId &&
                            <Column field="totalStudents" header="Total Students" className="datatable-cel" sortable />
                        }
                        {this.state?.dropdownsData?.sectionId &&
                            <Column field="username" header="Students" className="datatable-cel"
                                body={(rowData) => <p>{(rowData?.username)?.slice(3)}</p>}
                            />
                        }
                    </DataTable>

                    <div className='flex justify-content-center mb-4 mt-4'>
                        <Button
                            className='submitButtonn w-2'
                            onClick={() => {
                                if (Array.isArray(this.state?.selectedStudents)) {
                                    if (this.state.selectedStudents[0]?.user_id) {
                                        this.onAssignStudents(
                                            this.state.selectedStudents.map(i => i.user_id),
                                            "users"
                                        );
                                    } else if (this.state.selectedStudents[0]?.sectionId) {
                                        this.onAssignStudents(
                                            this.state.selectedStudents.map(i => i.sectionId),
                                            "sections"
                                        );
                                    } else {
                                        this.onAssignStudents(
                                            this.state.selectedStudents.map(i => i.classId),
                                            "grades"
                                        );
                                    }
                                }
                            }}
                        >
                            <p className='submitButtonnText'>Assign</p>
                        </Button>
                        <Button
                            className='cancelButtonn w-2 ml-3'
                            onClick={this.props.onClose}
                        >
                            <p className='cancelButtonnText'>Cancel</p>
                        </Button>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    branchLevels: state.branchDataLatest?.data?.levels,
    branchData: state.branchDataLatest?.data?.data
});

export default connect(
    mapStateToProps,
    { getBoardsData, getBranchesLatest }
)(Authentication(withRouter(AssignExamToSection)));


