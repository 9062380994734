import React, { Component } from 'react'
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD'
import { CancelRed, CrossInBox, GreenCheck, PopupGreeTick } from '../../svgIcons';
import { Column } from 'react-formio/lib/types';
import { DataTable } from 'primereact/datatable';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { BasicLazyParams } from '../../../utile';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Authentication from "../../../session"
import { getBoardsData } from '../../../store/actions';
import BranchToSectionApprovalHierarchy from '../../BaseDropdownComponents/BranchToSectionApprovalHierarchy';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
class ApprovalRequestSubstitution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            substitutionRequests: [],
            openCreateFeeDialog: false,
            searchType: 1,
            searchLevel: 1,
            searchInput: "",
            isDialogOpenForApproveRequest: false,
            isDialogOpenForRejectRequest: false,
        }
        this.service = new Service();
    }

    getSubstitutionRequests = async () => {
        this.setState({ substitutionRequests: [] });
        const academicYear = localStorage.getItem('userAcademicYear');
        const url = `${baseUrlAdmin}/timetable/get-substitution-requests`
        let payload = {
            sectionId: this.state?.dropdownsData?.sectionId,
            academicYear: academicYear,
            assignedHeirarchy: this.state?.dropdownsData?.heirarchy,
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res && res.status && res.res.status && res.res.data.length > 0) {
                this.setState({ substitutionRequests: res.res.data });
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Fetched',
                    life: 3000
                });
            } else {
                this.setState({ substitutionRequests: [] });
            }
            console.log(res);
        } catch (err) {
            console.log(err);
            this.toast.show({
                severity: 'error',
                summary: 'Invalid',
                detail: 'Some Error Occured',
                life: 3000
            });
        }
    }
    setDropdownValues = (dropdownsData) => {
        this.setState({ dropdownsData }, () => this.getSubstitutionRequests(dropdownsData))

    }
    onHandleSubstitutionApprove = async (rowData) => {
        let payload = {
            ...rowData,
            handledBy: rowData?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == rowData?.branchId)
        };
        try {
            const url = `${baseUrlAdmin}/timetable/approve-raised-request`;
            const res = await this.service.post(url, payload, true);
            if (res && res.status && res.res.status) {

                this.getSubstitutionRequests(this.state.dropdownsData);
                this.setState({ isDialogOpenForApproveRequest: false });
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Approved',
                    life: 3000
                });

            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Invalid',
                    detail: 'Some Error Occured',
                    life: 3000
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    onHandleSubstitutionReject = async (rowData) => {
        // console.log(rowData);
        let payload = {
            ...rowData,
            handledBy: rowData?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == rowData?.branchId)
        };
        try {
            const url = `${baseUrlAdmin}/timetable/reject-raised-request/${rowData._id}`;
            const res = await this.service.put(url, payload, true);
            if (res && res.status && res.res.status) {
                this.getSubstitutionRequests(this.state.dropdownsData);
                this.setState({ isDialogOpenForRejectRequest: false });
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Rejected',
                    life: 3000
                });
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Invalid',
                    detail: 'Some Error Occured',
                    life: 3000
                });

            }
        } catch (err) {
            console.log(err);
            this.toast.show({
                severity: 'error',
                summary: 'Invalid',
                detail: 'Some Error Occured',
                life: 3000
            });
        }
    }
    render() {
        return (
            <div>
                <div className='grid m-2 mb-6'>
                    <BranchToSectionApprovalHierarchy selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} approvalType={'substitutionRequest'} />
                </div>
                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.substitutionRequests}
                    dataKey="id"
                    // paginator
                    lazy
                    responsiveLayout="scroll"
                    // className="dataTableValuesText"
                    // showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                // globalFilter={this.state.globalFilter}
                >
                    <Column
                        alignHeader={'center'}

                        bodyClassName="text-center"
                        header="S.no"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex justify-content-center">
                                    {rowIndex + 1}
                                </div>
                            );
                        }}
                    ></Column>
                    <Column
                        filterField=""
                        body={(rowData) => {
                            const substitutionDate = new Date(rowData?.substitutionDate);
                            const dd = String(substitutionDate.getDate()).padStart(2, '0');
                            const mm = String(substitutionDate.getMonth() + 1).padStart(2, '0');
                            const yy = String(substitutionDate.getFullYear()).slice(-2);
                            const formattedDate = `${dd}-${mm}-${yy}`;

                            const startTime = new Date(rowData?.startTime);
                            const formattedTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                            return (
                                <div>
                                    {formattedDate} / {rowData?.Day} <br />
                                    {/* {formattedTime} */}
                                </div>
                            );
                        }}


                        bodyClassName="text-center"
                        header="Date / Day"
                        alignHeader="center"
                    />
                    <Column filterField="" field="name" bodyClassName="text-center" header="Period No" alignHeader={'center'} />

                    <Column filterField="" body={(rowData) => {
                        return (<div>{rowData.Teacher.name} / {rowData.Subject?.subjectName}</div>)
                    }} bodyClassName="text-center" header="Teacher / Subject" alignHeader={'center'} />

                    <Column filterField="" body={(rowData, { rowIndex }) => {
                        return (
                            <div className="flex justify-content-center">
                                {rowData.substitutionTeacher?.name} / {rowData.substitutionSubject?.subjectName}
                            </div>
                        );
                    }} bodyClassName="text-center" header="Substitute Teacher / Subject" alignHeader={'center'} />
                    {/* <Column filterField="" field="Day"  bodyClassName="text-center" header="Day" alignHeader={'center'} /> */}


                    <Column filterField="" field="reason" bodyClassName="text-center" header="Reason" alignHeader={'center'} />
                    <Column
                        style={{ width: '15rem', height: '6rem' }}
                        alignHeader={'center'}

                        headerClassName="text-color-secondary"
                        bodyClassName="text-center"
                        className=" "
                        header="Approve / Reject"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    {rowData?.isRefundApproved == undefined && (
                                        <div className="flex justify-content-evenly">
                                            <div className="cursor-pointer" onClick={() => {
                                                this.setState({ isDialogOpenForApproveRequest: true, rowData: rowData });
                                                // this.onHandleSubstitutionApprove(rowData, true)
                                            }}>
                                                <GreenCheck />
                                            </div>

                                            <div className="cursor-pointer" onClick={() => {
                                                this.setState({ rowData: rowData, isDialogOpenForRejectRequest: true })
                                                // this.onHandleSubstitutionReject(rowData, false)
                                            }}>
                                                <CancelRed />
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>
                <Dialog
                    visible={this.state.isDialogOpenForApproveRequest}
                    style={{ width: 500 }}
                    header={() => (
                        <div className="mt-2 formHeadingInter">
                            <div className="mb-3">
                                <PopupGreeTick />
                            </div>
                            <p style={{ fontWeight: 'bold', fontSize: 20 }}>Approve Status Change</p>
                        </div>
                    )}
                    footer={() => (
                        <div className="" style={{ display: 'flex' }}>
                            <Button style={{ borderRadius: 10, backgroundColor: '#076EFF', width: '170%', padding: 0, justifyContent: 'center', alignItems: 'center' }} onClick={() => this.onHandleSubstitutionApprove(this.state.rowData, false)}>
                                <p className="text-xl ml-2">Accept</p>
                            </Button>
                            <Button
                                style={{ borderRadius: 10, backgroundColor: '#E0E0E0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 0 }}
                                onClick={() => this.setState({ isDialogOpenForApproveRequest: false })}
                            >
                                <p className="text-xl">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForApproveRequest: false })}
                >
                    <p className="topicName ml-6" style={{ color: 'black', alignSelf: 'center', paddingBottom: 10 }}>
                        <p>Subject Name : {this.state.rowData?.substitutionSubject?.subjectName}</p>
                        <p>Original Teacher Name: {this.state.rowData?.Teacher?.name}</p>
                        <p>Substitute Teacher Name : {this.state.rowData?.substitutionTeacher?.name}</p>
                        {/* <p>Substitute Date : {this.state.rowData?.substitutionDate}</p> */}
                        <p>Reason : {this.state.rowData?.reason}</p>
                    </p>
                </Dialog>
                <Dialog
                    visible={this.state.isDialogOpenForRejectRequest}
                    style={{ width: 500 }}
                    header={() => (
                        <div className="mt-2 formHeadingInter">
                            <div className="mb-3">
                                <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                            </div>
                            <p style={{ fontWeight: 'bold', fontSize: 20 }}>Reject Status Change</p>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex justify-content-center ">
                            <Button className="formSubmitButton px-6" style={{ border: 0 }} onClick={() => this.onHandleSubstitutionReject(this.state.rowData, false)}>
                                <p className="formSubmitButtonText">Reject</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isDialogOpenForRejectRequest: false })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForRejectRequest: false })}
                >
                    <p className="topicName ml-6" style={{ color: 'black', alignSelf: 'center', paddingBottom: 10 }}>
                        <p>Subject Name : {this.state.rowData?.substitutionSubject?.subjectName}</p>
                        <p>Original Teacher Name: {this.state.rowData?.Teacher?.name}</p>
                        <p>Substitute Teacher Name : {this.state.rowData?.substitutionTeacher?.name}</p>
                        {/* <p>Substitute Date : {this.state.rowData?.substitutionDate}</p> */}
                        <p>Reason : {this.state.rowData?.reason}</p>
                    </p>
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(ApprovalRequestSubstitution)));