import React, { Component } from 'react';
import { ApproveConcessionIcon, ApproveRefundIcon, BankIcon, CollectFeeIcon, DashBoardIcon, MoneyIcon, RefundIcon, ReportsIcon, FilterIcon, FilterIcon2, upArrow, UpArrow, DownArrow } from '../svgIcons';
import GridLayout from 'react-grid-layout';
import ReactApexChart from 'react-apexcharts';
import { SelectButton } from 'primereact/selectbutton';
import './financeDashboard.css';
import withRouter from '../lib/withRouter';
import Authentication from '../../session';
import { connect } from 'react-redux';
import BranchToClassDD from "../BaseDropdownComponents/BranchToClassDD";
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import { Dialog } from 'primereact/dialog';
class FinanceDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            financeData: null,
            series1: [{
                name: 'Fee Collected',
                data: []
            }],
            series2: [],
            series3: [],
            options1: {
                chart: {
                    type: 'bar',
                    parentHeightOffset: 0,
                    toolbar: { show: false },
                    redrawOnParentResize: true,
                    background: 'transparent',
                    height: '85%',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                        distributed: true,
                        columnWidth: '10%',
                        barHeight: '70%',
                        endingShape: 'rounded'
                    }
                },
                colors: ['#962DFF', '#E0C6FD', '#93AAFD', '#4A3AFF'],
                dataLabels: { enabled: false },
                legend: { show: false },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', "May", "Jun", "Jul", "Aug"],
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return (value / 1000000).toFixed(1) + 'M';
                        },
                        style: {
                            colors: '#9291A5',
                            fontSize: '12px',
                            font: "normal 400 12px InterSemibold",
                        }
                    }
                },
                grid: {
                    show: true,
                    borderColor: "#E5E5EF",
                    strokeDashArray: 5, 
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false 
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true 
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }
                },
            },
            options2: {
                chart: {
                    type: 'pie',
                    width: '400',
                    height: '400',
                    parentHeightOffset: 0,
                    offsetX: 0,
                    offsetY: 30
                },
                colors: ['#4A3AFF', '#962DFF', '#93AAFD'],
                labels: ['Granted', 'Rejected', 'Pending'],
                legend: {
                    show: true,
                    position: 'right',
                    fontSize: '12px',
                    fontFamily: 'InterRegular',
                    labels: {
                        colors: '#1E1B39',
                        useSeriesColors: false
                    },
                    formatter: function(seriesName, opts) {
                        return `<span style="font: normal 400 12px/14px InterRegular">${seriesName}</span>`;
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        radius: 6
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 5
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        customScale: 0.9,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    },
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                chart: {
                    animations: {
                        dynamicAnimation: {
                            enabled: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                noData: {
                    text: 'No Data Available',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#000',
                        fontSize: '14px',
                        fontFamily: 'Inter',

                    }
                },
            },
            options3: {
                chart: {
                    type: 'pie',
                    width: '400',
                    height: '400',
                    parentHeightOffset: 0,
                    offsetX: 0,
                    offsetY: 30
                },
                colors: ['#4A3AFF', '#962DFF', '#93AAFD', '#E0C6FD'],
                labels: ['Cash', 'Cheque/DD', 'Online Banking', 'QR/UPI'],
                legend: {
                    show: true,
                    position: 'right',
                    fontSize: '14px',
                    fontFamily: 'InterRegular',
                    labels: {
                        colors: '#1E1B39',
                        useSeriesColors: false
                    },
                    formatter: function(seriesName, opts) {
                        return `<span style="font: normal 400 12px/14px InterRegular">${seriesName}</span>`;
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        radius: 6
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 5
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        customScale: 0.9,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    },
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                chart: {
                    animations: {
                        dynamicAnimation: {
                            enabled: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                noData: {
                    text: 'No Data Available',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#000',
                        fontSize: '14px',
                        fontFamily: 'Inter',

                    }
                },
            },
            containerWidth: 0,
            timestamp: Date.now(),
            layouts: [
                { i: 'chart1', x: 0, y: 0, w: 7, h: 4, minW: 6, maxW: 12, minH: 4 }, 
                { i: 'chart2', x: 8, y: 0, w: 5, h: 4, minW: 4, maxW: 12, minH: 4 },
                { i: 'chart3', x: 0, y: 5, w: 5, h: 4, minW: 4, maxW: 12, minH: 4 }
            ],
            periodOptions: [
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' }
            ],
            analysisOptions: [
                { label: 'Value', value: 'value' },
                {label: 'Transactions', value: 'transactions'}
            ],
            selectedPeriods: {
                chart1: 'daily',
                chart2: 'daily',
                chart3: 'value'
            },
            chartData: {
                daily: { data: [], categories: [] },
                weekly: { data: [], categories: [] },
                monthly: { data: [], categories: [] }
            },
            showFilterDialog: false,
            savedDropdownValues: null,
        };
        this.containerRef = React.createRef();
        this.service = new Service();
    }

    formatNumberWithCommas = (number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0";
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
        // Initialize with default values
        const defaultDropdownData = {
            branchId: null,
            boardId: null,
            classId: null
        };
        this.setState({ dropdownsData: defaultDropdownData, branchInfo: this.props.branchData }, () => {
            this.fetchInitialData();
        });
    }

    fetchInitialData = async () => {
        try {
            this.setState({ isLoading: true });
            let academicYear = localStorage.getItem("userAcademicYear");
            const url = `${baseUrlAdmin}/financeDashboard/get-dashboard`;
            let branchInfo = this.props.branchData.filter((each) => each.level == 1).map((each) => {
                return each.key
            });
            const payload = {
                academicYear: academicYear,
                branchId: branchInfo
            };

            const response = await this.service.post(url, payload, true);

            if (response.status && response.res.status) {
                const dashboardData = response.res.data.data;
                this.setState({
                    financeData: dashboardData,
                    dropdownsData: {
                        ...this.state.dropdownsData,
                        branchId: dashboardData.branches?.[0]?.branchId || null
                    }
                }, () => this.onFilterData());
            }
        } catch (error) {
            console.error("API Error:", error);
        } finally {
            this.setState({ isLoading: false });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if (this.containerRef.current) {
            const width = this.containerRef.current.offsetWidth;
            this.setState({ containerWidth: width });
        }
    };

    onLayoutChange = (layout) => {
        const newLayout = layout.map((item, index) => {
            const otherItems = layout.filter((_, i) => i !== index);
            let { x, y, w, h } = item;

            if (x + w > 12) {
                w = 12 - x;
            }

            otherItems.forEach(other => {
                if (this.isOverlapping(item, other)) {
                    y = other.y + other.h;
                }
            });

            return { ...item, x, y, w, h };
        });

        this.setState({ layouts: newLayout });
    };

    isOverlapping = (item1, item2) => {
        return !(item1.x + item1.w <= item2.x ||
            item2.x + item2.w <= item1.x ||
            item1.y + item1.h <= item2.y ||
            item2.y + item2.h <= item1.y);
    };

    toggleFilterDialog = () => {
        this.setState(prev => ({
            showFilterDialog: !prev.showFilterDialog,
            savedDropdownValues: !prev.showFilterDialog ? null : prev.dropdownsData
        }));
    }

    renderFilterIcon = () => (
        <div className="filter-icon-container">
            <div className="filter-icon" onClick={this.toggleFilterDialog}>
                <div className="filter-svg">
                    <FilterIcon2 color={"#fff"} />
                </div>
            </div>
            {this.state.showFilterDialog && (
                <Dialog
                    visible={this.state.showFilterDialog}
                    onHide={this.toggleFilterDialog}
                    draggable={false}
                    style={{ width: '400px', height: 'auto' }}
                    header={<h4 className='chart-Title text-center'>Filters</h4>}
                >
                    <BranchToClassDD
                        setValues={this.setDropdownValues}
                        rowData={this.state.rowData || { value: [] }} // Ensure default value
                        initialValues={this.state.dropdownsData}
                    />
                </Dialog>
            )}
        </div>
    );

    renderSelectButton = (chartId) => {
        const handleChange = (e) => {
            if (e.originalEvent) {
                e.originalEvent.preventDefault();
                e.originalEvent.stopPropagation();
            }

            const newValue = e.value;
            if (newValue) {
                this.setState(prevState => {
                    const newSelectedPeriods = {
                        ...prevState.selectedPeriods,
                        [chartId]: newValue
                    };

                    let newSeries = prevState.series1;
                    let newOptions = prevState.options1;

                    if (chartId === 'chart1') {
                        newSeries = [{
                            name: 'Fee Collected',
                            data: prevState.chartData[newValue].data
                        }];
                        newOptions = {
                            ...prevState.options1,
                            xaxis: {
                                ...prevState.options1.xaxis,
                                categories: prevState.chartData[newValue].categories
                            }
                        };
                    }

                    return {
                        selectedPeriods: newSelectedPeriods,
                        series1: chartId === 'chart1' ? newSeries : prevState.series1,
                        options1: chartId === 'chart1' ? newOptions : prevState.options1
                    };
                });
            }
        };

        return (
            <div
                className="select-button-wrapper"
                onClick={e => e.stopPropagation()}
                onMouseDown={e => e.stopPropagation()}
            >
                <SelectButton
                    value={this.state.selectedPeriods[chartId]}
                    options={this.state.periodOptions}
                    onChange={handleChange}
                    className="period-selector"
                    optionLabel="label"
                    optionValue="value"
                    unselectable={false}
                />
            </div>
        );
    };

    renderPaymentModeButton = (chartId) => {
        const handleChange = (e) => {
            if (e.originalEvent) {
                e.originalEvent.preventDefault();
                e.originalEvent.stopPropagation();
            }

            const newValue = e.value;
            if (newValue) {
                // Update payment modes chart based on selection
                const { filteredData } = this.state;
                const paymentModes = filteredData?.charts?.paymentModes;
                
                if (paymentModes) {
                    const nonZeroModes = Object.entries(paymentModes.details)
                        .filter(([_, data]) => {
                            const value = newValue === 'value' ? data.amount : data.count;
                            return value > 0;
                        })
                        .map(([key, data]) => ({
                            label: key.toUpperCase().replace(/_/g, ' '),
                            value: newValue === 'value' ? data.amount : data.count
                        }));

                    this.setState({
                        selectedPeriods: {
                            ...this.state.selectedPeriods,
                            [chartId]: newValue
                        },
                        series3: nonZeroModes.map(mode => mode.value),
                        options3: {
                            ...this.state.options3,
                            labels: nonZeroModes.map(mode => mode.label),
                            tooltip: {
                                ...this.state.options3.tooltip,
                                custom: function ({ series, seriesIndex, w }) {
                                    return `<div class="custom-tooltip">
                                        <span>${w.config.labels[seriesIndex]}: ${series[seriesIndex]} ${newValue === 'value' ? '₹' : ' transactions'}</span>
                                    </div>`;
                                }
                            }
                        }
                    });
                }
            }
        };

        return (
            <div
                className="select-button-wrapper"
                onClick={e => e.stopPropagation()}
                onMouseDown={e => e.stopPropagation()}
            >
                <SelectButton
                    value={this.state.selectedPeriods[chartId]}
                    options={this.state.analysisOptions}
                    onChange={handleChange}
                    className="period-selector"
                    optionLabel="label"
                    optionValue="value"
                    unselectable={false}
                />
            </div>
        );
    };

    renderFeeCollectedHeader = (chartId) => (
        <div className="flex flex-column ">
            <h4 className="chart-Title">Fee Collected</h4>
            <div className="flex flex-row justify-content-start align-items-center">
                <div className="flex align-items-center gap-2">
                    <span className="chart-sub-tilte">Analysis</span>
                </div>
                <div className="flex align-items-center ml-auto">
                    {this.renderSelectButton(chartId)}
                    <div
                        className="select-button-wrapper prevent-grid-drag"
                        onMouseDown={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.props.navigate('/feecollectionreport');
                        }}
                        style={{ cursor: 'pointer', position: 'relative', zIndex: 1001 }}
                    >
                        <ReportsIcon />
                    </div>
                </div>
            </div>
        </div>
    );

    renderConcessionsHeader = (chartId) => (
        <div className="flex flex-column gap-2">
            <div className="flex justify-content-start align-items-center">
                <h4 className="chart-Title">Concessions</h4>
            </div>
            <div className="flex flex-row justify-content-start align-items-center ">
                <div className="flex align-items-center gap-2">
                    <span className="chart-sub-tilte">Overview</span>
                </div>
                <div className="flex align-items-center gap-2 ml-auto">
                    <div
                        className="select-button-wrapper prevent-grid-drag"
                        onMouseDown={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.props.navigate('/feeconcessionreport');
                        }}
                        style={{ cursor: 'pointer', position: 'relative', zIndex: 1001 }}
                    >
                        <ReportsIcon />
                    </div>
                </div>
            </div>
        </div>
    );

    renderPaymentModesHeader = (chartId) => (
        <div className="flex flex-column ">
            <h4 className="chart-Title">Payment Mode</h4>
            <div className="flex flex-row justify-content-start align-items-center">
                <div className="flex align-items-center gap-2">
                    <span className="chart-sub-tilte">Analysis</span>
                </div>
                <div className="flex align-items-center ml-auto">
                    {this.renderPaymentModeButton(chartId)}
                    <div
                        className="select-button-wrapper prevent-grid-drag"
                        onMouseDown={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.props.navigate('/feecollectionreport');
                        }}
                        style={{ cursor: 'pointer', position: 'relative', zIndex: 1001 }}
                    >
                        <ReportsIcon />
                    </div>
                </div>
            </div>
        </div>
    );

    onFilterData = () => {
        const { dropdownsData, financeData } = this.state;
        if (!dropdownsData || !financeData) return;

        let dashboardData = {
            summaryCards: financeData.dashboardData.summaryCards || {},
            charts: financeData.dashboardData.charts || {}
        };

        try {
            const branch = financeData.branches?.find(b => b.branchId === dropdownsData.branchId);

            if (branch) {
                if (!dropdownsData.boardId) {
                    dashboardData = {
                        summaryCards: branch.branchData.summaryCards || {},
                        charts: branch.branchData.charts || {}
                    };
                } else {
                    const board = branch.boards?.find(b => b.boardId === dropdownsData.boardId);
                    if (board) {
                        if (!dropdownsData.classId?.length) {
                            dashboardData = {
                                summaryCards: board.boardData.summaryCards || {},
                                charts: board.boardData.charts || {}
                            };
                        } else {
                            // Sum up data for multiple selected classes
                            const selectedClasses = board.classes?.filter(c => dropdownsData.classId.includes(c.classId)) || [];
                            
                            // Sum up summary cards
                            const summaryCards = {
                                feeCollection: selectedClasses.reduce((sum, c) => sum + (c.dashboardData.summaryCards.feeCollection || 0), 0),
                                feeConcession: selectedClasses.reduce((sum, c) => sum + (c.dashboardData.summaryCards.feeConcession || 0), 0),
                                bankReconciliation: selectedClasses.reduce((sum, c) => sum + (c.dashboardData.summaryCards.bankReconciliation || 0), 0),
                                totalRefund: selectedClasses.reduce((sum, c) => sum + (c.dashboardData.summaryCards.totalRefund || 0), 0)
                            };

                            // Sum up chart data
                            const periods = ['daily', 'weekly', 'monthly'];
                            const feeCollection = {};
                            
                            periods.forEach(period => {
                                const labels = selectedClasses[0]?.dashboardData.charts.feeCollection[period].labels || [];
                                const data = Array(labels.length).fill(0);
                                
                                selectedClasses.forEach(classData => {
                                    const classChartData = classData.dashboardData.charts.feeCollection[period].data[0] || [];
                                    classChartData.forEach((value, index) => {
                                        data[index] = (data[index] || 0) + (value || 0);
                                    });
                                });

                                feeCollection[period] = {
                                    labels,
                                    data: [data],
                                    total: data.reduce((sum, val) => sum + val, 0)
                                };
                            });

                            // Sum up concessions data
                            const concessions = {
                                data: [0, 0, 0],
                                labels: ['Granted', 'Rejected', 'Pending'],
                                details: {
                                    granted: { count: 0, amount: 0 },
                                    rejected: { count: 0, amount: 0 },
                                    pending: { count: 0, amount: 0 }
                                }
                            };

                            selectedClasses.forEach(classData => {
                                const classConcessions = classData.dashboardData.charts.concessions;
                                classConcessions.data.forEach((value, index) => {
                                    concessions.data[index] += value;
                                });
                                
                                ['granted', 'rejected', 'pending'].forEach(status => {
                                    concessions.details[status].count += classConcessions.details[status].count;
                                    concessions.details[status].amount += classConcessions.details[status].amount;
                                });
                            });

                            // Sum up payment modes data
                            const paymentModes = {
                                labels: [],
                                data: [],
                                details: {
                                    cash: { count: 0, amount: 0 },
                                    online: { count: 0, amount: 0 },
                                    credit_card: { count: 0, amount: 0 },
                                    debit_card: { count: 0, amount: 0 },
                                    qr_code: { count: 0, amount: 0 },
                                    cheque_dd: { count: 0, amount: 0 }
                                },
                                total: 0
                            };

                            // Combine data from all selected classes
                            selectedClasses.forEach(classData => {
                                const classPaymentModes = classData.dashboardData.charts.paymentModes;
                                classPaymentModes.labels.forEach((label, index) => {
                                    const value = classPaymentModes.data[index] || 0;
                                    const key = label.toLowerCase().replace(/ /g, '_');
                                    
                                    if (paymentModes.details[key]) {
                                        paymentModes.details[key].amount += value;
                                        paymentModes.details[key].count += classPaymentModes.details[key]?.count || 0;
                                    }
                                });
                            });

                            // Filter out zero values and create final arrays
                            const viewMode = this.state.selectedPeriods.chart3 || 'value';
                            const nonZeroModes = Object.entries(paymentModes.details)
                                .filter(([_, data]) => {
                                    const value = viewMode === 'value' ? data.amount : data.count;
                                    return value > 0;
                                })
                                .map(([key, data]) => ({
                                    label: key.toUpperCase().replace(/_/g, ' '),
                                    value: viewMode === 'value' ? data.amount : data.count
                                }));

                            paymentModes.labels = nonZeroModes.map(mode => mode.label);
                            paymentModes.data = nonZeroModes.map(mode => mode.value);
                            paymentModes.total = nonZeroModes.reduce((sum, mode) => sum + mode.value, 0);

                            dashboardData = {
                                summaryCards,
                                charts: {
                                    feeCollection,
                                    concessions,
                                    paymentModes
                                }
                            };
                        }
                    }
                }
            }

            // Ensure data is properly formatted
            const newChartData = {
                daily: {
                    data: Array.isArray(dashboardData.charts?.feeCollection?.daily?.data?.[0])
                        ? dashboardData.charts.feeCollection.daily.data[0]
                        : [],
                    categories: Array.isArray(dashboardData.charts?.feeCollection?.daily?.labels)
                        ? dashboardData.charts.feeCollection.daily.labels
                        : []
                },
                weekly: {
                    data: Array.isArray(dashboardData.charts?.feeCollection?.weekly?.data?.[0])
                        ? dashboardData.charts.feeCollection.weekly.data[0]
                        : [],
                    categories: Array.isArray(dashboardData.charts?.feeCollection?.weekly?.labels)
                        ? dashboardData.charts.feeCollection.weekly.labels
                        : []
                },
                monthly: {
                    data: Array.isArray(dashboardData.charts?.feeCollection?.monthly?.data?.[0])
                        ? dashboardData.charts.feeCollection.monthly.data[0]
                        : [],
                    categories: Array.isArray(dashboardData.charts?.feeCollection?.monthly?.labels)
                        ? dashboardData.charts.feeCollection.monthly.labels
                        : []
                }
            };

            // Ensure series2 data is an array
            const concessionData = Array.isArray(dashboardData.charts?.concessions?.data) &&
                dashboardData.charts?.concessions?.data.length > 0 &&
                dashboardData.charts?.concessions?.data.some(val => val > 0)
                ? dashboardData.charts.concessions.data
                : [];
            const concessionLabels = Array.isArray(dashboardData.charts?.concessions?.labels)
                ? dashboardData.charts.concessions.labels
                : ['Granted', 'Rejected', 'Pending'];

            this.setState({
                filteredData: dashboardData,
                chartData: newChartData,
                series2: concessionData,
                series3: Array.isArray(dashboardData.charts?.paymentModes?.data) 
                    ? dashboardData.charts.paymentModes.data 
                    : [],
                options2: {
                    ...this.state.options2,
                    labels: concessionLabels,
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                        fillSeriesColor: false,
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Inter'
                        },
                        custom: function ({ series, seriesIndex, w }) {
                            return `<div class="custom-tooltip">
                                <span>${w.config.labels[seriesIndex]}: ${series[seriesIndex]} </span>
                            </div>`;
                        }
                    }
                },
                options3: {
                    ...this.state.options3,
                    labels: Array.isArray(dashboardData.charts?.paymentModes?.labels)
                        ? dashboardData.charts.paymentModes.labels
                        : [],
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                        fillSeriesColor: false,
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Inter'
                        },
                        custom: function ({ series, seriesIndex, w }) {
                            return `<div class="custom-tooltip">
                                <span>${w.config.labels[seriesIndex]}: ${series[seriesIndex]} </span>
                            </div>`;
                        }
                    }
                }
            }, () => {
                const { selectedPeriods } = this.state;
                const currentPeriod = selectedPeriods.chart1;

                this.setState(prevState => ({
                    series1: [{
                        name: 'Fee Collected',
                        data: newChartData[currentPeriod].data
                    }],
                    options1: {
                        ...prevState.options1,
                        xaxis: {
                            ...prevState.options1.xaxis,
                            categories: newChartData[currentPeriod].categories
                        }
                    }
                }));
            });

        } catch (error) {
            console.error("Error in filtering data:", error);
        }
    }

    updateChartData = (data) => {
        if (!data?.charts) return;

        const { selectedPeriods } = this.state;
        const currentPeriod = selectedPeriods.chart1;

        const newChartData = {
            daily: {
                data: data.charts?.feeCollection?.daily?.data || [],
                categories: data.charts?.feeCollection?.daily?.categories || []
            },
            weekly: {
                data: data.charts?.feeCollection?.weekly?.data || [],
                categories: data.charts?.feeCollection?.weekly?.categories || []
            },
            monthly: {
                data: data.charts?.feeCollection?.monthly?.data || [],
                categories: data.charts?.feeCollection?.monthly?.categories || []
            }
        };

        this.setState({
            chartData: newChartData,
            series1: [{
                name: 'Fee Collected',
                data: newChartData[currentPeriod].data || []
            }],
            series2: data.charts?.concessions?.data || [],
            series3: data.charts?.paymentModes?.data || [25, 30, 25, 20]
        });
    };

    setDropdownValues = (dropdownsData) => {
        this.setState({ 
            dropdownsData: dropdownsData,
            savedDropdownValues: dropdownsData
        }, () => this.onFilterData());
    }

    getTrendData = () => {
        const { chartData, selectedPeriods } = this.state;
        const currentPeriod = selectedPeriods.chart1;
        const data = chartData[currentPeriod].data;
        const categories = chartData[currentPeriod].categories;

        if (!data || !data.length) return [];

        const total = data.reduce((sum, value) => sum + value, 0);

        return data.map((value, index) => {
            let isIncrease;
            let percentageChange;

            if (index === 0) {
                const nextValue = data[index + 1];
                if (nextValue !== undefined) {
                    percentageChange = value === 0 ? 
                        (nextValue > 0 ? 100 : 0) : 
                        ((nextValue - value) / value) * 100;
                    isIncrease = nextValue < value;
                } else {
                    percentageChange = 0;
                    isIncrease = false;
                }
            } else {
                const previousValue = data[index - 1];
                percentageChange = previousValue === 0 ? 
                    (value > 0 ? 100 : 0) : 
                    ((value - previousValue) / previousValue) * 100;
                isIncrease = value > previousValue;
            }

            const percentageOfTotal = total ? (value / total) * 100 : 0;

            return {
                label: categories[index] || '',
                value: value,
                percentage: percentageOfTotal,
                change: percentageChange,
                isIncrease: isIncrease,
                color: this.state.options1.colors[index % this.state.options1.colors.length]
            };
        });
    };

    render() {
        const { filteredData } = this.state;
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#E6E8EA',
                padding: '0',
                marginLeft: "100px",
                marginTop: "50px",
                overflow: 'auto'
            }}>
                <div className="finance-dashboard-container">

                    <div className="dashboard-main-section">
                        <div className='dashboard-summary-cards'>
                            <div className='cards'>
                                <div className="finance-card">
                                    <div className="finance-icon-group">
                                        <MoneyIcon className="finance-icon" />
                                        <span className="finance-label">Collected Fee</span>
                                    </div>
                                    <div className="finance-divider"></div>
                                    <div className="finance-amount">
                                        {this.formatNumberWithCommas(filteredData?.summaryCards?.feeCollection)}
                                    </div>
                                </div>
                                <div className="finance-card">
                                    <div className="finance-icon-group">
                                        <BankIcon className="finance-icon" />
                                        <span className="finance-label">Bank Reconcilation - Cash</span>
                                    </div>
                                    <div className="finance-divider"></div>
                                    <div className="finance-amount">
                                        {this.formatNumberWithCommas(filteredData?.summaryCards?.bankReconciliation)}
                                    </div>
                                </div>
                            </div>
                            <div className='cards'>
                                <div className="finance-card">
                                    <div className="finance-icon-group">
                                        <MoneyIcon className="finance-icon" />
                                        <span className="finance-label">Total Concession</span>
                                    </div>
                                    <div className="finance-divider"></div>
                                    <div className="finance-amount">
                                        {this.formatNumberWithCommas(filteredData?.summaryCards?.feeConcession)}
                                    </div>
                                </div>
                                <div className="finance-card">
                                    <div className="finance-icon-group">
                                        <RefundIcon className="finance-icon" />
                                        <span className="finance-label">Total Refund</span>
                                    </div>
                                    <div className="finance-divider"></div>
                                    <div className="finance-amount">
                                        {this.formatNumberWithCommas(filteredData?.summaryCards?.totalRefund)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='quick-actions-container'>
                            <h5 className="quick-actions-header">Quick Actions</h5>
                            <div className="flex justify-content-between align-items-center quick-actions">
                                <div className="text-center quick-action-item" onClick={() => this.props.navigate('/approveConcession')}>
                                    <ApproveConcessionIcon className="finance-icon" />
                                    <p className='quickActions-title'>Approve Concessions</p>
                                </div>
                                <div className="text-center quick-action-item" onClick={() => this.props.navigate('/collectFee')} >
                                    <CollectFeeIcon className="finance-icon" />
                                    <p className='quickActions-title'>Collect Fee</p>
                                </div>
                                <div className="text-center quick-action-item" onClick={() => this.props.navigate('/approvals-fee-refund')}>
                                    <ApproveRefundIcon className="finance-icon" />
                                    <p className='quickActions-title'>Approve Refunds</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={this.containerRef} style={{ width: '100%', marginTop: '1rem', }} className=''>
                        <GridLayout
                            className="layout"
                            layout={this.state.layouts}
                            cols={12}
                            rowHeight={80}
                            width={this.state.containerWidth}
                            margin={[19, 19]}
                            containerPadding={[0, 0]}
                            isDraggable={true}
                            isResizable={true}
                            resizeHandles={['se']}
                            onLayoutChange={this.onLayoutChange}
                            compactType="vertical"
                            preventCollision={false}
                        >
                            <div key="chart1" className='flex flex-column chart-container'>
                                <div className='card' style={{ background: 'transparent', boxShadow: 'none', border: 'none' }}>
                                    <div className="chart-header">
                                        {this.renderFeeCollectedHeader('chart1')}
                                    </div>
                                    <span className="icon-divider"></span>
                                    <div className="chart-body">
                                        <div className="chart-flex-container">
                                            <div className="chart-area">
                                                <ReactApexChart
                                                    key={`chart1-${this.state.timestamp}`}
                                                    options={this.state.options1}
                                                    series={this.state.series1}
                                                    type="bar"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                            <div className="trend-list">
                                                {this.getTrendData().map((item, index) => (
                                                    <div key={index} className="flex flex-row align-items-center trend-row">
                                                        <div className="trend-circle" style={{ borderColor: item.color }}>
                                                        </div>
                                                        <div className="trend-content flex flex-row align-items-center">
                                                            <span className="trend-label">{item.label}</span>
                                                            <span className="trend-percentage"> - {item.percentage.toFixed(0)}</span>
                                                            <div className={`trend-change ${item.isIncrease ? 'increase' : 'decrease'}`}>

                                                                {item.isIncrease ? <UpArrow /> : <DownArrow />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div key="chart2" className='flex flex-column chart-container'>
                                <div className='card h-full' style={{ background: 'transparent', boxShadow: 'none', border: 'none' }}>
                                    <div className="chart-header">
                                        {this.renderConcessionsHeader('chart2')}
                                    </div>
                                    <span className="icon-divider"></span>
                                    <div className="chart-body">
                                        <ReactApexChart
                                            key={`chart2-${this.state.timestamp}`}
                                            options={this.state.options2}
                                            series={this.state.series2}
                                            type="pie"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div key="chart3" className='flex flex-column chart-container'>
                                <div className='card h-full' style={{ background: 'transparent', boxShadow: 'none', border: 'none' }}>
                                    <div className="chart-header">
                                        {this.renderPaymentModesHeader('chart3')}
                                    </div>
                                    <span className="icon-divider"></span>
                                    <div className="chart-body">
                                        <ReactApexChart
                                            key={`chart3-${this.state.timestamp}`}
                                            options={this.state.options3}
                                            series={this.state.series3}
                                            type="pie"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </GridLayout>
                    </div>
                </div>
                {this.renderFilterIcon()}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
})
export default connect(mapStatesToProps, {})(Authentication(withRouter(FinanceDashboard)));