import React, { Component } from 'react';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams } from '../../../utile';
import _ from 'lodash';
import { AddIcon2, ClassetEditIcon, CodeLinkIcon, MailIcon, MoneyIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin } from '../../../store/apiConstants';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import { getFormFields, onTextChange, onDropDownChange, onToggleChange, isFormValid } from '../../../utile/formHelper';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
const createFeeTypeFields = require('./createFeeType.json');

class FeeType extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createFeeTypeFields, this.props.editData);
        this.state = {
            feeTypes: [],
            formValidations: this.formFields?.formValidations,
            feeTypeFormData: {},
            lazyParams: BasicLazyParams,
            feeTypeDataTable: true,
            openCreateFeeDialog: false,
            editDialog: false,
            feeCategoriesOptions: [],
            admissionCategories: [],
            isOneTime: false,
            totalRecords: 0,
            isDialogOpenForViewFeeType: false,
            viewFeeType: {}
        };

        this.service = new Service();
    }
    handleViewRequest = (feeType) => {
        this.setState({
            viewFeeType: { ...feeType },
            isDialogOpenForViewFeeType: true
        });
    }
    getFeeTypes = async () => {
        this.setState({
            isLoading: true
        });
        let { lazyParams } = this.state;
        let url = `${baseUrlAdmin}/fee-types?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&academicYear=${localStorage.getItem('userAcademicYear')}`;
        this.service.get(url, true).then((data) => {
            if (data.status) {
                if (data?.res?.status && data?.res?.data?.response) {
                    this.setState({
                        feeTypes: data.res.data.response,
                        totalRecords: data?.res?.data?.totalRecords,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    };

    async componentDidMount() {
        await this.getFeeTypes();
        await this.getAdmissionCategoryOptions();

    }

    handleOpenCreateFeeType = () => {
        this.setState({ openCreateFeeDialog: true, editDialog: false });
    };
    onHideCreateFeeDialog = () => {
        this.setState({
            openCreateFeeDialog: false,
            feeTypeFormData: {}
        });
    };
    onEdit = (rowData) => {
        let optionsFeeCats = this.state.allFeeCats?.filter((e) => e.isFeeStructureCreated == false || rowData?.feeCategories?.includes(e._id)).map((each) => {
            return {
                label: each.categoryName,
                value: each._id
            };
        })

        this.setState({
            openCreateFeeDialog: true, editDialog: true, feeTypeFormData: rowData, backupEditData: rowData,
            optionsFeeCats: optionsFeeCats

        });
    };
    onSubmitFeeType = () => {
        this.setState({ isLoading: true });
        const AcademicYear = localStorage.getItem('userAcademicYear');
        let data = this.state?.feeTypeFormData;

        const formStatus = isFormValid(createFeeTypeFields, this.formFields.formValidations, data);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({ formValidations: formStatus.formValidations });
            isCompleteFormValid = false;
        }
        let payload = this.state.feeTypeFormData;
        payload.academicYear = AcademicYear;
        if (isCompleteFormValid) {
            if (!this.state.editDialog) {
                let url = `${baseUrlAdmin}/fee-types`;
                this.service
                    .post(url, payload, true)
                    .then((data) => {
                        if (data?.status) {
                            this.setState({
                                isLoading: false,
                                editDialog: false,
                                openCreateFeeDialog: false,
                                feeTypeFormData: {}
                            });
                            this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                            this.getFeeTypes();
                        } else {
                            this.setState({ isLoading: false });
                            this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                        }
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Creating the fee type', life: 3000 });
                    });
            } else if (this.state.editDialog) {
                let url = `${baseUrlAdmin}/fee-types`;
                this.service
                    .put(url, payload, true)
                    .then((data) => {
                        if (data?.status) {
                            this.setState({
                                isLoading: false,
                                feeTypeFormData: {},
                                editDialog: false,
                                openCreateFeeDialog: false
                            });
                            this.getFeeTypes();
                            this.toast?.show({ severity: 'info', summary: 'Success', detail: data?.res?.message, life: 3000 });
                        } else {
                            this.setState({ isLoading: false });
                            this.toast?.show({ severity: 'error', summary: 'Error', detail: data?.errMessage, life: 3000 });
                        }
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while Updating the fee type', life: 3000 });
                    });
            }
        } else {
            this.setState({ isLoading: false });
            this.toast?.show({ severity: 'error', summary: 'Error', detail: 'Please Enter All The Require Fields', life: 3000 });
        }
    };

    getAdmissionCategoryOptions = async () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`;
        this.service.get(url, true).then((data) => {
            const options = data?.res?.data?.map((each) => {
                return {
                    label: each?.categoryName,
                    value: each?._id
                };
            });

            if (data?.status && data?.res?.data) {
                this.setState({ admissionCategories: data?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast?.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while getting the Admission category options', life: 3000 });
            }
        });
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getFeeTypes);
    };

    onEditMultiDD = (selectedValue, key) => {
        if (this.state.editDialog) {
            let isCondition = this.state.backupEditData[key]?.every((item) => selectedValue.includes(item));
            if (!isCondition && this.state.backupEditData.assignedStudents > 0) {
                this.toast?.show({ severity: 'warn', summary: `can't remove already assigned value`, life: 3000 });
                return false;
            }
        }

        return true;
    };

    render() {
        const { feeTypeFormData, formValidations, lazyParams } = this.state;
        const header = <div className="flex justify-content-between bg-white"></div>;

        return (
            <>
                <div className="admin-management">
                    <div className="grid mb-6 mt-2">
                        <div className="col-12 flex justify-content-end align-items-center">
                            <Authorizer permId={PERMISSIONS.CREATE_FEE_TYPE}>
                                <Button onClick={this.handleOpenCreateFeeType} className="addButtonn">
                                    <p className="">Add Fee Type</p>
                                    <AddIcon2 />
                                </Button>
                            </Authorizer>
                        </div>
                    </div>

                    <div className="ma-m30">
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.feeTypes}
                            dataKey="id"
                            paginator
                            lazy
                            // showGridlines
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fee Types"
                            globalFilter={this.state.globalFilter}
                            responsiveLayout="scroll"

                        >
                            <Column
                                header="S.No."
                                className="datatable-cel"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            {/* <p>{(this.state.lazyParams.page) * (this.state.lazyParams.rows) +index.rowIndex +1}</p> */}
                                            {/* <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}</p> */}
                                            <p>{index.rowIndex + 1}</p>
                                        </>
                                    );
                                }}
                            />
                            <Column field="feeType" header="Fee Type" className="datatable-cel" />
                            <Column
                                header="1 time / installment"
                                className="datatable-cel"
                                field="admissionType"
                                body={(rowData, index) => {
                                    return (
                                        <>
                                            <div>
                                                {rowData?.isOneTime ? 'One Time' : 'Installment'}
                                            </div>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                header="Admission Category"
                                className="datatable-cel"

                                body={(rowData, index) => {
                                    // const labels = admissionTypes
                                    //     .filter(type => rowData?.admissionTypes.includes(type.value))
                                    //     .map(type => type.label);

                                    // return (
                                    //     <div>
                                    //         {labels.map((label, index) => (
                                    //             <div key={index}>{label}</div>
                                    //         ))}
                                    //     </div>
                                    // );
                                    return (
                                        <div>
                                            {rowData?.admissionTypes?.map((label, index) => (
                                                <div key={index}>{label}</div>
                                            ))}
                                        </div>
                                    );
                                }}
                            />

                            <Column field="assignedStudents" header="Total Students" className="datatable-cel" />
                            <Column field="assignedFee" header="Total Assigned Fee" className="datatable-cel" />

                            <Column
                                className=" "
                                header="Actions"
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <div className="flex cursor-pointer">
                                            <div onClick={() => this.onEdit(rowData)}>
                                                <ClassetEditIcon width={28} height={28} color={'black'} />
                                            </div>
                                            <div onClick={() => {
                                                this.handleViewRequest(rowData);
                                            }} style={{ marginLeft: '10px' }}>
                                                <i className="pi pi-eye" style={{ fontSize: '1.5rem', color: 'black' }}></i>
                                            </div>
                                        </div>
                                    );
                                }}
                            ></Column>


                            {/* <Column
                                className=" "
                                header=""
                                body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            <Authorizer permId={PERMISSIONS.UPDATE_FEE_TYPE}>
                                                <div className="flex">
                                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                                    <span
                                                        data-pr-tooltip="Edit Fee Type"
                                                        data-pr-position=""
                                                        className={`ma-ml10 awl${rowIndex}`}
                                                        onClick={() => {
                                                            this.onEdit(rowData);
                                                        }}
                                                    >
                                                        <ClassetEditIcon width={32} height={32} color={'#000000'} />
                                                    </span>
                                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                                </div>
                                            </Authorizer>
                                        </>
                                    );
                                }}
                            ></Column> */}
                        </DataTable>

                        <Toast ref={(el) => (this.toast = el)} position="bottom-right" />

                        {this.state.isLoading && <LoadingComponent />}
                    </div >
                </div >
                <Dialog
                    visible={this.state.isDialogOpenForViewFeeType}
                    draggable={false}
                    closeOnEscape={true}
                    style={{
                        width: '50%',
                        maxWidth: '600px',
                        margin: '0 auto'
                    }}
                    dismissableMask={true}
                    closable={true}
                    header={() => (
                        <div style={{ padding: '1rem', borderBottom: '1px solid #dee2e6' }}>
                            <h3 style={{ textAlign: 'center', margin: 0, fontSize: '1.5rem', fontWeight: '600' }}>
                                View Fee Type Details
                            </h3>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button label="Close" className="confirmDialogCancelBtn px-6" onClick={() => this.setState({ isDialogOpenForViewFeeType: false, viewFeeType: {} })} />
                        </div>
                    )}
                    onHide={() => this.setState({ isDialogOpenForViewFeeType: false, viewFeeType: {} })}
                >
                    <div style={{ padding: '1.5rem' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Fee Type Name
                                </label>
                                <p>{this.state.viewFeeType.feeType}</p>
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Admission Category
                                </label>
                                {this.state.viewFeeType.admissionTypes?.map((label, index) => (
                                    <div key={index}>{label}</div>
                                ))}
                                {/* <p>{this.state.viewFeeType.admissionTypes?.join(', ')}</p> */}
                            </div>
                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    One Time Fee
                                </label>
                                <p>{this.state.viewFeeType.isOneTime ? 'Yes' : 'No'}</p>
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Refundable Fee
                                </label>
                                <p>{this.state.viewFeeType.isRefundableFee ? 'Yes' : 'No'}</p>
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Enable Pro-rata
                                </label>
                                <p>{this.state.viewFeeType.isProRata ? 'Yes' : 'No'}</p>
                            </div>
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    Is Transport Fee Type
                                </label>
                                <p>{this.state.viewFeeType.isTransportFeeType ? 'Yes' : 'No'}</p>
                            </div>
                          {/*   {this.state.viewFeeType.isTransportFeeType && (
                                <div>
                                    <label style={{
                                        display: 'block',
                                        marginBottom: '0.5rem',
                                        fontWeight: '500'
                                    }}>
                                        Is Differential Fee
                                    </label>
                                    <p>{this.state.viewFeeType.isDifferentialFee ? 'Yes' : 'No'}</p>
                                </div>
                            )} */}
                            <div>
                                <label style={{
                                    display: 'block',
                                    marginBottom: '0.5rem',
                                    fontWeight: '500'
                                }}>
                                    GST/VAT
                                </label>
                                <p>{this.state.viewFeeType.isgst ? 'Yes' : 'No'}</p>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    isOpen={this.state.openCreateFeeDialog}
                    visible={this.state.openCreateFeeDialog}
                    // style={{ width: '30%', height: '75%', position: 'fixed', right: 0, top: '17%', bottom: "10%" }}
                    // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    // className={`dialog-${this.getDeviceSize()}`}
                    position='right'
                    style={{ position: 'fixed', right: '0' }}
                    draggable={false}
                    onHide={this.onHideCreateFeeDialog}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2 mb-4">
                                <h3 className="formhead text-center ">{!this.state.editDialog ? 'Add Fee Type' : 'Edit Fee Type'}</h3>
                            </div>
                        );
                    }}
                // footer={() => (
                //     <div className='flex justify-content-center mb-4 mt-4'>

                //         <Button className='submitButton w-7' onClick={this.onSubmitFeeType} >
                //             {!this.state.editDialog && <AddIcon2 />}
                //             <p className='submitButtonText'>{!this.state.editDialog ? 'Add ' : 'Save'}</p>
                //         </Button>
                //         <Button className='cancelButtonn w-4'
                //             onClick={this.onHideCreateFeeDialog} >
                //             <p className='cancelButtonnText'>Cancel</p>
                //         </Button>
                //     </div>
                // )}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div className="">
                            <div className="col-12">
                                <label className="label mb-2 ">
                                    Fee Type Name<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id="feeType"
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type Name"
                                        value={feeTypeFormData.feeType}
                                        onChange={(e) => onTextChange(e.target.value, 'feeType', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                    />
                                </div>
                                {formValidations && !formValidations.fields['feeType'].isValid && <p className="p-error">{formValidations.fields['feeType'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <label className="label">
                                    Admission Category<span className="ma-required">*</span>
                                </label>
                                <div className="mt-2">
                                    <ClassetMultiSelect
                                        icon={<MailIcon />}
                                        id="admissionType"
                                        className=""
                                        height="48px"
                                        width="368px"
                                        // options={admissionTypes}
                                        options={this.state.admissionCategories}
                                        optionLabel="categoryName"
                                        optionValue="_id"
                                        value={feeTypeFormData.admissionType}
                                        onChange={(e) => {
                                            this.onEditMultiDD(e.target.value, 'admissionType')
                                                && onDropDownChange(e.target.value, 'admissionType', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)

                                        }}
                                        placeholder={
                                            <div>
                                                <span className="">Select Admission Type</span>
                                            </div>
                                        }
                                    />
                                </div>
                                {formValidations && !formValidations.fields['admissionType'].isValid && <p className="p-error">{formValidations.fields['admissionType'].errorMsg}</p>}
                            </div>
                            <div className="col-12 ">
                                <Checkbox
                                    // inputId="isRefundableFee"
                                    checked={feeTypeFormData.isOneTime}
                                    id="isOneTime"
                                    value={feeTypeFormData.isOneTime}
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    // style={{borderRadius: '20px',borderWidth:'4px'}}
                                    // className="p-checkbox"
                                    onChange={(e) => onToggleChange(e.checked, 'isOneTime', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isOneTime" className="label ml-2">
                                    One Time Fee
                                </label>
                            </div>
                            <div className="col-12 ">
                                <Checkbox
                                    // inputId="isRefundableFee"
                                    checked={feeTypeFormData.isRefundableFee}
                                    id="isRefundableFee"
                                    value={feeTypeFormData.isRefundableFee}
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    // style={{borderRadius: '20px',borderWidth:'4px'}}
                                    // className="p-checkbox"
                                    onChange={(e) => onToggleChange(e.checked, 'isRefundableFee', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isRefundableFee" className="label ml-2">
                                    Refundable Fee
                                </label>
                            </div>
                            <div className="col-12  ">
                                <Checkbox
                                    checked={feeTypeFormData.isProRata}
                                    value={feeTypeFormData.isProRata}
                                    id="isProRata"
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    onChange={(e) => onTextChange(e.checked, 'isProRata', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isProRata" className="label ml-2">
                                    Enable Pro-rata
                                </label>
                            </div>
                            <div className="col-12 ">
                                <Checkbox
                                    checked={feeTypeFormData.isTransportFeeType}
                                    value={feeTypeFormData.isTransportFeeType}
                                    id="isTransportFeeType"
                                    disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    onChange={(e) => onToggleChange(e.checked, 'isTransportFeeType', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isTransportFeeType" className="label ml-2">
                                    Is Transport Fee Type
                                </label>
                            </div>
                          {/*   {feeTypeFormData.isTransportFeeType && (
                                <div className="col-12 ">
                                    <Checkbox
                                        checked={feeTypeFormData.isDifferentialFee}
                                        value={feeTypeFormData.isDifferentialFee}
                                        disabled={this.state.editDialog && feeTypeFormData?.assignedStudents > 0 ? true : false}
                                        id="isDifferentialFee"
                                        // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                        onChange={(e) => onToggleChange(e.checked, 'isDifferentialFee', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                    />
                                    <label htmlFor="isDifferentialFee" className="label ml-2">
                                        Is Differential Fee{' '}
                                    </label>
                                </div>
                            )} */}
                            <div className="col-12   flex">
                                <Checkbox
                                    value={feeTypeFormData.isgst}
                                    checked={feeTypeFormData.isgst}
                                    className="label mt-2"
                                    id="isgst"
                                    // style={{ border: '2px solid #C6CED2', borderRadius: '8px', height: '24px', width: '24px' }}
                                    onChange={(e) => onToggleChange(e.checked, 'isgst', this, createFeeTypeFields, feeTypeFormData, formValidations, 'feeTypeFormData', formValidations)}
                                />
                                <label htmlFor="isgst" className="label ml-2 mt-2">
                                    GST/VAT
                                </label>
                            </div>
                            <div className="flex justify-content-center mb-4 mt-4">
                                <Button className="submitButton w-7 mr-2" onClick={this.onSubmitFeeType}>
                                    {!this.state.editDialog && <AddIcon2 />}
                                    <p className="submitButtonText">{!this.state.editDialog ? 'Add ' : 'Update'}</p>
                                </Button>
                                <Button className="cancelButtonn w-4" onClick={this.onHideCreateFeeDialog}>
                                    <p className="cancelButtonnText">Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
export default FeeType;
