import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon, MailIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData } from '../../store/actions';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';

class BoardToSectionDDNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardId: null,
            classId: null,
            groupId: null,
            branchId: null,
            classes: [],
            branches: [],
            curriculumInfo: {},
        };
        this.service = new Service()
    }

    componentDidMount() {
        this.props.getBoardsData(true);
    }

    componentDidUpdate() {
        if (this.state.boardId == null && this.props.boards?.length > 0) {
            if (this.props.defaultValues?.boardId && this.props.defaultValues?.classId) {
                const selectedBoard = this.props.boards.find(
                    (board) => board?.boardId === this.props.defaultValues.boardId
                );
                if (selectedBoard) {
                    const selectedClass = selectedBoard.classes.find(
                        (classd) => classd.classId === this.props.defaultValues.classId
                    );
                    this.setState({
                        classes: selectedBoard.classes,
                        boardId: selectedBoard.boardId,
                        selectedBoardName: selectedBoard.boardName,
                        classId: selectedClass?.classId,
                        selectedClass: selectedClass,
                        groupId: selectedClass?.groupId,
                        branches: selectedClass?.branches,
                        selectedClassName: selectedClass?.className,
                    }, () => this.setDropdownsData());
                }
            } else {
                const selectedBoard = this.props.boards[0];
                if (selectedBoard?.classes?.length > 0) {
                    const selectedClass = selectedBoard.classes[0];
                    this.setState({
                        classes: selectedBoard.classes,
                        boardId: selectedBoard.boardId,
                        selectedBoardName: selectedBoard.boardName,
                        classId: selectedClass.classId,
                        selectedClass: selectedClass,
                        groupId: selectedClass.groupId,
                        branches: selectedClass.branches,
                        selectedClassName: selectedClass.className,
                    }, () => this.setDropdownsData());
                }
            }
        }
    }

    onChangeBoard = (boardId) => {
        if (this.props.disabledFields?.board) return;

        this.setState({
            classes: [], classId: null, curriculumInfo: null, branches: [], sections: []
        });
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board?.boardId === boardId);

        this.setState({
            classes: selectedBoard?.classes,
            boardId,
            selectedBoardName: selectedBoard?.boardName,
            classId: '',
            selectedClass: {},
            groupId: '',
            branches: [],
            selectedClassName: ''
        });
    };

    onChangeClass = (classId) => {
        if (this.props.disabledFields?.grade) return;

        this.setState({
            branches: [], sections: []
        });
        let selectedClass = this.state?.classes?.find((classd) => classd.classId == classId)
        this.setState({
            classId: classId,
            selectedClass: selectedClass,
            groupId: selectedClass?.groupId,
            branches: selectedClass?.branches,
            selectedClassName: selectedClass?.className,
            branchId: '',
            selectedbranchName: '',
            sections: []
        });
    };

    onChangeBranch = (branchId) => {
        this.setState({
            sections: []
        });

        const selectedbranchName = this.state?.branches?.find(
            (branchd) => branchd.branchId === branchId
        )?.branchName;

        // Get all sections for this branch from the selected class
        const sections = this.state.selectedClass?.sections?.filter(
            (section) => section.branchId === branchId
        ) || [];

        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            sections: sections,
            sectionId: '',
            selectedsectionName: '',
        }, () => this.setDropdownsData());  // Call setDropdownsData after state update
    };

    onChangeSection = (sectionId) => {
        const selectedSection = this.state.sections?.find(
            (sectiond) => sectiond.sectionId == sectionId
        );
    
        this.setState({
            sectionId: sectionId,
            selectedsectionName: selectedSection?.sectionName || '',
        }, () => this.setDropdownsData());
    };

    setDropdownsData = () => {
        let {
            boardId,
            classId,
            branchId,
            sectionId,
            selectedBoardName,
            selectedClassName,
            selectedbranchName,
            selectedsectionName
        } = this.state
        let returnData = {
            boardId,
            classId,
            branchId,
            sectionId,
            selectedBoardName,
            selectedClassName,
            selectedbranchName,
            selectedsectionName
        }
        this.props.setValues(returnData)
    }

    render() {
        const { disabledFields = {} } = this.props;

        return (
            <div>
                <div>
                    <div className='grid m-2'>
                        <div className="col-3">
                            <ClassetDropdown
                                required={true}
                                label={'Board'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Board'}
                                options={this.props.boards}
                                value={this.state?.boardId}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                optionLabel="boardName"
                                optionValue="boardId"
                                disabled={disabledFields.board}
                            />
                        </div>
                        <div className="col-3">
                            <ClassetDropdown
                                required={true}
                                label={'Grade'}
                                icon={<MailIcon />}
                                placeholder={'Select Grade'}
                                options={this.state?.classes}
                                value={this.state.classId}
                                onChange={(e) => this.onChangeClass(e.value)}
                                optionLabel="className"
                                optionValue="classId"
                                disabled={disabledFields.grade}
                            />
                        </div>
                        <div className="col-3">
                            <ClassetDropdown
                                required={true}
                                label={'Branch'}
                                icon={<MailIcon height={16.5} width={19.5} />}
                                placeholder={'Select Branch'}
                                options={this.state?.branches}
                                value={this.state.branchId}
                                onChange={(e) => this.onChangeBranch(e.value)}
                                optionLabel="branchName"
                                optionValue="branchId"
                                disabled={disabledFields.branch}
                            />
                        </div>
                        <div className="col-3">
                            <ClassetDropdown
                                required={false}
                                label={'Section'}
                                icon={<MailIcon />}
                                placeholder={'Select Section'}
                                options={this.state?.sections}
                                value={this.state.sectionId}
                                onChange={(e) => this.onChangeSection(e.value)}
                                optionLabel="sectionName"
                                optionValue="sectionId"
                                disabled={disabledFields.section}
                            />
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms?.permissionIds,
    userInfo: state.currentUserPerms?.userInfo || {},
});

export default connect(mapStatesToProps, { getBoardsData })(BoardToSectionDDNew);