import React, { Component } from 'react';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import moment from 'moment';
import './styles.scss';
import { QuestionTypeKeys, QuestionTypeKeysWithAQ } from '../questionBank/constants';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// Create a wrapper component to get URL params
const withRouter = (WrappedComponent) => {
    return (props) => {
        const params = useParams();
        return <WrappedComponent {...props} params={params} />;
    };
};

class PaperDownload extends Component {

    constructor(props) {
        super(props);
        // Get examId from props.params instead of props.match.params
        const { examId, examDate } = this.props.params;
        this.state = {
            examId,
            examDate,
            exam: null,
            examType: null

        }

        this.service = new Service();
        this.questionCounter = 0;
    }


    callMathJax = () => {
        if (window && window.MathJax && window.MathJax.Hub) {

            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }

    }

    minToHours = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes == 0) {
            return rhours;
        } else {
            return rhours + ":" + rminutes;
        }

    }

    componentDidMount() {
        if (this.state.examId) {
            let url = `${baseUrlAdmin}/examination/exam/${this.state.examId}`;

            this.service.get(url, false).then((res) => { // Set to false to not add auth token
                if (res.status && res.res && res.res.Item) {
                    this.setState({
                        exam: res.res.Item[0],
                        examType: res.res.Item[0].examType
                    });
                }
            }).catch(e => {
                console.error("Error fetching exam:", e);
            });
        }
    }

    componentDidUpdate() {
        this.callMathJax();
    }

    renderQuestion = (q, qIndex, questions) => {
        switch (q.questionType) {
            case QuestionTypeKeysWithAQ.SCCT:
            case QuestionTypeKeysWithAQ.MCCT:
                return <>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                    {
                        q.options && q.options.length && q.options.map((a, oIndex) => {
                            return <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={a.id}><p className='option'>{a.id}. {a.value} </p>
                                {
                                    a.imageUrl && a.imageUrl.length > 2 && <>
                                        <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + a.imageUrl} className='o-attach' />
                                    </>
                                }
                            </div>
                        })
                    }
                </>
            case QuestionTypeKeysWithAQ.INT:
            case QuestionTypeKeysWithAQ.NUM:
            case QuestionTypeKeysWithAQ.FB:
                return <>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                    <p className='num-answer'></p>
                </>
            case QuestionTypeKeysWithAQ.PQS:
            case QuestionTypeKeysWithAQ.PQM:
                return <>
                    {(!qIndex) || !(qIndex && q.psgQId == questions[qIndex - 1].psgQId) && <>
                        <p className='question p-question'>{q.paragraph}</p>
                        {
                            q.paragraphUrl && q.paragraphUrl.length > 2 && <>
                                <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + q.paragraphUrl} className='q-attach' />
                            </>
                        }
                    </>}
                    <div className='sp-clearFix'></div>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                    {
                        q.options && q.options.length && q.options.map((a, oIndex) => {
                            return <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={a.id}><p className='option'>{a.id}. {a.value} </p>
                                {
                                    a.imageUrl && a.imageUrl.length > 2 && <>
                                        <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + a.imageUrl} className='o-attach' />
                                    </>
                                }
                            </div>
                        })
                    }
                </>
            case QuestionTypeKeysWithAQ.VSAQ:
            case QuestionTypeKeysWithAQ.SAQ:
            case QuestionTypeKeysWithAQ.LAQ:
                return <>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://classetimage-old.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                </>
            default:
                return <></>
        }
    }

    // Helper function to organize questions by section
    organizeQuestionsBySection = (subject) => {
        // Create a map to store questions by section
        const sectionMap = {};

        // Initialize sections with empty questions array
        if (subject.sections && subject.sections.length > 0) {
            subject.sections.forEach(section => {
                sectionMap[section.sectionName] = {
                    ...section,
                    questions: []
                };
            });
        }

        // Assign questions to their respective sections
        if (subject.questions && subject.questions.length > 0) {
            subject.questions.forEach(question => {
                const sectionName = question.sectionName;
                if (sectionName && sectionMap[sectionName]) {
                    sectionMap[sectionName].questions.push(question);
                }
            });
        }

        return Object.values(sectionMap);
    }

    render() {
        const { exam, examType, examDate } = this.state;

        if (!exam) {
            return <div className="loading-container">Loading exam paper...</div>;
        }

        // Reset counter at the start of render
        this.questionCounter = 0;

        return (
            <div className='e-paper'>
                <style type="text/css">
                    {`
                  /* Page break controls - will apply when printed */
                  @media print {
                    /* Make sure subjects start on a new page */
                    .subject-container {
                      page-break-before: always;
                    }
                    
                    /* First subject doesn't need a page break before it */
                    .subject-container:first-child {
                      page-break-before: avoid;
                    }
                    
                    /* Prevent questions from breaking across pages */
                    .question-wrapper {
                      page-break-inside: avoid;
                    }
                    
                    /* Keep section headers with their content */
                    .section-name, 
                    .section-instruction-container {
                      page-break-after: avoid;
                    }
                    
                    /* Ensure section doesn't start at the bottom of a page */
                    .section-container {
                      page-break-inside: avoid;
                    }
                    
                    /* Print styling improvements */
                    body {
                      background-color: white !important;
                    }
                    
                    /* Set margins for print */
                    @page {
                      margin: 0.5in;
                    }
                  }
                `}
                </style>
                {/* Header Section */}
                <header>
                    <h1 className='school-title'>{exam.orgInfo?.title || 'Educational Institution'}</h1>
                    <h4 className='school-address'>
                        {exam.orgInfo?.city && exam.orgInfo?.state
                            ? `${exam.orgInfo.city}, ${exam.orgInfo.state}`
                            : 'Location'}
                    </h4>
                </header>

                {/* Exam Title */}
                <h2 className='e-exam-name'><span>{exam.examName}</span></h2>

                {/* Exam Details */}
                <div className='e-header'>
                    <span className='e-date'>
                        <strong>Duration:</strong> {this.minToHours(parseInt(exam.totalTime) || 0)} Hours
                    </span>
                    <div className='sp-right'>
                        <p className='e-date sp-no-pm'>
                            Date: {moment(examDate).format("DD-MM-YYYY")}
                        </p>
                        <p className='e-date sp-no-pm'>
                            <strong>Max. Marks:</strong> {exam.totalMarks || 0}
                        </p>
                    </div>
                </div>

                {/* Exam Instructions */}
                <h5 className='exam-instruction'>Exam Instructions</h5>
                <div className='sp-ml10 e-instructions'
                    dangerouslySetInnerHTML={{ __html: exam.instructions || 'No instructions provided.' }}>
                </div>

                {/* AdhocMockTest format with sections */}
                {examType === 'AdhocMockTest' && (
                    <div className="exam-content">
                        {exam.subjects.map((subject, subIndex) => {
                            const sectionsWithQuestions = this.organizeQuestionsBySection(subject);

                            return (
                                <div key={subject.id || subIndex} className="subject-container">
                                    <h3 className={`subject-name ${subIndex > 0 ? 'sp-mt40' : ''}`}>
                                        <span>{subject.subjectName}</span>
                                    </h3>
                                    <hr />

                                    {/* Render sections within subject */}
                                    {sectionsWithQuestions.map((section, sectionIndex) => (
                                        <div key={sectionIndex} className="section-container">
                                            <h4 className='section-name'>Section {sectionIndex + 1}: {section.sectionName}</h4>

                                            {/* Section instructions if available */}
                                            {section.sectionInstruction && (
                                                <div className="section-instruction-container">
                                                    <h6 className='section-instruction-header'>Section Instructions:</h6>
                                                    <div className='section-instructions'>
                                                        {section.sectionInstruction}
                                                    </div>
                                                </div>
                                            )}

                                            <div className='paper-download'>
                                                {section.questions.map((q, qIndex) => {
                                                    this.questionCounter++;
                                                    return (
                                                        <React.Fragment key={q.questionId}>
                                                            <div className='question-wrapper'>
                                                                {this.renderQuestion({
                                                                    ...q,
                                                                    qNo: this.questionCounter
                                                                }, qIndex, section.questions)}
                                                            </div>
                                                            <div className='sp-clearFix'></div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

// Export the component wrapped with the new withRouter
export default withRouter(PaperDownload);