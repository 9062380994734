import React, { Component } from 'react'
import Service from '../../../services';
import { connect } from 'react-redux';
import withRouter from '../../../lib/withRouter';
import { Toolbar } from 'primereact/toolbar';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import SvgViewer from '../../../customComponents/svgViewer';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { InputText } from 'primereact/inputtext';
import { getBoardsData } from '../../../../store/actions';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import SingleQuestion from '../../../questionBank/common/singleQuestion';
import { userAssignedBoards } from '../../../../store/selectors/userAssignedBoards';
import { Dropdown } from 'primereact/dropdown';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../../utile/formHelper';
import { QuestionTargets, QuestionTypeKeys } from '../../../questionBank/constants';
import { BasicLazyParams, getServerTime } from '../../../../utile';
import PreviewQuestion from '../../../questionBank/common/previewQuestion';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import LoadingComponent from '../../../loadingComponent';
import QuestionInTable from '../../../questionBank/common/questionInTable';
import moment from 'moment';
import { TabView, TabPanel } from 'primereact/tabview';
import { Chips } from 'primereact/chips';
import { DeleteIcon, PreviewIcon } from '../../../svgIcons';
import { FIELDS_INFO } from '../../../../constants';
import InputTextB from '../../../customComponents/inputTextB';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressBar } from 'primereact/progressbar';
import { Message } from 'primereact/message';

const adhocFieldss = require('../examForms/adhoc.json');
let adhocFields = _.cloneDeep(adhocFieldss);

const processEditAdhoc = (editQuiz) => {
    // editUser = _.cloneDeep(editUser);
    // editUser.gradeId = editUser.classId;
    // editUser.phone_number = editUser.phone_number.substring(3);

    // editUser.grade = editUser['custom:grade'];
    // editUser.group = editUser['custom:group'];
    // editUser.board = editUser['custom:board'];
    // editUser.board = editUser['custom:board'];
    return editQuiz;
}

class CreateAdhoc extends Component {
    constructor(props) {
        super(props);

        // Remove the questionTarget default from formFields
        this.formFields = getFormFields(adhocFields, {});
        this.state = {
            exam: this.formFields.data,
            formValidations: this.formFields.formValidations,
            examTemplates: [],
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            boardName: null,
            className: null,
            groupName: null,
            subjectName: null,

            isLoading: false,
            columns: [{ columnName: 'Question Type', isVisible: true, id: 'questionType' }],
            showSearch: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            questions: [],
            currentStep: 1,
            selectedQIds: [],

            questionIds: [],
            selectedExamQuestions: [],

            //   ...oldState
            showBulkQuestionSelector: false,
            availableQuestions: [],
            selectedBulkQuestions: [],
            currentSubjectId: null,
            currentSectionName: null,
            currentQuestionType: null,
            isLoadingQuestions: false,
            questionSelection: {
                subjectId: null,
                sectionName: null,
                questionType: null
            },
            questionFilters: {
                chapterId: null,
                topicId: null,
                difficulty: null
            }
        }

        this.service = new Service();
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }



    formGradesDataForEdit = (editExam) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editExam.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editExam.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editExam.groupId)

                this.setState(
                    {
                        boardId: editExam.boardId,
                        classId: editExam.classId,
                        groupId: editExam.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }



    getTemplates = () => {



        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/examtemplate/gettemplates`
        this.service.post(url, {}, true).then((data) => {
            if (data && data.res && data.res.data && data.res.data.examTemplates.length) {
                this.setState({
                    examTemplates: data.res.data.examTemplates,
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }

    formQuestionsFromExam = (exam) => {
        let questions = [];
        exam.subjects.forEach((s) => {
            questions = [...questions, ...s.questions]
        })



        return questions;
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData(true);
        }

        this.getTemplates();

        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });

            this.service.get(`${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/AdhocMockTest`, true).then((data) => {
                if (data && data.res && data.res.Item && data.res.Item.length) {
                    this.setState({
                        exam: data.res.Item[0],
                        selectedExamQuestions: this.formQuestionsFromExam(data.res.Item[0]),
                        isLoading: false,
                    }, () => this.formGradesDataForEdit(data.res.Item[0]));
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });
        }
    }




    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        this.setState((prevState) => ({
            exam: {
                ...prevState.exam,
                boardId,
                boardName: selectedBoard.boardName,
            },
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null
        }));

    };

    onChangeClass = (classId) => {

        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));

            this.setState((prevState) => {
                return {
                    exam: {
                        ...prevState.exam,
                        classId,
                        className: selectedClass.className,
                        groupId: _groups[0].value,
                        groupName: _groups[0].label,
                    },
                    groups: _groups,
                    classId,
                    className: selectedClass.className,
                    groupId: _groups[0].value,
                    groupName: _groups[0].label,
                }
            }, () => {
                this.getCurriculumData();
            }
            );
        }
    }


    // grades selection --- end


    //subjects --- start
    getSelectedCurriculum = (subjects) => {
        let { subjectId, chapterId, topicId } = this.state.exam;

        let selectedSubject = subjects.find((subject) => subject.subjectId == subjectId);
        let selectedChapter = selectedSubject && selectedSubject.chapters && selectedSubject.chapters.find(c => c.chapterId == chapterId);
        let selectedTopic = selectedChapter && selectedChapter.topics && selectedChapter.topics.find(c => c.topicId == topicId);

        return {
            subjects,
            chapters: selectedSubject.chapters,
            topics: (selectedChapter && selectedChapter.topics) ? selectedChapter.topics : [],
            subjectId,
            chapterId,
            topicId,
            subjectName: selectedSubject.subjectName,
            chapterName: selectedChapter ? selectedChapter.chapterName : null,
            topicName: selectedTopic ? selectedTopic.topicName : null,
            content: null,

        };

    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {



                if (data && data.res && data.res.Item) {
                    // let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}

                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,

                        //   ...selected
                    }, () => {





                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (sId, subjectId) => {
        let selectedSubject = this.state.subjects.find((subject) => subject.subjectId == subjectId);
        let exam = this.state.exam;

        exam.subjects.forEach((s) => {
            if (s.id == sId) {
                s.subjectId = subjectId;
                s.subjectName = selectedSubject.subjectName
            }
        })

        this.setState((prevState) => {
            return {
                exam
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,

            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName
            });
    };


    updateDateFileds = (isApplicable) => {
        if (isApplicable == 'closed') {
            adhocFields = adhocFields.map(field => {
                if (field.FieldName == 'fromDateTime' || field.FieldName == 'toDateTime') {
                    field.Required = true;
                }
                return field;
            });

            this.formFields = getFormFields(adhocFields, this.state.org);


            this.setState({
                formValidations: this.formFields.formValidations
            })

        } else {
            adhocFields = adhocFields.map(field => {
                if (field.FieldName == 'fromDateTime' || field.FieldName == 'toDateTime') {
                    field.Required = undefined;
                }
                return field;
            });

            this.formFields = getFormFields(adhocFields, this.state.org);


            this.setState({
                formValidations: this.formFields.formValidations
            })
        }
    }



    isAdditionalValid = (exam) => {
        let { formValidations } = this.state;
        let isValid = true;


        if (exam.testType == 'closed') {
            if (!(moment(exam.fromDateTime).isBefore(exam.toDateTime))) {
                formValidations.fields['fromDateTime'] = {
                    ...formValidations.fields['fromDateTime'],
                    isValid: false,
                    errorMsg: `Exam start date and time should less the end date.`
                }
                isValid = false;
            }
        }


        if (exam.subjects && exam.subjects.length) {
            let isSubjectNotSelected = false;
            let isDuplicateSubject = false;

            let subjectIds = [];
            exam.subjects.forEach(s => {
                if (s.subjectId == '') {
                    isSubjectNotSelected = true
                    isValid = false
                } else {
                    if (subjectIds.includes(s.subjectId)) {

                        isDuplicateSubject = true
                        isValid = false
                    }
                    subjectIds.push(s.subjectId)
                }
            });


            if (isSubjectNotSelected) {
                formValidations.fields['subjects'] = {
                    ...formValidations.fields['subjects'],
                    isValid: false,
                    errorMsg: `Please select all subjects`
                }
            } else {
                formValidations.fields['subjects'] = {
                    ...formValidations.fields['subjects'],
                    isValid: true,
                    errorMsg: ``
                }
            }


            if (!isSubjectNotSelected) {
                if (isDuplicateSubject) {
                    formValidations.fields['subjects'] = {
                        ...formValidations.fields['subjects'],
                        isValid: false,
                        errorMsg: `Duplicate subjects `
                    }
                } else {
                    formValidations.fields['subjects'] = {
                        ...formValidations.fields['subjects'],
                        isValid: true,
                        errorMsg: ``
                    }
                }
            }
        }

        if (exam.testType == 'closed') {
            if (!(moment(exam.fromDateTime).isAfter(getServerTime()))) {
                formValidations.fields['fromDateTime'] = {
                    ...formValidations.fields['fromDateTime'],
                    isValid: false,
                    errorMsg: `Exam start time must greater than current time.`
                }
                isValid = false;
            }


            if (!(moment(exam.fromDateTime).isBefore(exam.toDateTime))) {
                formValidations.fields['toDateTime'] = {
                    ...formValidations.fields['toDateTime'],
                    isValid: false,
                    errorMsg: `Exam end time must greater than start time.`
                }
                isValid = false;
            }

        }



        this.setState({
            formValidations,
            exam
        })

        return isValid;
    }






    onProceed = () => {

        const {
            exam,
            currentStep
        } = this.state;
        if (currentStep == 1) {
            const formStatus = isFormValid(adhocFields, this.formFields.formValidations, exam);
            if (!formStatus.formValidations.isFormValid) {
                this.setState({
                    isSubmitClick: true,
                    formValidations: formStatus.formValidations,
                });
            } else if (!this.isAdditionalValid(exam)) {

            } else {
                this.setState({
                    currentStep: 2
                })
            }
        } else {

            this.validateAndSubmit();
        }
    }



    validateAndSubmit = () => {
        const {
            exam,
            selectedExamQuestions
        } = this.state;

        if (parseInt(exam.totalNoOfQuestions) != selectedExamQuestions.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the required number of questions.', life: 3000 });
            return
        }

        // Update the total marks before saving
        let examToSave = this.processExamData(exam, selectedExamQuestions);
        examToSave.totalMarks = this.calculateTotalMarks();
        examToSave.examType = 'AdhocMockTest';

        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });
            this.service.put(`${baseUrlAdmin}/examination/${exam.examId}/exam-type/AdhocMockTest/update-exam`, examToSave, true)
                .then((data) => {
                    if (data && data.res && data.res.success) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // Show success message
                            this.toast.show({
                                severity: 'success',
                                summary: 'Hurray!',
                                detail: 'Exam Successfully edited',
                                life: 3000
                            });

                            // Call the onCreateSuccess callback from parent
                            if (typeof this.props.onCreateSuccess === 'function') {
                                this.props.onCreateSuccess(true);
                            }

                            // Navigate back to tests and exams screen
                            // Using timeout to allow toast message to be seen briefly
                            setTimeout(() => {
                                this.props.navigate("/TestsandExams");
                            }, 1000);
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Unable to update exam record', life: 3000 });
                    }
                })
                .catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: e.message || 'An error occurred', life: 3000 });
                });
        } else {
            this.setState({
                isLoading: true
            });
            this.service.post(`${baseUrlAdmin}/examination/add-exam-test`, examToSave, true)
                .then((data) => {
                    if (data && data.res && data.res.success) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // Show success message
                            this.toast.show({
                                severity: 'success',
                                summary: 'Hurray!',
                                detail: 'Exam Successfully created',
                                life: 3000
                            });

                            // Call the onCreateSuccess callback if it exists
                            if (typeof this.props.onCreateSuccess === 'function') {
                                this.props.onCreateSuccess();
                            }

                            // Navigate back to tests and exams screen
                            // Using timeout to allow toast message to be seen briefly
                            setTimeout(() => {
                                this.props.navigate("/TestsandExams");
                            }, 1000);
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Unable to create exam record', life: 3000 });
                    }
                })
                .catch(e => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: e.message || 'An error occurred', life: 3000 });
                });
        }
    }

    processExamData = (fExam, selectedExamQuestions) => {
        let finalExam = _.cloneDeep(fExam);
        let qNo = 1;

        finalExam.subjects.forEach((s, index) => {
            s.questions = [];
            let subjectQuestions = selectedExamQuestions.filter((q) => q.subjectId == s.subjectId);

            // Sort questions by passage question ID if it exists
            subjectQuestions.sort((a, b) => {
                // Handle null or undefined psgQId
                const aId = a.psgQId || '';
                const bId = b.psgQId || '';
                return aId.localeCompare(bId);
            });

            subjectQuestions.forEach(question => {
                question.qNo = qNo;
                s.questions.push(question);
                qNo++;
            });

            s.priority = (index + 1);

            // Process markingScheme within each section instead of directly on the subject
            if (s.sections && Array.isArray(s.sections)) {
                s.sections.forEach(section => {
                    if (section.markingScheme && Array.isArray(section.markingScheme)) {
                        section.markingScheme.forEach(schema => {
                            // Convert string values to integers with fallback to 0
                            schema.negativeMarking = parseInt(schema.negativeMarking || 0);
                            schema.optionalQuestions = parseInt(schema.optionalQuestions || 0);
                            schema.partialMarking = parseInt(schema.partialMarking || 0);
                            schema.positiveMarking = parseInt(schema.positiveMarking || 0);
                            schema.totalMarkedQuestions = parseInt(schema.totalMarkedQuestions || 0);
                            schema.totalQuestions = parseInt(schema.totalQuestions || 0);
                            schema.totalSelectedQuestions = parseInt(schema.totalSelectedQuestions || 0);
                        });
                    }
                });
            }
        });

        finalExam.gradeId = finalExam.classId || '';
        finalExam.gradeName = finalExam.className || '';

        finalExam.totalMarkedQuestions = parseInt(finalExam.totalMarkedQuestions || 0);
        finalExam.totalMarks = parseInt(finalExam.totalMarks || 0);
        finalExam.totalNoOfQuestions = parseInt(finalExam.totalNoOfQuestions || 0);
        finalExam.totalTime = parseInt(finalExam.totalTime || 0);

        if (!finalExam.questionType) {
            finalExam.questionType = finalExam.questionTypes || '';
        }

        return finalExam;
    }

    onAddQuestions = () => {
        let { selectedQuestions, exam } = this.state;
        if (selectedQuestions && selectedQuestions.length) {
            if (exam.noOfQuestions && exam.noOfQuestions != '') {
                if (exam.questions.length < parseInt(exam.noOfQuestions)) {
                    if (selectedQuestions && selectedQuestions.length) {
                        if ((selectedQuestions.length + exam.questions.length) <= parseInt(exam.noOfQuestions)) {
                            exam.questions = [...exam.questions, ...selectedQuestions];
                            exam.questions = [... new Set(exam.questions)]
                            this.setState({
                                exam: exam,
                                selectedQuestions: []
                            });
                        } else {
                            let removeCount = (selectedQuestions.length + exam.questions.length) - parseInt(exam.noOfQuestions);
                            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Number questions are exceeded, Remove ${removeCount} questions`, life: 3000 });
                        }
                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'You have already selected maximum number of question required for the Exam', life: 3000 });
                }
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
            }
        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select questions to add', life: 3000 });
        }
    }



    removeQuestion = (remQ) => {
        let { selectedExamQuestions } = this.state;

        // Filter out the question to be removed
        selectedExamQuestions = selectedExamQuestions.filter((q) => q._id != remQ._id);

        // Recalculate section counts after removal
        const updatedExam = this.recalculateSectionCounts();

        this.setState({
            selectedExamQuestions,
            exam: updatedExam
        });
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    onExamStartTImeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {
        let data = JSON.parse(JSON.stringify(formData));

        if ((moment(value).isAfter(getServerTime()))) {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Exam start date and time must be greater than current date and time.`
            }
        }

        if (!(moment(value).isBefore(data.timeFrameEnd))) {
            data.timeFrameEnd = '';
            formValidations.fields['timeFrameEnd'] = {
                ...formValidations.fields['timeFrameEnd'],
                isValid: false,
                errorMsg: `Please select exam end date, start date changed.`
            }
        }

        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    onExamEndTimeChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        data[fieldName] = new Date(value).toString();
        if (moment(value).isAfter(data.fromDateTime)) {

            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Exam End date must be greater than start date.`
            }
        }
        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    onTemplateChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        if (value) {
            data[fieldName] = value;
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Exam End date must be greater than start date.`
            }
        }
        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations,
        }, () => this.getExamTemplate(value));
    }

    getExamTemplate = (templateId) => {
        this.setState({
            isLoading: true
        });

        this.service.get(`${baseUrlAdmin}/examtemplate/getTemplateById?templateId=${templateId}`, true).then((data) => {
            if (data && data.res && data.res.data) {
                const templateData = data.res.data;

                // Reset totalSelectedQuestions for each marking scheme
                if (templateData.subjects && templateData.subjects.length) {
                    templateData.subjects.forEach(subject => {
                        if (subject.sections && subject.sections.length) {
                            subject.sections.forEach(section => {
                                if (section.markingScheme && section.markingScheme.length) {
                                    section.markingScheme.forEach(scheme => {
                                        scheme.totalSelectedQuestions = 0;
                                    });
                                }
                            });
                        }
                    });
                }

                this.setState((prevState) => {
                    return {
                        examTemplate: templateData,
                        exam: { ...prevState.exam, ...templateData },
                        isLoading: false,
                        // Clear any previously selected questions since we're changing templates
                        selectedExamQuestions: []
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }

    recalculateSectionCounts = () => {
        const { exam, selectedExamQuestions } = this.state;
        let updatedExam = _.cloneDeep(exam);

        // First, reset all counts
        if (updatedExam.subjects && Array.isArray(updatedExam.subjects)) {
            updatedExam.subjects.forEach(subject => {
                if (subject && subject.sections && Array.isArray(subject.sections)) {
                    subject.sections.forEach(section => {
                        if (section && section.markingScheme && Array.isArray(section.markingScheme)) {
                            section.markingScheme.forEach(scheme => {
                                if (scheme) {
                                    scheme.totalSelectedQuestions = 0;
                                }
                            });
                        }
                    });
                }
            });
        }

        // Now count each question and increment the appropriate counter
        if (Array.isArray(selectedExamQuestions)) {
            selectedExamQuestions.forEach(question => {
                if (!question) return;

                // Find the subject this question belongs to
                const subject = updatedExam.subjects?.find(s => s.subjectId === question.subjectId);
                if (subject) {
                    const section = subject.sections?.find(s =>
                        // Match by sectionId if available, otherwise use sectionName
                        (question.sectionId && s.sectionId === question.sectionId) ||
                        (question.sectionName && s.sectionName === question.sectionName)
                    );

                    if (section) {
                        const scheme = section.markingScheme?.find(s => s.questionType === question.questionType);
                        if (scheme) {
                            // Increment the count
                            scheme.totalSelectedQuestions = (scheme.totalSelectedQuestions || 0) + 1;
                        }
                    }
                }
            });
        }

        return updatedExam;
    }

    canAddQuestion = (finalQuestions, subjects, question) => {
        let canAdd = false;

        // Check if the question is already added
        let isAlreadyAdded = false;
        if (question.question.questionType == QuestionTypeKeys.PSGS || question.question.questionType == QuestionTypeKeys.PSGM) {
            question.question.questions.forEach((subQ) => {
                // Check if this question is already added anywhere
                if (finalQuestions.find(sq => sq.questionId == subQ.questionId)) {
                    isAlreadyAdded = true;
                }
            });
        } else {
            if (finalQuestions.find(sq => sq.questionId == question.question.questionId)) {
                isAlreadyAdded = true;
            }
        }

        if (isAlreadyAdded) {
            return false;
        }

        // Find the subject this question belongs to
        const targetSubject = subjects.find(subject => subject.subjectId == question.subjectId);
        if (!targetSubject) return false;

        // Find the appropriate section for this question type
        let targetSection = null;
        let targetScheme = null;

        // Search through sections to find one with a marking scheme for this question type
        if (targetSubject.sections && targetSubject.sections.length > 0) {
            for (const section of targetSubject.sections) {
                if (section.markingScheme && section.markingScheme.length > 0) {
                    const scheme = section.markingScheme.find(
                        scheme => scheme.questionType === question.question.questionType
                    );

                    if (scheme) {
                        targetSection = section;
                        targetScheme = scheme;
                        break;
                    }
                }
            }
        }

        if (!targetSection || !targetScheme) return false;

        // Count CURRENT questions in this section of this type
        const currentCount = finalQuestions.filter(q =>
            q.subjectId === question.subjectId &&
            q.sectionName === targetSection.sectionName &&
            q.questionType === question.question.questionType
        ).length;

        // Handle passage questions (PSGS, PSGM) differently
        if (question.question.questionType === QuestionTypeKeys.PSGS ||
            question.question.questionType === QuestionTypeKeys.PSGM) {
            const newQuestionsCount = question.question.questions.length;
            if (currentCount + newQuestionsCount <= parseInt(targetScheme.mandatoryQuestions)) {
                canAdd = true;
            }
        } else {
            // Handle regular questions
            if (currentCount < parseInt(targetScheme.mandatoryQuestions)) {
                canAdd = true;
            }
        }

        return canAdd;
    }

    orderQuestions = (questions, qIds) => {
        let qs = [];
        qIds.forEach(qId => {
            let inex = questions.findIndex(q => q.questionId == qId);
            if (inex >= 0) {
                let dd = JSON.parse(JSON.stringify(questions.splice(inex, 1)[0]))
                qs.push(dd);
            }

        });



        return qs.concat(questions);

    }


    onFetchQuestions = (questions, qIds) => {
        if (questions && questions.length) {
            questions = this.orderQuestions(questions, qIds);
        }

        let notFoundQuestions = [];

        for (let i = 0; i < qIds.length; i++) {
            let question = questions.find(q => q.questionId == qIds[i]);
            if (!question) {
                notFoundQuestions.push(qIds[i]);
            }
        }

        if (notFoundQuestions.length) {
            this.toast.show({
                severity: 'error',
                summary: 'Questions are not added due to some questions not found.',
                detail: `Having issue with the following questions: ${notFoundQuestions.join(',')}.`,
                life: 20000
            });
            return;
        }

        let { exam, selectedExamQuestions } = this.state;
        let subjectIds = exam.subjects.map((q) => q.subjectId);

        let passageQuestions = [];
        let finalQuestions = _.cloneDeep(selectedExamQuestions);
        let examT = _.cloneDeep(exam);

        // Check for duplicate questions
        let duplicateQuestions = [];
        if (questions.length) {
            questions.forEach(q => {
                if (q && q.question) {
                    if (q.question.questionType == 'PSGS' || q.question.questionType == 'PSGM') {
                        q.question.questions.forEach(subQ => {
                            if (finalQuestions.find(qa => qa.questionId == subQ.questionId)) {
                                if (!duplicateQuestions.includes(q.questionId)) {
                                    duplicateQuestions.push(q.questionId);
                                }
                            }
                        });
                    } else {
                        if (finalQuestions.find(qa => qa.questionId == q.question.questionId)) {
                            if (!duplicateQuestions.includes(q.questionId)) {
                                duplicateQuestions.push(q.questionId);
                            }
                        }
                    }
                }
            });
        }

        if (duplicateQuestions.length) {
            this.toast.show({
                severity: 'error',
                summary: 'Questions are not added due to duplicate questions.',
                detail: `Having issue with the following questions: ${duplicateQuestions.join(',')}.`,
                life: 20000
            });
            return;
        }

        let questionsNotInSubjects = [];
        let newlyAddedQs = [];
        let notFitQuestions = [];

        if (questions && questions.length) {
            questions.forEach(q => {
                if (subjectIds.includes(q.subjectId)) {
                    if (q.question.questionType == QuestionTypeKeys.PSGS || q.question.questionType == QuestionTypeKeys.PSGM) {
                        if (this.canAddQuestion(finalQuestions, examT.subjects, q)) {
                            // Get section info before adding the questions
                            let sectionInfo = this.findSectionForQuestion(examT.subjects, q);

                            if (sectionInfo) {
                                q.question.questions.forEach((subQ) => {
                                    // Add the subject, section, and parent question ID info
                                    subQ.subjectId = q.subjectId;
                                    subQ.psgQId = q.questionId;
                                    subQ.sectionName = sectionInfo.sectionName;
                                    subQ.sectionId = sectionInfo.sectionId;

                                    finalQuestions.push(subQ);
                                });
                                newlyAddedQs.push(q.questionId);
                            }
                        } else {
                            if (!notFitQuestions.includes(q.questionId)) {
                                notFitQuestions.push(q.questionId);
                            }
                        }
                    } else {
                        if (this.canAddQuestion(finalQuestions, examT.subjects, q)) {
                            // Find the section this question belongs to
                            let sectionInfo = this.findSectionForQuestion(examT.subjects, q);

                            if (sectionInfo) {
                                // Add section and subject info to the question
                                q.question.subjectId = q.subjectId;
                                q.question.psgQId = '';
                                q.question.sectionName = sectionInfo.sectionName;
                                q.question.sectionId = sectionInfo.sectionId;

                                finalQuestions.push(q.question);
                                newlyAddedQs.push(q.questionId);
                            }
                        } else {
                            if (!notFitQuestions.includes(q.questionId)) {
                                notFitQuestions.push(q.questionId);
                            }
                        }
                    }
                } else {
                    if (!questionsNotInSubjects.includes(q.questionId)) {
                        questionsNotInSubjects.push(q.questionId);
                    }
                }
            });
        }

        // Clean up tracking arrays
        newlyAddedQs.forEach(q => {
            const index = notFitQuestions.indexOf(q);
            if (index > -1) notFitQuestions.splice(index, 1);

            const subjectIndex = questionsNotInSubjects.indexOf(q);
            if (subjectIndex > -1) questionsNotInSubjects.splice(subjectIndex, 1);
        });

        if (notFitQuestions.length || questionsNotInSubjects.length) {
            this.toast.show({
                severity: 'error',
                summary: 'Some questions could not be added',
                detail: `Questions that don't fit criteria: ${notFitQuestions.join(', ')}. 
                        Questions not in selected subjects: ${questionsNotInSubjects.join(', ')}.`,
                life: 20000
            });

            // If some questions were added successfully, update state
            if (newlyAddedQs.length > 0) {
                const updatedExam = this.recalculateSectionCounts();

                this.setState({
                    selectedExamQuestions: finalQuestions,
                    exam: updatedExam  // Use the recalculated version
                });

                this.toast.show({
                    severity: 'info',
                    summary: 'Partial Success',
                    detail: `${newlyAddedQs.length} questions were added successfully.`,
                    life: 10000
                });
            }
        } else {
            const updatedExam = this.recalculateSectionCounts();

            this.setState({
                selectedExamQuestions: finalQuestions,
                exam: updatedExam  // Use the recalculated version
            });

            this.toast.show({
                severity: 'success',
                summary: 'Success',
                detail: 'All questions added successfully.',
                life: 10000
            });
        }
    }

    getQuestions = () => {
        let qIds = this.state.questionIds;
        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/question/fetchquestionbyidarray`
        this.service.post(url, { questionIds: qIds }, true).then((data) => {
            if (data && data.res && data.res.questions && data.res.questions.length) {
                this.setState({
                    isLoading: false,
                    questionIds: []
                }, () => {
                    this.onFetchQuestions(data.res.questions, qIds)
                });
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'No questions', detail: 'No questions found with the ids.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
        });
    }

    openBulkQuestionSelector = () => {
        // Check if exam or subjects exists
        if (!this.state.exam || !this.state.exam.subjects || !Array.isArray(this.state.exam.subjects) || this.state.exam.subjects.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'No subjects available',
                detail: 'Please select a template with subjects first',
                life: 3000
            });
            return;
        }

        const activeSubjectIndex = this.state.activeSubjectIndex || 0;
        // Ensure the subject at this index exists
        if (!this.state.exam.subjects[activeSubjectIndex]) {
            this.toast.show({
                severity: 'error',
                summary: 'Subject not found',
                detail: 'Selected subject is not available',
                life: 3000
            });
            return;
        }

        const subject = this.state.exam.subjects[activeSubjectIndex];

        if (!subject || !subject.sections || !Array.isArray(subject.sections) || subject.sections.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'No sections available',
                detail: 'Please select a template with sections first',
                life: 3000
            });
            return;
        }

        // Make sure the first section has marking scheme
        if (!subject.sections[0].markingScheme || !Array.isArray(subject.sections[0].markingScheme) || subject.sections[0].markingScheme.length === 0) {
            this.toast.show({
                severity: 'error',
                summary: 'No marking scheme',
                detail: 'Section has no marking scheme defined',
                life: 3000
            });
            return;
        }

        this.setState({
            showBulkQuestionSelector: true,
            questionSelection: {
                subjectId: subject.subjectId,
                sectionName: subject.sections[0].sectionName,
                questionType: subject.sections[0].markingScheme[0].questionType
            },
            currentSubjectId: subject.subjectId,
            selectedBulkQuestions: []
        }, () => {
            this.fetchQuestionsForSection();
        });
    }

    // Modify fetchQuestionsForSection to not reset filters when applying them
    fetchQuestionsForSection = () => {
        const { questionSelection, questionFilters } = this.state;

        if (!questionSelection.subjectId) {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Please select a subject first',
                life: 3000
            });
            return;
        }

        // Only fetch chapters if they haven't been fetched already
        if (!this.state.chapters || this.state.chapters.length === 0) {
            this.fetchChaptersForSubject(questionSelection.subjectId, false);
        }

        this.setState({ isLoadingQuestions: true });

        // Prepare filters for API call
        let filters = {
            subjectId: questionSelection.subjectId,
            questionType: questionSelection.questionType || 'SCCT'
        };

        // Add optional filters if provided
        if (questionFilters.chapterId) filters.chapterId = questionFilters.chapterId;
        if (questionFilters.topicId) filters.topicId = questionFilters.topicId;
        if (questionFilters.difficulty) filters.difficulty = questionFilters.difficulty;

        // Call API to get questions
        this.service.post(`${baseUrlAdmin}/question/filter/questions`, filters, true)
            .then(response => {
                if (response && response.status && response.res && response.res.questions) {
                    // Process questions to ensure they have proper structure
                    const processedQuestions = response.res.questions.map(q => {
                        // Make sure question has the right structure for display
                        if (!q.question) {
                            return {
                                ...q,
                                question: q,  // This ensures QuestionInTable always gets a question object
                                questionId: q.questionId || q._id
                            };
                        }
                        return q;
                    });

                    this.setState({
                        availableQuestions: processedQuestions,
                        isLoadingQuestions: false
                    });
                } else {
                    this.setState({
                        availableQuestions: [],
                        isLoadingQuestions: false
                    });
                    this.toast.show({
                        severity: 'info',
                        summary: 'No questions found',
                        detail: 'No questions match your current filters',
                        life: 3000
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching questions:", error);
                this.setState({ isLoadingQuestions: false });
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to fetch questions. Please check your filters and try again.',
                    life: 3000
                });
            });
    }

    fetchChaptersForSubject = (subjectId, resetFilters = true) => {
        const { subjects } = this.state;

        // If resetFilters is true, reset the filters; otherwise keep existing values
        if (resetFilters) {
            this.setState({
                chapters: [],
                topics: [],
                questionFilters: {
                    ...this.state.questionFilters,
                    chapterId: null,
                    topicId: null
                }
            });
        }

        if (!subjects || !Array.isArray(subjects)) return;

        // Find the selected subject in the subjects array
        const selectedSubject = subjects.find(subject => subject.subjectId === subjectId);

        if (selectedSubject && selectedSubject.chapters && Array.isArray(selectedSubject.chapters)) {
            this.setState({
                chapters: selectedSubject.chapters
            });
        }
    }

    // Update handleQuestionSelectionChange to preserve filter selections when possible
    handleQuestionSelectionChange = (field, value) => {
        if (field === 'subjectId') {
            // When subject changes, we need to reset filters as they're subject-specific
            this.setState(prevState => ({
                questionSelection: {
                    ...prevState.questionSelection,
                    [field]: value,
                    // Reset section and question type when subject changes
                    sectionName: null,
                    questionType: null
                },
                questionFilters: {
                    chapterId: null,
                    topicId: null // Reset topic when subject changes
                },
                // Clear any previously loaded chapters and topics
                chapters: [],
                topics: []
            }), () => {
                this.fetchQuestionsForSection();
            });
        } else {
            // For other filters (section, question type), just update the value
            this.setState(prevState => ({
                questionSelection: {
                    ...prevState.questionSelection,
                    [field]: value
                }
            }), () => {
                if (field === 'sectionName' || field === 'questionType') {
                    this.fetchQuestionsForSection();
                }
            });
        }
    }

    addSelectedQuestions = () => {
        const { selectedBulkQuestions, questionSelection, exam } = this.state;

        if (!selectedBulkQuestions || selectedBulkQuestions.length === 0) {
            this.toast.show({
                severity: 'warn',
                summary: 'No questions selected',
                detail: 'Please select at least one question',
                life: 3000
            });
            return;
        }

        // Find the subject and section in exam
        const subject = exam.subjects.find(s => s.subjectId === questionSelection.subjectId);
        if (!subject) {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Selected subject not found in exam',
                life: 3000
            });
            return;
        }

        const section = subject.sections.find(s => s.sectionName === questionSelection.sectionName);
        if (!section) {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Selected section not found in exam',
                life: 3000
            });
            return;
        }

        // Find the marking scheme for the question type
        const scheme = section.markingScheme.find(m => m.questionType === questionSelection.questionType);
        if (!scheme) {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'No marking scheme found for this question type',
                life: 3000
            });
            return;
        }

        // Check if adding these questions would exceed the required count
        const currentCount = this.getSelectedQuestionCount(
            questionSelection.subjectId,
            questionSelection.sectionName,
            questionSelection.questionType
        );
        const requiredCount = parseInt(scheme.mandatoryQuestions);

        if (currentCount + selectedBulkQuestions.length > requiredCount) {
            this.toast.show({
                severity: 'warn',
                summary: 'Too many questions',
                detail: `You can only add ${requiredCount - currentCount} more ${questionSelection.questionType} questions to this section`,
                life: 3000
            });
            return;
        }

        // Process each selected question - add section info to each question
        const questionIds = selectedBulkQuestions.map(q => q.questionId);

        // Prepare questions with section information
        const preparedQuestions = selectedBulkQuestions.map(q => ({
            ...q,
            sectionInfo: {
                subjectId: questionSelection.subjectId,
                sectionId: section.sectionId,
                sectionName: questionSelection.sectionName,
                questionType: questionSelection.questionType
            }
        }));

        // Now use the existing service call to add these questions
        this.setState({
            isLoading: true,
            showBulkQuestionSelector: false,
            // Store the section info for later use
            currentSectionInfo: {
                subjectId: questionSelection.subjectId,
                sectionId: section.sectionId,
                sectionName: questionSelection.sectionName
            }
        });

        const url = `${baseUrlAdmin}/question/questions-by-ids`;
        this.service.post(url, {
            questionIds: questionIds,
            sectionInfo: {
                subjectId: questionSelection.subjectId,
                sectionId: section.sectionId,
                sectionName: questionSelection.sectionName,
                questionType: questionSelection.questionType
            }
        }, true)
            .then((data) => {
                if (data && data.res && data.res.questions && data.res.questions.length) {
                    // Add section info to the fetched questions
                    const questionsWithSectionInfo = data.res.questions.map(q => ({
                        ...q,
                        selectedSectionInfo: {
                            subjectId: questionSelection.subjectId,
                            sectionId: section.sectionId,
                            sectionName: questionSelection.sectionName,
                            questionType: questionSelection.questionType
                        }
                    }));

                    this.setState({ isLoading: false }, () => {
                        // Pass the modified questions to onFetchQuestions
                        this.onFetchQuestionsWithSection(questionsWithSectionInfo, questionIds);
                    });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({
                        severity: 'error',
                        summary: 'No questions',
                        detail: 'No questions found with the selected IDs.',
                        life: 3000
                    });
                }
            })
            .catch(e => {
                this.setState({ isLoading: false });
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to fetch questions. Please try again.',
                    life: 3000
                });
            });
    }

    // Add a new method to handle questions with section information
    onFetchQuestionsWithSection = (questions, qIds) => {
        if (questions && questions.length) {
            questions = this.orderQuestions(questions, qIds);
        }

        let notFoundQuestions = [];

        for (let i = 0; i < qIds.length; i++) {
            let question = questions.find(q => q.questionId == qIds[i]);
            if (!question) {
                notFoundQuestions.push(qIds[i]);
            }
        }

        if (notFoundQuestions.length) {
            this.toast.show({
                severity: 'error',
                summary: 'Questions are not added due to some questions not found.',
                detail: `Having issue with the following questions: ${notFoundQuestions.join(',')}.`,
                life: 20000
            });
            return;
        }

        let { exam, selectedExamQuestions } = this.state;
        let subjectIds = exam.subjects.map((q) => q.subjectId);

        let finalQuestions = _.cloneDeep(selectedExamQuestions);
        let examT = _.cloneDeep(exam);

        // Check for duplicate questions
        let duplicateQuestions = [];
        if (questions.length) {
            questions.forEach(q => {
                if (q && q.question) {
                    if (q.question.questionType == 'PSGS' || q.question.questionType == 'PSGM') {
                        q.question.questions.forEach(subQ => {
                            if (finalQuestions.find(qa => qa.questionId == subQ.questionId)) {
                                if (!duplicateQuestions.includes(q.questionId)) {
                                    duplicateQuestions.push(q.questionId);
                                }
                            }
                        });
                    } else {
                        if (finalQuestions.find(qa => qa.questionId == q.question.questionId)) {
                            if (!duplicateQuestions.includes(q.questionId)) {
                                duplicateQuestions.push(q.questionId);
                            }
                        }
                    }
                }
            });
        }

        if (duplicateQuestions.length) {
            this.toast.show({
                severity: 'error',
                summary: 'Questions are not added due to duplicate questions.',
                detail: `Having issue with the following questions: ${duplicateQuestions.join(',')}.`,
                life: 20000
            });
            return;
        }

        let questionsNotInSubjects = [];
        let newlyAddedQs = [];
        let notFitQuestions = [];

        if (questions && questions.length) {
            questions.forEach(q => {
                // Use the selected section info from the bulk selection
                const sectionInfo = q.selectedSectionInfo;

                if (subjectIds.includes(q.subjectId)) {
                    if (q.question.questionType == QuestionTypeKeys.PSGS || q.question.questionType == QuestionTypeKeys.PSGM) {
                        // For passage questions, use the section info from the bulk selector
                        const targetSubject = examT.subjects.find(subject => subject.subjectId == q.subjectId);
                        if (!targetSubject) {
                            questionsNotInSubjects.push(q.questionId);
                            return;
                        }

                        // Find the section this question should go to based on the selection
                        const targetSection = targetSubject.sections.find(section =>
                            section.sectionName === sectionInfo.sectionName
                        );

                        if (!targetSection) {
                            notFitQuestions.push(q.questionId);
                            return;
                        }

                        // Find the matching scheme
                        const targetScheme = targetSection.markingScheme.find(
                            scheme => scheme.questionType === q.question.questionType
                        );

                        if (!targetScheme) {
                            notFitQuestions.push(q.questionId);
                            return;
                        }

                        // Count questions already in this section with this type
                        const currentCount = finalQuestions.filter(fq =>
                            fq.subjectId === q.subjectId &&
                            fq.sectionId === targetSection.sectionId &&
                            fq.questionType === q.question.questionType
                        ).length;

                        // Check if we can add all subquestions
                        const newQuestionsCount = q.question.questions.length;

                        if (currentCount + newQuestionsCount <= parseInt(targetScheme.mandatoryQuestions)) {
                            // We can add all subquestions
                            targetScheme.totalSelectedQuestions = currentCount + newQuestionsCount;

                            // Add subquestions with proper section info
                            q.question.questions.forEach((subQ) => {
                                subQ.subjectId = q.subjectId;
                                subQ.psgQId = q.questionId;
                                subQ.sectionName = sectionInfo.sectionName;  // Use the selected section name
                                subQ.sectionId = targetSection.sectionId;
                                subQ.questionType = q.question.questionType;  // Make sure question type is also set

                                finalQuestions.push(subQ);
                            });

                            newlyAddedQs.push(q.questionId);
                        } else {
                            notFitQuestions.push(q.questionId);
                        }
                    } else {
                        // For regular questions
                        const targetSubject = examT.subjects.find(subject => subject.subjectId == q.subjectId);
                        if (!targetSubject) {
                            questionsNotInSubjects.push(q.questionId);
                            return;
                        }

                        // Find the section this question should go to based on the selection
                        const targetSection = targetSubject.sections.find(section =>
                            section.sectionName === sectionInfo.sectionName
                        );

                        if (!targetSection) {
                            notFitQuestions.push(q.questionId);
                            return;
                        }

                        // Get the question type directly from q (not q.question)
                        const questionType = q.questionType;

                        // Find the matching scheme using the question type from q
                        const targetScheme = targetSection.markingScheme.find(
                            scheme => scheme.questionType === questionType
                        );

                        if (!targetScheme) {
                            notFitQuestions.push(q.questionId);
                            return;
                        }

                        // Count questions already in this section with this type
                        const currentCount = finalQuestions.filter(fq =>
                            fq.subjectId === q.subjectId &&
                            fq.sectionName === targetSection.sectionName &&
                            fq.questionType === questionType
                        ).length;

                        if (currentCount < parseInt(targetScheme.mandatoryQuestions)) {
                            // We can add this question
                            targetScheme.totalSelectedQuestions = currentCount + 1;

                            // Add question properties directly to q (not q.question)
                            q.psgQId = '';
                            q.sectionName = sectionInfo.sectionName;
                            q.sectionId = targetSection.sectionId;

                            // Push the question itself (not q.question)
                            finalQuestions.push(q);
                            newlyAddedQs.push(q.questionId);
                        } else {
                            notFitQuestions.push(q.questionId);
                        }
                    }
                } else {
                    questionsNotInSubjects.push(q.questionId);
                }
            });
        }

        // Clean up tracking arrays
        newlyAddedQs.forEach(q => {
            const index = notFitQuestions.indexOf(q);
            if (index > -1) notFitQuestions.splice(index, 1);

            const subjectIndex = questionsNotInSubjects.indexOf(q);
            if (subjectIndex > -1) questionsNotInSubjects.splice(subjectIndex, 1);
        });

        if (notFitQuestions.length || questionsNotInSubjects.length) {
            this.toast.show({
                severity: 'error',
                summary: 'Some questions could not be added',
                detail: `Questions that don't fit criteria: ${notFitQuestions.join(', ')}. 
                        Questions not in selected subjects: ${questionsNotInSubjects.join(', ')}.`,
                life: 20000
            });

            // If some questions were added successfully, update state
            if (newlyAddedQs.length > 0) {
                const updatedExam = this.recalculateSectionCounts();

                this.setState({
                    selectedExamQuestions: finalQuestions,
                    exam: updatedExam  // Use the recalculated version
                });

                this.toast.show({
                    severity: 'info',
                    summary: 'Partial Success',
                    detail: `${newlyAddedQs.length} questions were added successfully.`,
                    life: 10000
                });
            }
        } else {
            const updatedExam = this.recalculateSectionCounts();

            this.setState({
                selectedExamQuestions: finalQuestions,
                exam: updatedExam  // Use the recalculated version
            });

            this.toast.show({
                severity: 'success',
                summary: 'Success',
                detail: 'All questions added successfully.',
                life: 10000
            });
        }
    }

    getSelectedQuestionCount = (subjectId, sectionName, questionType) => {
        const { selectedExamQuestions } = this.state;

        // Simple validation
        if (!subjectId || !sectionName || !questionType || !selectedExamQuestions) {
            return 0;
        }

        // Find questions that match exactly this subject, section name, and question type
        const count = selectedExamQuestions.filter(question =>
            question &&
            question.subjectId === subjectId &&
            question.sectionName === sectionName &&
            question.questionType === questionType
        ).length;

        return count;
    }

    getRequiredQuestionCount = (subjectId, sectionName, questionType) => {
        const { exam } = this.state;

        // Check for invalid input parameters
        if (!subjectId || !sectionName || !questionType) return 0;

        // Check if we have a valid exam with subjects
        if (!exam || !exam.subjects) return 0;

        // Make sure subjects is treated as an array
        const subjects = Array.isArray(exam.subjects) ? exam.subjects : [];

        const subject = subjects.find(s => s && s.subjectId === subjectId);
        if (!subject || !subject.sections) return 0;

        // Make sure sections is treated as an array
        const sections = Array.isArray(subject.sections) ? subject.sections : [];

        const section = sections.find(s => s && s.sectionName === sectionName);
        if (!section || !section.markingScheme) return 0;

        // Make sure markingScheme is treated as an array
        const markingScheme = Array.isArray(section.markingScheme) ? section.markingScheme : [];

        const scheme = markingScheme.find(s => s && s.questionType === questionType);
        return scheme ? parseInt(scheme.mandatoryQuestions || 0) : 0;
    }

    handleFilterChange = (field, value) => {
        // Store the current filter state to retain other selections
        const currentFilters = { ...this.state.questionFilters };

        if (field === 'chapterId') {
            // Find the selected chapter to get its topics
            const selectedChapter = this.state.chapters.find(chapter => chapter.chapterId === value);

            // Update state with new chapter ID and reset topic
            this.setState({
                questionFilters: {
                    ...currentFilters,
                    chapterId: value,
                    topicId: null // Reset topic when chapter changes
                },
                // If chapter has topics, load them
                topics: selectedChapter && selectedChapter.topics ? selectedChapter.topics : []
            });
        } else {
            // For other filters (topic, difficulty), just update the value
            this.setState({
                questionFilters: {
                    ...currentFilters,
                    [field]: value
                }
            });
        }
    }

    // Add this new method to the class
    findSectionForQuestion = (subjects, question) => {
        // Find the appropriate section for this question type
        const targetSubject = subjects.find(subject => subject.subjectId === question.subjectId);
        if (!targetSubject) return null;

        // Find section with matching marking scheme
        for (const section of targetSubject.sections || []) {
            if (section.markingScheme && Array.isArray(section.markingScheme)) {
                const matchingScheme = section.markingScheme.find(
                    scheme => scheme.questionType === question.question.questionType
                );

                if (matchingScheme) {
                    return {
                        sectionName: section.sectionName,
                        sectionId: section.sectionId
                    };
                }
            }
        }

        return null;
    }

    // Add this method to calculate the total marks based on selected questions
    calculateTotalMarks = () => {
        const { exam, selectedExamQuestions } = this.state;
        let totalMarks = 0;

        if (exam?.subjects && Array.isArray(exam.subjects)) {
            // Go through each subject, section, and marking scheme
            exam.subjects.forEach(subject => {
                if (subject?.sections && Array.isArray(subject.sections)) {
                    subject.sections.forEach(section => {
                        if (section?.markingScheme && Array.isArray(section.markingScheme)) {
                            section.markingScheme.forEach(scheme => {
                                // Count how many questions of this type are in this section
                                const questionCount = selectedExamQuestions.filter(q =>
                                    q.subjectId === subject.subjectId &&
                                    q.sectionName === section.sectionName &&
                                    q.questionType === scheme.questionType
                                ).length;

                                // Add the marks for these questions
                                totalMarks += questionCount * parseInt(scheme.positiveMarking || 0);
                            });
                        }
                    });
                }
            });
        }

        return totalMarks;
    }

    render() {
        const { exam, formValidations, questions, selectedExamQuestions } = this.state;
        console.log('state', this.state)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalFilter} className="ma-tbl-search p-inputtext-md" onChange={(e) => {
                        this.setState({
                            globalFilter: e.target.value
                        })
                    }} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        {/* <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" /> */}
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        //   console.log('this.props', this.props)
        return (<div className='create-quiz'>
            {this.state.currentStep == 1 && (
                <div className='ma-p20 paper-s1'>
                    <div className="grid">
                        {/* First Row */}
                        <div className="col-4">
                            <p className='ma-label-s2'>Name of the Test<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.EXAM_NAME} id="kexpT">
                                <InputText
                                    value={exam.examName}
                                    onChange={(e) => { onTextChange(e.target.value, 'examName', this, adhocFields, exam, formValidations, 'exam', 'formValidations') }}
                                    className='w-full'
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['examName'].isValid &&
                                <p className="p-error">{formValidations.fields['examName'].errorMsg}</p>}
                        </div>

                        <div className="col-4">
                            <p className='ma-label-s2'>Test Type<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.TEST_TYPE} id="lexpT">
                                <Dropdown
                                    value={exam.testType}
                                    className='w-full'
                                    options={[{ label: 'Open Test', value: 'open' }, { label: 'Closed Test', value: 'closed' }]}
                                    onChange={(e) => {
                                        onDropDownChange(e.value, 'testType', this, adhocFields, exam, formValidations, 'exam', 'formValidations')
                                        this.updateDateFileds(e.value)
                                    }}
                                    placeholder="Select Test Type"
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['testType'].isValid &&
                                <p className="p-error">{formValidations.fields['testType'].errorMsg}</p>}
                        </div>

                        <div className="col-4">
                            <p className='ma-label-s2'>Template<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.TEMPLATE} id="lea2eT">
                                <Dropdown
                                    value={exam.templateId}
                                    className='w-full'
                                    options={this.state.examTemplates}
                                    optionValue='_id'
                                    optionLabel='name'
                                    disabled={this.props.editExamId}
                                    onChange={(e) => { this.onTemplateChange(e.value, 'templateId', this, adhocFields, exam, formValidations, 'exam', 'formValidations') }}
                                    placeholder="Select Template"
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['templateId'].isValid &&
                                <p className="p-error">{formValidations.fields['templateId'].errorMsg}</p>}
                        </div>

                        {/* Second Row */}
                        <div className="col-4">
                            <p className='ma-label-s2'>Board Name<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.BOARD_SELECT} id="leeaeT">
                                <Dropdown
                                    value={this.state.boardId}
                                    className='w-full'
                                    options={this.props.boards}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    disabled={this.props.editExamId}
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board"
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['boardId'].isValid &&
                                <p className="p-error">{formValidations.fields['boardId'].errorMsg}</p>}
                        </div>

                        <div className="col-4">
                            <p className='ma-label-s2'>Class Name<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.CLASS_SELECT} id="leaaeT">
                                <Dropdown
                                    value={this.state.classId}
                                    options={this.state.classes}
                                    optionLabel="className"
                                    optionValue="classId"
                                    className='w-full'
                                    disabled={this.props.editExamId}
                                    onChange={(e) => this.onChangeClass(e.value)}
                                    placeholder="Select Class"
                                />
                            </InputTextB>
                            {formValidations && !formValidations.fields['classId'].isValid &&
                                <p className="p-error">{formValidations.fields['classId'].errorMsg}</p>}
                        </div>

                        {/* Add test type specific fields conditionally */}
                        {exam.testType == 'closed' && (
                            <>
                                <div className="col-4">
                                    <p className='ma-label-s2'>Start date and time<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.VALID_FROM} id="lexpeT">
                                        <Calendar
                                            value={exam.fromDateTime ? new Date(exam.fromDateTime) : getServerTime()}
                                            showIcon={true}
                                            placeholder='Start Date'
                                            id='fromDates'
                                            showTime
                                            minDate={getServerTime()}
                                            onChange={(e) => this.onExamStartTImeChange(e.value, 'fromDateTime', this, adhocFields, exam, formValidations, 'exam', 'formValidations')}
                                            className='w-full'
                                        />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['fromDateTime'].isValid &&
                                        <p className="p-error">{formValidations.fields['fromDateTime'].errorMsg}</p>}
                                </div>

                                <div className="col-4">
                                    <p className='ma-label-s2'>End date and time<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.VALID_TO} id="leaeT">
                                        <Calendar
                                            value={exam.toDateTime ? new Date(exam.toDateTime) : getServerTime()}
                                            showIcon={true}
                                            placeholder='End Date'
                                            id='toDateTi'
                                            showTime
                                            minDate={new Date(exam.fromDateTime)}
                                            onChange={(e) => this.onExamEndTimeChange(e.value, 'toDateTime', this, adhocFields, exam, formValidations, 'exam', 'formValidations')}
                                            className='w-full'
                                        />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['toDateTime'].isValid &&
                                        <p className="p-error">{formValidations.fields['toDateTime'].errorMsg}</p>}
                                </div>
                            </>
                        )}
                    </div>

                    {/* Subjects section */}
                    {exam.templateId && (
                        <div className="subjects-section mt-4">
                            <h3 className="mb-3">Subjects</h3>
                            <div className="grid">
                                {exam.subjects && exam.subjects.map((s, index) => (
                                    <div className="col-4" key={s.id || `subject-${index}`}>
                                        <p className='ma-label-s2'>Subject {index + 1}<span className='ma-required'>*</span></p>
                                        <InputTextB info={FIELDS_INFO.SUBJECT_SELECT} id={`subject-${index}`}>
                                            <Dropdown
                                                disabled={this.props.editExamId}
                                                value={s.subjectId}
                                                options={this.state.subjects}
                                                optionLabel='subjectName'
                                                optionValue='subjectId'
                                                className='w-full'
                                                onChange={(e) => this.onChangeSubject(s.id, e.value)}
                                                placeholder="Select Subject"
                                            />
                                        </InputTextB>
                                    </div>
                                ))}
                            </div>
                            {formValidations && !formValidations.fields['subjects'].isValid &&
                                <p className="p-error mt-2">{formValidations.fields['subjects'].errorMsg}</p>}
                        </div>
                    )}
                </div>
            )}

            {this.state.currentStep == 2 && (
                <>
                    <div className='ma-right mb-3'>
                        <p className='adhoc-q-select-c'> <span>{selectedExamQuestions.length}</span> out of {exam.totalNoOfQuestions} Questions selected</p>
                        <p className='adhoc-q-select-c'>All subjects total marks: <span>{this.calculateTotalMarks()}</span></p>
                    </div>
                    <div className='ma-clearFix'></div>

                    <div className='adhoc-q-t mb-4'>
                        <div className="p-grid ma-w100p ma-p20">
                            <div className="p-col-12 p-md-6">
                                <TabView
                                    activeIndex={this.state.activeSubjectIndex}
                                    onTabChange={(e) => this.setState({ activeSubjectIndex: e.index })}
                                    className="adhoc-subjects-tabs"
                                >
                                    {exam.subjects && exam.subjects.length > 0 ? exam.subjects.map((subject) => {
                                        return (
                                            <TabPanel key={subject.id || `subject-${subject.subjectId}`} header={subject.subjectName || 'Subject'}>
                                                {subject.sections && subject.sections.length > 0 ? (
                                                    subject.sections.map((section, sectionIndex) => (
                                                        <div key={`section-${sectionIndex}`} className="section-container mb-3 p-2 border-1 border-round surface-100">
                                                            <h4 className="section-name mb-2">{section.sectionName}</h4>
                                                            <div className="section-info mb-2 flex align-items-center justify-content-between">
                                                                <span>Total Questions: {section.totalQuestions}</span>
                                                                <span>Instruction: {section.sectionInstruction}</span>
                                                            </div>

                                                            {section.markingScheme && section.markingScheme.length > 0 ? (
                                                                section.markingScheme.map((scheme, schemeIndex) => (
                                                                    <div key={`scheme-${sectionIndex}-${schemeIndex}`} className="adhoc-q-selection">
                                                                        <span className='q-t-label'>{scheme.questionType}</span>
                                                                        <span className='q-t-counts'>
                                                                            {this.getSelectedQuestionCount(
                                                                                subject.subjectId,
                                                                                section.sectionName,
                                                                                scheme.questionType
                                                                            )} / {scheme.mandatoryQuestions || 0}
                                                                        </span>
                                                                        <span className='q-t-label q-tl2 ma-ml20'>{scheme.questionType} Marks</span>
                                                                        <span className='q-t-counts'>
                                                                            {parseInt(scheme.mandatoryQuestions || 0) * parseInt(scheme.positiveMarking || 0)}
                                                                        </span>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="p-2">No marking scheme available for this section</div>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="p-3">No sections available for this subject</div>
                                                )}
                                            </TabPanel>
                                        );
                                    }) : (
                                        <TabPanel header="No Subjects">
                                            <div>No subjects available. Please select a template first.</div>
                                        </TabPanel>
                                    )}
                                </TabView>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-card p-3 border-1 surface-border border-round">
                                    <div className="mb-3">
                                        <p className='ma-label-s2 mb-2'>Enter question Ids</p>
                                        <div>
                                            <Chips
                                                className='w-full'
                                                value={this.state.questionIds}
                                                onChange={(e) => {
                                                    this.setState({
                                                        questionIds: e.value
                                                    })
                                                }}
                                                separator=','
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-content-between align-items-center mt-3">
                                        <Button
                                            label='Choose'
                                            icon="pi pi-list"
                                            className='p-button-secondary'
                                            onClick={this.openBulkQuestionSelector}
                                        />
                                        <Button
                                            label='Add Questions'
                                            icon="pi pi-plus"
                                            className='add-questions p-button-primary'
                                            onClick={this.getQuestions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add clear separation between the question entry area and the table */}
                    <div className="mb-4"></div>

                    <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table">
                        <DataTable
                            ref={(el) => this.dt = el}
                            scrollable
                            scrollHeight="500px"
                            responsiveLayout="scroll"
                            dataKey="_id"
                            value={
                                // Filter questions to only show those from the selected subject
                                this.state.selectedExamQuestions.filter(question =>
                                    this.state.exam.subjects[this.state.activeSubjectIndex || 0]?.subjectId === question.subjectId
                                )
                                    // Sort by section name first, then by question number
                                    .sort((a, b) => {
                                        // First sort by section name
                                        if (a.sectionName !== b.sectionName) {
                                            return a.sectionName?.localeCompare(b.sectionName || '');
                                        }
                                        // Then sort by question number
                                        return (a.qNo || 0) - (b.qNo || 0);
                                    })
                            }
                            className="ma-table-d"
                            tableClassName='ma-table-tbl'
                            paginatorLeft={() => {
                                return (
                                    <p className='avail-q'> Questions selected: <span>{this.state.selectedExamQuestions.length}</span> </p>
                                );
                            }}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            header={header}
                        >
                            <Column headerClassName='section-name' field="sectionName" header="Section" />
                            <Column filterField='questionId' headerClassName='question-id' field="questionId" header="Question ID" />
                            <Column filterField='questionType' headerClassName='question-type' field="questionType" header="Question Type" />
                            <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                                return (
                                    <>
                                        <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id} ma-mr20`}
                                            onClick={() => {
                                                this.setState({
                                                    isShowQuestionPreview: true,
                                                    questionToPreview: rowData
                                                })
                                            }}>
                                            <PreviewIcon width={20} height={20} />
                                        </span>
                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />

                                        <span data-pr-tooltip="Remove" data-pr-position="bottom" className={`delete${rowData._id}`}
                                            onClick={() => this.removeQuestion(rowData)}>
                                            <DeleteIcon width={20} height={20} />
                                        </span>
                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.delete${rowData._id}`} />
                                    </>
                                );
                            }} />
                        </DataTable>
                    </div>
                </>
            )}


            <Toolbar className="ma-toolbard ma-mt20" right={() => <>
                {
                    this.state.currentStep == 2 && <Button label='Previous' className='ma-m-lr10 p-button-outlined'
                        onClick={() => {
                            this.setState({
                                currentStep: 1
                            })
                        }} />
                }

                <Button label='Proceed' className='ma-m-lr10'
                    onClick={this.onProceed}

                />

            </>}

            ></Toolbar>



            {
                this.state.isShowQuestionPreview && <PreviewQuestion
                    question={this.state.questionToPreview}
                    onHide={() => {
                        this.setState({
                            isShowQuestionPreview: false
                        })
                    }}
                />
            }
            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            <Dialog
                header="Select Questions"
                visible={this.state.showBulkQuestionSelector}
                style={{ width: '90vw' }}
                modal
                onHide={() => this.setState({ showBulkQuestionSelector: false })}
                footer={
                    <div>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            onClick={() => this.setState({ showBulkQuestionSelector: false })}
                            className="p-button-text"
                        />
                        <Button
                            label="Add Selected"
                            icon="pi pi-check"
                            onClick={this.addSelectedQuestions}
                            autoFocus
                            disabled={!this.state.selectedBulkQuestions.length}
                        />
                    </div>
                }
            >
                <div className="grid">
                    <div className="col-12 md:col-4">
                        <div className="p-field mb-3">
                            <label htmlFor="subject-select" className="block font-medium mb-2">Subject</label>
                            <Dropdown
                                id="subject-select"
                                value={this.state.questionSelection.subjectId}
                                options={this.state.exam.subjects}
                                optionLabel="subjectName"
                                optionValue="subjectId"
                                onChange={(e) => this.handleQuestionSelectionChange('subjectId', e.value)}
                                placeholder="Select Subject"
                                className="w-full"
                            />
                        </div>

                        <div className="p-field mb-3">
                            <label htmlFor="section-select" className="block font-medium mb-2">Section</label>
                            <Dropdown
                                id="section-select"
                                value={this.state.questionSelection.sectionName}
                                options={
                                    this.state.exam && this.state.exam.subjects && Array.isArray(this.state.exam.subjects) &&
                                        this.state.questionSelection.subjectId ?
                                        (this.state.exam.subjects.find(s => s && s.subjectId === this.state.questionSelection.subjectId)?.sections || []) :
                                        []
                                }
                                optionLabel="sectionName"
                                optionValue="sectionName"
                                onChange={(e) => this.handleQuestionSelectionChange('sectionName', e.value)}
                                placeholder="Select Section"
                                className="w-full"
                            />
                        </div>

                        <div className="p-field mb-3">
                            <label htmlFor="questionType-select" className="block font-medium mb-2">Question Type</label>
                            <Dropdown
                                id="questionType-select"
                                value={this.state.questionSelection.questionType}
                                options={
                                    this.state.exam && this.state.exam.subjects && Array.isArray(this.state.exam.subjects) &&
                                        this.state.questionSelection.subjectId && this.state.questionSelection.sectionName ?
                                        (this.state.exam.subjects.find(s => s && s.subjectId === this.state.questionSelection.subjectId)
                                            ?.sections?.find(sec => sec && sec.sectionName === this.state.questionSelection.sectionName)
                                            ?.markingScheme?.map(m => ({ label: m.questionType, value: m.questionType })) || []) :
                                        []
                                }
                                onChange={(e) => this.handleQuestionSelectionChange('questionType', e.value)}
                                placeholder="Select Question Type"
                                className="w-full"
                            />
                        </div>

                        {/* Show progress of selected questions */}
                        <div className="mb-3">
                            <label className="block font-medium mb-2">Selection Progress</label>
                            <ProgressBar
                                value={
                                    (this.getSelectedQuestionCount(
                                        this.state.questionSelection.subjectId,
                                        this.state.questionSelection.sectionName,
                                        this.state.questionSelection.questionType
                                    ) / Math.max(1, this.getRequiredQuestionCount(
                                        this.state.questionSelection.subjectId,
                                        this.state.questionSelection.sectionName,
                                        this.state.questionSelection.questionType
                                    ))) * 100
                                }
                                showValue={false}
                            />
                            <div className="flex justify-content-between">
                                <span className="font-medium">
                                    Selected: {this.getSelectedQuestionCount(
                                        this.state.questionSelection.subjectId,
                                        this.state.questionSelection.sectionName,
                                        this.state.questionSelection.questionType
                                    )}
                                </span>
                                <span className="font-medium">
                                    Required: {this.getRequiredQuestionCount(
                                        this.state.questionSelection.subjectId,
                                        this.state.questionSelection.sectionName,
                                        this.state.questionSelection.questionType
                                    )}
                                </span>
                            </div>
                        </div>

                        {/* Optional filters section */}
                        <div className="p-card p-3 border-1 surface-border border-round">
                            <h5 className="mt-0">Additional Filters</h5>

                            <div className="p-field mb-3">
                                <label htmlFor="chapter-select" className="block font-medium mb-2">Chapter</label>
                                <Dropdown
                                    id="chapter-select"
                                    value={this.state.questionFilters.chapterId}
                                    options={this.state.chapters || []}
                                    optionLabel="chapterName"
                                    optionValue="chapterId"
                                    onChange={(e) => this.handleFilterChange('chapterId', e.value)}
                                    placeholder="Select Chapter"
                                    className="w-full"
                                />
                            </div>

                            <div className="p-field mb-3">
                                <label htmlFor="topic-select" className="block font-medium mb-2">Topic</label>
                                <Dropdown
                                    id="topic-select"
                                    value={this.state.questionFilters.topicId}
                                    options={this.state.topics || []}
                                    optionLabel="topicName"
                                    optionValue="topicId"
                                    onChange={(e) => this.handleFilterChange('topicId', e.value)}
                                    placeholder="Select Topic"
                                    className="w-full"
                                    disabled={!this.state.questionFilters.chapterId}
                                />
                            </div>

                            <div className="p-field">
                                <label htmlFor="difficulty-select" className="block font-medium mb-2">Difficulty</label>
                                <Dropdown
                                    id="difficulty-select"
                                    value={this.state.questionFilters.difficulty}
                                    options={[
                                        { label: 'Easy', value: 'Easy' },
                                        { label: 'Medium', value: 'Medium' },
                                        { label: 'Hard', value: 'Hard' }
                                    ]}
                                    onChange={(e) => this.handleFilterChange('difficulty', e.value)}
                                    placeholder="Select Difficulty"
                                    className="w-full"
                                />
                            </div>

                            <Button
                                label="Apply Filters"
                                icon="pi pi-filter"
                                className="w-full mt-3"
                                onClick={this.fetchQuestionsForSection}
                            />
                        </div>
                    </div>

                    <div className="col-12 md:col-8">
                        {this.state.isLoadingQuestions ? (
                            <div className="flex align-items-center justify-content-center" style={{ height: '400px' }}>
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                            </div>
                        ) : this.state.availableQuestions.length === 0 ? (
                            <div className="flex align-items-center justify-content-center flex-column" style={{ height: '400px' }}>
                                <i className="pi pi-search" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>
                                <p className="text-center">No questions found matching your criteria.</p>
                            </div>
                        ) : (
                            <>
                                <div className="mb-3">
                                    <Message
                                        severity="info"
                                        text={`Found ${this.state.availableQuestions.length} questions. You can select up to ${Math.min(
                                            this.state.availableQuestions.length,
                                            this.getRequiredQuestionCount(
                                                this.state.questionSelection.subjectId,
                                                this.state.questionSelection.sectionName,
                                                this.state.questionSelection.questionType
                                            ) - this.getSelectedQuestionCount(
                                                this.state.questionSelection.subjectId,
                                                this.state.questionSelection.sectionName,
                                                this.state.questionSelection.questionType
                                            )
                                        )
                                            } questions.`}
                                    />
                                </div>

                                <DataTable
                                    value={this.state.availableQuestions}
                                    selection={this.state.selectedBulkQuestions}
                                    onSelectionChange={(e) => this.setState({ selectedBulkQuestions: e.value })}
                                    dataKey="questionId"
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="p-datatable-sm"
                                    selectionMode="multiple"
                                    emptyMessage="No questions found"
                                    resizableColumns
                                    columnResizeMode="fit"
                                >
                                    {/* Tighter column spacing with consistent widths */}
                                    <Column selectionMode="multiple" headerStyle={{ width: '2rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }} style={{ width: '2rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }} />
                                    <Column field="questionId" header="ID" sortable style={{ width: '8rem' }} />
                                    <Column
                                        header="Preview"
                                        headerStyle={{ width: '5rem', textAlign: 'center' }}
                                        style={{ width: '5rem', textAlign: 'center' }}
                                        body={(rowData) => {
                                            return (
                                                <span
                                                    data-pr-tooltip="Preview Question"
                                                    data-pr-position="bottom"
                                                    style={{ cursor: 'pointer', display: 'flex' }}
                                                    onClick={() => {
                                                        try {
                                                            let questionToPreview;

                                                            if (rowData.question && typeof rowData.question === 'object') {
                                                                questionToPreview = { ...rowData.question };
                                                            } else {
                                                                questionToPreview = { ...rowData };
                                                            }

                                                            if (!questionToPreview.questionType) {
                                                                questionToPreview.questionType = rowData.questionType || "SCCT";
                                                            }

                                                            this.setState({
                                                                isShowQuestionPreview: true,
                                                                questionToPreview: questionToPreview
                                                            });
                                                        } catch (error) {
                                                            this.toast.show({
                                                                severity: 'error',
                                                                summary: 'Error',
                                                                detail: 'Could not preview this question: ' + error.message,
                                                                life: 3000
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <i className="pi pi-eye" style={{ fontSize: '1.2rem', color: '#2196F3' }}></i>
                                                </span>
                                            );
                                        }}
                                    />
                                    <Column field="difficulty" header="Difficulty" sortable style={{ width: '8rem' }} />
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </Dialog>
        </div >)
    }
}


const mapsStatesToProps = (state) => {
    return {
        boards: userAssignedBoards(state, 'activeBoards'),
        isLoading: state.boardsData.loading,
    };
};

export default connect(mapsStatesToProps, {
    getBoardsData,
})(withRouter(CreateAdhoc));
