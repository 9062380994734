import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { retrieveUserFromLocalStorage, authUser } from '../store/actions';
import { getUserData, clearUserData } from '../store/actions';
import { isAuthorizedOne } from './authorize';
import withRouter from '../components/lib/withRouter';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

export default function (ComposedComponent) {
    function Authentication(props) {
        useEffect(() => {
            if (!props.authenticated) {

                retrieveUserFromLocalStorage(props.dispatch)
                    .then((res) => {
                        if (res) {
                            props.authUser(res);
                            // props.getUserData();
                        }
                    })
                    .catch((e) => {
                        props.navigate('/login');
                    });
            }
        }, []);
        // useEffect(() => {
        //   
        //     if (!props.authenticated) {
        //   

        //         props.navigate('/login');
        //     }
        // }, [props.authenticated]);

        return (
            <>
                <ComposedComponent {...props} />
            </>
        );
    }
  /*   class Authentication extends Component {
      state = {
        open: false
      };

      handleClick = () => {
        this.setState({ open: true });
      };

      handleClose = () => {
        this.setState({ open: false });
      };

      componentDidMount() {
        console.log(this.props)
        if (!this.props.authenticated) {
          retrieveUserFromLocalStorage(this.props.dispatch)
            .then((res) => {
              if (res) {
                this.props.authUser(res);
                this.props.getUserData();
              }
            })
            .catch((e) => {
              this.props.navigate('/login');
            });
        }
      }

      componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
          if (!this.props.authenticated) {
            this.props.navigate('/login');
          }
        }
      }

      render() {
        return (
          <>
            <ComposedComponent {...this.props} />
          </>
        );
      }
    } */

    function mapStateToProps(state) {
        return {
            authenticated: state.auth && state.auth.authenticated,
            userData: state.userData && state.userData.data && state.userData.data,
            userDataSuccess: state.userData && state.userData.userDataSuccess && state.userData.userDataSuccess,
            permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds
        };
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            authUser: (data) => dispatch(authUser(data)),
            // getUserData: () => dispatch(getUserData()),
            clearUserData: () => dispatch(clearUserData()),
            dispatch
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(withRouter(Authentication));
}
