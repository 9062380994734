import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import Service from '../../services';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin } from '../../../store/apiConstants';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import BranchToSectionDDPrefillCol from '../../BaseDropdownComponents/BranchToSectionDDPrefillCol';
import { AddIconInButtonFull, CalenderIcon, ClockIcon, CodeLinkIcon, MailIcon } from '../../svgIcons';
import './styles.scss';

// Event type options
const EVENT_TYPES = [
    { value: 'AE', label: "Academic Event" },
    { value: 'SE', label: "Sports Event" },
    { value: 'CE', label: "Cultural Event" },
    { value: 'ME', label: "Miscellaneous Event" },
    { value: 'EE', label: "Examination" },
    { value: 'HE', label: 'Holiday' }
];

// Repeat options for recurring events
const REPEAT_OPTIONS = [
    { value: 'DAILY', label: "Daily" },
    { value: 'WEEKLY', label: "Weekly" },
    { value: 'MONTHLY', label: "Monthly" },
    { value: 'YEARLY', label: "Yearly" },
];

// Event colors based on event type
const EVENT_COLORS = {
    'AE': '#0077B6', // Academic Event
    'SE': '#FAB710', // Sports Event
    'CE': '#A020F0', // Cultural Event
    'ME': '#E6E8EA', // Miscellaneous Event
    'EE': '#BF0031', // Examination
    'HE': '#268900', // Holiday
    'default': '#076EFF'
};

class AcademicCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: false,
            showEventDialog: false,
            showActionDialog: false,
            showDeleteDialog: false,
            isEditMode: false,
            selectedEvent: null,
            eventForm: {
                title: '',
                eventType: null,
                eventDate: null,
                startTime: null,
                endTime: null,
                fdEvent: false,
                recurring: false,
                eventDescription: '',
                repeat: null,
                recStartDate: null,
                recEndDate: null,
            },
            dropdownsData: {},
            validationErrors: {}
        };

        this.toast = React.createRef();
        this.service = new Service();
        this.branchSectionRef = React.createRef();
    }

    componentDidMount() {
        this.fetchEvents();
    }

    // Fetch events from API
    fetchEvents = async () => {
        this.setState({ loading: true });
        try {
            const academicYear = localStorage.getItem('userAcademicYear');
            const url = `${baseUrlAdmin}/event-calender/calenders?academicYear=${academicYear}`;
            const response = await this.service.get(url, true);

            if (response && response.res && response.res.data) {
                // Process events to handle recurring events with RRule format
                const processedEvents = this.processEventsForCalendar(response.res.data);
                this.setState({ events: processedEvents });
            } else {
                this.setState({ events: [] });
                this.showToast('error', 'Error', response?.res?.message || 'Failed to fetch events');
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            this.showToast('error', 'Error', 'Failed to fetch events');
            this.setState({ events: [] });
        } finally {
            this.setState({ loading: false });
        }
    };

    // Process events for the calendar, converting recurring events to RRule format
    processEventsForCalendar = (apiEvents) => {
        return apiEvents.map(event => {
            // Make a copy of the event to avoid modifying the original
            const processedEvent = { ...event };

            // Always set color properties regardless of event type
            // Use eventType color first, fall back to color property, then default
            const eventColor = event.eventType && EVENT_COLORS[event.eventType]
                ? EVENT_COLORS[event.eventType]
                : (event.color || EVENT_COLORS.default);

            processedEvent.backgroundColor = eventColor;
            processedEvent.borderColor = eventColor;

            // Add key event details as class names for CSS targeting
            const classNames = [];
            if (event.eventType) classNames.push(`event-type-${event.eventType.toLowerCase()}`);
            if (event.recurring) classNames.push('recurring-event');
            if (!event.allDay && !event.fdEvent) classNames.push('timed-event');

            processedEvent.classNames = classNames;

            // Store ALL event properties in extendedProps for ALL events (not just recurring)
            // This ensures time values are always available
            processedEvent.extendedProps = {
                ...event,
                backgroundColor: eventColor,
                borderColor: eventColor,
                // Make sure startTime and endTime are always included
                startTime: event.startTime || null,
                endTime: event.endTime || null
            };

            // Handle recurring events
            if (event.recurring && event.repeat) {
                processedEvent.title = event.title;
                processedEvent.allDay = event.allDay || event.fdEvent;

                // Create an RRule definition for this event
                const rruleOptions = this.createRRuleOptions(event);
                if (rruleOptions) {
                    processedEvent.rrule = rruleOptions;

                    // For recurring events, we use the rrule instead of start/end
                    delete processedEvent.start;
                    delete processedEvent.end;
                }
            }

            return processedEvent;
        });
    };

    // Create RRule options based on the event recurrence pattern
    createRRuleOptions = (event) => {
        if (!event.recurring || !event.repeat || !event.recStartDate || !event.recEndDate) {
            return null;
        }

        // Parse start and end dates
        const startDate = event.start || event.recStartDate;

        // DO NOT add an extra day to the end date to make it inclusive
        // This was causing the extra day to appear
        let endDate = event.end || event.recEndDate;

        // Base rrule options for all recurrence types
        const rruleOptions = {
            dtstart: startDate,
            until: endDate
        };

        // Rest of the method remains the same...

        // Set frequency based on repeat value
        switch (event.repeat) {
            case 'DAILY':
                rruleOptions.freq = 'daily';
                break;
            case 'WEEKLY':
                rruleOptions.freq = 'weekly';
                // Get the day of week from the start date (0=SU, 1=MO, etc.)
                const startMoment = moment(startDate);
                const dayOfWeek = startMoment.day();
                // Get the day name for RRule (SU, MO, TU, etc.)
                const daysOfWeek = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
                rruleOptions.byweekday = [daysOfWeek[dayOfWeek]];
                break;
            case 'MONTHLY':
                rruleOptions.freq = 'monthly';
                // Use the same day of month
                rruleOptions.bymonthday = [moment(startDate).date()];
                break;
            case 'YEARLY':
                rruleOptions.freq = 'yearly';
                break;
            default:
                return null;
        }

        // For events with times, include the time in the dtstart
        if (!event.allDay && !event.fdEvent) {
            // If there are specific start and end times, include them
            if (event.startTime) {
                const startMoment = moment(startDate);
                const startTimeMoment = moment(event.startTime);
                rruleOptions.dtstart = moment({
                    year: startMoment.year(),
                    month: startMoment.month(),
                    date: startMoment.date(),
                    hour: startTimeMoment.hour(),
                    minute: startTimeMoment.minute(),
                    second: 0
                }).toDate();
            }

            // Duration is needed for timed events
            if (event.endTime && event.startTime) {
                const startTimeMoment = moment(event.startTime);
                const endTimeMoment = moment(event.endTime);
                const durationMs = endTimeMoment.diff(startTimeMoment);
                rruleOptions.duration = { milliseconds: durationMs };
            }
        }

        return rruleOptions;
    };

    // Handle event click on calendar
    handleEventClick = (info) => {
        const eventData = info.event;
        this.setState({
            selectedEvent: eventData,
            showActionDialog: true
        });
    };

    // Open event dialog for adding a new event
    openAddEventDialog = () => {
        // Reset form state
        this.setState({
            eventForm: {
                title: '',
                eventType: null,
                eventDate: null,
                startTime: null,
                endTime: null,
                fdEvent: false,
                recurring: false,
                eventDescription: '',
                repeat: null,
                recStartDate: null,
                recEndDate: null
            },
            dropdownsData: {
                boardId: null,
                branchId: null,
                classId: null,
                sectionId: null,
                selectedBoardName: '',
                selectedClassName: '',
                selectedbranchName: '',
                selectedsectionName: ''
            },
            validationErrors: {},
            isEditMode: false,
            showEventDialog: true
        });
    };

    // Open event dialog for editing an existing event
    openEditEventDialog = () => {
        const { selectedEvent } = this.state;
        if (!selectedEvent) return;

        // Populate form data from selected event
        const extProps = selectedEvent.extendedProps || {};

        // More reliable time extraction - try multiple sources
        const extractTime = (timeString, fallbackDate) => {
            // If no time string is provided, return null
            if (!timeString) {
                if (fallbackDate) {
                    // If we have a fallback date that includes time info, use that
                    const parsedDate = new Date(fallbackDate);
                    if (!isNaN(parsedDate.getTime())) {
                        // Create a date object with just the time portion
                        return new Date(0, 0, 0, parsedDate.getHours(), parsedDate.getMinutes(), 0);
                    }
                }
                return null;
            }

            try {
                // Try to parse the ISO string time
                const parsedTime = new Date(timeString);
                if (!isNaN(parsedTime.getTime())) {
                    return parsedTime;
                }
                return null;
            } catch (e) {
                console.error('Error parsing time:', e);
                return null;
            }
        };

        // Log what we're working with to debug
        console.log('Event data available for edit:', {
            title: selectedEvent.title,
            eventType: extProps.eventType,
            startTime: extProps.startTime,
            endTime: extProps.endTime,
            start: selectedEvent.start || extProps.start,
            end: selectedEvent.end || extProps.end,
            allDay: selectedEvent.allDay || extProps.allDay,
            fdEvent: extProps.fdEvent
        });

        // Try multiple sources for start and end times, prioritizing explicit startTime/endTime properties
        let startTime = null;
        let endTime = null;

        // First check for explicit startTime/endTime properties (preferred)
        if (extProps.startTime) {
            startTime = extractTime(extProps.startTime);
        }

        if (extProps.endTime) {
            endTime = extractTime(extProps.endTime);
        }

        // If times are still null, try to extract from start/end dates
        // but only for non-all-day events
        if (!startTime && !selectedEvent.allDay && !extProps.fdEvent) {
            const eventStart = selectedEvent.start || extProps.start;
            if (eventStart) {
                startTime = extractTime(null, eventStart);
            }
        }

        if (!endTime && !selectedEvent.allDay && !extProps.fdEvent) {
            const eventEnd = selectedEvent.end || extProps.end;
            if (eventEnd) {
                endTime = extractTime(null, eventEnd);
            }
        }

        // Update dropdown data with proper null checks
        const dropdownsData = {
            boardId: extProps.boardId || null,
            branchId: extProps.branchId || null,
            classId: extProps.classId || null,
            sectionId: extProps.sectionId || null,
            selectedBoardName: extProps.selectedBoardName || '',
            selectedClassName: extProps.selectedClassName || '',
            selectedbranchName: extProps.selectedbranchName || '',
            selectedsectionName: extProps.selectedsectionName || ''
        };

        // More robust eventDate extraction
        let eventDate = null;
        if (selectedEvent.start) {
            eventDate = new Date(selectedEvent.start);
        } else if (extProps.start) {
            eventDate = new Date(extProps.start);
        } else if (extProps.recStartDate && !extProps.recurring) {
            eventDate = new Date(extProps.recStartDate);
        }

        // Create the form state object
        const eventForm = {
            title: selectedEvent.title || '',
            eventType: extProps.eventType || null,
            eventDate: eventDate,
            startTime: startTime,
            endTime: endTime,
            fdEvent: Boolean(selectedEvent.allDay || extProps.fdEvent),
            recurring: Boolean(extProps.recurring),
            eventDescription: extProps.eventDescription || '',
            repeat: extProps.repeat || null,
            recStartDate: extProps.recStartDate ? new Date(extProps.recStartDate) : null,
            recEndDate: extProps.recEndDate ? new Date(extProps.recEndDate) : null
        };

        // Debugging to help diagnose issues
        console.log('Event data for edit:', {
            title: selectedEvent.title,
            startTime,
            endTime,
            original: {
                startTime: extProps.startTime,
                endTime: extProps.endTime,
                start: extProps.start,
                end: extProps.end
            }
        });

        this.setState({
            dropdownsData,
            eventForm,
            isEditMode: true,
            showActionDialog: false,
            showEventDialog: true,
            validationErrors: {} // Reset validation errors
        });
    };

    // Delete the selected event
    deleteEvent = async () => {
        const { selectedEvent } = this.state;
        if (!selectedEvent || !selectedEvent.extendedProps._id) return;

        this.setState({ loading: true });
        try {
            const url = `${baseUrlAdmin}/event-calender/${selectedEvent.extendedProps._id}`;
            const response = await this.service.delete(url, true);

            if (response && response.status && response.res.status) {
                this.showToast('success', 'Success', 'Event deleted successfully');
                this.setState({
                    showDeleteDialog: false,
                    showActionDialog: false
                });
                await this.fetchEvents(); // Refresh events
            } else {
                this.showToast('error', 'Error', response?.res?.message || 'Failed to delete event');
            }
        } catch (error) {
            console.error('Error deleting event:', error);
            this.showToast('error', 'Error', 'Failed to delete event');
        } finally {
            this.setState({ loading: false });
        }
    };

    // Handle form input changes
    handleInputChange = (value, field) => {
        this.setState(prevState => {
            // If changing event type, we need special handling
            if (field === 'eventType') {
                const prevType = prevState.eventForm.eventType;
                const newType = value;

                // If switching from a type that doesn't need dropdowns to one that does,
                // or vice versa, we should reset certain fields
                const prevRequiresDropdowns = ['AE', 'SE', 'CE', 'ME'].includes(prevType);
                const newRequiresDropdowns = ['AE', 'SE', 'CE', 'ME'].includes(newType);

                if (prevRequiresDropdowns !== newRequiresDropdowns) {
                    return {
                        eventForm: {
                            ...prevState.eventForm,
                            [field]: value,
                            // Reset description if switching between types
                            eventDescription: newRequiresDropdowns ? prevState.eventForm.eventDescription : '',
                        },
                        // Clear validation errors
                        validationErrors: {
                            ...prevState.validationErrors,
                            [field]: undefined,
                            boardId: undefined,
                            branchId: undefined,
                            classId: undefined,
                            sectionId: undefined,
                            eventDescription: undefined
                        }
                    };
                }
            }

            // Default case - just update the field
            return {
                eventForm: {
                    ...prevState.eventForm,
                    [field]: value
                },
                validationErrors: {
                    ...prevState.validationErrors,
                    [field]: undefined // Clear validation error for this field
                }
            };
        });
    };

    // Set dropdown data from BranchToSectionDDPrefillCol component
    setDropdownValues = (dropdownsData) => {
        // Ensure we always have a valid object to work with
        const safeDropdownsData = dropdownsData || {};

        this.setState({
            dropdownsData: {
                boardId: safeDropdownsData.boardId || null,
                branchId: safeDropdownsData.branchId || null,
                classId: safeDropdownsData.classId || null,
                sectionId: safeDropdownsData.sectionId || null,
                selectedBoardName: safeDropdownsData.selectedBoardName || '',
                selectedClassName: safeDropdownsData.selectedClassName || '',
                selectedbranchName: safeDropdownsData.selectedbranchName || '',
                selectedsectionName: safeDropdownsData.selectedsectionName || ''
            }
        });
    }

    // Format date and time for API
    formatDateTime = (date, time) => {
        if (!date) return null;

        if (time) {
            const dateMoment = moment(date);
            const timeMoment = moment(time);

            return moment({
                year: dateMoment.year(),
                month: dateMoment.month(),
                date: dateMoment.date(),
                hour: timeMoment.hour(),
                minute: timeMoment.minute(),
                second: 0
            }).toISOString();
        }

        return moment(date).format('YYYY-MM-DD');
    };

    // Validate form data
    validateForm = () => {
        const { eventForm, dropdownsData } = this.state;
        const errors = {};

        // Basic required fields
        if (!eventForm.title) errors.title = "Event name is required";
        if (!eventForm.eventType) errors.eventType = "Event type is required";

        // Date validation for non-recurring events
        if (!eventForm.recurring && !eventForm.eventDate) {
            errors.eventDate = "Date is required";
        }

        // Validate recurring event fields
        if (eventForm.recurring) {
            if (!eventForm.recStartDate) errors.recStartDate = "Start date is required";
            if (!eventForm.recEndDate) errors.recEndDate = "End date is required";
            if (!eventForm.repeat) errors.repeat = "Repeat option is required";

            // Only validate time fields for non-full-day recurring events
            if (!eventForm.fdEvent && !['HE', 'EE'].includes(eventForm.eventType)) {
                if (!eventForm.startTime) errors.startTime = "Start time is required";
                if (!eventForm.endTime) errors.endTime = "End time is required";
            }
        }

        // For non-recurring, non-full-day events, validate time fields
        if (!eventForm.recurring && !eventForm.fdEvent && !['HE', 'EE'].includes(eventForm.eventType)) {
            if (!eventForm.startTime) errors.startTime = "Start time is required";
            if (!eventForm.endTime) errors.endTime = "End time is required";
        }

        // For specific event types, validate branch/class/section selections
        if (['AE', 'SE', 'CE', 'ME'].includes(eventForm.eventType)) {
            // Event description is required for these event types
            if (!eventForm.eventDescription || eventForm.eventDescription.trim() === '') {
                errors.eventDescription = "Event description is required";
            }

            // For these event types, ensure all dropdown fields are selected
            if (!dropdownsData.boardId) {
                errors.boardId = "Board selection is required";
            }

            if (!dropdownsData.branchId) {
                errors.branchId = "Branch selection is required";
            }

            if (!dropdownsData.classId) {
                errors.classId = "Grade selection is required";
            }

            if (!dropdownsData.sectionId) {
                errors.sectionId = "Section selection is required";
            }
        }

        this.setState({ validationErrors: errors });
        return Object.keys(errors).length === 0;
    };

    // Save or update event
    saveEvent = async () => {
        if (!this.validateForm()) {
            this.showToast('error', 'Validation Error', 'Please fill all required fields');
            return;
        }

        this.setState({ loading: true });

        try {
            const academicYear = localStorage.getItem('userAcademicYear');
            const { isEditMode, selectedEvent, eventForm, dropdownsData = {} } = this.state;
            const eventId = isEditMode && selectedEvent?.extendedProps?._id;

            // Prepare data for API with safe property access
            let payload = {
                title: eventForm.title || '',
                eventType: eventForm.eventType || '',
                eventDescription: eventForm.eventDescription || '',
                fdEvent: Boolean(eventForm.fdEvent),
                recurring: Boolean(eventForm.recurring),
                academicYear,
            };

            // Set color based on event type with fallback
            payload.color = EVENT_COLORS[eventForm.eventType] || EVENT_COLORS.default;

            // Add safe access to dropdownsData properties
            payload.boardId = dropdownsData?.boardId || null;
            payload.branchId = dropdownsData?.branchId || null;
            payload.classId = dropdownsData?.classId || null;
            payload.sectionId = dropdownsData?.sectionId || null;
            payload.selectedBoardName = dropdownsData?.selectedBoardName || '';
            payload.selectedClassName = dropdownsData?.selectedClassName || '';
            payload.selectedbranchName = dropdownsData?.selectedbranchName || '';
            payload.selectedsectionName = dropdownsData?.selectedsectionName || '';

            // Handle dates and times
            if (eventForm.recurring) {
                payload.repeat = eventForm.repeat;
                payload.recStartDate = this.formatDateTime(eventForm.recStartDate);
                payload.recEndDate = this.formatDateTime(eventForm.recEndDate);

                // Handle different recurrence types
                switch (eventForm.repeat) {
                    case 'DAILY':
                        // Daily recurrence handling
                        if (eventForm.fdEvent || ['HE', 'EE'].includes(eventForm.eventType)) {
                            // Full day recurring events
                            payload.start = this.formatDateTime(eventForm.recStartDate);
                            // DO NOT add one day to the end date
                            payload.end = this.formatDateTime(eventForm.recEndDate);
                            payload.allDay = true;
                        } else {
                            // Regular recurring events with time
                            payload.start = this.formatDateTime(eventForm.recStartDate, eventForm.startTime);
                            // For same-day events, use recStartDate, otherwise use recEndDate for end time
                            payload.end = this.formatDateTime(eventForm.recEndDate, eventForm.endTime);
                            payload.allDay = false;
                        }
                        break;

                    case 'WEEKLY':
                        // Weekly recurrence handling
                        if (eventForm.fdEvent || ['HE', 'EE'].includes(eventForm.eventType)) {
                            // Full day recurring events
                            payload.start = this.formatDateTime(eventForm.recStartDate);
                            // DO NOT add one day to the end date
                            payload.end = this.formatDateTime(eventForm.recEndDate);
                            payload.allDay = true;

                            // Add the day of week info
                            const dayOfWeek = moment(eventForm.recStartDate).day(); // 0 = Sunday, 1 = Monday, etc.
                            payload.weeklyDayOfWeek = dayOfWeek;
                        } else {
                            // Regular recurring events with time
                            payload.start = this.formatDateTime(eventForm.recStartDate, eventForm.startTime);
                            payload.end = this.formatDateTime(eventForm.recEndDate, eventForm.endTime);
                            payload.allDay = false;

                            // Add the day of week info
                            const dayOfWeek = moment(eventForm.recStartDate).day();
                            payload.weeklyDayOfWeek = dayOfWeek;
                        }
                        break;

                    case 'MONTHLY':
                        // Monthly recurrence handling
                        if (eventForm.fdEvent || ['HE', 'EE'].includes(eventForm.eventType)) {
                            payload.start = this.formatDateTime(eventForm.recStartDate);
                            // DO NOT add one day to the end date
                            payload.end = this.formatDateTime(eventForm.recEndDate);
                            payload.allDay = true;

                            // Add the day of month info
                            const dayOfMonth = moment(eventForm.recStartDate).date(); // e.g., 15th of the month
                            payload.monthlyDayOfMonth = dayOfMonth;
                        } else {
                            payload.start = this.formatDateTime(eventForm.recStartDate, eventForm.startTime);
                            payload.end = this.formatDateTime(eventForm.recEndDate, eventForm.endTime);
                            payload.allDay = false;

                            // Add the day of month info
                            const dayOfMonth = moment(eventForm.recStartDate).date();
                            payload.monthlyDayOfMonth = dayOfMonth;
                        }
                        break;

                    case 'YEARLY':
                        // Yearly recurrence handling
                        if (eventForm.fdEvent || ['HE', 'EE'].includes(eventForm.eventType)) {
                            payload.start = this.formatDateTime(eventForm.recStartDate);
                            // DO NOT add one day to the end date
                            payload.end = this.formatDateTime(eventForm.recEndDate);
                            payload.allDay = true;

                            // Add month and day info
                            const month = moment(eventForm.recStartDate).month() + 1; // 1-12
                            const day = moment(eventForm.recStartDate).date(); // 1-31
                            payload.yearlyMonth = month;
                            payload.yearlyDay = day;
                        } else {
                            payload.start = this.formatDateTime(eventForm.recStartDate, eventForm.startTime);
                            payload.end = this.formatDateTime(eventForm.recEndDate, eventForm.endTime);
                            payload.allDay = false;

                            // Add month and day info
                            const month = moment(eventForm.recStartDate).month() + 1; // 1-12
                            const day = moment(eventForm.recStartDate).date(); // 1-31
                            payload.yearlyMonth = month;
                            payload.yearlyDay = day;
                        }
                        break;

                    default:
                        // Default case for unknown recurrence types
                        if (eventForm.fdEvent || ['HE', 'EE'].includes(eventForm.eventType)) {
                            payload.start = this.formatDateTime(eventForm.recStartDate);
                            // DO NOT add one day to the end date
                            payload.end = this.formatDateTime(eventForm.recEndDate);
                            payload.allDay = true;
                        } else {
                            payload.start = this.formatDateTime(eventForm.recStartDate, eventForm.startTime);
                            payload.end = this.formatDateTime(eventForm.recEndDate, eventForm.endTime);
                            payload.allDay = false;
                        }
                }
            } else {
                // For regular events (non-recurring)
                if (eventForm.fdEvent || ['HE', 'EE'].includes(eventForm.eventType)) {
                    // Full day events just need the date
                    payload.start = this.formatDateTime(eventForm.eventDate);

                    // For single-day full-day events, add one day to end date
                    const endDate = moment(eventForm.eventDate).add(1, 'days').toDate();
                    payload.end = this.formatDateTime(endDate);
                    payload.allDay = true;
                } else {
                    // Regular events with start and end times
                    payload.start = this.formatDateTime(eventForm.eventDate, eventForm.startTime);
                    payload.end = this.formatDateTime(eventForm.eventDate, eventForm.endTime);
                    payload.allDay = false;

                    // IMPORTANT: Always store the time values separately for all event types
                    // This ensures they can be retrieved when editing
                    payload.startTime = moment(eventForm.startTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                    payload.endTime = moment(eventForm.endTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                }
            }

            // Preserve the time values separately for recurring events
            if (eventForm.startTime) {
                payload.startTime = moment(eventForm.startTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            }
            if (eventForm.endTime) {
                payload.endTime = moment(eventForm.endTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            }

            // For debugging
            console.log('Saving event with payload:', {
                title: payload.title,
                eventType: payload.eventType,
                color: payload.color,
                startTime: payload.startTime,
                endTime: payload.endTime,
                allDay: payload.allDay,
                recurring: payload.recurring
            });

            // API request
            const url = eventId
                ? `${baseUrlAdmin}/event-calender/${eventId}`
                : `${baseUrlAdmin}/event-calender/create`;

            const method = eventId ? 'put' : 'post';
            const response = await this.service[method](url, payload, true);

            if (response && response.status) {
                this.showToast('success', 'Success', isEditMode ? 'Event updated successfully' : 'Event created successfully');
                this.setState({ showEventDialog: false });
                await this.fetchEvents(); // Refresh events
            } else {
                this.showToast('error', 'Error', response?.res?.message || 'Failed to save event');
            }
        } catch (error) {
            console.error('Error saving event:', error);
            this.showToast('error', 'Error', 'An unexpected error occurred');
        } finally {
            this.setState({ loading: false });
        }
    };

    // Show toast message
    showToast = (severity, summary, detail) => {
        this.toast.current.show({
            severity,
            summary,
            detail,
            life: 3000
        });
    };

    // Render event dialog footer
    renderEventDialogFooter = () => {
        const { isEditMode } = this.state;
        return (
            <div className="mt-3" style={{ display: 'flex' }}>
                <Button
                    style={{
                        borderRadius: 10,
                        backgroundColor: '#076EFF',
                        width: '170%',
                        padding: 0,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={this.saveEvent}
                >
                    <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                    <p className='submitButtonnText'>{isEditMode ? "Update" : "Add"}</p>
                </Button>
                <Button
                    style={{
                        border: 0,
                        borderRadius: 10,
                        backgroundColor: '#E0E0E0',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '10px'
                    }}
                    onClick={() => this.setState({ showEventDialog: false })}
                >
                    <p className='cancelButtonnText'>Cancel</p>
                </Button>
            </div>
        );
    };

    // Render delete dialog footer
    renderDeleteDialogFooter = () => (
        <div className="flex justify-content-center align-items-center">
            <Button
                label="Cancel"
                className='p-button-outlined'
                onClick={() => this.setState({ showDeleteDialog: false })}
            />
            <Button
                label="Delete"
                className='ma-m-lr10 p-button-danger'
                onClick={this.deleteEvent}
            />
        </div>
    );

    // Render action dialog footer
    renderActionDialogFooter = () => (
        <div className="flex justify-content-center align-items-center">
            <Button
                label="Cancel"
                className='p-button-outlined'
                onClick={() => this.setState({ showActionDialog: false })}
            />
            <Button
                label="Edit"
                className='ma-m-lr10'
                onClick={this.openEditEventDialog}
            />
            <Button
                label="Delete"
                className='ma-m-lr10 p-button-danger'
                onClick={() => {
                    this.setState({
                        showActionDialog: false,
                        showDeleteDialog: true
                    });
                }}
            />
        </div>
    );

    render() {
        const {
            events, loading, showEventDialog, showActionDialog, showDeleteDialog,
            isEditMode, eventForm, validationErrors, dropdownsData
        } = this.state;

        return (
            <>
                <div className='grid'>
                    {/* Left sidebar with legend */}
                    <div className='col-2 card shadow-5 my-5'>
                        <div className='flex justify-content-center'>
                            <Button
                                className='shadow-5'
                                style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                                onClick={this.openAddEventDialog}
                            >
                                <p className='addButtonClass' style={{ fontSize: '12px' }}>Add Event</p>
                            </Button>
                        </div>

                        <div className='mt-8'>
                            {/* Legend items */}
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ borderColor: '#076EFF', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Today</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px', backgroundColor: '#F8F9FA' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Sunday</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ borderColor: '#0077B6', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Academic Event</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px', backgroundColor: '#268900' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Holiday</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ color: '#BF0031', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Examination</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ color: '#FAB710', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Sports Event</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ color: '#A020F0', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Cultural Event</p>
                                </div>
                            </div>
                            <div className='ml-4 mt-3'>
                                <div className='flex'>
                                    <div className="border-2" style={{ color: '#E6E8EA', height: '20px', width: '20px' }}></div>
                                    <p className='repText font-bold ml-4' style={{ maxWidth: '80%', overflowWrap: 'break-word' }}>Miscellaneous Event</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Calendar container */}
                    <div className="col-10">
                        <div className="calendar-demo">
                            <FullCalendar
                                initialDate={moment(new Date()).format('YYYY-MM-DD')}
                                initialView="dayGridMonth"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
                                headerToolbar={{ left: 'title', center: '', right: 'prev,next' }}
                                editable={false}
                                selectable
                                selectMirror
                                dayMaxEvents
                                events={events}
                                eventClick={this.handleEventClick}
                                dayCellClassNames={(arg) => {
                                    // Add a special class for Sundays (day 0)
                                    return arg.date.getDay() === 0 ? 'sunday-cell' : '';
                                }}
                                eventDidMount={(info) => {
                                    // Force color application on event render
                                    const eventType = info.event.extendedProps?.eventType;
                                    if (eventType && EVENT_COLORS[eventType]) {
                                        // Apply styles directly to the event element
                                        info.el.style.backgroundColor = EVENT_COLORS[eventType];
                                        info.el.style.borderColor = EVENT_COLORS[eventType];

                                        // Apply to all child elements that might inherit styling
                                        const allEventElements = info.el.querySelectorAll('.fc-event-main, .fc-event-title, .fc-event-time');
                                        allEventElements.forEach(element => {
                                            element.style.backgroundColor = EVENT_COLORS[eventType];
                                            element.style.borderColor = EVENT_COLORS[eventType];
                                        });

                                        // Special handling for Miscellaneous Event to ensure text is readable
                                        if (eventType === 'ME') {
                                            info.el.style.color = '#333333';
                                            allEventElements.forEach(element => {
                                                element.style.color = '#333333';
                                            });
                                        } else {
                                            // For all other events, ensure text is white
                                            info.el.style.color = 'white';
                                            allEventElements.forEach(element => {
                                                element.style.color = 'white';
                                            });
                                        }

                                        // Ensure time event content displays properly
                                        if (!info.event.allDay) {
                                            const mainContainer = info.el.querySelector('.fc-event-main-frame');
                                            if (mainContainer) {
                                                mainContainer.style.backgroundColor = EVENT_COLORS[eventType];
                                            }
                                        }
                                    }
                                }}
                                eventContent={(eventInfo) => (
                                    <div>
                                        <b>{eventInfo.event.title}</b>
                                        <br />
                                        <span>{eventInfo.event.extendedProps?.eventDescription}</span>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>

                {/* Add/Edit Event Dialog */}
                <Dialog
                    visible={showEventDialog}
                    draggable={false}
                    style={{
                        width: '500px',
                        maxHeight: '90vh',
                        borderRadius: '8px',
                        overflow: 'auto'
                    }}
                    modal={true}
                    closable={true}
                    className="event-dialog"
                    header={() => (
                        <div className='mt-2'>
                            <h4 className="sideHeading" style={{ fontSize: 36, color: 'black' }}>
                                {isEditMode ? "Edit Event" : "Add Event"}
                            </h4>
                        </div>
                    )}
                    onHide={() => this.setState({ showEventDialog: false })}
                    footer={this.renderEventDialogFooter()}
                >
                    <div className='mb-5 ml-5 mr-5'>
                        <div className="">
                            {/* Event Name */}
                            <div className="col-12 mb-3">
                                <label className='label mb-2'>Event Name<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='title'
                                        icon={<CodeLinkIcon height={22} />}
                                        className=""
                                        height="48px"
                                        width="361px"
                                        placeholder="Enter Event name"
                                        value={eventForm.title}
                                        onChange={(e) => this.handleInputChange(e.target.value, 'title')}
                                    />
                                </div>
                                {validationErrors.title && <p className="p-error">{validationErrors.title}</p>}
                            </div>

                            {/* Event Type */}
                            <div className="col-12 mb-3">
                                <label className='label mb-2'>Event Type<span className='ma-required'>*</span></label>
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id='eventType'
                                        icon={<MailIcon />}
                                        className=""
                                        height="48px"
                                        width="361px"
                                        options={EVENT_TYPES}
                                        value={eventForm.eventType}
                                        onChange={(e) => this.handleInputChange(e.target.value, 'eventType')}
                                        placeholder={<div><span className=''>Select Event Type</span></div>}
                                    />
                                </div>
                                {validationErrors.eventType && <p className="p-error">{validationErrors.eventType}</p>}
                            </div>

                            {/* Show additional fields only if event type is selected */}
                            {eventForm.eventType && (
                                <>
                                    {/* Full Day Event and Recurring toggles side by side */}
                                    <div className='col-12 mb-3'>
                                        <div className="flex align-items-center justify-content-between">
                                            {/* Left side: Full Day Event toggle (shown only for applicable event types) */}
                                            {['AE', 'SE', 'CE', 'ME'].includes(eventForm.eventType) && (
                                                <div className="flex align-items-center">
                                                    <InputSwitch
                                                        id="fdEvent"
                                                        className=''
                                                        checked={eventForm.fdEvent}
                                                        value={eventForm.fdEvent}
                                                        onChange={(e) => this.handleInputChange(e.value, 'fdEvent')}
                                                    />
                                                    <label className='labelToggle'>Full Day Event</label>
                                                </div>
                                            )}

                                            {/* Right side: Recurring toggle (always shown) */}
                                            <div className="flex align-items-center">
                                                <InputSwitch
                                                    id='recurring'
                                                    className=''
                                                    checked={eventForm.recurring}
                                                    value={eventForm.recurring}
                                                    onChange={(e) => this.handleInputChange(e.value, 'recurring')}
                                                />
                                                <label className='labelToggle'>Recurring</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Date field for non-recurring events */}
                                    {!eventForm.recurring && (
                                        <div className="col-12 mb-3">
                                            <label className='label mb-2'>Date<span className='ma-required'>*</span></label>
                                            <div className="mt-2">
                                                <ClassetCalendar
                                                    icon={<CalenderIcon height={20} />}
                                                    className=""
                                                    height="48px"
                                                    width="361px"
                                                    placeholder="Select Date"
                                                    calendarMode="single"
                                                    id="eventDate"
                                                    value={eventForm.eventDate}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'eventDate')}
                                                />
                                            </div>
                                            {validationErrors.eventDate && <p className="p-error">{validationErrors.eventDate}</p>}
                                        </div>
                                    )}

                                    {/* Start and End Time fields for applicable events */}
                                    {!eventForm.fdEvent &&
                                        !['HE', 'EE'].includes(eventForm.eventType) &&
                                        !eventForm.recurring && (
                                            <div className="col-12 flex mb-3">
                                                <div className="mr-3" style={{ width: '170px' }}>
                                                    <label className='label mb-2'>Start Time<span className='ma-required'>*</span></label>
                                                    <div className="mt-2">
                                                        <span className="p-input-icon-left w-full">
                                                            <ClockIcon height={20} />
                                                            <Calendar
                                                                value={eventForm.startTime}
                                                                onChange={(e) => this.handleInputChange(e.value, 'startTime')}
                                                                timeOnly
                                                                hourFormat="12"
                                                                showSeconds={false}
                                                                className="w-full"
                                                                placeholder="Select Time"
                                                                panelClassName="time-panel"
                                                                inputClassName="p-inputtext w-full"
                                                                style={{ height: '48px' }}
                                                            />
                                                        </span>
                                                    </div>
                                                    {validationErrors.startTime && <p className="p-error">{validationErrors.startTime}</p>}
                                                </div>
                                                <div style={{ width: '170px' }}>
                                                    <label className='label mb-2'>End Time<span className='ma-required'>*</span></label>
                                                    <div className="mt-2">
                                                        <span className="p-input-icon-left w-full">
                                                            <ClockIcon height={20} />
                                                            <Calendar
                                                                value={eventForm.endTime}
                                                                onChange={(e) => this.handleInputChange(e.value, 'endTime')}
                                                                timeOnly
                                                                hourFormat="12"
                                                                showSeconds={false}
                                                                className="w-full"
                                                                placeholder="Select Time"
                                                                panelClassName="time-panel"
                                                                inputClassName="p-inputtext w-full"
                                                                style={{ height: '48px' }}
                                                            />
                                                        </span>
                                                    </div>
                                                    {validationErrors.endTime && <p className="p-error">{validationErrors.endTime}</p>}
                                                </div>
                                            </div>
                                        )}

                                    {/* Recurring event fields */}
                                    {eventForm.recurring && (
                                        <>
                                            <div className="col-12 mb-3">
                                                <label className='label mb-2'>Start On<span className='ma-required'>*</span></label>
                                                <div className="mt-2">
                                                    <ClassetCalendar
                                                        icon={<CalenderIcon height={20} />}
                                                        className=""
                                                        height="48px"
                                                        width="361px"
                                                        placeholder="Select Date"
                                                        calendarMode="single"
                                                        id="recStartDate"
                                                        value={eventForm.recStartDate}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'recStartDate')}
                                                    />
                                                </div>
                                                {validationErrors.recStartDate && <p className="p-error">{validationErrors.recStartDate}</p>}
                                            </div>

                                            {/* Add Start and End Time fields for recurring events that are not full day */}
                                            {!eventForm.fdEvent && !['HE', 'EE'].includes(eventForm.eventType) && (
                                                <div className="col-12 flex mb-3">
                                                    <div className="mr-3" style={{ width: '170px' }}>
                                                        <label className='label mb-2'>Start Time<span className='ma-required'>*</span></label>
                                                        <div className="mt-2">
                                                            <span className="p-input-icon-left w-full">
                                                                <ClockIcon height={20} />
                                                                <Calendar
                                                                    value={eventForm.startTime}
                                                                    onChange={(e) => this.handleInputChange(e.value, 'startTime')}
                                                                    timeOnly
                                                                    hourFormat="12"
                                                                    showSeconds={false}
                                                                    className="w-full"
                                                                    placeholder="Select Time"
                                                                    panelClassName="time-panel"
                                                                    inputClassName="p-inputtext w-full"
                                                                    style={{ height: '48px' }}
                                                                />
                                                            </span>
                                                        </div>
                                                        {validationErrors.startTime && <p className="p-error">{validationErrors.startTime}</p>}
                                                    </div>
                                                    <div style={{ width: '170px' }}>
                                                        <label className='label mb-2'>End Time<span className='ma-required'>*</span></label>
                                                        <div className="mt-2">
                                                            <span className="p-input-icon-left w-full">
                                                                <ClockIcon height={20} />
                                                                <Calendar
                                                                    value={eventForm.endTime}
                                                                    onChange={(e) => this.handleInputChange(e.value, 'endTime')}
                                                                    timeOnly
                                                                    hourFormat="12"
                                                                    showSeconds={false}
                                                                    className="w-full"
                                                                    placeholder="Select Time"
                                                                    panelClassName="time-panel"
                                                                    inputClassName="p-inputtext w-full"
                                                                    style={{ height: '48px' }}
                                                                />
                                                            </span>
                                                        </div>
                                                        {validationErrors.endTime && <p className="p-error">{validationErrors.endTime}</p>}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="col-12 mb-3">
                                                <label className='label mb-2'>Ends On<span className='ma-required'>*</span></label>
                                                <div className="mt-2">
                                                    <ClassetCalendar
                                                        icon={<CalenderIcon height={20} />}
                                                        className=""
                                                        height="48px"
                                                        width="361px"
                                                        placeholder="Select Date"
                                                        calendarMode="single"
                                                        id="recEndDate"
                                                        value={eventForm.recEndDate}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'recEndDate')}
                                                    />
                                                </div>
                                                {validationErrors.recEndDate && <p className="p-error">{validationErrors.recEndDate}</p>}
                                            </div>

                                            <div className="col-12 mb-3">
                                                <label className='label mb-2'>Repeat<span className='ma-required'>*</span></label>
                                                <div className="mt-2">
                                                    <ClassetDropdown
                                                        id='repeat'
                                                        icon={<MailIcon />}
                                                        className=""
                                                        height="48px"
                                                        width="361px"
                                                        options={REPEAT_OPTIONS}
                                                        value={eventForm.repeat}
                                                        onChange={(e) => this.handleInputChange(e.target.value, 'repeat')}
                                                        placeholder={<div><span className=''>Select Repeat</span></div>}
                                                    />
                                                </div>
                                                {validationErrors.repeat && <p className="p-error">{validationErrors.repeat}</p>}
                                            </div>
                                        </>
                                    )}

                                    {/* Event Description */}
                                    {['AE', 'SE', 'CE', 'ME'].includes(eventForm.eventType) && (
                                        <div className="col-12 mb-3">
                                            <label className='label'>Event Description<span className='ma-required'>*</span></label>
                                            <div className='mt-2'>
                                                <ClassetTextArea
                                                    id="eventDescription"
                                                    className='border-round-md border-none'
                                                    height={50}
                                                    value={eventForm.eventDescription}
                                                    onChange={(e) => this.handleInputChange(e.target.value, 'eventDescription')}
                                                    placeholder="Description"
                                                />
                                            </div>
                                            {validationErrors.eventDescription && <p className="p-error">{validationErrors.eventDescription}</p>}
                                        </div>
                                    )}

                                    {/* Branch to Section Dropdowns */}
                                    {(['AE', 'SE', 'CE', 'ME'].includes(eventForm.eventType)) && (
                                        <div className="col-12 mb-3">
                                            <BranchToSectionDDPrefillCol
                                                setValues={this.setDropdownValues}
                                                Required={true}
                                                initialBoard={dropdownsData.boardId}
                                                initialBranch={dropdownsData.branchId}
                                                initialClass={dropdownsData.classId}
                                                initialSection={dropdownsData.sectionId}
                                            />
                                            {validationErrors.boardId && <p className="p-error">{validationErrors.boardId}</p>}
                                            {validationErrors.branchId && <p className="p-error">{validationErrors.branchId}</p>}
                                            {validationErrors.classId && <p className="p-error">{validationErrors.classId}</p>}
                                            {validationErrors.sectionId && <p className="p-error">{validationErrors.sectionId}</p>}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Dialog>

                {/* Action Dialog */}
                <Dialog
                    header="Event Actions"
                    visible={showActionDialog}
                    style={{ width: '400px' }}
                    modal
                    footer={this.renderActionDialogFooter()}
                    onHide={() => this.setState({ showActionDialog: false })}
                >
                </Dialog>

                {/* Delete Dialog */}
                <Dialog
                    header="Delete Event"
                    visible={showDeleteDialog}
                    style={{ width: '400px' }}
                    modal
                    footer={this.renderDeleteDialogFooter()}
                    onHide={() => this.setState({ showDeleteDialog: false })}
                >
                    <p className='text-lg text-600 text-center font-bold white-space-normal'>Are you sure you want to delete this event?</p>
                </Dialog>

                {loading && <LoadingComponent />}
                <Toast ref={this.toast} position="bottom-right" />
            </>
        );
    }
}


export default AcademicCalendar;