import React, { Component } from 'react';
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import { cloneDeep } from 'lodash';

class BranchToSectionMultiDDTillSecReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardIds: null,
            classIds: null,
            groupId: null,
            branchIds: null,
            sectionIds: null,
            boards: [],
            classes: [],
            sections: [],
            curriculumInfo: {}
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.getData();
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.branchIds == null && this.props.boards && this.props.boards.length > 0 && this.props.branchData && this.props.branchData.length > 0 && this.props && this.props.dropdownsData !== undefined && this.props.dropdownsData !== null) {
            const { branchIds, boardIds, classIds, sectionIds } = this.props.dropdownsData;
            let branchdIds = branchIds;
            let filteredBoards = cloneDeep(
                this.props.boards.filter((board) => {
                    return board.assignedBranches.some((branchId) => branchdIds?.includes(branchId));
                })
            );
            if (filteredBoards.length > 0) {
                filteredBoards.forEach((board) => {
                    board?.classes?.forEach((classs) => {
                        classs.className = board?.boardName + ' -- ' + classs.className;
                        classs.sections.forEach((sec) => (sec.sectionName = classs.className + ' -- ' + sec.sectionName));
                    });
                });

                let filterClasses = filteredBoards
                    ?.filter((board) => boardIds?.includes(board.boardId))
                    ?.map((e) => e.classes)
                    .flat();

                let filteredSections = filterClasses
                    .filter((e) => classIds?.includes(e.classId))
                    ?.map((e) => e.sections)
                    .flat();

                this.setState({
                    branchIds: branchdIds,
                    boards: filteredBoards,
                    boardIds: boardIds,
                    classes: filterClasses,
                    classIds: classIds,
                    sectionIds: sectionIds,
                    sections: filteredSections
                });
            }
        }
    }

    getData = () => {
        this.props.getBranchesLatest();
        this.props.getBoardsData(true);
    };

    onChangeBranch = (branchIds) => {
        this.setState({
            boards: [],
            classes: [],
            sections: []
        });

        let filteredBoards = cloneDeep(
            this.props.boards.filter((board) => {
                return board.assignedBranches.some((branchId) => branchIds?.includes(branchId));
            })
        );

        filteredBoards.forEach((board) => {
            board?.classes?.forEach((classs) => {
                classs.className = board?.boardName + ' -- ' + classs.className;
                classs.sections.forEach((sec) => (sec.sectionName = classs.className + ' -- ' + sec.sectionName));
            });
        });

        this.setState(
            {
                branchIds: branchIds,
                boards: filteredBoards,
                classes: [],
                boardIds: '',
                classIds: '',
                branches: [],
                sections: [],
                sectionIds: []
            },
            () => console.log('after branchs')
        );
    };

    onChangeBoard = (boardIds) => {
        this.setState({
            classes: [],
            sections: []
        });

        let filteredClasses = [];

        this.state.boards.forEach((board) => {
            if (boardIds.includes(board.boardId)) {
                filteredClasses.push(...board.classes);
            }
        });

        this.setState(
            {
                classes: filteredClasses,
                boardIds,
                classIds: '',
                branches: [],
                sections: [],
                sectionIds: []
            },
            () => this.setDropdownsData()
        );
    };

    onChangeClass = (classIds) => {
        this.setState({
            sections: []
        });
        let filterClasses1 = this.state.classes?.filter((classs) => classIds?.includes(classs?.classId));
        let lsecs = [];
        filterClasses1?.map((fClass) => {
            let secc = fClass.sections?.filter((sec) => this.state.branchIds?.includes(sec?.branchId));
            lsecs = [...lsecs, ...secc];
        });
        this.setState(
            {
                classIds: classIds,
                sections: lsecs,
                sectionIds: []
            },
            () => this.setDropdownsData()
        );
    };

    onChangeSection = (sectionIds) => {
        this.setState(
            {
                sectionIds: sectionIds
            },
            () => this.setDropdownsData()
        );
    };

    setDropdownsData = () => {
        let { boardIds, classIds, branchIds, sectionIds } = this.state;
        let branchNames,
            sectionNames = [];
        if (sectionIds?.length) {
            branchNames = branchIds?.map((e) => this.props.branchData?.find((f) => e == f.key).name);
            sectionNames = sectionIds?.map((e) => this.state?.sections?.find((f) => e == f.sectionId)?.sectionName);
        }

        this.props.setValues({
            boardIds,
            classIds,
            branchIds,
            sectionIds,
            branchNames,
            sectionNames
        });
    };

    render() {
        return (
            <div>
                <div>
                    <div className="grid -m-1">
                        <div className=" col-3">
                            <ClassetMultiSelect
                                required={true}
                                label={'Branch'}
                                icon={<BoardsIconInActive height={16.5} width={19.5} />}
                                placeholder={'Select Branch'}
                                options={this.props.branchData}
                                value={this.state.branchIds}
                                onChange={(e) => this.onChangeBranch(e.value)}
                                optionLabel="name"
                                optionValue="key"
                            />
                        </div>
                        <div className=" col-3">
                            <ClassetMultiSelect
                                required={true}
                                label={'Board'}
                                icon={<BoardsIconInActive height={16.5} width={19.5} />}
                                placeholder={'Select Board'}
                                options={this.state.boards}
                                value={this.state.boardIds}
                                onChange={(e) => this.onChangeBoard(e.value)}
                                optionLabel="boardName"
                                optionValue="boardId"
                            />
                        </div>
                        <div className=" col-3">
                            <ClassetMultiSelect
                                label={'Grade'}
                                required={true}
                                icon={<GradesIcon />}
                                placeholder={'Select (Board - Grade)'}
                                options={this.state?.classes}
                                value={this.state.classIds}
                                onChange={(e) => this.onChangeClass(e.value)}
                                optionLabel="className"
                                optionValue="classId"
                            />
                        </div>
                        <div className=" col-3">
                            <ClassetMultiSelect
                                required={true}
                                label={'Section'}
                                icon={<GradesIcon />}
                                placeholder={'Select (Board - Grade - Section)'}
                                options={this.state?.sections}
                                value={this.state.sectionIds}
                                onChange={(e) => this.onChangeSection(e.value)}
                                optionLabel="sectionName"
                                optionValue="sectionId"
                            />
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToSectionMultiDDTillSecReports);
