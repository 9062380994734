import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import '../style.scss';
import { MailIcon, TickBgIcon, ArrowClockWise, InputIcon, CodeLinkIcon, CalendarIcon, ArrowLeftRight, Plus } from '../../svgIcons';
import { BasicLazyParams } from '../../../utile';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { getBoardsData } from '../../../store/actions';
import withRouter from '../../lib/withRouter';
import Authentication from "../../../session"
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../../services';
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';


export class MyTimeTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            openCreateSubstituionDialog: false,
            searchType: 1,
            searchInput: "",
            periodSubstitutionData: {},
            Reason: ''
        }
        this.service = new Service();
    }
    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData
            },
            () => {
                if (dropdownsData?.boardId?.length > 0 && dropdownsData?.classId?.length > 0) {
                    const filteredBoards = this.props.boards.filter((board) => board.boardId === dropdownsData.boardId);
                    if (filteredBoards.length > 0) {
                        const filteredClasses = filteredBoards[0].classes.filter((classItem) => classItem.classId === dropdownsData.classId);

                        this.setState(
                            {
                                groupId: filteredClasses[0].groups[0].groupId
                            },
                            () => {
                                if (dropdownsData.boardId && dropdownsData.classId && dropdownsData.branchId && dropdownsData.sectionId) {
                                    this.getTimeTableIfExists(dropdownsData)
                                }
                            }
                        );
                    }
                }
            }
        );
    };
    
   
    getTimeTableIfExists = async (dropdownsData, date) => {
        const { branchId, boardId, classId, sectionId } = dropdownsData;
        const academicYear = localStorage.getItem('userAcademicYear');
        // if date is not there send new date
        if (!date) {
            date = new Date();
            date.setHours(0, 0, 0, 0); // Normalize date
        }
        let url = `${baseUrlAdmin}/timetable/getsubstimetable?branchId=${branchId}&boardId=${boardId}&classId=${classId}&sectionId=${sectionId}&academicYear=${academicYear}&date=${date}`;
        if (branchId && boardId && classId && sectionId) {
            this.setState({ isLoading: true, date: date });
            try {
                await this.service.get(url, true).then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.data) {
                            const timeTableData = res.res.data;
                            this.setState({ timeTableId: res.res.data._id, timeTableData: timeTableData.timeTableData, selectedTemplate: timeTableData.templateId, isTimeTableExistsInDB: true, isLoading: false }, () => {
                                this.dynamicColumns(timeTableData.timeTableData)
                            });
                        } else {
                            this.setState({ timeTableId: null, timeTableData: [], selectedTemplate: null, isTimeTableExistsInDB: true, isLoading: false }, () => {
                                this.dynamicColumns({})
                            });
                            this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                        }
                    } else {
                        throw new Error(res?.errMessage);
                    }
                }).catch((err) => {
                    this.setState({ timeTableId: null, timeTableData: [], selectedTemplate: null, isTimeTableExistsInDB: true, isLoading: false }, () => {
                        this.dynamicColumns({})
                    });
                    this.toast.show({ severity: 'error', summary: 'ERROR', detail: 'Time table not created', life: 3000 });
                });

            } catch (err) {
                console.log(err);
                this.setState({ timeTableId: null, timeTableData: [], selectedTemplate: null, isTimeTableExistsInDB: true, isLoading: false }, () => {
                    this.dynamicColumns({})
                });
            }

        }

    }
    dynamicColumns = (timeTableData) => {
        if (!timeTableData || !timeTableData?.TimeTable || !this.state.date) {
            return []
        };

        const columns = [];
        const selectedDateObj = new Date(this.state.date);
        selectedDateObj.setHours(0, 0, 0, 0); // Normalize date

        // Get Monday as the start of the week (Handles Sunday properly)
        const startOfWeek = new Date(selectedDateObj);
        const dayIndex = selectedDateObj.getDay();
        startOfWeek.setDate(selectedDateObj.getDate() - ((dayIndex === 0 ? 7 : dayIndex) - 1));

        columns.push(
            <Column
                key="day"
                field="day"
                header={<div style={{ color: 'black' }}>Days/Periods</div>}
                align={'center'}
                body={(rowData, index) => {
                    return (
                        <div style={{ color: 'black' }}>
                            <p>
                                {rowData.day}
                            </p>
                            <p style={{ fontSize: '12px', color: 'gray' }}>
                                {rowData.date}
                            </p>
                        </div>
                    );
                }}
            />
        );

        if (timeTableData.TimeTable.length > 0) {
            const firstDayPeriods = timeTableData.TimeTable[0].Periods;

            firstDayPeriods.forEach((_, index) => {
                columns.push(
                    <Column
                        key={`period-${index + 1}`}
                        field={`Period_${index + 1}`}
                        header={<div style={{ color: 'black' }}>{_.name}</div>}
                        body={(rowData) => {
                            const periodData = rowData.Periods[index];

                            if (periodData?.type === 'break') {
                                return (
                                    <div style={{
                                        margin: '0', padding: '0', display: 'flex',
                                        flexDirection: 'column', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="break-period" style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                            BREAK
                                        </div>
                                    </div>
                                );
                            }

                            return (
                                <div style={{
                                    margin: '0', padding: '0', display: 'flex',
                                    flexDirection: 'column', justifyContent: 'space-between'
                                }}>
                                    {
                                        periodData?.isSubstitution ? <>
                                            <div style={{ marginBottom: '20px' }} >

                                            </div>
                                            <div style={{ textAlign: 'center', fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal', color: periodData?.Teacher?.status == 'pending' ? 'orange' : 'green' }}>
                                                {periodData?.Subject?.subjectName
                                                    ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                                    : <p style={{ fontStyle: 'italic' }}>Not Assigned</p>}
                                            </div>
                                            <div style={{ textAlign: 'center', fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal', color: periodData?.Teacher?.status == 'pending' ? 'orange' : 'green', fontSize: '12px' }}>
                                                {periodData?.Teacher?.name
                                                    ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                                    : ''}
                                            </div>

                                        </> :

                                            <>
                                                {!rowData.isDisabled &&
                                                    <div style={{ marginBottom: '20px' }} >
                                                    </div>}

                                                <div style={{ textAlign: 'center', fontWeight: periodData?.Subject?.subjectName ? 'bold' : 'normal', color: periodData?.Subject?.subjectName ? '#0A9EDC' : 'lightgray' }}>
                                                    {periodData?.Subject?.subjectName
                                                        ? `${periodData.Subject.subjectName.charAt(0).toUpperCase()}${periodData.Subject.subjectName.slice(1)}`
                                                        : <p style={{ fontStyle: 'italic' }}>Not Assigned</p>}
                                                </div>
                                                <div style={{ textAlign: 'center', fontWeight: periodData?.Teacher?.name ? 'bold' : 'normal', color: periodData?.Teacher?.name ? 'gray' : 'black', fontSize: '12px' }}>
                                                    {periodData?.Teacher?.name
                                                        ? `${periodData.Teacher.name.charAt(0).toUpperCase()}${periodData.Teacher.name.slice(1)}`
                                                        : ''}
                                                </div>
                                            </>
                                    }

                                </ div>
                            );
                        }}
                    />
                );
            });
        }

        return columns;
    };

    render() {
        const { timeTableData, dropdownsData } = this.state;

        const getNextMondayFromSaturday = () => {
            if (!timeTableData?.TimeTable || timeTableData.TimeTable.length === 0) return null;

            // Find Saturday's date from the timetable
            const saturdayEntry = timeTableData.TimeTable.find(entry => entry.day === "Saturday");
            if (!saturdayEntry || !saturdayEntry.date) return null;

            const saturdayDate = new Date(saturdayEntry.date);
            const nextMonday = new Date(saturdayDate);
            nextMonday.setDate(saturdayDate.getDate() + 2); // Add 2 days to get Monday
            nextMonday.setHours(0, 0, 0, 0); // Normalize date
            return nextMonday;
        };

        const getPreviousMondayFromMonday = () => {
            if (!this.state.date) return null;

            const currentMonday = new Date(this.state.date);
            const previousMonday = new Date(currentMonday);
            previousMonday.setDate(currentMonday.getDate() - 7); // Subtract 7 days to get the previous Monday
            previousMonday.setHours(0, 0, 0, 0); // Normalize date
            return previousMonday;
        };

        const isNavigationButtonsVisible = dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && timeTableData?.TimeTable;

        return (
            <div className="m-2">
                <div className="flex flex-column m-2 mb-6">
                    <BranchToSectionDD setValues={this.setDropdownValues} />
                    {isNavigationButtonsVisible && timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                        <div className="flex justify-content-end mt-2 gap-2">
                            <Button
                                label="Previous Week"
                                className="p-button-outlined"
                                onClick={() => {
                                    const previousMonday = getPreviousMondayFromMonday();
                                    if (previousMonday) {
                                        this.setState({ date: previousMonday });
                                        this.getTimeTableIfExists(dropdownsData, previousMonday);
                                    } else {
                                        this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Unable to calculate previous week's Monday", life: 3000 });
                                    }
                                }}
                            />
                            <Button
                                label="Next Week"
                                className="p-button-outlined"
                                onClick={() => {
                                    const nextMonday = getNextMondayFromSaturday();
                                    if (nextMonday) {
                                        this.setState({ date: nextMonday });
                                        this.getTimeTableIfExists(dropdownsData, nextMonday);
                                    } else {
                                        this.toast.show({ severity: 'error', summary: 'ERROR', detail: "Unable to calculate next week's Monday", life: 3000 });
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>


                {
                    timeTableData?.TimeTable && dropdownsData?.branchId && dropdownsData?.boardId && dropdownsData?.classId && dropdownsData?.sectionId && (
                        <>
                            <div className="flex align-items-center mt-2">
                                <div className="flex align-items-center mr-4">
                                    <div style={{ width: '16px', height: '16px', backgroundColor: 'orange', marginRight: '8px', borderRadius: 100 }}></div>
                                    <span className="text-gray-600">Substitution Request Pending</span>
                                </div>
                                <div className="flex align-items-center">
                                    <div style={{ width: '16px', height: '16px', backgroundColor: 'green', marginRight: '8px', borderRadius: 100 }}></div>
                                    <span className="text-gray-600">Substitution Request Approved</span>
                                </div>
                            </div>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={timeTableData.TimeTable}
                                dataKey="id"
                                lazy
                                responsiveLayout="scroll"
                                className="dataTableValuesTextt"
                                showGridlines
                                selectionMode={'checkbox'}
                                columnResizeMode="expand"
                                resizableColumns
                                onPage={this.onPage}
                                size='large'
                            >
                                {this.dynamicColumns(timeTableData)}
                            </DataTable>
                        </>

                    )
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div >
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(MyTimeTable)));