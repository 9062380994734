import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import Login from './components/login/index';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgData, getOrgDataByCode } from './store/actions';
import Service from './components/services';
import { baseUrlAdmin } from './store/apiConstants';
import withRouter from './components/lib/withRouter';
import Authentication from './components/session/index'
import PaperDownload from './components/paperDownload';
import ExamResultsDownload from './components/ClassetAcademicsModule/Examination/testsExam/examResultsDownload';

let timer1

const AppWrapper = () => {
  let location = useLocation();
  const dispatch = useDispatch()
  const assignedModules = useSelector((state) => state.orgData.assignedModules)
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      window.scrollTo(0, 0);
      dispatch(getOrgData());
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    if (assignedModules) {
      let orgCode = new URLSearchParams(window.location.search).get("org");
      if (localStorage.getItem('orgCode')) {
        orgCode = localStorage.getItem('orgCode')
      }
      if (orgCode) {
        localStorage.setItem('orgCode', orgCode);
        dispatch(getOrgDataByCode(orgCode));
      } else {
        dispatch(getOrgData());
      }
    }
    document.documentElement.setAttribute('data-theme', localStorage.getItem('data-theme'));
    let service = new Service();
    let url = `${baseUrlAdmin}/user/server/time/forexam`;
    service.get(url, true).then((data) => {
      if (data && data.status) {
        localStorage.setItem('serverTime1', data.res.serverTime);
        var serverDate = new Date();
        serverDate.setTime(data.res.serverTime);

        timer1 = setInterval(() => {
          let time = Number(localStorage.getItem('serverTime1'));
          time += 1000;
          localStorage.setItem('serverTime1', time);
        }, 1000);
      }
    });
  }, [])

  return (
    <Routes>
      <Route path="/paper-download/:examId" element={<PaperDownload />} />
      <Route path="/exam-report/:examId/userId/:userId" element={<ExamResultsDownload />} />
      <Route path="/*" element={<ProtectedRoutes />} />
    </Routes>
  );
};

const ProtectedRoutes = withRouter(Authentication(() => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/error" element={<Error />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="/access" element={<Access />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
}));

export default withRouter(AppWrapper);

