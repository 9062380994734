import React, { Component } from 'react'
import moment from 'moment';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import Authentication from '../../session';
import { getBranchesLatest } from '../../store/actions';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { BasicLazyParams } from '../../utile';
import Service from '../../services';
import { paymentModes } from '../../constants';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { Toast } from 'primereact/toast';
import { DownloadIcon } from '../svgIcons';
import BulkFeeReciepts from '../financeManagement/bulkFeeReciepts';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import ClassetCalendar from '../../classetComponents/classetCalender';
import e from '@toast-ui/react-image-editor';
const dateRangeOptions = [
  { value: 1, label: "Today" },
  { value: 2, label: "Yesterday" },
  { value: 3, label: "This Week" },
  { value: 4, label: "Last Week" },
  { value: 5, label: "This Month" },
  { value: 6, label: "Last 3 Months" },
  { value: 7, label: "Academic Session" },
 // { value: 8, label: "Custom" },
]


class FeeCollectionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      totalRecords: 0,
      lazyParams: BasicLazyParams,
      fullFeeCollectionReport: [],
      filteredFeeCollectionReport: [],
      feeCollectionReport: [],
    };

    this.service = new Service();
  }

  async componentDidMount() {
    this.props.getBranchesLatest(true);
    await this.getFeeCollectionReportInfo();
  }

  onPage = (event) => {
    this.setState((prevState) => ({
      lazyParams: {
        ...prevState.lazyParams,
        ...event
      }
    }), this.updatePaginatedData);
  };

  async getFeeCollectionReportInfo() {
    // Simulate fetching all data at once
    let fullData = await this.service.post(`${baseUrlAdmin}/financeReports/feeCollectReport`, {
      academicYear: localStorage.getItem('userAcademicYear'),
      branchId: this.state.branchId ? [this.state.branchId] : this.props.branchData?.map(item => item.key),
      paymentMode: this.state.paymentMode,
      dateRangeType: this.state.dateRangeType,
    }, true);

    if (fullData.status && fullData?.res?.status) {
      this.setState({
        fullFeeCollectionReport: fullData?.res?.data,
        totalRecords: fullData?.res?.totalRecords,
        isLoading: false,
      }, this.updatePaginatedData);
    } else {
      this.setState({ isLoading: false });
    }
  }

  filterByDateRange = () => {
    const { dateRangeType, fullFeeCollectionReport } = this.state;

    const today = moment();
    let filteredData = fullFeeCollectionReport;

    switch (dateRangeType) {
      case 1: // Today
        filteredData = fullFeeCollectionReport.filter(item =>
          moment(String(item.receiptDate)).isSame(today, 'day')
        );
        break;
      case 2: // Yesterday  
        filteredData = fullFeeCollectionReport.filter(item =>
          moment(String(item.receiptDate)).isSame(today.clone().subtract(1, 'day'), 'day')
        );
        break;
      case 3: // This Week
        filteredData = fullFeeCollectionReport.filter(item =>
          moment(String(item.receiptDate)).isSame(today, 'week')
        );
        break;
      case 4: // Last Week
        filteredData = fullFeeCollectionReport.filter(item =>
          moment(String(item.receiptDate)).isSame(today.clone().subtract(1, 'week'), 'week')
        );
        break;
      case 5: // This Month
        filteredData = fullFeeCollectionReport.filter(item =>
          moment(String(item.receiptDate)).isSame(today, 'month')
        );
        break;
      case 6: // Last 3 Months
        filteredData = fullFeeCollectionReport.filter(item =>
          moment(String(item.receiptDate)).isAfter(today.clone().subtract(3, 'months'))
        );
        break;
      case 7:
        filteredData = this.state.fullFeeCollectionReport
        break;
      case 8: // Custom
        // Handle custom date range if applicable
        break;
      default:
        break;
    }

    this.setState({
      filteredFeeCollectionReport: filteredData,
      totalRecords: filteredData.length
    }, this.updatePaginatedData);
  };

  updatePaginatedData = () => {
    const { lazyParams, filteredFeeCollectionReport, fullFeeCollectionReport, dateRangeType } = this.state;
    const dataToPaginate = dateRangeType ? filteredFeeCollectionReport : fullFeeCollectionReport;
    const startIndex = lazyParams.first || 0;
    const endIndex = startIndex + lazyParams.rows;
    const paginatedData = dataToPaginate.slice(startIndex, endIndex);
    this.setState({ feeCollectionReport: paginatedData });
  };

  render() {
    return (
      <>
        <div className='card'>
          <div className="flex">
            <div className="col-4">
              <p className="add-vehicle-field-label">
                Branch
              </p>
              <div className="flex justify-content-start  align-items-center">
                <ClassetDropdown
                  optionLabel="name"
                  optionValue="key"
                  value={this.state.branchId}
                  options={this.props.branchData}
                  onChange={(e) =>
                    this.setState(
                      {
                        branchId: e.target.value
                      }, () => this.getFeeCollectionReportInfo()
                    )
                  }
                  width={"25vw"}
                  height={"48px"}
                  placeholder={
                    <div className="flex justify-content-start  align-items-center">
                      <span className="">Select Branch</span>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="col-4">
              <label className="add-vehicle-field-label">
                Payment Mode
              </label>
              <div className="flex justify-content-start  align-items-center">
                <ClassetMultiSelect
                  id="paymentMode"
                  width={"25vw"}
                  height={"48px"}
                  options={paymentModes}
                  value={this.state.paymentMode}
                  onChange={(e) => this.setState({ paymentMode: e.target.value },)}
                  placeholder={
                    <div className="flex justify-content-start  align-items-center">
                      <span className="">Select Mode</span>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="col-4">
              <label className="add-vehicle-field-label">Date Range</label>
              <div className="flex justify-content-start  align-items-center">
                <ClassetDropdown
                  width={"25vw"}
                  height={"48px"}
                  options={dateRangeOptions}
                  value={this.state.dateRangeType}
                  onChange={(e) => {
                    this.setState({ dateRangeType: e.target.value, startDate: null, endDate: null }, this.filterByDateRange);
                  }}
                  style={{ border: '10px solid #E6E8EA', }}
                  placeholder={(
                    <div className="flex justify-content-start  align-items-center">
                      <span className=''>Select Range</span>
                    </div>
                  )}
                />

              </div>
            </div>

          </div>
          <div className="flex justify-content-between">

            <div className="flex order-0 gap-5">
              {this.state.dateRangeType == 8 &&
                <>
                  <div className="col-6">
                    <label className='label'>From Date</label>
                    <ClassetCalendar
                      calendarMode="single"
                      className="text-xl"
                      width={"25vw"}
                      height={"48px"}
                      value={this.state.startDate}
                      placeholder="Select From Date"
                      onChange={(e) => {
                        const selectedStartDate = e.value;
                        this.setState({ startDate: selectedStartDate }, () => {
                          if (this.state.endDate && moment(this.state.endDate).isBefore(selectedStartDate)) {
                            this.setState({ endDate: null });
                          }
                          if (this.state.startDate && this.state.endDate) {
                            this.getFeeCollectionReportInfo();
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label className='label'>To Date</label>
                    <ClassetCalendar
                      calendarMode="single"
                      className="text-xl"
                      width={"25vw"}
                      height={"48px"}
                      value={this.state.endDate}
                      placeholder="Select To Date"
                      onChange={(e) => {
                        const selectedEndDate = e.value;
                        if (!this.state.startDate) {
                          this.toast.show({
                            severity: 'warn',
                            summary: 'Warning',
                            detail: 'Please select a start date first',
                            life: 3000
                          });
                          return;
                        }
                        if (moment(selectedEndDate).isBefore(this.state.startDate)) {
                          this.toast.show({
                            severity: 'warn',
                            summary: 'Warning',
                            detail: "End date cannot be earlier than start date.",
                            life: 3000
                          });
                          return;
                        }
                        // Only update state if validation passes
                        if (this.state.startDate && selectedEndDate) {
                          this.setState({ endDate: selectedEndDate }, () => {
                            this.getFeeCollectionReportInfo();
                          });
                        }
                      }}
                    />
                  </div>
                </>
              }
            </div>

            <div className='flex gap-2 order-1 ml-auto mt-5'>
              <Button label="Download Report" style={{ width: "137px", height: "40px", padding: "10px", borderRadius: "10px", border: '1px solid #E6E8EA', backgroundColor: "#F4F5F6", color: "#000", }} />
              <Button label="View Analysis" className="p-button-primary"
                style={{ width: "137px", height: "40px", padding: "10px", borderRadius: "10px", border: '1px solid #E6E8EA', backgroundColor: "#076EFF", color: "#fff", }} />
            </div>
          </div>
        </div>

        <div>
          <DataTable
            value={this.state.feeCollectionReport}
            dataKey="id"
            paginator
            lazy
            responsiveLayout="scroll"
            className="dataTableValuesText"
            showGridlines
            columnResizeMode="expand"
            resizableColumns
            onPage={this.onPage}
            first={this.state.lazyParams.first}
            last={this.state.totalRecords}
            rows={this.state.lazyParams.rows}
            totalRecords={this.state.totalRecords}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={this.state.globalFilter}
          >
            <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Student Id" alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.username.slice(3)}
                  </p>
                )
              }} />
            <Column style={{ width: "15rem", height: "6rem" }} filterField="firstName" field="" headerClassName="text-color-secondary" bodyClassName='text-center' header="Student Name" alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.firstName + " " + rowData?.lastName}
                  </p>
                )
              }}
            />
            <Column style={{ width: "15rem", height: "6rem" }} filterField="className" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Class-Section" alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.className + "-" + rowData?.sectionName}
                  </p>
                )
              }} />
            <Column style={{ width: "15rem", height: "6rem" }} filterField="receiptNo" field="receiptNo" headerClassName="text-color-secondary" bodyClassName='text-center' header="Receipt No" alignHeader={"center"} />
            <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Fee Paid" alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.feePaidDetails?.amountPaying || 0}
                  </p>
                )
              }}
            />
            <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Fee Type" alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.feePaidDetails?.feeType}
                  </p>
                )
              }}
            />

            <Column style={{ width: "15rem", height: "6rem" }} filterField="username" field="username" headerClassName="text-color-secondary" bodyClassName='text-center' header="Payment Mode" alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.paymentMode}
                  </p>
                )
              }}
            />
            <Column
              style={{ width: "15rem", height: "6rem" }}
              filterField="username"
              field="username"
              headerClassName="text-color-secondary"
              bodyClassName='text-center'
              header="Paid Date"
              alignHeader={"center"}
              body={(rowData, { rowIndex }) => {
                return (
                  <p>
                    {rowData?.receiptDate ? moment(rowData.receiptDate).format('DD/MM/YYYY') : '-'}
                  </p>
                )
              }}
            />
            <Column
              alignHeader={'center'}
              bodyClassName="text-center"
              header="Actions"
              body={(rowData, { rowIndex }) => {
                return (
                  <div className="flex justify-content-center">
                    <div className='mx-2'
                      onClick={() => this.setState({ isBulkfeeReceipts: true, receiptInfo: [{ ...rowData, feePaidDetails: [rowData?.feePaidDetails] }] })}
                    >
                      <DownloadIcon width={20} height={20} color={'#000000'} />
                    </div>
                  </div>
                );
              }}

            ></Column>
          </DataTable>
          {this.state.isBulkfeeReceipts && (
            <BulkFeeReciepts
              receiptData={this.state?.receiptInfo}
              hideFeeReciept={() => {
                this.setState({
                  isBulkfeeReceipts: false,
                  receiptInfo: []
                });
              }}
            />
          )}
        </div>
        {this.state.isLoading && <LoadingComponent />}
        <Toast ref={(el) => this.toast = el} position="bottom-right" />

      </>

    )
  }
}



const mapStateToProps = (state) => ({
  isLoading: state.boardsData.loading,
  isBranchLoading: state.branchDataLatest.isLoading,
  branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
  branchData:
    (state.branchDataLatest &&
      state.branchDataLatest.data &&
      state.branchDataLatest.data.data.filter((each) => {
        if (each.level == 1) {
          return { ...each };
        }
      })) ||
    [],
  _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._idF
});
export default connect(mapStateToProps, {
  getBranchesLatest
})(Authentication(withRouter(FeeCollectionReport)));

